/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, ArrowDropDown, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	ClickAwayListener,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import LoadingModal from "../../../modal/LoadingModal";
import ReminderModal from "../../../modal/ReminderForUserModal";
import KycApprovalModal from "../../../modal/UserKycApprovalModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				background: "rgba(244, 67, 54, 0.5)",
				color: "#FFFFFF",
				borderRadius: "0.5rem",
				fontSize: "0.875rem",
			}
		: id === "awaiting_documents"
			? {
					background: "rgba(33, 150, 243, 0.5)",
					color: "#FFFFFF",
					padding: "0.5rem",
					borderRadius: "0.5rem",
					fontSize: "0.875rem",
				}
			: id === "kyc_pending"
				? {
						background: "rgba(237, 108, 2, 0.5)",
						color: "rgb(156, 73, 5)",
						borderRadius: "0.5rem",
						fontSize: "0.875rem",
					}
				: id === "kyc_completed"
					? {
							background: "rgba(76, 175, 80, 0.5)",
							color: "rgb(22, 104, 25)",
							padding: "0.5rem",
							borderRadius: "0.5rem",
							fontSize: "0.875rem",
						}
					: {
							backgroundColor: "#FFFFFF",
						},
);
const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));
const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const UserKycDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [kycOpenModal, setKycOpenMoadal] = React.useState(false);

	const [kycdata, setKycData] = React.useState<any>([]);

	const [reasonModal, setReasonModal] = React.useState(false);

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [editStatusRowId, setEditStatusRowId] = React.useState<string | null>(null);

	const [editAssignedRowId, setEditAssignedRowId] = React.useState<string | null>(null);

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/users-kyc-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setAccessLevel(res.payload.accessLevel);
			setListOfModerators(res.payload.list_of_moderators);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the status

  */

	const handleChange = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/kyc_status", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				status: event.target.value,
				userId: cellValues.row.userId,
			}),
		});

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/kyc-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				userId: cellValues.row.userId,
			}),
		});

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "kyc-table/user/kyc-delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				userId: cellValues.row.userId,
			}),
		});

		setOpenMoadal(false);
	};

	const handleClose = () => {
		setEditStatusRowId("");
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Untouched",
			value: "untouched",
			backgroundColor: "rgba(244, 67, 54, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 2,
			label: "Awaiting Documents",
			value: "awaiting_documents",
			backgroundColor: "rgba(33, 150, 243, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 3,
			label: "KYC Pending",
			value: "kyc_pending",
			backgroundColor: "rgba(237, 108, 2, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 4,
			label: "KYC Completed",
			value: "kyc_completed",
			backgroundColor: "rgba(76, 175, 80, 0.5)",
			color: "#FFFFFF",
		},
	];

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "username",
			headerName: "Username",
			width: 200,
		},
		{
			field: "userId",
			headerName: "User ID",
			width: 200,
		},

		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},

		{
			field: "status",
			headerName: "Status",
			width: 150,
			editable: true,
			valueOptions: menuItems,
			valueFormatter: (params: any) => {
				if (!params) {
					return "";
				}
				return params.value === "untouched"
					? "Untouched"
					: params.value === "awaiting_documents"
						? "Awaiting Documents"
						: params.value === "kyc_pending"
							? "KYC Pending"
							: params.value === "kyc_completed"
								? "KYC Completed"
								: "";
			},
			renderCell: (cellValues: any) => {
				const status_option = menuItems.find((option) => option.value === cellValues.value);
				const isEditing = editStatusRowId === cellValues.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditStatusRowId(null)}>
						<Select
							value={status_option ? status_option.label : ""}
							onChange={(e) => {
								const selected_status_option = menuItems.find((option) => option.label === e.target.value);
								if (selected_status_option) {
									handleChange(
										{ target: { value: selected_status_option.value, name: "" } } as SelectChangeEvent,
										cellValues,
									);
									setEditStatusRowId("");
								}
							}}
							variant="standard"
							onClose={handleClose}
							fullWidth
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: status_option ? status_option.backgroundColor : "#FFFFFF",
									color: status_option ? status_option.color : "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
								hover: {
									background: status_option ? status_option.backgroundColor : "#FFFFFF",
									color: status_option ? status_option.color : "#000000",
								},
							}}
						>
							{menuItems.map((option) => (
								<MenuItem
									key={option.value}
									value={option.label}
									sx={{
										background: option.backgroundColor,
										color: option.color,
										padding: "0.5rem 0.5rem 0.5rem 0.5rem",
										borderRadius: "0.5rem",
										fontSize: "0.875rem",
										margin: "0.25rem 0rem 0.25rem 0rem",
										display: "flex",
										justifyContent: "center",
										gap: "0.5rem",
										alignItems: "center",
										"&.Mui-selected": {
											background: option.backgroundColor,
											color: option.color,
										},
										"&.Mui-selected:hover": {
											background: `${option.backgroundColor} !important`,
											color: `${option.color} !important`,
										},
										"&:hover": {
											background: option.backgroundColor,
											color: option.color,
										},
									}}
								>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<ChipData
						id={cellValues.value}
						label={status_option ? status_option.label : ""}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
						sx={{
							width: "100%",
							boxShadow: " 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
							borderRadius: "0.5rem",
							"&:hover": {
								background: status_option ? status_option.backgroundColor : "#FFFFFF",
								color: status_option ? status_option.color : "#000000",
							},
						}}
						onClick={() => {
							setEditStatusRowId(cellValues.id);
						}}
					/>
				);
			},
		},

		{
			field: "assigned_to",
			headerName: "Assigned",
			width: 300,
			editable: true,
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				const isEditing = editAssignedRowId === cellValues.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditAssignedRowId(null)}>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							fullWidth={true}
							onChange={(event: any) => handleChangeAssigned(event, cellValues)}
							value={data ? data.assigned_to : cellValues.value}
							onClose={() => setEditAssignedRowId(null)}
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
							}}
						>
							{list_of_moderators.map((item: any, index: number) => (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<ActionActions
						sx={{
							padding: "0.8rem",
						}}
					>
						{data && data.assigned_to?.length > 0 ? (
							data.assigned_to.map((item: any, index: number) => (
								<Chip
									id={item._id}
									key={index}
									label={item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"}
									variant="outlined"
									onDelete={() => {
										deleteAssigned(item._id, cellValues);
									}}
									color="primary"
								/>
							))
						) : (
							<Chip
								id="assign"
								label="Assign to"
								variant="outlined"
								onDelete={() => {}}
								deleteIcon={<Add />}
								color="primary"
								onClick={() => setEditAssignedRowId(cellValues.id)}
							/>
						)}
					</ActionActions>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={data ? data.assigned_to : cellValues.value}
					>
						{list_of_moderators.map((item: any, index: number) => (
							<MenuItem
								value={item._id}
								key={index}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}
							>
								{item.first_name + " " + item.last_name}
							</MenuItem>
						))}
					</Select>
				);
			},
		},

		{
			field: "action",
			headerName: "Action",
			width: 250,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Link
							href={"tel:+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<div
							onClick={() => {
								kycFunction(cellValues.row);
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</div>
						{cellValues?.row?.action?.kyc_status === "awaiting_documents" ? (
							<Button
								variant="contained"
								sx={{
									padding: "0.5rem 1rem",
									textTransform: "none",
									borderRadius: "1rem",
									fontSize: "0.75rem",
									background: "primary",
									color: "#fff",
								}}
								onClick={() => {
									setReasonModal(true);
									setKycData(cellValues.row);
								}}
							>
								remind
							</Button>
						) : null}
					</ActionActions>
				);
			},
		},
	];
	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	const handleClick = () => {};

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			KYC
		</Typography>,
	];

	const kycFunction = async (cellValues: any) => {
		setKycData(cellValues);
		setKycOpenMoadal(true);
	};

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Know Your Customer (KYC)</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}
	return (
		<MainContainer>
			<LoadingModal open={openModal} />
			<ReminderModal
				open={reasonModal}
				userId={kycdata?.id}
				onClose={() => setReasonModal(false)}
				is_business={false}
			/>
			<KycApprovalModal
				open={kycOpenModal}
				data={kycdata}
				onClose={() => setKycOpenMoadal(false)}
				user_id={DashboardData.user_id}
			/>
			<HeadContainer>
				<HeadText>Know Your Customer (KYC)</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("kyc")) || accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							return {
								id: item ? item._id : key,
								userId: item._id,
								profile_type: item.kyc_info?.kyc_profile_type,
								phonenumber: item.phone,
								username: item.first_name ? item.first_name + " " + item.last_name : "No Name",
								status: item.kyc_info?.kyc_status,
								assigned: item.kyc_info ? item.kyc_info.assigned_to : [],
								action: item.kyc_info,
							};
						})}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default UserKycDataTable;

import {
	DeleteShortenedURLResponseInterface,
	ExpandedURLResponseInterface,
	ListAllShortenedURLsResponseInterface,
	ListShortenedURLResponseInterface,
	ShortenedURLResponseInterface,
	URLShortenerField,
} from "../redux-magic/sub-interfaces/url-shortener-interfaces";

export const shortenURL: (
	url: string,
	title?: string,
	keyword?: string,
) => Promise<ShortenedURLResponseInterface> = async (url: string, title?: string, keyword?: string) => {
	return (await (
		await fetch(
			(process.env.URL_SHORTENER_API_URL || "") +
				"?signature=" +
				(process.env.URL_SHORTENER_API_KEY || "") +
				"&action=shorturl&format=json&url=" +
				encodeURIComponent(url) +
				(title ? "&title=" + encodeURIComponent(title || "") : "") +
				(keyword ? "&keyword=" + encodeURIComponent(keyword || "") : ""),
		)
	).json()) as ShortenedURLResponseInterface;
};

export const expandURL: (keyword: string) => Promise<ExpandedURLResponseInterface> = async (keyword: string) => {
	return (await (
		await fetch(
			(process.env.URL_SHORTENER_API_URL || "") +
				"?signature=" +
				(process.env.URL_SHORTENER_API_KEY || "") +
				"&action=expand&format=json&keyword=" +
				encodeURIComponent(keyword),
		)
	).json()) as ExpandedURLResponseInterface;
};

export const listShortenedURLs: (
	skip: number,
	limit: number,
	sort?: {
		field: URLShortenerField;
		order: 1 | -1;
	},
	query?: string,
	select?: Array<URLShortenerField>,
) => Promise<ListShortenedURLResponseInterface> = async (
	skip: number,
	limit: number,
	sort?: {
		field: URLShortenerField;
		order: 1 | -1;
	},
	query?: string,
	select?: Array<URLShortenerField>,
) => {
	return (await (
		await fetch(
			(process.env.URL_SHORTENER_API_URL || "") +
				"?signature=" +
				(process.env.URL_SHORTENER_API_KEY || "") +
				"&action=list&format=json&offset=" +
				skip +
				"&perpage=" +
				limit +
				"&sortby=" +
				(sort?.field || "timestamp") +
				"&sortorder=" +
				(sort ? (sort.order === 1 ? "ASC" : "DESC") : "DESC") +
				(query ? "&query=" + encodeURIComponent(query || "") : "") +
				(select
					? [...new Set(select)].map((field: URLShortenerField, index: number) => "&fields[]=" + field).join("")
					: ""),
		)
	).json()) as ListShortenedURLResponseInterface;
};

export const listAllShortenedURLs: () => Promise<ListAllShortenedURLsResponseInterface> = async () => {
	return (await (
		await fetch(
			(process.env.URL_SHORTENER_API_URL || "") +
				"?signature=" +
				(process.env.URL_SHORTENER_API_KEY || "") +
				"&action=listall&format=json",
		)
	).json()) as ListAllShortenedURLsResponseInterface;
};

export const deleteShortenedURL: (keyword: string) => Promise<DeleteShortenedURLResponseInterface> = async (
	keyword: string,
) => {
	return (await (
		await fetch(
			(process.env.URL_SHORTENER_API_URL || "") +
				"?signature=" +
				(process.env.URL_SHORTENER_API_KEY || "") +
				"&action=delete&format=json&shorturl=" +
				encodeURIComponent(keyword),
		)
	).json()) as DeleteShortenedURLResponseInterface;
};

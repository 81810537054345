import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import { Box, FormControl, MenuItem, Select, styled, Tab, Tabs, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import AnalyticsGraph from "../analyticsGraph";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "1px solid #B05911" : "none",
	textTransform: "capitalize",
	minWidth: "23rem",
	// width: "100%",
	minHeight: "8rem",
	borderRadius: "16px",
	margin: "0rem 1rem 0.5rem 0rem",
	background: "#FFFFFF",
	// "&:hover": {
	// 	background: "#FEEADA",
	// },
}));

const TitleContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
	// height: "100%",
	width: "100%",
	gap: "0.5rem",
	// color: "#000000",
	minWidth: "12rem",
	minHeight: "3rem",
	color: " #B05911",
}));

const TitleMain = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	// boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	// border: "1px solid #B05911",
	borderRadius: "8px",
	color: " #B05911",
}));
const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	textTransform: "capitalize",
	color: " #B05911",
	padding: "0rem 0.5rem 0rem 0.5rem",
}));
const MainTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
	fontSize: "1.2rem",
	fontWeight: 600,
}));
const SubTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
}));
const TitleAndIcon = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	fontSize: "1rem",
	width: "100%",
	color: " #B05911",
	textTransform: "capitalize",
	padding: "0.5rem 0.5rem 0rem 0.5rem",
}));
const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const ContainerForSelect = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	color: " #B05911",
}));

const ContainerFortotal = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	height: "100%",
	width: "100%",
	// gap: "0.5rem",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	color: " #B05911",
}));
const FormControlContainer = styled(FormControl)(({ theme }) => ({
	width: "40%",
	height: "2rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0.5rem 0rem 0rem 0rem",
	border: "none",
}));

const SelectContainer = styled(Select)(({ theme }) => ({
	// width: "100%",
	height: "100%",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	color: " #B05911",
	border: "1px solid #B05911",
	"& .MuiSelect-select": {
		padding: "0rem 0rem 0rem 0rem",
		margin: "0rem 0rem 0rem 0rem",
		border: "none",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));
/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={2}>{children}</Box>}
		</div>
	);
}

const BeegruPointsTab = ({ DashboardData }: { DashboardData: any }) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const [selectedForPointsSpent, setselectedForPointsSpent] = React.useState("week");

	const [selectedForPointsGifted, setselectedForPointsGifted] = React.useState("week");

	const [selectedForPointsPurchased, setselectedForPointsPurchased] = React.useState("week");

	const [pointsSpentData, setPointsSpentData] = React.useState<any>();

	const [pointsGiftedData, setPointsGiftedData] = React.useState<any>();

	const [pointsPurchasedData, setPointsPurchasedData] = React.useState<any>();

	const handleChangeForPointsSpent = async (event: SelectChangeEvent) => {
		setselectedForPointsSpent(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-spent-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsSpentData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-spent-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsSpentData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-spent-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsSpentData(data.payload);
					});
				break;
			default:
				break;
		}
	};

	const handleChangeForPointsGifted = async (event: SelectChangeEvent) => {
		setselectedForPointsGifted(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-gifted-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsGiftedData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-gifted-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsGiftedData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-gifted-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsGiftedData(data.payload);
					});
				break;
			default:
				break;
		}
	};

	const handleChangeForPointsPurchased = async (event: SelectChangeEvent) => {
		setselectedForPointsPurchased(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-purchased-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsPurchasedData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-purchased-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsPurchasedData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/points-purchased-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPointsPurchasedData(data.payload);
					});
				break;
			default:
				break;
		}
	};

	return (
		<React.Fragment>
			<TabsContainer>
				<Tabs
					value={value}
					orientation="horizontal"
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "transparent",
						},
					}}
				>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 0 ? "primary" : "default"}>
									<MainTitle>Points spent</MainTitle>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										<SubTitle>all time</SubTitle>
										<Title>{DashboardData.AnalyticsData?.PointsSpent?.total_points_spent_to_date}</Title>
									</ContainerFortotal>
									<ContainerFortotal>
										<SubTitle>
											{selectedForPointsSpent === "week"
												? "this week"
												: selectedForPointsSpent === "month"
													? "this month"
													: selectedForPointsSpent === "year"
														? "this year"
														: selectedForPointsSpent === "yesterday"
															? "today"
															: null}
										</SubTitle>
										<Title>
											{selectedForPointsSpent === "week"
												? DashboardData.AnalyticsData?.PointsSpent?.points_spent_this_week
												: selectedForPointsSpent === "month"
													? pointsSpentData?.points_spent_this_month
													: selectedForPointsSpent === "year"
														? pointsSpentData?.points_spent_this_year
														: selectedForPointsSpent === "yesterday"
															? pointsSpentData?.points_spent_today
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>
								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForPointsSpent === "week" ? (
											DashboardData.AnalyticsData?.PointsSpent.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsSpent === "month" ? (
											pointsSpentData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsSpent === "year" ? (
											pointsSpentData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsSpent === "yesterday" ? (
											pointsSpentData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForPointsSpent === "week"
											? DashboardData.AnalyticsData?.PointsSpent?.percentage_change_display_string
											: selectedForPointsSpent === "month"
												? pointsSpentData?.percentage_change_display_string
												: selectedForPointsSpent === "year"
													? pointsSpentData?.percentage_change_display_string
													: selectedForPointsSpent === "yesterday"
														? pointsSpentData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											aria-label="points spent"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForPointsSpent}
											onChange={handleChangeForPointsSpent}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={0}
						tabColor={value === 0 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 1 ? "primary" : "default"}>
									<MainTitle>Points gifted</MainTitle>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										<SubTitle>all time</SubTitle>
										<Title>{DashboardData.AnalyticsData?.PointsGifted.total_points_spent_to_date}</Title>
									</ContainerFortotal>
									<ContainerFortotal>
										<SubTitle>
											{selectedForPointsGifted === "week"
												? "this week"
												: selectedForPointsGifted === "month"
													? "this month"
													: selectedForPointsGifted === "year"
														? "this year"
														: selectedForPointsGifted === "yesterday"
															? "today"
															: null}
										</SubTitle>
										<Title>
											{selectedForPointsGifted === "week"
												? DashboardData.AnalyticsData?.PointsGifted?.points_spent_this_week.toFixed(2)
												: selectedForPointsGifted === "month"
													? pointsGiftedData?.points_gifted_this_month
													: selectedForPointsGifted === "year"
														? pointsGiftedData?.points_gifted_this_year
														: selectedForPointsGifted === "yesterday"
															? pointsGiftedData?.points_gifted_today
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>
								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForPointsGifted === "week" ? (
											DashboardData.AnalyticsData?.PointsGifted.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsGifted === "month" ? (
											pointsGiftedData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsGifted === "year" ? (
											pointsGiftedData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsGifted === "yesterday" ? (
											pointsGiftedData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForPointsGifted === "week"
											? DashboardData.AnalyticsData?.PointsGifted?.percentage_change_display_string
											: selectedForPointsGifted === "month"
												? pointsGiftedData?.percentage_change_display_string
												: selectedForPointsGifted === "year"
													? pointsGiftedData?.percentage_change_display_string
													: selectedForPointsGifted === "yesterday"
														? pointsGiftedData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForPointsGifted}
											onChange={handleChangeForPointsGifted}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={1}
						tabColor={value === 1 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 2 ? "primary" : "default"}>
									<MainTitle>Points Purchased</MainTitle>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										<SubTitle>all time</SubTitle>
										<Title>{DashboardData.AnalyticsData?.PointsPurchased.total_points_purchased_to_date}</Title>
									</ContainerFortotal>
									<ContainerFortotal>
										<SubTitle>
											{selectedForPointsPurchased === "week"
												? "this week"
												: selectedForPointsPurchased === "month"
													? "this month"
													: selectedForPointsPurchased === "year"
														? "this year"
														: selectedForPointsPurchased === "yesterday"
															? "today"
															: null}
										</SubTitle>
										<Title>
											{selectedForPointsPurchased === "week"
												? DashboardData.AnalyticsData?.PointsPurchased?.points_purchased_this_week
												: selectedForPointsPurchased === "month"
													? pointsPurchasedData?.points_purchased_this_month
													: selectedForPointsPurchased === "year"
														? pointsPurchasedData?.points_purchased_this_year
														: selectedForPointsPurchased === "yesterday"
															? pointsPurchasedData?.points_purchased_today
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>

								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForPointsPurchased === "week" ? (
											DashboardData.AnalyticsData?.PointsPurchased.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsPurchased === "month" ? (
											pointsPurchasedData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsPurchased === "year" ? (
											pointsPurchasedData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPointsPurchased === "yesterday" ? (
											pointsPurchasedData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForPointsPurchased === "week"
											? DashboardData.AnalyticsData?.PointsPurchased.percentage_change_display_string
											: selectedForPointsPurchased === "month"
												? pointsPurchasedData?.percentage_change_display_string
												: selectedForPointsPurchased === "year"
													? pointsPurchasedData?.percentage_change_display_string
													: selectedForPointsPurchased === "yesterday"
														? pointsPurchasedData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											aria-label="points purchased"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForPointsPurchased}
											onChange={handleChangeForPointsPurchased}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={2}
						tabColor={value === 2 ? "primary" : "default"}
					/>
				</Tabs>
			</TabsContainer>
			<TabContentContainer>
				<TabPanel
					value={value}
					index={0}
				>
					{selectedForPointsSpent === "month" ? (
						<AnalyticsGraph
							title="Points Spent Monthly"
							graphData={pointsSpentData?.graph}
						/>
					) : selectedForPointsSpent === "year" ? (
						<AnalyticsGraph
							title="Points Spent Yearly"
							graphData={pointsSpentData?.graph}
						/>
					) : selectedForPointsSpent === "week" ? (
						<AnalyticsGraph
							title="Points Spent Weekly"
							graphData={DashboardData.AnalyticsData?.PointsSpent.graph}
						/>
					) : (
						<AnalyticsGraph
							title="Points Spent Hourly"
							graphData={pointsSpentData?.graph}
						/>
					)}
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
				>
					{selectedForPointsGifted === "month" ? (
						<AnalyticsGraph
							title="Points Gifted Monthly"
							graphData={pointsGiftedData?.graph}
						/>
					) : selectedForPointsGifted === "year" ? (
						<AnalyticsGraph
							title="Points Gifted Yearly"
							graphData={pointsGiftedData?.graph}
						/>
					) : selectedForPointsGifted === "week" ? (
						<AnalyticsGraph
							title="Points Gifted Weekly"
							graphData={DashboardData.AnalyticsData?.PointsGifted.graph}
						/>
					) : (
						<AnalyticsGraph
							title="Points Gifted Hourly"
							graphData={pointsGiftedData?.graph}
						/>
					)}
				</TabPanel>
				<TabPanel
					value={value}
					index={2}
				>
					{selectedForPointsPurchased === "month" ? (
						<AnalyticsGraph
							title="Points Purchased Monthly"
							graphData={pointsPurchasedData?.graph}
						/>
					) : selectedForPointsPurchased === "year" ? (
						<AnalyticsGraph
							title="Points Purchased Yearly"
							graphData={pointsPurchasedData?.graph}
						/>
					) : selectedForPointsPurchased === "week" ? (
						<AnalyticsGraph
							title="Points Purchased Weekly"
							graphData={DashboardData.AnalyticsData?.PointsPurchased.graph}
						/>
					) : (
						<AnalyticsGraph
							title="Points Purchased Hourly"
							graphData={pointsPurchasedData?.graph}
						/>
					)}
				</TabPanel>
			</TabContentContainer>
		</React.Fragment>
	);
};

export default BeegruPointsTab;

/* Common Imports */

import { styled } from "@mui/system";
import React from "react";
// import { merge } from "lodash";

/* Component Imports */

import { Typography } from "@mui/material";
import ReactApexChart from ".";
import BaseOptionChart from "./cssStylingChart";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	// margin: "0rem 0rem 1rem 0rem",
	borderRadius: "0.5rem",
	".apexcharts-tooltip": {
		color: "#000000",
	},
	".apexcharts-text ": {
		fill: theme.palette.mode == "dark" ? "#FFFFFF" : "#000000",
	},
	// padding: "1rem 0rem 1rem 0rem",
	// border: "1px",
	// boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	backgroundColor: "#ffffff",
	// margin: "1rem 0rem 1rem 0rem",
}));

const Title = styled(Typography)(({ theme }) => ({
	padding: "1rem",
	fontSize: "1rem",
	fontWeight: "500",
	lineHeight: "123.5%",
	textTransform: "uppercase",
}));

const AnalyticsGraph = (props: any) => {
	/* x-axis data */
	// const chartOptions = merge(BaseOptionChart(), {
	//   // xaxis: {
	//   //   type: "category",
	//   // },
	// });

	const week: Array<string> = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

	const month: Array<string> = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

	/* series data */

	const seriesData = props.graphData?.y.map((views: number, index: number) => ({
		x: props.title.includes("Weekly")
			? week[new Date(props.graphData.x[index]).getDay()] +
				" " +
				new Date(props.graphData.x[index]).getDate().toString() +
				" " +
				month[new Date(props.graphData.x[index]).getMonth()]
			: props.title.includes("Monthly")
				? new Date(props.graphData.x[index]).getDate().toString() +
					" " +
					month[new Date(props.graphData.x[index]).getMonth()]
				: props.title.includes("Active")
					? week[new Date(props.graphData.x[index]).getDay()] +
						" " +
						new Date(props.graphData.x[index]).getDate().toString() +
						" " +
						month[new Date(props.graphData.x[index]).getMonth()]
					: props.graphData.x[index],

		y: views,
	}));

	const series = [
		{
			name: props.title,
			data: seriesData,
		},
	];

	return (
		<React.Fragment>
			<MainContainer>
				<Title variant="h6">{props.title}</Title>
				<ReactApexChart
					type="area"
					series={series}
					options={BaseOptionChart()}
					height={450}
					width="100%"
				/>
			</MainContainer>
		</React.Fragment>
	);
};

export default AnalyticsGraph;

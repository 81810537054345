import CloseIcon from "@mui/icons-material/Close";
import { Button, DialogActions, DialogTitle, IconButton, TextField, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as React from "react";

import { useRouter } from "next/router";

const MainContainer = styled(DialogContentText)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
}));

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
	position: "absolute",
	zIndex: 1,
	top: 0,
	left: 0,
	height: "4rem",
	width: "100%",
}));

const SaveButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const GrandAccessModal = ({ open, onClose }: { open: any; onClose: any }) => {
	const [phone, setPhone] = React.useState("");

	const router = React.useRef(useRouter()).current;

	const submit = async () => {
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/grant-user", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
			},
			body: JSON.stringify({ phone }),
		});

		onClose();
	};
	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				maxWidth="xs"
				fullWidth={true}
			>
				<DialogTitle>Enter phone number</DialogTitle>
				<Container>
					<Close
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.5rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</Container>
				<DialogContent>
					<DialogContentText sx={{
						color: "warning.main",
						fontSize: "0.875rem",
					}}>
						Please note that the user must have a valid account with us.
						<br />
					</DialogContentText>
					<MainContainer>
						<TextField
							id="outlined-basic"
							label="Phone Number"
							placeholder="Enter phone number to grant access"
							fullWidth={true}
							variant="outlined"
							value={phone}
							onChange={(event) => {
								if (isNaN(Number(event.target.value))) {
									return;
								}
								setPhone(event.target.value);
							}}
						/>
					</MainContainer>
				</DialogContent>
				<DialogActions>
					<SaveButton
						variant="contained"
						autoFocus
						onClick={() => {
							submit();
						}}
					>
						Done
					</SaveButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default GrandAccessModal;

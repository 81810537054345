import { ApexOptions } from "apexcharts";
// @mui

// ----------------------------------------------------------------------

export default function BaseOptionChart(): ApexOptions {
	// const theme = useTheme();

	return {
		chart: {
			toolbar: { show: false },
			zoom: { enabled: false },
			animations: {
				enabled: true,
				speed: 800,
			},
		},

		// Fill
		fill: {
			opacity: 1,
			gradient: {
				type: "vertical",
				shadeIntensity: 0,
				opacityFrom: 0.4,
				opacityTo: 0,
				stops: [0, 100],
			},
			colors: ["#FC8019"],
		},
		theme: {
			mode: "light",
			palette: "palette1",
			monochrome: {
				enabled: false,
				color: "#fff",
				shadeTo: "light",
				shadeIntensity: 0.65,
			},
		},
		markers: {
			size: [6, 7],
			colors: ["#FC8019"],
			strokeColors: '"#FC8019"',
			strokeWidth: 2,
			strokeOpacity: 0.9,
			strokeDashArray: 0,
			fillOpacity: 1,
			discrete: [],
			shape: "circle",
			// radius: 2,
			offsetX: 0,
			offsetY: 0,
		},

		// Datalabels
		dataLabels: {
			enabled: true,
		},
		colors: ["#FC8019"],
	};
}

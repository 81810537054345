import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle, IconButton, Typography, styled } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import React from "react";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));



const QueryParametersModal = ({ open, onClose, queryParams }: { open: any; onClose: any; queryParams: Array<any> }) => {
	const [columns, setColumns] = React.useState<any>([]);
	const [rows, setRows] = React.useState<any>([]);

	/*

    & Make request to userstack API

    */

	// const response: Response = await fetch(requestURL);

	React.useEffect(() => {
		if (queryParams) {
			setRows(queryParams);
			setColumns([
				{ field: "key", headerName: "Key", width: 200 },
				{ field: "value", headerName: "Value", width: 200 },
			]);
		}
	}, [queryParams]);

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography variant="h6">
						Query Parameters
					</Typography>
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<Box sx={{ height: "20", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						// rows={}
						rows={rows}
						columns={columns}
						getRowId={(row) => row._id}
						hideFooter={true}
						sx={{
							backgroundColor: "#FFFFFF",
							"& > .MuiDataGrid-columnSeparator": {
								display: "none",
							},
							"& .MuiDataGrid-cell": {
								borderBottom: "none",
							},
						}}
					/>
				</Box>
			</Dialog>
		</div>
	);
};

export default QueryParametersModal;

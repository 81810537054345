import Dialog from "@mui/material/Dialog";
import React from "react";

import {
	Autocomplete,
	Box,
	Button,
	Chip,
	CircularProgress,
	CircularProgressProps,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	TextField,
	Typography,
	styled,
} from "@mui/material";

import { Cancel, Check, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ObjectId } from "bson";
import dayjs, { Dayjs } from "dayjs";
import { useRouter } from "next/router";
import uploadFiles from "../../lib/uploadFiles";
import validateFiles from "../../lib/validateFiles";

const Image = styled("img")(({ theme }) => ({
	objectFit: "contain",
	width: "100%",
	height: "12.5rem",
}));

const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1.5rem 0rem 1rem 0rem",
}));

const MessageBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	boxShadow: "none",
	// border: "1px solid #B05911",
	// wordSpacing: "0.5rem",
}));

const MessageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-end",
	// boxShadow: " 0px 1px 3px rgba(0, 0, 0, 0.12)",
	width: "100%",
	padding: "1rem",
	[theme.breakpoints.only("sm")]: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	// wordSpacing: "0.5rem",
}));

interface FileAndMore {
	file: File;
	mime_type: string;
}

interface UploadProgress {
	fileId: ObjectId | null;
	percentage: number;
	stage: string;
	isMultiple: boolean;
}

const ProgressContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const DocumentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	width: "100%",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		gap: "0.5rem",
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		gap: "0.5rem",
	},
}));

const StatusContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
}));

const UploadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	border: theme.palette.mode == "dark" ? "2px dashed #FFFFFF" : "2px dashed #00000061",
	borderRadius: "8px",
	background: theme.palette.background.paper,
	backgroundColor: "#FEEADA",

	height: "12.5rem",
	// [theme.breakpoints.down("md")]: {
	//   display: "none",
	// },
}));

const InputContent = styled("input")(({ theme }) => ({
	display: "none",
	minWidth: "50%",
	maxWidth: "50%",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	lineHeight: "2rem",
	fontSize: "1.3rem",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
	color: "#B05911",
	fontWeight: 500,
	textTransform: "uppercase",
	fontSize: "0.875rem",
}));

const MenuItemColumn = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
}));

const FrontDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "0rem",
	width: "100%",
}));

const CreateBlogAuthorPersonaModal = ({
	open,
	onClose,
	data,
	user_id,
	setQueryOptions,
}: {
	open: any;
	onClose: any;
	data: any;
	user_id: any;
	setQueryOptions: any;
}) => {
	const router = React.useRef(useRouter()).current;

	const [blogId, setBlogId] = React.useState("");

	const [tags, setTags] = React.useState<string[]>([]);

	const [tagInput, setTagInput] = React.useState("");

	const [createdAt, setCreatedAt] = React.useState<Dayjs | null>(null);

	const [updatedAt, setUpdatedAt] = React.useState<Dayjs | null>(null);

	const [fileName, setFileName] = React.useState("");

	const [description, setDescription] = React.useState("");

	const [linkedUsers, setLinkedUsers] = React.useState<string[]>([]);

	const [linkedUsersOptions, setLinkedUsersOptions] = React.useState<any[]>([]);

	const [percentage, setPercentage] = React.useState<Array<number>>([0]);

	const [stage, setStage] = React.useState<Array<string>>(["NOT_STARTED"]);

	const [loading, setLoading] = React.useState<boolean>(false);

	const [load, setLoad] = React.useState<boolean>(false);

	const [selectedFiles, setSelectedFiles] = React.useState<Array<FileAndMore>>([]);

	const [selectedFilePreview, setSelectedFilePreview] = React.useState<Array<string>>([]);

	const [fileId, setFileId] = React.useState<string>("");

	const [mime_type, setMime_type] = React.useState<string>("");

	const BlogInput = React.useRef<HTMLInputElement>(null);

	const usertext = React.useRef<HTMLInputElement>(null);

	const [percentageForProfile, setPercentageForProfile] = React.useState<Array<number>>([0]);

	const [stageForProfile, setStageForProfile] = React.useState<Array<string>>(["NOT_STARTED"]);

	const [selectedFilesForProfile, setSelectedFilesForProfile] = React.useState<Array<FileAndMore>>([]);

	const [selectedFilePreviewForProfile, setSelectedFilePreviewForProfile] = React.useState<Array<string>>([]);

	const [arrayFilenames, setArrayFileNames] = React.useState<string[]>([]);

	const [sessionId, setSessionId] = React.useState<string>("");

	const [cover_image_alt_text, setCoverImageAltText] = React.useState<string>("");

	const [first_name, setFirstName] = React.useState<string>("");

	const [last_name, setLastName] = React.useState<string>("");

	const [profileImageAltText, setProfileImageAltText] = React.useState<string>("");

	const [profileImageFileId, setProfileImageFileId] = React.useState<string>("");

	const [ownedBy, setOwnedBy] = React.useState<string>("");

	React.useEffect(() => {
		if (data) {
			setBlogId(data._id);

			setLinkedUsers([data.owned_by]);
			setFirstName(data.first_name);
			setLastName(data.last_name);
			setDescription(data.description);
			setTags(data.tags);
			setCreatedAt(dayjs(data.createdOn));
			setUpdatedAt(dayjs());
			setFileId(data.cover_image_file_id ? data.cover_image_file_id.toString() : "");
			setProfileImageFileId(data.profile_picture_file_id ? data.profile_picture_file_id.toString() : "");
			setProfileImageAltText(data.profile_picture_alt_text);
			setCoverImageAltText(data.cover_image_alt_text);
			setOwnedBy(data.owned_by ? data.owned_by.toString() : "");
		}

	}, [data]);

	React.useEffect(() => {
		if (!open) return;

		const fetchLinkedData = async () => {
			try {
				setLoading(true);
				const usersResponse = await fetch(process.env.PRODUCTION_API_URL + "blogs-author/fetch-users", {
					method: "POST",
					body: JSON.stringify({ user_id }),
				});

				const usersData = await usersResponse.json();

				if (usersData.status === "success") {
					setLinkedUsersOptions(
						usersData.payload?.map((user: any) => ({
							_id: user._id,
							title: user.first_name ? `${user.first_name} ${user.last_name}` : `${user.country_code} ${user.phone}`,
						})) || [],
					);
				}
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch linked data:", error);
			}
		};

		fetchLinkedData();
	}, [user_id, open]);

	React.useEffect(() => {
		const fetchLinkedData = async () => {
			if (fileId) {
				await fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-file-name", {
					method: "POST",
					body: JSON.stringify({ fileId: [fileId] }),
					headers: {
						"Content-Type": "text/plain",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.status === "success") {
							setFileName(data.payload);
						}
					})
					.catch((error) => {
						console.error("Failed to fetch linked properties:", error);
					});
			}

			if (profileImageFileId) {
				await fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-file-name", {
					method: "POST",
					body: JSON.stringify({ fileId: [profileImageFileId] }),
					headers: {
						"Content-Type": "text/plain",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.status === "success") {
							setArrayFileNames(data.payload);
						}
					})
					.catch((error) => {
						console.error("Failed to fetch linked properties:", error);
					});
			}
		};
		if (open && (fileId || profileImageFileId)) {
			fetchLinkedData();
		}
	}, [fileId, profileImageFileId, open]);

	React.useEffect(() => {
		const fetchLinkedData = async () => {
			if (ownedBy) {
				await fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-sessionId", {
					method: "POST",
					body: JSON.stringify({ user_id: ownedBy }),
					headers: {
						"Content-Type": "text/plain",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.status === "success") {
							setSessionId(data.payload);
						}
					})
					.catch((error) => {
						console.error("Failed to fetch linked properties:", error);
					});
			}
		};
		if (open && ownedBy) {
			fetchLinkedData();
		}
	}, [ownedBy, open]);

	const handleUpload = async (validFiles: Array<FileAndMore>, filePreview: Array<string>) => {
		setLoad(true);
		await uploadFiles(
			false,
			sessionId,
			validFiles.map((file: FileAndMore) => ({
				file: file.file,
				mime_type: file.mime_type,
				original_file_id: new ObjectId(),
				original_file_server_path: "",
				original: true,
				is_business_profile: false,
				business_profile_id: new ObjectId(),
				entity: "business_profile",
				entity_id: new ObjectId(),
				use_case: "blogs",
				uiPath: "/",
				isPublic: false,
				text: "",
			})),
			(uploadProgress: Array<UploadProgress>) => {
				const data = uploadProgress.map((progress) => progress.fileId);
				const stageInfo = uploadProgress.map((progress) => progress.stage);
				setPercentage(uploadProgress.map((progress) => progress.percentage));
				stage[0] !== "NOT_STARTED" ? setStage([...stage, ...stageInfo]) : setStage(stageInfo);
				setFileId(
					uploadProgress
						.map((progress) => progress.fileId)
						.filter((fileId) => fileId !== null)[0]
						?.toString(),
				);
				setMime_type(validFiles[0].mime_type);
			},
		);

		setLoad(false);
	};

	const handleUploadMultipleImages = async (validFiles: Array<FileAndMore>, filePreview: Array<string>) => {
		setLoad(true);
		await uploadFiles(
			true,
			sessionId,
			validFiles.map((file: FileAndMore) => ({
				file: file.file,
				mime_type: file.mime_type,
				original_file_id: new ObjectId(),
				original_file_server_path: "",
				original: true,
				is_business_profile: false,
				business_profile_id: new ObjectId(),
				entity: "business_profile",
				entity_id: new ObjectId(),
				use_case: "blogs",
				uiPath: "/",
				isPublic: false,
				text: "",
			})),
			(uploadProgress: Array<UploadProgress>) => {
				const data = uploadProgress.map((progress) => progress.fileId);
				const stageInfo = uploadProgress.map((progress) => progress.stage);
				setPercentageForProfile(uploadProgress.map((progress) => progress.percentage));
				stageForProfile[0] !== "NOT_STARTED"
					? setStageForProfile([...stageForProfile, ...stageInfo])
					: setStageForProfile(stageInfo);
				setProfileImageFileId(
					uploadProgress
						.map((progress) => progress.fileId)
						.filter((fileId) => fileId !== null)[0]
						?.toString(),
				);
			},
		);

		setLoad(false);
	};

	function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
		return (
			<Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress
					variant="determinate"
					{...props}
				/>
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="caption"
						component="div"
						sx={{ color: "text.secondary" }}
					>{`${Math.round(props.value)}%`}</Typography>
				</Box>
			</Box>
		);
	}

	const handleTagInputChange = (event: any) => {
		setTagInput(event.target.value);
	};

	const handleTagInputKeyDown = (event: any) => {
		if (event.key === "Enter" || event.key === ",") {
			event.preventDefault();
			const newTags = tagInput
				.split(",")
				.map((tag) => tag.trim())
				.filter((tag) => tag !== "");
			setTags([...tags, ...newTags]);
			setTagInput("");
		}
	};

	const handleDeleteTag = (tagToDelete: string) => {
		setTags(tags.filter((tag) => tag !== tagToDelete));
	};

	const handleCreatedAtChange = (date: Dayjs | null) => {
		setCreatedAt(date);
	};

	const handleUpdatedAtChange = (date: Dayjs | null) => {
		setUpdatedAt(date);
	};

	const submit = async () => {
		if (data) {
			const response = await fetch(process.env.PRODUCTION_API_URL + "blogs-author/update-blog-author", {
				method: "POST",
				body: JSON.stringify({
					_id: blogId,
					first_name,
					last_name,
					description,
					tags,
					createdAt: createdAt,
					updatedAt: updatedAt,
					fileId,
					cover_image_alt_text,
					profileImageFileId,
					profileImageAltText,
					ownedBy,
				}),
			});
			const payload = await response.json();
			if (payload.status === "success") {
				onClose();
				setQueryOptions(undefined);
				setTags([]);
				setCreatedAt(null);
				setUpdatedAt(null);
				setFileId("");
				setLinkedUsers([]);
				setSelectedFilePreview([]);
				setPercentage([]);
				setStage([]);
				setLoading(false);
				setLoad(false);
				setSelectedFilesForProfile([]);
				setSelectedFilePreviewForProfile([]);
				setPercentageForProfile([]);
				setStageForProfile([]);
				setArrayFileNames([]);
				setSessionId("");
				setCoverImageAltText("");
				setFileName("");
				setFirstName("");
				setLastName("");
				setDescription("");
				setCoverImageAltText("");
				setProfileImageAltText("");
				setSelectedFiles([]);
				setSelectedFilePreview([]);
				setSelectedFilesForProfile([]);
				setSelectedFilePreviewForProfile([]);
			} else {
				alert("Error updating blog");
			}
		} else {
			const response = await fetch(process.env.PRODUCTION_API_URL + "blogs-author/create-blog-author", {
				method: "POST",
				body: JSON.stringify({
					first_name,
					last_name,
					description,
					tags,
					createdAt: createdAt,
					updatedAt: updatedAt,
					fileId,
					cover_image_alt_text,
					profileImageFileId,
					profileImageAltText,
					ownedBy,
				}),
			});
			const payload = await response.json();
			if (payload.status === "success") {
				onClose();
				setQueryOptions(undefined);
				setTags([]);
				setCreatedAt(null);
				setUpdatedAt(null);
				setFileId("");
				setLinkedUsers([]);
				setSelectedFilePreview([]);
				setPercentage([]);
				setStage([]);
				setLoading(false);
				setLoad(false);
				setSelectedFilesForProfile([]);
				setSelectedFilePreviewForProfile([]);
				setPercentageForProfile([]);
				setStageForProfile([]);
				setArrayFileNames([]);
				setSessionId("");
				setCoverImageAltText("");
				setFileName("");
				setFirstName("");
				setLastName("");
				setDescription("");
				setCoverImageAltText("");
				setProfileImageAltText("");
				setSelectedFiles([]);
				setSelectedFilePreview([]);
				setSelectedFilesForProfile([]);
				setSelectedFilePreviewForProfile([]);
			} else {
				alert("Error creating blog");
			}
		}
	};

	if (loading) {
		return (
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
				sx={{
					"& .MuiDialog-paper": {
						borderRadius: "1rem",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
					},
				}}
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{}}
				>
					<MainHeading>Blogs Author Persona</MainHeading>
					<Divider />
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
							setQueryOptions(undefined);
							setFileName("");
							setFirstName("");
							setLastName("");
							setDescription("");
							setCoverImageAltText("");
							setProfileImageAltText("");
							setSelectedFiles([]);
							setSelectedFilePreview([]);
							setSelectedFilesForProfile([]);
							setSelectedFilePreviewForProfile([]);
							setTags([]);
							setCreatedAt(null);
							setUpdatedAt(null);

							setLinkedUsers([]);
							setSelectedFilePreview([]);
							setPercentage([]);
							setStage([]);
							setLoading(false);
							setLoad(false);
							setSelectedFilesForProfile([]);
							setSelectedFilePreviewForProfile([]);
							setPercentageForProfile([]);
							setStageForProfile([]);

							setArrayFileNames([]);
							setSessionId("");
							setCoverImageAltText("");
						}}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.5rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent>
					<MessageContainer>
						<MessageBoxContainer
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							<CircularProgress />
							<Typography>Loading...</Typography>
						</MessageBoxContainer>
					</MessageContainer>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog
			open={Boolean(open)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "1rem",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				},
			}}
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{}}
			>
				<MainHeading>Blogs</MainHeading>
				<Divider />
				<Close
					aria-label="close"
					onClick={() => {
						onClose();
						setQueryOptions(undefined);
						setFileName("");
						setFirstName("");
						setLastName("");
						setDescription("");
						setCoverImageAltText("");
						setProfileImageAltText("");
						setSelectedFiles([]);
						setSelectedFilePreview([]);
						setSelectedFilesForProfile([]);
						setSelectedFilePreviewForProfile([]);
						setTags([]);
						setCreatedAt(null);
						setUpdatedAt(null);
						setFileId("");

						setLinkedUsers([]);
						setSelectedFilePreview([]);
						setPercentage([]);
						setStage([]);
						setLoading(false);
						setLoad(false);
						setSelectedFilesForProfile([]);
						setSelectedFilePreviewForProfile([]);
						setPercentageForProfile([]);
						setStageForProfile([]);

						setMime_type("");
						setArrayFileNames([]);
						setSessionId("");
						setCoverImageAltText("");
					}}
					sx={{
						position: "absolute",
						right: "0.5rem",
						top: "0.5rem",
						color: "#F76C35",
					}}
				>
					<CloseIcon />
				</Close>
			</DialogTitle>
			<DialogContent>
				<DocumentDiv>
					<FrontDiv>
						<InputContent
							disabled={sessionId === ""}
							ref={BlogInput}
							type="file"
							accept="image/jpeg, image/png"
							onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
								const files = event.target.files;
								const customMimeTypes = ["image/jpeg", "image/png"];
								const customValidExtensions = ["jpg", "jpeg", "png"];
								const uploadType: string = "user_or_business";
								if (files && files.length > 0) {
									const {
										validFiles,
										invalidFiles,
									}: {
										validFiles: Array<FileAndMore>;
										invalidFiles: Array<File>;
									} = await validateFiles(Array.from(files), customMimeTypes, customValidExtensions, uploadType);
									if (validFiles.length > 0) {
										setSelectedFiles([...selectedFiles, ...validFiles]);

										const filePreview = [
											...selectedFilePreview,
											...validFiles.map((file: FileAndMore) => URL.createObjectURL(file.file)),
										];
										setSelectedFilePreview(filePreview);
										handleUpload(validFiles, filePreview);
									}
									if (invalidFiles.length > 0) {
										const fileNames: Array<string> = invalidFiles.map((file: File) => file.name);
										alert(`Invalid file types: ${fileNames.join(", ")}`);
									} else {
									}
								}
							}}
						/>
						<UploadContent
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								if (BlogInput.current) {
									BlogInput.current.click();
								}
							}}
						>
							<CloudUploadIcon
								fontSize="large"
								sx={{
									color: "#0000008A",
								}}
							/>
							Select a file or drag and drop here
							<span
								style={{
									fontSize: "0.8rem",
									color: "#00000061",
								}}
							>
								File types: JPEG or PNG; Max file size: 10MB
							</span>
							{fileName && fileName.length > 0 ? (
								<Typography
									sx={{
										color: "#000000",
										fontSize: "1rem",
										backgroundColor: "#FEEADA",
										borderRadius: "0.5rem",
										padding: "0.5rem",
										border: "1px solid #B05911",
									}}
								>
									{fileName}
								</Typography>
							) : null}
							<Button
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									if (usertext.current) {
										usertext.current.click();
									}
								}}
								// flavor="primary"
								variant="outlined"
								sx={{
									gap: "0.5rem",
									margin: "1rem 0rem 0rem 0rem",
								}}
							>
								{sessionId === "" ? "please select a user" : "Upload"}
							</Button>
							{selectedFilePreview.map((file: string, index: number) =>
								file !== "" ? (
									<ProgressContainer key={index}>
										{stage[index] && stage[index] !== "FETCHED_FILE_INFORMATION" ? (
											// <CircularProgress
											// 	size="1.5rem"
											// 	value={percentage[index]}
											// 	variant="determinate"
											// />
											<CircularProgressWithLabel value={percentage[index]} />
										) : stage[index] === undefined || stage[index] === "FETCHED_FILE_INFORMATION" ? (
											<Check
												sx={{
													color: "#4CC417",
													fontSize: "2rem",
													backgroundColor: "rgba(0, 0, 0, 0.5)",
													borderRadius: "50%",
												}}
											/>
										) : null}
									</ProgressContainer>
								) : null,
							)}
						</UploadContent>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">cover image alt text</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={cover_image_alt_text}
								placeholder="Enter your cover image alt text here"
								onChange={(event: any) => {
									setCoverImageAltText(event.target.value);
								}}
							/>
						</MessageBoxContainer>
					</FrontDiv>
				</DocumentDiv>

				<SubContainer>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: "2rem",
						}}
					>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">First Name</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={first_name}
								placeholder="Enter your first name here"
								onChange={(event: any) => {
									setFirstName(event.target.value);
								}}
							/>
						</MessageBoxContainer>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Last Name</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={last_name}
								placeholder="Enter your last name here"
								onChange={(event: any) => {
									setLastName(event.target.value);
								}}
							/>
						</MessageBoxContainer>

						<MessageBoxContainer
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								// gap: "1rem",
							}}
						>
							<SubHeading variant="h6">owned by</SubHeading>

							<Autocomplete
								ref={usertext}
								options={linkedUsersOptions}
								value={linkedUsersOptions.filter((option) => linkedUsers.includes(option._id))[0] || null}
								getOptionLabel={(option) => option.title || ""}
								onChange={(event, newValue) => {
									setLinkedUsers(newValue ? [newValue._id] : []);
									setOwnedBy(newValue ? newValue._id : "");
									setFirstName(newValue ? newValue.title.split(" ")[0] : "");
									setLastName(newValue ? newValue.title.split(" ")[1] : "");
								}}
								renderOption={(props, option) => (
									<li
										{...props}
										key={option._id}
									>
										{option.title}
									</li>
								)}
								renderInput={(params) => (
									<TextField
										sx={{
											width: "100%",
										}}
										{...params}
										variant="outlined"
										size="small"
										placeholder="Select linked users"
									/>
								)}
								sx={{
									width: "100%",
									minWidth: "10rem",
								}}
							/>
						</MessageBoxContainer>
					</div>
				</SubContainer>

				<MessageBoxContainer
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "1rem",
					}}
				>
					<SubHeading variant="h6">Description</SubHeading>
					<TextField
						variant="outlined"
						fullWidth
						multiline
						rows={10} // Adjust the number of rows as needed
						value={description}
						placeholder="Enter your description here"
						onChange={(event: any) => {
							setDescription(event.target.value);
						}}
						InputProps={{
							sx: {
								"& textarea": {
									resize: "both", // Make the textarea resizable
								},
							},
						}}
					/>{" "}
				</MessageBoxContainer>
				<MessageBoxContainer
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "1rem",
					}}
				>
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
							flexWrap: "wrap",
						}}
					>
						<SubHeading variant="h6">Upload Profile Image</SubHeading>
						{profileImageFileId
							? arrayFilenames.map((filename: string, key: number) => (
									<div
										key={key}
										style={{
											color: "#000000",
											fontSize: "1rem",
											backgroundColor: "#FEEADA",
											borderRadius: "0.5rem",
											padding: "0.5rem 0.5rem 0.5rem 0.5rem",
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											gap: "1rem",
										}}
									>
										<SubHeading>{filename}</SubHeading>
										<IconButton
											aria-label="delete"
											onClick={() => {
												setSelectedFilesForProfile(selectedFilesForProfile.filter((_, index) => index !== key));
												setSelectedFilePreviewForProfile(
													selectedFilePreviewForProfile.filter((_, index) => index !== key),
												);
												setStageForProfile(stageForProfile.filter((_, index) => index !== key));
												setPercentageForProfile(percentageForProfile.filter((_, index) => index !== key));
												setArrayFileNames(arrayFilenames.filter((_, index) => index !== key));
												setProfileImageFileId("");
											}}
											sx={{ color: "#FC8019" }}
										>
											<Cancel />
										</IconButton>
									</div>
								))
							: null}
					</div>
					<SubContainer>
						<input
							type="file"
							id="file"
							name="file"
							aria-label="file"
							accept="image/jpeg,image/png,image/jpg"
							style={{ display: "none" }} // Hide the default file input
							onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
								const files = event.target.files;
								const customMimeTypes = ["image/jpeg", "image/png", "image/jpg"];
								const customValidExtensions = ["jpeg", "png", "jpg"];
								const uploadType: string = "post";
								if (files && files.length > 0) {
									const {
										validFiles,
										invalidFiles,
									}: {
										validFiles: Array<FileAndMore>;
										invalidFiles: Array<File>;
									} = await validateFiles(Array.from(files), customMimeTypes, customValidExtensions, uploadType);
									if (validFiles.length > 0) {
										setSelectedFilesForProfile([...selectedFiles, ...validFiles]);

										const filePreview = [
											...selectedFilePreviewForProfile,
											...validFiles.map((file: FileAndMore) => URL.createObjectURL(file.file)),
										];
										setSelectedFilePreviewForProfile(filePreview);
										handleUploadMultipleImages(validFiles, filePreview);
									}
									if (invalidFiles.length > 0) {
										const fileNames: Array<string> = invalidFiles.map((file: File) => file.name);
										alert(`Invalid file types: ${fileNames.join(", ")}`);
									}
								}
							}}
						/>
						<Button
							variant="outlined"
							onClick={() => {
								document.getElementById("file")?.click();
							}}
							sx={{
								backgroundColor: "#FEEADA", // Change the background color here
								color: "#000000",
								border: "1px solid #B05911", // Change the border color here
								gap: "0.5rem",
							}}
						>
							<CloudUploadIcon />
							Select Files
						</Button>
						{selectedFilePreviewForProfile.map((file: string, index: number) =>
							file !== "" ? (
								<ProgressContainer key={index}>
									{stageForProfile[index] && stageForProfile[index] !== "FETCHED_FILE_INFORMATION" ? (
										<CircularProgressWithLabel value={percentageForProfile[index]} />
									) : stageForProfile[index] === undefined || stageForProfile[index] === "FETCHED_FILE_INFORMATION" ? (
										<Check
											sx={{
												color: "#4CC417",
												fontSize: "2rem",
												backgroundColor: "rgba(0, 0, 0, 0.5)",
												borderRadius: "50%",
											}}
										/>
									) : null}
									<IconButton
										aria-label="delete"
										onClick={() => {
											setSelectedFilesForProfile(selectedFilesForProfile.filter((_, index) => index !== index));
											setSelectedFilePreviewForProfile(
												selectedFilePreviewForProfile.filter((_, index) => index !== index),
											);
											setStageForProfile(stageForProfile.filter((_, index) => index !== index));
											setPercentageForProfile(percentageForProfile.filter((_, index) => index !== index));
											setArrayFileNames(arrayFilenames.filter((_, index) => index !== index));
											setProfileImageFileId("");
										}}
										sx={{ color: "#FC8019" }}
									>
										<DeleteIcon />
									</IconButton>
								</ProgressContainer>
							) : null,
						)}
					</SubContainer>
					<MessageBoxContainer>
						<SubHeading variant="h6">profile image alt text</SubHeading>
						<TextField
							variant="outlined"
							size="small"
							value={profileImageAltText}
							placeholder="Enter your profile image alt text here"
							onChange={(event: any) => {
								setProfileImageAltText(event.target.value);
							}}
						/>
					</MessageBoxContainer>
				</MessageBoxContainer>
				<SubContainer></SubContainer>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "2rem",
					}}
				>
					<MessageBoxContainer
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
						}}
					>
						<SubHeading variant="h6">Tags</SubHeading>
						<TextField
							variant="outlined"
							size="small"
							value={tagInput}
							placeholder="Enter tags separated by commas"
							onChange={handleTagInputChange}
							onKeyDown={handleTagInputKeyDown}
							fullWidth
						/>
						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								gap: "0.5rem",
								marginTop: "0.5rem",
							}}
						>
							{tags.map((tag, index) => (
								<Chip
									key={index}
									label={tag}
									onDelete={() => handleDeleteTag(tag)}
									sx={{
										backgroundColor: "#FEEADA",
										color: "#000000",
									}}
								/>
							))}
						</Box>
					</MessageBoxContainer>
				</div>

				<SubContainer
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						width: "100%",
					}}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<MessageBoxContainer>
							<SubHeading variant="h6">Created at :</SubHeading>
							<DatePicker
								label="created at"
								value={createdAt as Dayjs}
								onChange={handleCreatedAtChange}
								slotProps={{ textField: { size: "small" } }}
							/>
						</MessageBoxContainer>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<MessageBoxContainer>
							<SubHeading variant="h6">Updated at :</SubHeading>
							<DatePicker
								label="updated at"
								slotProps={{ textField: { size: "small" } }}
								value={updatedAt}
								onChange={handleUpdatedAtChange}
							/>
						</MessageBoxContainer>
					</LocalizationProvider>
				</SubContainer>
			</DialogContent>
			<DialogActions>
				<ViewOrUploadButton
					variant="outlined"
					sx={{
						color: "#FC8019",
					}}
					autoFocus
					onClick={() => {
						onClose();
						setQueryOptions(undefined);
						setFileName("");
						setFirstName("");
						setLastName("");
						setDescription("");
						setCoverImageAltText("");
						setProfileImageAltText("");
						setSelectedFiles([]);
						setSelectedFilePreview([]);
						setSelectedFilesForProfile([]);
						setSelectedFilePreviewForProfile([]);

						setTags([]);
						setCreatedAt(null);
						setUpdatedAt(null);

						setFileId("");

						setLinkedUsers([]);
						setSelectedFilePreview([]);
						setPercentage([]);
						setStage([]);
						setLoading(false);
						setLoad(false);
						setSelectedFilesForProfile([]);
						setSelectedFilePreviewForProfile([]);
						setPercentageForProfile([]);
						setStageForProfile([]);

						setMime_type("");
						setArrayFileNames([]);
						setSessionId("");
					}}
				>
					Cancel
				</ViewOrUploadButton>
				<ViewOrUploadButton
					variant="contained"
					onClick={() => {
						submit();
						onClose();
						setQueryOptions(undefined);
					}}
				>
					Save
				</ViewOrUploadButton>
			</DialogActions>
		</Dialog>
	);
};

export default CreateBlogAuthorPersonaModal;

import { Box, Button, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { ObjectId } from "bson";
import React from "react";
import PublishedUnpublishedLinksModal from "./PublishedUnpublishedLinksModal";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const BoostAudienceListItemRadiusContainer = styled("div")(() => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "1rem 0rem 1rem 1rem",
}));

/*

& Next, let's declare any static constants we intend to use on this page outside the functional component.

*/

const ListingCountModal = ({
	open,
	onClose,
	listings,
	listing_type,
	setListingType,
}: {
	open: any;
	onClose: any;
	listings: any;
	listing_type: any;
	setListingType: any;
}) => {
	const [loading, setLoading] = React.useState(false);

	const [publishedAndUnpublishedModal, setPublishedAndUnpublishedModal] = React.useState(false);

	const [businessProfileId, setBusinessProfileId] = React.useState<string | ObjectId>("");

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	return (
		<div>
			<PublishedUnpublishedLinksModal
				open={publishedAndUnpublishedModal}
				onClose={() => {
					setPublishedAndUnpublishedModal(false);
				}}
				businessProfileId={businessProfileId}
			/>

			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={() => {
					onClose();
				}}
				fullWidth={true}
				// fullScreen={true}
				maxWidth="md"
				disableScrollLock={true}
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.main",
					}}
				>
					<Typography
						variant="h6"
						sx={{ color: "#F76C35" }}
					>
						{listing_type === "property" ? "Property" : "Service"} Listing Count
					</Typography>
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<BoostAudienceListItemRadiusContainer>
					<Button
						variant="contained"
						sx={{
							backgroundColor: listing_type === "property" ? "#F76C35" : "white",
							color: listing_type === "property" ? "white" : "#F76C35",
						}}
						onClick={() => {
							setListingType("property");
						}}
					>
						Property
					</Button>
					<Button
						variant="contained"
						sx={{
							backgroundColor: listing_type === "service" ? "#F76C35" : "white",
							color: listing_type === "service" ? "white" : "#F76C35",
						}}
						onClick={() => {
							setListingType("service");
						}}
					>
						Service
					</Button>
				</BoostAudienceListItemRadiusContainer>

				{/* <Box sx={{ height: "auto", padding: "0rem 1rem 0rem 1rem", width: "100%" }}> */}
				<Box sx={{ height: "80vh", width: "auto", marginBottom: "1rem", padding: "0rem 1rem 0rem 1rem" }}>
					<DataGridPremium
						slots={{ toolbar: GridToolbar }}
						rows={listings.map((row: any, key: any) => ({
							...row,
							id: key,
						}))}
						columns={[
							{
								field: "business_profile_title",
								headerName: "Business Profile",
								flex: 0.5,
								headerAlign: "left",
								align: "left",
								width: 200,
							},
							{
								field: "business_profile_type",
								headerName: "Business Profile Type",
								headerAlign: "left",
								align: "left",
								width: 100,
								flex: 0.5,
							},
							{
								field: "count",
								headerName: "Listing Count",
								flex: 0.5,
								headerAlign: "center",
								align: "center",
								width: 100,
							},
							{
								field: "business_profile_id",
								headerName: "Listings",
								flex: 0.5,
								headerAlign: "center",
								align: "center",
								width: 100,
								renderCell: (cellValues: any) => {
									return (
										<Button
											variant="contained"
											style={{
												backgroundColor: "#F76C35",
												color: "#FFFFFF",
												borderRadius: "1rem",
												padding: "0.5rem 1rem",
											}}
											onClick={() => {
												setPublishedAndUnpublishedModal(true);
												setBusinessProfileId(cellValues.row.business_profile_id);
											}}
										>
											view listings
										</Button>
									);
								},
							},
						]}
						loading={listings.length === 0}
						getRowId={(row) => row.id} // Specify custom id for each row
						hideFooter
						scrollbarSize={0.5}
					/>
				</Box>
				{/* </Box> */}
			</Dialog>
		</div>
	);
};

export default ListingCountModal;

/*
? First, let's import the essentials - React and Styled
*/
import { styled } from "@mui/system";
import React from "react";
/*
? Next, let's import all the UI components we need on this page
*/
import { Paper, Typography } from "@mui/material";
import LoadingComponent from "../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../sub-components/YouDontHaveAccess";
import MainAnalyticstab from "./MainAnalytics";

/*
& Next, let's define the styled components we need on this page
*/
const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	// justifyContent: "flex-start",
	// alignItems: "flex-start",
	width: "100%",
	height: "100%",
	zIndex: 1,
}));

const HeadContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "auto",
	// margin: "0.5rem 0.5rem 0rem 0.5rem",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	// boxShadow: "1px 1px 1px  rgba(0, 0, 0, 0.1)",
}));
const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 1rem",
	textTransform: "capitalize",
}));

/*
& Next, let's describe the component
*/
const AnalyticsMain = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const [loading, setLoading] = React.useState(true);
	const Check = DashboardData
		? DashboardData.accessLevel
			? (DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("analytics")) ||
				DashboardData.accessLevel.includes("super_admin")
			: false
		: false;

	React.useEffect(
		() => {
			if (Check) {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[Check],
	);
	if (loading) {
		return (
			<MainContainer>
				<HeadContainer elevation={0}>
					<HeadText>Analytics</HeadText>
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<React.Fragment>
			<MainContainer>
				{DashboardData.accessLevel &&
				((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("analytics")) ||
					DashboardData.accessLevel.includes("super_admin")) ? (
					!DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
						<MainAnalyticstab DashboardData={DashboardData} />
					)
				) : (
					<YouDontHaveAccessComponent />
				)}
			</MainContainer>
		</React.Fragment>
	);
};
/*
& FInally, let's export the component
*/
export default AnalyticsMain;

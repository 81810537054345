import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle, IconButton, Skeleton, Typography, styled } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import React from "react";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0.6rem",
}));

const ClickerInfoModal = ({ open, onClose, userInfo }: { open: any; onClose: any; userInfo: any }) => {
	const [userInformation, setUserInformation] = React.useState<any>([]);

	/*

    & Make request to userstack API

    */

	// const response: Response = await fetch(requestURL);

	React.useEffect(() => {
		if (userInfo && userInfo.user_agent && userInfo.ip_address) {
			const user_agent: string = userInfo.user_agent
				? userInfo.user_agent
				: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3";
			const ip_address: string = userInfo.ip_address ? userInfo.ip_address : "";

			const requestURL: string =
				(process.env.USERSTACK_API_BASE_URL || "") +
				"detect?access_key=" +
				(process.env.USERSTACK_API_ACCESS_KEY || "") +
				"&ua=" +
				user_agent;

			const requestURLIp: string =
				(process.env.IPSTACK_API_BASE_URL || "") +
				ip_address +
				"?access_key=" +
				(process.env.IPSTACK_API_ACCESS_KEY || "");

			const userAgentAndIpaddress = async () => {
				const [uaResponse, ipResponse, userData] = await Promise.all([
					fetch(requestURL),
					fetch(requestURLIp),
					userInfo.is_signed_in
						? fetch(process.env.PRODUCTION_API_URL + "fetch-user", {
								method: "POST",
								cache: "no-store" as RequestCache,
								headers: {
									"Content-Type": "text/plain",
									"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
								},
								body: JSON.stringify({ userId: userInfo.user_id }),
							})
						: null,
				]);
				const uaData = await uaResponse.json();
				const ipData = await ipResponse.json();
				const userDataJson = await userData?.json();
				console.log("userDataJson", userDataJson);
				setUserInformation([
					{
						firstName: userInfo.is_signed_in ? userDataJson.payload.first_name : "not logged in",
						lastName: userInfo.is_signed_in ? userDataJson.payload.last_name : "not logged in",
						userId: userInfo.user_id,
						phonenumber: userInfo.is_signed_in
							? userDataJson.payload.country_code + userDataJson.payload.phone
							: "not logged in",
						browser: uaData.browser.name,
						operatingSystem: uaData.os.name,
						ip: ipData.ip,
						country: ipData.country_name,
						city: ipData.city,
						region: ipData.region_name,
						zip: ipData.zip,
					},
				]);
			};
			userAgentAndIpaddress();
		}
	}, [userInfo]);

	console.log(userInformation);

	const columns = [
		{
			field: "firstName",
			headerName: "First Name",
			width: 100,
		},
		{
			field: "lastName",
			headerName: "Last Name",
			width: 100,
		},
		{
			field: "userId",
			headerName: "User ID",
			width: 150,
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},
		{
			field: "browser",
			headerName: "Browser",
			width: 100,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Chrome"
									? "/images/icons/logo/🦆 icon _chrome_.svg"
									: cellValues.value === "Duck"
										? "/images/icons/logo/Duck Duck Go.svg"
										: cellValues.value === "Brave"
											? "/images/icons/logo/Brave.svg"
											: cellValues.value === "Edge"
												? "/images/icons/logo/Edge.svg"
												: cellValues.value === "Firefox"
													? "/images/icons/logo/Firefox.svg"
													: cellValues.value === "Opera"
														? "/images/icons/logo/Opera.svg"
														: cellValues.value?.includes("Safari")
															? "/images/icons/logo/Safari.svg"
															: cellValues.value === "Samsung"
																? "/images/icons/logo/Samsung Browser.svg"
																: cellValues.value === "undici"
																	? "/images/icons/logo/nodejs-icon.svg"
																	: cellValues.value === "Internet Explorer"
																		? "/images/icons/logo/Internet Explorer.svg"
																		: cellValues.value === "iOS"
																			? "/images/icons/logo/Apple.svg"
																			: "/images/icons/logo/🦆 icon _chrome_.svg"
							}
							alt="browser"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},

		{
			field: "operatingSystem",
			headerName: "Operating System",
			width: 100,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Windows"
									? "/images/icons/logo/Windows 11.svg"
									: cellValues.value === "MacOS"
										? "/images/icons/logo/Apple (1).svg"
										: cellValues.value?.includes("iOS")
											? "/images/icons/logo/Apple.svg"
											: cellValues.value?.includes("macOS")
												? "/images/icons/logo/Apple (1).svg"
												: cellValues.value === "Linux"
													? "/images/icons/logo/Linux.svg"
													: cellValues.value?.includes("Android")
														? "/images/icons/logo/android.svg"
														: "/images/icons/logo/Windows 11.svg"
							}
							alt="os"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},
		{
			field: "ip",
			headerName: "IP",
			width: 100,
		},
		{
			field: "country",
			headerName: "Country",
			width: 100,
		},
		{
			field: "city",
			headerName: "City",
			width: 100,
		},
		{
			field: "region",
			headerName: "Region",
			width: 100,
		},
		{
			field: "zip",
			headerName: "Zip",
			width: 100,
		},
	];

	if (!userInformation) {
		return (
			<div>
				<Skeleton
					variant="rectangular"
					width="100%"
					height="100%"
				/>
			</div>
		);
	}

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="lg"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography variant="h6">Clicker Information</Typography>
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
							setUserInformation([]);
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<Box sx={{ height: "20", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						rows={userInformation?.map((item: any, key: number) => {
							return {
								id: key,
								firstName: item.firstName ? item.firstName : "",
								lastName: item.lastName ? item.lastName : "",
								userId: item.userId ? item.userId : "",
								phonenumber: item.phonenumber ? item.phonenumber : "",
								browser: item.browser ? item.browser : "",
								operatingSystem: item.operatingSystem ? item.operatingSystem : "",
								ip: item.ip ? item.ip : "",
								country: item.country ? item.country : "",
								city: item.city ? item.city : "",
								region: item.region ? item.region : "",
								zip: item.zip ? item.zip : "",
							};
						})}
						// rows={rows}
						columns={columns}
						hideFooter={true}
						sx={{
							backgroundColor: "#FFFFFF",
							"& > .MuiDataGrid-columnSeparator": {
								display: "none",
							},
							"& .MuiDataGrid-cell": {
								borderBottom: "none",
							},
						}}
					/>
				</Box>
			</Dialog>
		</div>
	);
};

export default ClickerInfoModal;

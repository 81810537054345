import { ApexOptions } from "apexcharts";

// ----------------------------------------------------------------------

export default function BaseOptionChart(): ApexOptions {
	return {
		chart: {
			toolbar: { show: false },
			zoom: { enabled: false },
			animations: {
				enabled: true,
				speed: 800,
			},
		},

		// Fill
		fill: {
			opacity: 1,
			gradient: {
				type: "vertical",
				shadeIntensity: 0,
				opacityFrom: 0.4,
				opacityTo: 0,
				stops: [0, 100],
			},
			colors: [
				"#FF6384",
				"#36A2EB",
				"#FFCE56",
				"#4BC0C0",
				"#9966FF",
				"#FF9F40",
				"#FFCD56",
				"#FF5733",
				"#33FF57",
				"#3357FF",
				"#FF33A2",
				"#A233FF",
				"#33FFA2",
				"#FFA233",
				"#FF5733",
				"#33FF57",
				"#3357FF",
				"#FF33A2",
				"#A233FF",
				"#33FFA2",
				"#FFA233",
				"#FF5733",
				"#33FF57",
				"#3357FF",
				"#FF33A2",
				"#A233FF",
				"#33FFA2",
				"#FFA233",
				"#FF5733",
				"#33FF57",
				"#3357FF",
			],
		},
		theme: {
			mode: "light",
			palette: "palette1",
			monochrome: {
				enabled: false,
				color: "#fff",
				shadeTo: "light",
				shadeIntensity: 0.65,
			},
		},

		// Datalabels
		dataLabels: {
			enabled: true,
			style: {
				fontSize: "1rem",
				fontWeight: "400",
			},
		},
		colors: [
			"#FF6384",
			"#36A2EB",
			"#FFCE56",
			"#4BC0C0",
			"#9966FF",
			"#FF9F40",
			"#FFCD56",
			"#FF5733",
			"#33FF57",
			"#3357FF",
			"#FF33A2",
			"#A233FF",
			"#33FFA2",
			"#FFA233",
			"#FF5733",
			"#33FF57",
			"#3357FF",
			"#FF33A2",
			"#A233FF",
			"#33FFA2",
			"#FFA233",
			"#FF5733",
			"#33FF57",
			"#3357FF",
			"#FF33A2",
			"#A233FF",
			"#33FFA2",
			"#FFA233",
			"#FF5733",
			"#33FF57",
			"#3357FF",
		],
	};
}

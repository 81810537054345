/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/
import { Add, Apartment, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	ClickAwayListener,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { ObjectId } from "mongodb";
import { useRouter } from "next/router";
import ReactTimeAgo from "react-time-ago";
import ListingCountModal from "../../../modal/ListingCountModal";
import LoadingModal from "../../../modal/LoadingModal";
import PublishedUnpublishedLinksModal from "../../../modal/PublishedUnpublishedLinksModal";
import ReminderForBusinessModal from "../../../modal/ReminderForBusinessProfileModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "user"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "agent"
			? {
					backgroundColor: "#FFD700",
				}
			: id === "developer"
				? {
						backgroundColor: "#d5d5d7",
					}
				: id === "landowner"
					? {
							backgroundColor: "rgb(30,144,255,0.6)",
						}
					: id === "professional"
						? {
								backgroundColor: "rgba(0, 255, 0, 0.47)",
							}
						: {
								backgroundColor: "rgba(255, 255, 255, 0.002)",
							},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0.5rem 0rem 0.5rem 0rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

TimeAgo.addLocale(en);

const BusinessProfilesDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	const [openModal, setOpenMoadal] = React.useState(false);

	const [reminderModal, setReminderModal] = React.useState(false);

	const [publishedAndUnpublishedModal, setPublishedAndUnpublishedModal] = React.useState(false);

	const [businessProfileId, setBusinessProfileId] = React.useState<string | ObjectId>("");
	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);
	const [listing_type, setListingType] = React.useState("property");

	/*

  & Next, let's define a local state for the user id to add beegru points

  */
	const [userId, setUserId] = React.useState<string | ObjectId>("");

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [editAssignedRowId, setEditAssignedRowId] = React.useState<string | null>(null);

	const [listings, setListings] = React.useState([]);

	const [listingModal, setListingModal] = React.useState(false);

	const [loading, setLoading] = React.useState(true);
	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (router.query.filter) {
			FilteringFunction({
				items: [
					{
						field: "status",
						operator: "contains",
						value: router.query.filter,
					},
				],
			});
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page, queryOptions, router.query.filter]);

	React.useEffect(() => {
		const FetchListingCount = async () => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "business-profile-table/highest-no-of-listings", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					listing_type: listing_type,
				}),
			});

			const res = await data.json();
			setListings(res.payload);
		};

		FetchListingCount();
	}, [listing_type]);

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const verifyUser = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "business-profile-table/verify-business-profile", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				verify: event,
				userId: cellValues,
			}),
		});

		FilteringFunction(queryOptions);
		setOpenMoadal(false);
	};

	const managedUser = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "business-profile-table/managed-business-profile", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				managed: event,
				userId: cellValues,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the allow in featured

  */

	const allowInFeatured = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "business-profile-table/allow-in-featured", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				allow_in_featured: event,
				businessProfileId: cellValues,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};
	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "business-profile-table/business-profile-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				businessId: cellValues.id,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "business-profile-table/delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				businessId: cellValues.id,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "business-profile-table/business-profile-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setAccessLevel(res.payload.accessLevel);
			setListOfModerators(res.payload.list_of_moderators);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "businessId",
			headerName: "Business Id",
			width: 200,
		},
		{
			field: "businessProfileName",
			headerName: "Business Profile Name",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/" + cellValues.row.profileType + "s" + "/" + cellValues.row.slug}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "businessphonenumber",
			headerName: "Business Phone Number",
			width: 190,
		},
		{
			field: "slug",
			headerName: "Slug",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/" + cellValues.row.profileType + "s" + "/" + cellValues.row.slug}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "listings",
			headerName: "Listings",
			width: 150,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<Button
						variant="contained"
						style={{
							backgroundColor: "#f99168",
							color: "#FFFFFF",
							borderRadius: "1rem",
							padding: "0.5rem 1rem",
						}}
						onClick={() => {
							setPublishedAndUnpublishedModal(true);
							setBusinessProfileId(cellValues.row.businessId);
						}}
					>
						view listings
					</Button>
				);
			},
		},
		{
			field: "created_using_bd_tool",
			headerName: "Created using BD Tool",
			width: 150,
		},
		{
			field: "managed",
			headerName: "Managed",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							managedUser(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},
		{
			field: "Userphonenumber",
			headerName: "User Phone Number",
			width: 150,
		},
		{
			field: "createdOn",
			headerName: "Account created on",
			width: 150,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toLocaleDateString(); // Format the date as a string for display
			},
		},
		{
			field: "last_activity_date",
			headerName: "Last Activity Date",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);

				const date = new Date(cellValues.value);

				return (
					<ActionActions
						sx={{
							padding: "1rem 0rem 0rem 0rem",
						}}
					>
						{is_date_14_days_old ? (
							<Typography
								variant="body2"
								color="error"
							>
								{date.toLocaleString()}
							</Typography>
						) : (
							<Typography
								variant="body2"
								color="success"
							>
								{date.toLocaleString()}
							</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "last_activity",
			headerName: "Last Activity",
			width: 150,
			filterable: false,
			// fiterOperators: ["contains"],
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);
				return (
					<ActionActions>
						{cellValues.value ? (
							<ReactTimeAgo
								date={new Date(cellValues.value)}
								locale="en-US"
								style={{
									color: is_date_14_days_old ? "#F76C35" : "#000000",
									fontWeight: 400,
								}}
							/>
						) : (
							<Typography
								variant="body2"
								color="error"
							>
								no activity
							</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "profileType",
			headerName: "Profile Type",
			width: 150,
			renderCell: (cellValues: any) => {
				return cellValues.value?.length > 0 ? (
					<ActionActions
						sx={{
							padding: "0.8rem 0rem 0rem 0rem",
						}}
					>
						<ChipData
							id={cellValues.value}
							label={cellValues.value}
							variant="outlined"
							sx={{
								width: "100%",
								borderRadius: "0.5rem",
								boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
							}}
						/>
					</ActionActions>
				) : (
					<ChipData
						id=""
						label=""
						color="primary"
					/>
				);
			},
		},
		{
			field: "userName",
			headerName: "User Name",
			width: 150,
		},
		{
			field: "deleted",
			headerName: "Deleted",
			width: 150,
		},

		{
			field: "verify",
			headerName: "Verify",
			width: 100,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							verifyUser(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},

		{
			field: "allow_in_featured",
			headerName: "Allow in Featured",
			width: 100,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							allowInFeatured(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},

		{
			field: "action",
			headerName: "User Profiles Action",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions
						sx={{
							padding: "0.5rem 0rem 0rem 0rem",
						}}
					>
						<Link
							href={"tel:" + cellValues.row.Userphonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/" + cellValues.row.Userphonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
					</ActionActions>
				);
			},
		},
		{
			field: "businessaction",
			headerName: "Business Profiles Action",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions
						sx={{
							padding: "0.5rem 0rem 0rem 0rem",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<Link
							href={"tel:" + cellValues.row.businessphonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/" + cellValues.row.businessphonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								setUserId(cellValues.row.id);
								setReminderModal(true);
							}}
							sx={{
								backgroundColor: "#F76C35",
								color: "#FFFFFF",
								margin: "0rem 0rem 0rem 0.5rem",
							}}
						>
							reminder
						</Button>
					</ActionActions>
				);
			},
		},

		{
			field: "assigned_to",
			headerName: "Assigned",
			width: 300,
			// editable: true,
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				const isEditing = editAssignedRowId === cellValues.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditAssignedRowId(null)}>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							fullWidth={true}
							onChange={(event: any) => handleChangeAssigned(event, cellValues)}
							value={data ? data.assigned_to : cellValues.value}
							onClose={() => setEditAssignedRowId(null)}
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
							}}
						>
							{list_of_moderators.map((item: any, index: number) => (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<ActionActions
						sx={{
							padding: "0.8rem",
						}}
					>
						{data && data.assigned_to?.length > 0 ? (
							data.assigned_to.map((item: any, index: number) => (
								<Chip
									id={item._id}
									key={index}
									label={item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"}
									variant="outlined"
									onDelete={() => {
										deleteAssigned(item._id, cellValues);
									}}
									color="primary"
								/>
							))
						) : (
							<Chip
								id="assign"
								label="Assign to"
								variant="outlined"
								onDelete={() => {}}
								deleteIcon={<Add />}
								color="primary"
								onClick={() => setEditAssignedRowId(cellValues.id)}
							/>
						)}
					</ActionActions>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={data ? data.assigned_to : cellValues.value}
					>
						{DashboardData.list_of_moderators.map((item: any, index: number) => (
							<MenuItem
								value={item._id}
								key={index}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}
							>
								{item.first_name + " " + item.last_name}
							</MenuItem>
						))}
					</Select>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					sx={{
						color: "#F76C35",
						fontSize: "0.75rem",
						borderRadius: "0.5rem",
						margin: "0.5rem 1rem 0.2rem 1rem",
					}}
					onClick={() => {
						setListingModal(true);
					}}
					startIcon={<Apartment fontSize="small" />}
				>
					view listings
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Business Profiles
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Business Profiles</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}
	return (
		<MainContainer>
			<ListingCountModal
				open={listingModal}
				onClose={() => {
					setListingModal(false);
				}}
				listings={listings}
				setListingType={setListingType}
				listing_type={listing_type}
			/>
			<ReminderForBusinessModal
				open={reminderModal}
				onClose={() => {
					setReminderModal(false);
				}}
				userId={userId}
			/>
			<PublishedUnpublishedLinksModal
				open={publishedAndUnpublishedModal}
				onClose={() => {
					setPublishedAndUnpublishedModal(false);
				}}
				businessProfileId={businessProfileId}
			/>

			<LoadingModal open={openModal} />
			<HeadContainer>
				<HeadText>Business Profiles</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("business_profiles")) ||
				accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any) => {
							return {
								id: item._id,
								businessId: item._id,
								businessProfileName: item.title,
								Userphonenumber: item.created_by
									? item.created_by.country_code + " " + item.created_by.phone
									: "no number",
								businessphonenumber: item.phone
									? (item.phone.phone_number ? item.phone.country_code : "") +
										" " +
										(item.phone.phone_number ? item.phone.phone_number : "no number")
									: "no number",
								userName: item.created_by
									? item.created_by.first_name !== ""
										? item.created_by.first_name + " " + item.created_by.last_name
										: "no name"
									: "no name",
								createdOn: item.created_at ? new Date(item.created_at) : new Date(),
								last_activity_date: item.last_activity ? item.last_activity : item.created_at,
								last_activity: item.last_activity ? item.last_activity : item.created_at,

								slug: item.slug,
								//   bannedUsers: item.banned?.status,
								location: item.address?.city,
								profileType: item.business_profile_type,
								//   beegruPoints: item.beegru_points,
								//   totalExpenditure: item.totalExpenditure,
								verify: item.verified,
								assigned_to: item.assigned_to,
								allow_in_featured: item.allow_in_featured,
								// published_count: item.propertiesPublishedCount,
								// unpublished_count: item.propertiesUnpublishedCount,
								// total_property_count: item.totalProperties,
								// service_published_count: item.servicesPublishedCount,
								// service_unpublished_count: item.servicesUnpublishedCount,
								// total_service_count: item.totalServices,
								// publishedProperties: item.publishedPropertiesSlug,
								// unpublishedProperties: item.unpublishedPropertiesSlug,
								// publishedServices: item.publishedServicesSlug,
								// unpublishedServices: item.unpublishedServicesSlug,
								deleted: item.deleted,
								created_using_bd_tool: item.created_using_bd_tool,
								managed: item.managed,
							};
						})}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default BusinessProfilesDatatable;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, ArrowDropDown, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	ClickAwayListener,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import ReactTimeAgo from "react-time-ago";
import LoadingModal from "../../../modal/LoadingModal";
import UpdateServiceReminderModal from "../../../modal/UpdateServiceReminderModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
// import LoadingModal from "../../../modal/LoadingModal";
// import UpdateServiceReminderModal from "../../../modal/UpdateServiceReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				background: "rgba(244, 67, 54, 0.5)",
				color: "#FFFFFF",
				borderRadius: "0.5rem",
				fontSize: "0.875rem",
			}
		: id === "work_in_progress"
			? {
					background: "rgba(33, 150, 243, 0.5)",
					color: "#FFFFFF",
					padding: "0.5rem",
					borderRadius: "0.5rem",
					fontSize: "0.875rem",
				}
			: id === "pending_qc"
				? {
						background: "rgba(237, 108, 2, 0.5)",
						color: "rgb(156, 73, 5)",
						borderRadius: "0.5rem",
						fontSize: "0.875rem",
					}
				: id === "published"
					? {
							background: "rgba(76, 175, 80, 0.5)",
							color: "rgb(22, 104, 25)",
							padding: "0.5rem",
							borderRadius: "0.5rem",
							fontSize: "0.875rem",
						}
					: id === "publish_requested"
						? {
								background: "rgb(255, 191, 0)",
								color: "rgb(114, 90, 20)",
								padding: "0.5rem",
								borderRadius: "0.5rem",
								fontSize: "0.875rem",
							}
						: id === "edited"
							? {
									background: "#d5d5d7",
									color: "rgb(102, 101, 96)",
									// color: " #000000",
									padding: "0.5rem",
									borderRadius: "0.5rem",
									fontSize: "0.875rem",
								}
							: {
									background: "#FFFFFF",
									color: "#000000",
									padding: "0.5rem",
									borderRadius: "0.5rem",
									fontSize: "0.875rem",
								},
);
const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "1rem 0rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));
const LastSeen = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const LastSeenSub = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const ServiceDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [reminderModal, setReminderModal] = React.useState(false);

	const [serviceId, setServiceId] = React.useState("");

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [editStatusRowId, setEditStatusRowId] = React.useState<string | null>(null);

	const [editAssignedRowId, setEditAssignedRowId] = React.useState<string | null>(null);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (router.query.filter) {
			FilteringFunction({
				items: [
					{
						field: "status",
						operator: "contains",
						value: router.query.filter,
					},
				],
			});
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page, queryOptions, router.query.filter]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	// React.useEffect(() => {
	// 	setRowCountState(DashboardData.rowCount || 0);
	// 	setDashboardData(DashboardData.DashboardData || []);
	// }, [DashboardData]);

	/*

  & Next, let's define a function to handle the change in the select and update the status

  */

	const handleChange = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-status", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				status: event.target.value,
				serviceId: cellValues.id,
				pre_status: cellValues.row.status,
				email: session ? (session.user ? session.user.email : "") : "",
				github_username: session ? (session.user ? session.username : "") : "",
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				serviceId: cellValues.id,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				serviceId: cellValues.id,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "service-table/service-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const showInFeatured = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "service-table/service-show-in-featured", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				show_in_featured: e.target.checked,
				serviceId: cellValues.id,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Untouched",
			value: "untouched",
			backgroundColor: "rgba(244, 67, 54, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 2,
			label: "Work in progress",
			value: "work_in_progress",
			backgroundColor: "rgba(33, 150, 243, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 3,
			label: "Pending QC",
			value: "pending_qc",
			backgroundColor: "rgba(237, 108, 2, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 4,
			label: "Published",
			value: "published",
			backgroundColor: "rgba(76, 175, 80, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 5,
			label: "Publish Requested",
			value: "publish_requested",
			backgroundColor: "rgb(255, 191, 0)",
			color: "#FFFFFF",
		},
		{
			id: 6,
			label: "Edited",
			value: "edited",
			backgroundColor: "#d5d5d7",
		},
	];

	const handleClose = () => {
		setEditStatusRowId("");
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "serviceId",
			headerName: "Service ID",
			width: 150,
		},
		{
			field: "serviceName",
			headerName: "Service Name",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/services/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "slug",
			headerName: "Slug",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/services/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 200,
		},
		{
			field: "username",
			headerName: "Username",
			width: 200,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
			valueOptions: menuItems,
			valueFormatter: (params: any) => {
				if (!params) {
					return "";
				}
				return params.value === "untouched"
					? "Untouched"
					: params.value === "work_in_progress"
						? "Work in progress"
						: params.value === "pending_qc"
							? "Pending QC"
							: params.value === "published"
								? "Published"
								: params.value === "publish_requested"
									? "Publish Requested"
									: params.value === "edited"
										? "Edited"
										: "";
			},
			renderCell: (cellValues: any) => {
				const status_option = menuItems.find((option) => option.value === cellValues.value);
				const isEditing = editStatusRowId === cellValues.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditStatusRowId(null)}>
						<Select
							value={status_option ? status_option.label : ""}
							onChange={(e) => {
								const selected_status_option = menuItems.find((option) => option.label === e.target.value);
								if (selected_status_option) {
									handleChange(
										{ target: { value: selected_status_option.value, name: "" } } as SelectChangeEvent,
										cellValues,
									);
									setEditStatusRowId("");
								}
							}}
							variant="standard"
							onClose={handleClose}
							fullWidth
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: status_option ? status_option.backgroundColor : "#FFFFFF",
									color: status_option ? status_option.color : "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
								hover: {
									background: status_option ? status_option.backgroundColor : "#FFFFFF",
									color: status_option ? status_option.color : "#000000",
								},
							}}
						>
							{menuItems.map((option) => (
								<MenuItem
									key={option.value}
									value={option.label}
									sx={{
										background: option.backgroundColor,
										color: option.color,
										padding: "0.5rem 0.5rem 0.5rem 0.5rem",
										borderRadius: "0.5rem",
										fontSize: "0.875rem",
										margin: "0.25rem 0rem 0.25rem 0rem",
										display: "flex",
										justifyContent: "center",
										gap: "0.5rem",
										alignItems: "center",
										"&.Mui-selected": {
											background: option.backgroundColor,
											color: option.color,
										},
										"&.Mui-selected:hover": {
											background: `${option.backgroundColor} !important`,
											color: `${option.color} !important`,
										},
										"&:hover": {
											background: option.backgroundColor,
											color: option.color,
										},
									}}
								>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<ChipData
						id={cellValues.value}
						label={status_option ? status_option.label : ""}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
						sx={{
							width: "100%",
							boxShadow: " 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
							borderRadius: "0.5rem",
							"&:hover": {
								background: status_option ? status_option.backgroundColor : "#FFFFFF",
								color: status_option ? status_option.color : "#000000",
							},
						}}
						onClick={() => {
							setEditStatusRowId(cellValues.id);
						}}
					/>
				);
			},
		},
		{
			field: "createdOn",
			headerName: "Service created on",
			width: 150,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
			},
		},

		{
			field: "last_activity_date",
			headerName: "Last Activity Date",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);

				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});

				return (
					<ActionActions>
						{is_date_14_days_old ? (
							<Tooltip
								title="Last seen more than 14 days ago"
								placement="top"
							>
								<LastSeen
									sx={{
										color: "rgba(255,0,0,0.8)",
									}}
								>
									<LastSeenSub>
										<CalendarTodayOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",").slice(0, 2).join(",")}
										</Typography>
									</LastSeenSub>
									<LastSeenSub>
										<AccessTimeOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",")[2]}
										</Typography>
									</LastSeenSub>
								</LastSeen>
							</Tooltip>
						) : (
							<LastSeen>
								<LastSeenSub>
									<CalendarTodayOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",").slice(0, 2).join(",")}
									</Typography>
								</LastSeenSub>
								<LastSeenSub>
									<AccessTimeOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",")[2]}
									</Typography>
								</LastSeenSub>
							</LastSeen>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "last_activity",
			headerName: "Last Activity",
			width: 150,
			filterable: false,
			// fiterOperators: ["contains"],
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);
				return (
					<ActionActions
						sx={{
							padding: "0rem 0rem",
						}}
					>
						{cellValues.value ? (
							<ReactTimeAgo
								date={new Date(cellValues.value)}
								locale="en-US"
								style={{
									color: is_date_14_days_old ? "#F76C35" : "#000000",
									fontWeight: 400,
								}}
							/>
						) : (
							<Typography
								variant="body2"
								color="error"
							>
								no activity
							</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "show_in_featured",
			headerName: "Show In Featured",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							showInFeatured(e, cellValues);
						}}
					/>
				);
			},
		},
		{
			field: "assigned_to",
			headerName: "Assigned",
			width: 300,
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				const isEditing = editAssignedRowId === cellValues.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditAssignedRowId(null)}>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							fullWidth={true}
							onChange={(event: any) => handleChangeAssigned(event, cellValues)}
							value={data ? data.assigned_to : cellValues.value}
							onClose={() => setEditAssignedRowId(null)}
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
							}}
						>
							{list_of_moderators.map((item: any, index: number) => (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<ActionActions
						sx={{
							padding: "0.8rem",
						}}
					>
						{data && data.assigned_to?.length > 0 ? (
							data.assigned_to.map((item: any, index: number) => (
								<Chip
									id={item._id}
									key={index}
									label={item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"}
									variant="outlined"
									onDelete={() => {
										deleteAssigned(item._id, cellValues);
									}}
									color="primary"
								/>
							))
						) : (
							<Chip
								id="assign"
								label="Assign to"
								variant="outlined"
								onDelete={() => {}}
								deleteIcon={<Add />}
								color="primary"
								onClick={() => setEditAssignedRowId(cellValues.id)}
							/>
						)}
					</ActionActions>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={data ? data.assigned_to : cellValues.value}
					>
						{list_of_moderators.map((item: any, index: number) => (
							<MenuItem
								value={item._id}
								key={index}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}
							>
								{item.first_name + " " + item.last_name}
							</MenuItem>
						))}
					</Select>
				);
			},
		},

		{
			field: "action",
			headerName: "Action",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<Link
							href={"tel:+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://beegru.com/services/" + cellValues.row.action}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						{cellValues?.row?.status === "publish_requested" ? (
							<Button
								variant="contained"
								sx={{
									textTransform: "none",
									borderRadius: "0.5rem",
									fontSize: "0.75rem",
									background: "primary",
									color: "#fff",
									width: "100%",
									margin: "0rem 0rem 0.5rem 0rem",
								}}
								onClick={() => {
									setReminderModal(true);
									setServiceId(cellValues.row.id);
								}}
							>
								remind
							</Button>
						) : null}
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Link
			underline="hover"
			key="2"
			color="inherit"
			href="#"
			onClick={handleClick}
		>
			Moderation
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Services
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Moderation</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<UpdateServiceReminderModal
				open={reminderModal}
				onClose={() => {
					setReminderModal(false);
				}}
				serviceId={serviceId}
			/>
			<LoadingModal open={openModal} />
			<HeadContainer>
				<HeadText>Moderation</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("service")) || accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						initialState={{
							pinnedColumns: {
								right: ["status", "action"],
							},
						}}
						rows={dashboardData?.map((item: any) => {
							const date = new Date(item.created_at);
							return {
								id: item._id,
								serviceId: item._id,
								serviceName: item.title,
								phonenumber: item.created_by
									? item.created_by.user_id
										? item.created_by.user_id.country_code
											? item.created_by.user_id.country_code + " " + item.created_by.user_id.phone
											: "+91 " + item.created_by.user_id.phone
										: "no phone"
									: "no phone",
								slug: item.slug,
								username: item.created_by
									? item.created_by.business_profile_id
										? item.created_by.business_profile_id.title
										: item.created_by.user_id
											? item.created_by.user_id.first_name + " " + item.created_by.user_id.last_name
											: "no name"
									: "no name",
								status: item.status == "active" ? "Untouched" : item.status,
								show_in_featured: item.show_in_featured,
								assigned: item.assigned_to,
								action: item.slug,
								createdOn: date.toLocaleDateString(),
								last_activity_date: item.updated_at ? item.updated_at : item.updated_at,
								last_activity: item.updated_at ? item.updated_at : item.updated_at,
							};
						})}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ServiceDataTable;

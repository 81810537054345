/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, debounce, Divider, Link, Switch, Typography } from "@mui/material";
import { useRouter } from "next/router";
import AddEmployeeModal from "../../../modal/AddEmployeeModal";
import EditEmployeeModal from "../../../modal/EditEmployeeModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
// import AddEmployeeModal from "../../modal/AddEmployeeModal";
// import EditEmployeeModal from "../../modal/EditEmployeeModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 1rem",
}));

const TableButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const EmployeesDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a constant for router, let's use ref to get the current value of router

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the modal for loading

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	/*

  & Next, let's define a local state for the modal for access level

  */

	const [openAccessModal, setOpenAccessMoadal] = React.useState(false);

	const [data, setData] = React.useState();

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a function to close the access level modal

  */

	const handleClose = () => {
		setOpenAccessMoadal(false);
	};
	const [editModal, setEditMoadal] = React.useState(false);

	const handleEditClose = () => {
		setEditMoadal(false);
	};

	/*

  & Next, let's define a function to remove admin access

  */

	const remove = async (cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "employees-table/delete-employee", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				employee_id: cellValues.row.employeeId,
			}),
		});
		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to remove admin access

  */

	const testChange = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "employees-table/update-test", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				_id: cellValues.id,
				test: e.target.checked,
			}),
		});
		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

		  & Next, let's define a useEffect to update the row count when the DashboardData changes

		  */

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "employees-table/employees-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};
	/*

  & Next, let's define a function to handle the click on grant access button

  */

	const handleClickGrandAccess = () => {
		setOpenAccessMoadal(true);
	};

	/*

  & Next, let's define the data for the menu items

  */

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "employeeId",
			headerName: "Employee ID",
			width: 250,
		},
		{
			field: "firstname",
			headerName: "First Name",
			width: 100,
		},
		{
			field: "lastname",
			headerName: "Last Name",
			width: 100,
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 200,
		},
		{
			field: "email",
			headerName: "Email",
			width: 150,
		},
		{
			field: "department",
			headerName: "Department",
			width: 150,
		},
		{
			field: "designation",
			headerName: "Designation",
			width: 150,
		},
		{
			field: "test",
			headerName: "Test",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							testChange(e, cellValues.row);
						}}
					/>
				);
			},
		},
		{
			field: "github_username",
			headerName: "Github Username",
			width: 150,
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<ButtonContainer>
						<TableButton
							variant="outlined"
							sx={{
								color: "#FC8019",
							}}
							onClick={() => {
								setEditMoadal(true);
								setData(cellValues.row);
							}}
						>
							Edit
						</TableButton>
						<TableButton
							variant="contained"
							onClick={() => {
								remove(cellValues);
							}}
						>
							Remove
						</TableButton>
					</ButtonContainer>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<TableButton
					variant="contained"
					color="primary"
					startIcon={<Add />}
					onClick={() => {
						handleClickGrandAccess();
					}}
				>
					Add Employee
				</TableButton>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on breadcrumb

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Emplyees
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Employees</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<AddEmployeeModal
				open={openAccessModal}
				onClose={handleClose}
			/>
			<EditEmployeeModal
				open={editModal}
				onClose={handleEditClose}
				data={data}
			/>
			<HeadContainer>
				<HeadText>Employees</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel ? (
				(accessLevel.includes("admin") && accessLevel.includes("employees")) || accessLevel.includes("super_admin") ? (
					<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any) => {
								return {
									id: item._id,
									employeeId: item.employee_id,
									firstname: item.first_name,
									lastname: item.last_name,
									phonenumber: item.contact
										? item.contact.work.country_code + "" + item.contact.work.phone
										: "Not Available",
									department: item.department,
									designation: item.designation,
									email: item.contact ? item.contact.work.email : "Not Available",
									test: item.test,
									github_username: item.github_username,
								};
							})}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							sx={{
								background: "#fff",
								scrollbarWidth: "thin",
								scrollbarColor: "#B05911 transparent",
								"&::-webkit-scrollbar": {
									width: "10px",
									height: "10px",
								},
								"&::-webkit-scrollbar-thumb": {
									backgroundColor: "#B05911",
									borderRadius: "10px",
									border: "2px solid transparent",
									backgroundClip: "padding-box",
									transition: "background-color 0.3s ease, border-color 0.3s ease",
								},
								"&::-webkit-scrollbar-thumb:hover": {
									backgroundColor: "#8A3D0F",
									borderColor: "#8A3D0F",
								},
								"&::-webkit-scrollbar-track": {
									backgroundColor: "#F5F5F5",
									borderRadius: "10px",
								},
								"&::-webkit-scrollbar-track:hover": {
									backgroundColor: "#E0E0E0",
								},
							}}
						/>
					</Box>
				) : (
					<YouDontHaveAccessComponent />
				)
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default EmployeesDatatable;

/* Common import */
import React from "react";

//@mui imports

import {
	AdminPanelSettings,
	Analytics,
	ArrowBack,
	Bolt,
	BugReport,
	BusinessCenter,
	Campaign,
	Construction,
	ContentCopy,
	Group,
	HolidayVillage,
	LocalActivity,
	LocalAtmTwoTone,
	ManageAccounts,
	ManageSearch,
	MarkUnreadChatAlt,
	Menu,
	Mouse,
	Person,
	ProductionQuantityLimits,
	QrCode,
	RateReview,
	Report,
	RequestQuote,
	Reviews,
	RocketLaunch,
	Subscriptions,
	SupervisedUserCircle,
	Translate,
	Web,
	WhatsApp
} from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import {
	Collapse,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { styled } from "@mui/system";

/* component import */

/* Redux import */

/* Styling */

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	width: "20rem",
	gap: "1rem",
	border:"none",
	// borderRight: "1px solid rgba(0, 0, 0, 0.23)",
	padding: "1rem 1rem",
	height: "100%",
}));

const UserContent = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "4.5rem",
	padding: "0.5rem",
	backgroundColor: "#FFF2E8",
	boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	gap: "1rem",
}));

const ImageContent = styled("img")(({ theme }) => ({
	width: "2.5rem",
	height: "2.5rem",
	borderRadius: "0.25rem",
}));

const HeadingContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.5rem",
	width: "100%",
	textAlign: "left",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "400",
	lineHeight: "1.251rem",
	letterSpacing: "0.009rem",
	color: "rgba(0, 0, 0, 0.54)",
	width: "100%",
	textAlign: "left",
}));

const ListContainer = styled(List)(({ theme }) => ({}));

const MenuContent = ({
	session,
	selectedList,
	handleListItemClick,
	collapseState,
}: {
	session: any;
	selectedList: string;
	handleListItemClick: (event: any, name: string) => void;
	collapseState: Function;
}) => {
	const [openModeration, setOpenModeration] = React.useState(true);
	const [openKyC, setOpenKyC] = React.useState(false);
	const [openTransaction, setOpenTransaction] = React.useState(false);
	const [collapse, setCollapse] = React.useState(false);
	const [collapseKyc, setCollapseKyc] = React.useState(false);
	const [searchQuery, setSearchQuery] = React.useState("");

	// List of items to render
	const listItems = [
		{ name: "Property", icon: <HolidayVillage />, key: "property" },
		{ name: "Service", icon: <Construction />, key: "service" },
		{ name: "Post", icon: <ImageIcon />, key: "post" },
		{ name: "Business Profiles", icon: <BusinessCenter />, key: "business-profiles" },
		{ name: "Boost", icon: <RocketLaunch />, key: "boost" },
		{ name: "User-KYC", icon: <Person />, key: "user-kyc" },
		{ name: "Business-KYC", icon: <BusinessCenter />, key: "business-kyc" },
		{ name: "Analytics", icon: <Analytics />, key: "analytics" },
		{ name: "Leads", icon: <ProductionQuantityLimits />, key: "leads" },
		{ name: "IAM", icon: <AdminPanelSettings />, key: "iam" },
		{ name: "User Management", icon: <ManageAccounts />, key: "user-mgmnt" },
		{ name: "Bug Report", icon: <BugReport />, key: "bug-report" },
		{ name: "Reports", icon: <Report />, key: "report" },
		{ name: "Subscriptions", icon: <Subscriptions />, key: "subscriptions" },
		{ name: "Employees", icon: <Group />, key: "employees" },
		{ name: "Activity Log", icon: <LocalActivity />, key: "activity_log" },
		{ name: "Logs", icon: <ManageSearch />, key: "logs" },
		{ name: "Beegru Points Transaction", icon: <LocalAtmTwoTone />, key: "beegru_points_transaction" },
		{ name: "Cash Transactions", icon: <RequestQuote />, key: "cash_transaction" },
		{ name: "Search Logs", icon: <ManageSearch />, key: "search_logs" },
		{ name: "Boost Pricing Manual Overrides", icon: <Bolt />, key: "boost_pricing_manual_overrides" },
		{ name: "Latest Changes", icon: <MarkUnreadChatAlt />, key: "latest-changes" },
		{ name: "Translations", icon: <Translate />, key: "translations" },
		{ name: "Reviews", icon: <Reviews />, key: "reviews" },
		{ name: "PWA Options", icon: <Web />, key: "pwa" },
		{ name: "WhatsApp", icon: <WhatsApp />, key: "whatsapp" },
		{ name: "WhatsApp Content Templates", icon: <ContentCopy />, key: "twilioWhatsappTemplates" },
		{ name: "Simple campaigns", icon: <Campaign />, key: "simple_campaigns" },
		{ name: "Clicks", icon: <Mouse />, key: "clicks" },
		{ name: "Blogs", icon: <RateReview />, key: "blogs" },
		{ name: "Blog Author Personas", icon: <SupervisedUserCircle />, key: "blog_author_personas" },
		{ name: "URL Shortener", icon: <QrCode />, key: "url_shortener" },
	];

	// Filter the list based on the search query
	const filteredItems = listItems.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

	const handleClick = () => {
		setOpenModeration(!openModeration);
	};

	const handleClickKyc = () => {
		setOpenKyC(!openKyC);
	};

	const handleClickTransaction = () => {
		setOpenTransaction(!openTransaction);
	};

	const handleClickCollapse = () => {
		setCollapse(!collapse);
	};

	const handleClickKycCollapse = () => {
		setCollapseKyc(!collapseKyc);
	};

	React.useEffect(() => {
		collapseState(collapse);
	}, [collapse, collapseState]);

	return (
		<Collapse
			orientation="horizontal"
			in={!collapse}
			collapsedSize={80}
			sx={{
				transition: "width 0.5s",
				width: "20rem",
				height: "100%",
				overflowY: "auto",
				overflowX: "hidden",
			}}
		>
			<ContentContainer>
				<UserContent elevation={1}>
					<IconButton
						onClick={() => {
							handleClickCollapse();
							openModeration ? handleClick() : null;
							openTransaction ? handleClickTransaction() : null;
							openKyC ? handleClickKyc() : null;
						}}
						role="button"
					>
						{collapse ? <Menu /> : <ArrowBack />}
					</IconButton>
					<HeadingContent>
						<HeadText>
							{session ? (session.user ? session.user.name : "You're not signed in") : "You're not signed in"}
						</HeadText>
						<SubText>
							{session
								? session.isGitHub
									? session.user
										? session.username
										: ""
									: session.user
										? session.user.email
										: ""
								: ""}
						</SubText>
					</HeadingContent>
					<ImageContent
						src={
							session
								? session.user
									? session.user.image
									: "https://www.w3schools.com/howto/img_avatar.png"
								: "https://www.w3schools.com/howto/img_avatar.png"
						}
						alt="User"
					/>
				</UserContent>
				{/* Search Input */}
				<TextField
					placeholder="Search..."
					variant="outlined"
					size="small"
					fullWidth
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					sx={{
						background: "#FFFFFF",
						borderRadius: "0.5rem",
						border: "1px solid rgb(83, 83, 83)",
						boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",

						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								border: "none",
							},
							"&:hover fieldset": {
								border: "none",
							},
						},
					}}
				/>
				<List
					component="nav"
					aria-labelledby="nested-list-subheader"
					sx={{
						width: "100%",
						gap: "1rem",
						height: "100%",
						overflowY: "auto",
						scrollbarWidth: "thin", // For Firefox
						scrollbarColor: " transparent", // For Firefox
						"&::-webkit-scrollbar": {
							width: "8px", // Width of the scrollbar
						},
						"&::-webkit-scrollbar-thumb": {
							color: "#B05911", // Color of the scrollbar thumb
							borderRadius: "4px", // Rounded corners for the thumb
						},
						"&::-webkit-scrollbar-thumb:hover": {
							color: "#8A3D0F", // Darker color on hover
						},
						"&::-webkit-scrollbar-track": {
							color: "transparent", // Background of the scrollbar track
						},
					}}
				>
					{filteredItems.map((item) => (
						<ListItemButton
							key={item.key}
							sx={{
								borderRadius: "0.5rem",
								padding: "0.5rem 1rem",
								"&:hover": {
									backgroundColor: "rgba(255, 117, 24, 0.16)",
								},
							}}
							onClick={(event) => handleListItemClick(event, item.key)}
						>
							<ListItemIcon
								sx={selectedList === item.key ? { color: "rgb(247, 108, 53)" } : { color: "rgba(0, 0, 0, 0.54)" }}
							>
								{item.icon}
							</ListItemIcon>
							<ListItemText
								primary={item.name}
								sx={selectedList === item.key ? { color: "rgb(247, 108, 53)" } : { color: "#000000" }}
							/>
						</ListItemButton>
					))}
				</List>
				{/* <List
					component="nav"
					aria-labelledby="nested-list-subheader"
					sx={{ width: "100%", gap: "1rem" }}
				>
					<ListItemButton
						onClick={() => {
							handleClick();
							{
								collapse ? handleClickCollapse() : null;
							}
						}}
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
					>
						<ListItemIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
							<Shield />
						</ListItemIcon>
						<ListItemText primary="Moderation" />
						{openModeration ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse
						in={openModeration}
						timeout="auto"
						unmountOnExit
					>
						<List
							component="div"
							disablePadding
						>
							<ListItemButton
								sx={{
									pl: 4,
									marginTop: "1rem",
									borderRadius: "0.5rem",

									padding: "0.5rem 2rem",
									"&:hover": {
										backgroundColor: "rgba(255, 117, 24, 0.16)",
									},
								}}
								onClick={(event) => handleListItemClick(event, "property")}
							>
								<ListItemIcon
									sx={
										selectedList === "property"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "rgba(0, 0, 0, 0.54)",
												}
									}
								>
									<HolidayVillage />
								</ListItemIcon>
								<ListItemText
									primary="Property"
									sx={
										selectedList === "property"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "#000000",
												}
									}
								/>
							</ListItemButton>
							<ListItemButton
								sx={{
									pl: 4,

									borderRadius: "0.5rem",

									padding: "0.5rem 2rem",
									"&:hover": {
										backgroundColor: "rgba(255, 117, 24, 0.16)",
									},
								}}
								onClick={(event) => handleListItemClick(event, "service")}
							>
								<ListItemIcon
									sx={
										selectedList === "service"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "rgba(0, 0, 0, 0.54)",
												}
									}
								>
									<Construction />
								</ListItemIcon>
								<ListItemText
									primary="Service"
									sx={
										selectedList === "service"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "#000000",
												}
									}
								/>
							</ListItemButton>
							<ListItemButton
								sx={{
									pl: 4,

									borderRadius: "0.5rem",

									padding: "0.5rem 2rem",
									"&:hover": {
										backgroundColor: "rgba(255, 117, 24, 0.16)",
									},
								}}
								onClick={(event) => handleListItemClick(event, "post")}
							>
								<ListItemIcon
									sx={
										selectedList === "post"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "rgba(0, 0, 0, 0.54)",
												}
									}
								>
									<ImageIcon />
								</ListItemIcon>
								<ListItemText
									primary="Post"
									sx={
										selectedList === "post"
											? { color: "rgb(247, 108, 53)" }
											: {
													color: "#000000",
												}
									}
								/>
							</ListItemButton>
						</List>
					</Collapse>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "business-profiles")}
					>
						<ListItemIcon
							sx={
								selectedList === "business-profiles"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<BusinessCenter />
						</ListItemIcon>
						<ListItemText primary="Business Profiles" /> */}
				{/* {open ? <ExpandLess /> : <ExpandMore />}  */}
				{/* </ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "boost")}
					>
						<ListItemIcon
							sx={
								selectedList === "boost"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<RocketLaunch />
						</ListItemIcon>
						<ListItemText primary="Boost" /> */}
				{/* {open ? <ExpandLess /> : <ExpandMore />}  */}
				{/* </ListItemButton> */}
				{/*
					<List
						component="nav"
						aria-labelledby="nested-list-subheader"
						sx={{ width: "100%", gap: "1rem" }}
					>
						<ListItemButton
							onClick={() => {
								handleClickKyc();
								{
									collapseKyc ? handleClickKycCollapse() : null;
								}
							}}
							sx={{
								borderRadius: "0.5rem",
								padding: "0.5rem 1rem",
								"&:hover": {
									backgroundColor: "rgba(255, 117, 24, 0.16)",
								},
							}}
						>
							<ListItemIcon
								sx={
									openKyC
										? { color: "rgb(247, 108, 53)" }
										: {
												color: "rgba(0, 0, 0, 0.54)",
											}
								}
							>
								<DocumentScanner />
							</ListItemIcon>
							<ListItemText primary="KYC" />
							{openKyC ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse
							in={openKyC}
							timeout="auto"
							unmountOnExit
						>
							<List
								component="div"
								disablePadding
							>
								<ListItemButton
									sx={{
										pl: 4,

										borderRadius: "0.5rem",

										padding: "0.5rem 2rem",
										"&:hover": {
											backgroundColor: "rgba(255, 117, 24, 0.16)",
										},
									}}
									onClick={(event) => handleListItemClick(event, "user-kyc")}
								>
									<ListItemIcon
										sx={
											selectedList === "user-kyc"
												? { color: "rgb(247, 108, 53)" }
												: {
														color: "rgba(0, 0, 0, 0.54)",
													}
										}
									>
										<Person />
									</ListItemIcon>
									<ListItemText primary="User-KYC" />
								</ListItemButton>{" "}
								<ListItemButton
									sx={{
										pl: 4,

										borderRadius: "0.5rem",

										padding: "0.5rem 2rem",
										"&:hover": {
											backgroundColor: "rgba(255, 117, 24, 0.16)",
										},
									}}
									onClick={(event) => handleListItemClick(event, "business-kyc")}
								>
									<ListItemIcon
										sx={
											selectedList === "business-kyc"
												? { color: "rgb(247, 108, 53)" }
												: {
														color: "rgba(0, 0, 0, 0.54)",
													}
										}
									>
										<BusinessCenter />
									</ListItemIcon>
									<ListItemText primary="Business-KYC" />
								</ListItemButton>
							</List>
						</Collapse>
					</List>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "analytics")}
					>
						<ListItemIcon
							sx={
								selectedList === "analytics"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Analytics />
						</ListItemIcon>
						<ListItemText primary="Analytics" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "leads")}
					>
						<ListItemIcon
							sx={
								selectedList === "leads"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ProductionQuantityLimits />
						</ListItemIcon>
						<ListItemText primary="Leads" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "iam")}
					>
						<ListItemIcon
							sx={
								selectedList === "iam"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<AdminPanelSettings />
						</ListItemIcon>
						<ListItemText primary="IAM" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "user-mgmnt")}
					>
						<ListItemIcon
							sx={
								selectedList === "user-mgmnt"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ManageAccounts />
						</ListItemIcon>
						<ListItemText primary="User Management" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "bug-report")}
					>
						<ListItemIcon
							sx={
								selectedList === "bug-report"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<BugReport />
						</ListItemIcon>
						<ListItemText primary="Bug Report" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "report")}
					>
						<ListItemIcon
							sx={
								selectedList === "report"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Report />
						</ListItemIcon>
						<ListItemText primary="Reports" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "subscriptions")}
					>
						<ListItemIcon
							sx={
								selectedList === "subscriptions"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Subscriptions />
						</ListItemIcon>
						<ListItemText primary="Subscriptions" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "employees")}
					>
						<ListItemIcon
							sx={
								selectedList === "employees"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Group />
						</ListItemIcon>
						<ListItemText primary="Employees" /> */}
				{/* {open ? <ExpandLess /> : <ExpandMore />}  */}
				{/* </ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "activity_log")}
					>
						<ListItemIcon
							sx={
								selectedList === "activity_log"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<LocalActivity />
						</ListItemIcon>
						<ListItemText primary="Activity Log" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "logs")}
					>
						<ListItemIcon
							sx={
								selectedList === "logs"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ManageSearch />
						</ListItemIcon>
						<ListItemText primary="Logs" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "beegru_points_transaction")}
					>
						<ListItemIcon
							sx={
								selectedList === "beegru_points_transaction"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<LocalAtmTwoTone />
						</ListItemIcon>
						<ListItemText primary="Beegru Points transaction" />
					</ListItemButton> */}
				{/* <ListItemButton
            sx={{
              borderRadius: "0.5rem",
              padding: "0.5rem 1rem",
              width: "100%",
              "&:hover": {
                backgroundColor: "rgba(255, 117, 24, 0.16)",
              },
            }}
            onClick={(event) =>
              handleListItemClick(event, "manual_points_transaction")
            }
          >
            <ListItemIcon
              sx={
                selectedList === "manual_points_transaction"
                  ? { color: "rgb(247, 108, 53)" }
                  : {
                      color: "rgba(0, 0, 0, 0.54)",
                    }
              }
            >
              <CurrencyExchangeTwoTone />
            </ListItemIcon>
            <ListItemText primary="Manual Points transaction" />
          </ListItemButton> */}
				{/* <ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "cash_transaction")}
					>
						<ListItemIcon
							sx={
								selectedList === "cash_transaction"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<RequestQuote />
						</ListItemIcon>
						<ListItemText primary="Cash transactions" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "search_logs")}
					>
						<ListItemIcon
							sx={
								selectedList === "search_logs"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ManageSearch />
						</ListItemIcon>
						<ListItemText primary="Search Logs" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "boost_pricing_manual_overrides")}
					>
						<ListItemIcon
							sx={
								selectedList === "boost_pricing_manual_overrides"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Bolt />
						</ListItemIcon>
						<ListItemText primary="Boost pricing manual overrides" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "latest-changes")}
					>
						<ListItemIcon
							sx={
								selectedList === "latest-changes"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<MarkUnreadChatAlt />
						</ListItemIcon>
						<ListItemText primary="Latest Changes" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "translations")}
					>
						<ListItemIcon
							sx={
								selectedList === "translations"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Translate />
						</ListItemIcon>
						<ListItemText primary="Translations" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "reviews")}
					>
						<ListItemIcon
							sx={
								selectedList === "reviews"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Reviews />
						</ListItemIcon>
						<ListItemText primary="Reviews" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "pwa")}
					>
						<ListItemIcon
							sx={
								selectedList === "pwa"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Web />
						</ListItemIcon>
						<ListItemText primary="PWA Options" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "whatsapp")}
					>
						<ListItemIcon
							sx={
								selectedList === "whatsapp"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<WhatsApp />
						</ListItemIcon>
						<ListItemText primary="WhatsApp" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "twilioWhatsappTemplates")}
					>
						<ListItemIcon
							sx={
								selectedList === "twilioWhatsappTemplates"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<ContentCopy />
						</ListItemIcon>
						<ListItemText primary="WhatsApp Content Templates" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "simple_campaigns")}
					>
						<ListItemIcon
							sx={
								selectedList === "simple_campaigns"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Campaign />
						</ListItemIcon>
						<ListItemText primary="Simple campaigns" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "clicks")}
					>
						<ListItemIcon
							sx={
								selectedList === "clicks"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<Mouse />
						</ListItemIcon>
						<ListItemText primary="Clicks" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "blogs")}
					>
						<ListItemIcon
							sx={
								selectedList === "blogs"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<RateReview />
						</ListItemIcon>
						<ListItemText primary="Blogs" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "blog_author_personas")}
					>
						<ListItemIcon
							sx={
								selectedList === "blog_author_personas"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<SupervisedUserCircle />
						</ListItemIcon>
						<ListItemText primary="Blog Author Personas" />
					</ListItemButton>
					<ListItemButton
						sx={{
							borderRadius: "0.5rem",
							padding: "0.5rem 1rem",
							width: "100%",
							"&:hover": {
								backgroundColor: "rgba(255, 117, 24, 0.16)",
							},
						}}
						onClick={(event) => handleListItemClick(event, "url_shortener")}
					>
						<ListItemIcon
							sx={
								selectedList === "url_shortener"
									? { color: "rgb(247, 108, 53)" }
									: {
											color: "rgba(0, 0, 0, 0.54)",
										}
							}
						>
							<QrCode />
						</ListItemIcon>
						<ListItemText primary="URL Shortener" />
					</ListItemButton>
				</List> */}
			</ContentContainer>
		</Collapse>
	);
};

export default MenuContent;

/* Common Imports */

import { styled } from "@mui/system";
import { merge } from "lodash";
import React from "react";

/* Component Imports */

import { colors, Typography } from "@mui/material";
import ReactApexChart from ".";
import BaseOptionChart from "./BaseOptionChart";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	margin: "0rem 0rem 0rem 0rem",
	borderRadius: "0.5rem",
	backgroundColor: "#ffffff",
}));

const Title = styled(Typography)(({ theme }) => ({
	padding: "1rem",
	fontSize: "1rem",
	fontWeight: 500,
	lineHeight: "123.5%",
	textTransform: "uppercase",
}));

const AnalyticsBarGraph = (props: any) => {
	/* x-axis data */
	const chartOptions = merge(BaseOptionChart(), {
		xaxis: {
			type: props.graphData?.x,
			tickPlacement: "on",
			labels: {
				rotate: 0,
				style: {
					fontSize: "8px",
					colors: ["#000000"],
				},
			},
		},
		chart: {
			animations: {
				enabled: true,
				easing: "easeinout",
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
			colors: colors,
		},
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'light',
				type: "horizontal",
				shadeIntensity: 0.25,
				gradientToColors: undefined,
				inverseColors: true,
				opacityFrom: 0.85,
				opacityTo: 0.85,
				stops: [50, 0, 100]
			},
		},

		colors: ["#f76c35"],
	});

	/* series data */

	const seriesData = props.graphData?.y.map((item: number, index: number) => ({
		x: props.title.includes("Budget")
			? props.graphData.x[index]
			: props.title.includes("PDF")
				? props.graphData.x[index]
				: props.title.includes("Top User")
					? props.graphData.x[index].name
						? props.graphData.x[index].name
						: props.graphData.x[index]._id
					: props.graphData.x[index].title || props.graphData.x[index].name,
		y: item,
	}));

	const series = [
		{
			name: props.title,
			data: seriesData,
		},
	];


	return (
		<React.Fragment>
			<MainContainer>
				<Title variant="h6">{props.title}</Title>
				<ReactApexChart
					type="bar"
					series={series}
					options={chartOptions}
					height={450}
					width="100%"
				/>
			</MainContainer>
		</React.Fragment>
	);
};

export default AnalyticsBarGraph;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Chip, debounce, Divider, Link, Switch, Typography } from "@mui/material";
import { useRouter } from "next/router";
import LoadingModal from "../../../modal/LoadingModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "user-premium"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "agent-premium"
			? {
					backgroundColor: "#FFD700",
				}
			: id === "developer-premium"
				? {
						backgroundColor: "#FFFF00",
					}
				: id === "landowner-premium"
					? {
							backgroundColor: "rgb(30,144,255,0.6)",
						}
					: id === "professional-premium"
						? {
								backgroundColor: " #00FF00",
							}
						: {
								backgroundColor: "rgba(255, 255, 255, 0.002)",
							},
);

const StatusPlan = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "Cancelled"
		? {
				backgroundColor: "#EEE3CB",
			}
		: id === "Active"
			? {
					backgroundColor: "rgba(76, 175, 80, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0.5rem 0rem 0rem 0rem",
}));

const SubscriptionsDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [openModal, setOpenMoadal] = React.useState(false);

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */
	React.useEffect(() => {
		FilteringFunction(queryOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const cancelPlans = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "subscriptions-table/cancel-plans", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				cancel: event,
				planId: cellValues,
			}),
		});
		FilteringFunction(queryOptions);
		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "subscriptions-table/subscriptions-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setAccessLevel(res.payload.accessLevel);
			setListOfModerators(res.payload.list_of_moderators);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 200,
		},
		{
			field: "username",
			headerName: "Username",
			width: 200,
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},
		{
			field: "start_date",
			headerName: "Start Date",
			width: 150,
			filterable: false,
		},

		{
			field: "end_date",
			headerName: "End Date",
			width: 150,
			filterable: false,
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
			renderCell: (cellValues: any) => {
				return cellValues.value?.length > 0 ? (
					<ActionActions>
						<StatusPlan
							id={cellValues.value}
							label={cellValues.value}
							variant="outlined"
						/>
					</ActionActions>
				) : (
					<StatusPlan
						id=""
						label=""
						color="primary"
					/>
				);
			},
		},
		{
			field: "plan",
			headerName: "Plan",
			width: 200,
			renderCell: (cellValues: any) => {
				return cellValues.value?.length > 0 ? (
					<ActionActions>
						<ChipData
							id={cellValues.value}
							label={cellValues.value}
							variant="outlined"
						/>
					</ActionActions>
				) : (
					<ChipData
						id=""
						label=""
						color="primary"
					/>
				);
			},
		},
		{
			field: "cancel",
			headerName: "Cancel Plan",
			width: 100,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							cancelPlans(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Subscriptions
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Subscriptions</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<LoadingModal open={openModal} />
			<HeadContainer>
				<HeadText>Subscriptions</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("subscriptions")) ||
				accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any) => {
							const startdate = new Date(item.start_date);
							const enddate = new Date(item.end_date);
							return {
								id: item._id,
								userId: item.plan_slug?.includes("user")
									? item.subscribed_user_id
										? item.subscribed_user_id._id
										: "--"
									: item.subscribed_entity_id
										? item.subscribed_entity_id._id
										: "--",
								username: item.plan_slug?.includes("user")
									? item.subscribed_user_id
										? item.subscribed_user_id.first_name + " " + item.subscribed_user_id.last_name
										: "--"
									: item.subscribed_entity_id
										? item.subscribed_entity_id.title
										: "--",
								phonenumber: item.plan_slug?.includes("user")
									? item.subscribed_user_id
										? (item.subscribed_user_id.country_code ? item.subscribed_user_id.country_code + " " : "+91 ") +
											item.subscribed_user_id.phone
										: "--"
									: item.subscribed_entity_id
										? item.subscribed_entity_id.phone.country_code + " " + item.subscribed_entity_id.phone.phone_number
										: "--",
								start_date: startdate.toLocaleDateString(),
								end_date: enddate.toLocaleDateString(),
								status: item.cancelled ? "Cancelled" : "Active",
								plan: item.plan_slug,
								cancel: item.cancelled,
							};
						})}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default SubscriptionsDatatable;

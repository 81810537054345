9; /*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import {
	Autocomplete,
	Box,
	Chip,
	IconButton,
	InputAdornment,
	Stack,
	styled,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import LoadingComponent from "../../sub-components/LoadingComponent";
import AnalyticsBarGraph from "../analticsBarGraph";
import AnalyticsGraph from "../analyticsGraph";
import HeatMapForDeveloperAndProfessional from "../heatMapForDeveloperAndProfessional";
import HeatMapForLandownerAndAgents from "../heatMapsForAgentsAndLandowner";
import AllBusinessProfilesViewsTable from "./AllBusinessProfilesViewsTable";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
	id?: string;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "1px solid #B05911" : "none",
	textTransform: "capitalize",
	minWidth: "13rem",
	// width: "100%",
	minHeight: "auto",
	borderRadius: "16px",
	margin: "0rem 1rem 0.5rem 0rem",
	background: "#FFFFFF",
}));
const MainTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
	fontSize: "1.2rem",
	fontWeight: 600,
}));

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) => ({
	background: "#FEEADA",
	color: "#B05911",
	border: "1px solid #B05911",
	textTransform: "capitalize",
	fontSize: "0.75rem",
	padding: "0.5rem 1rem",
	borderRadius: "0.5rem",
	margin: "0rem 1rem 0.5rem 0rem",
	"&:hover": {
		boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
		background: "#FEEADA",
	},
	"&:active": {
		boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
		background: "#FEEADA",
	},
	"&:focus": {
		boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
		background: "#FEEADA",
	},

	cursor: "pointer",
}));
const TitleContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
	// height: "100%",
	width: "100%",
	gap: "0.5rem",
	textTransform: "lowercase",
	minWidth: "12rem",
	minHeight: "3rem",
	color: " #B05911",
}));

const TitleMain = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	// border: tabColor === "primary" ? "2px solid #F76C35" : "2px solid rgb(187, 185, 184)",
	borderRadius: "8px",
	color: " #B05911",
}));

const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	padding: "1rem 0rem 0rem 0rem",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const ContainerForSelect = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "2rem 0rem 0rem 2.5rem",
}));

const MiddleContainerTwo = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	padding: "2rem",
}));

const GraphContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));
/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={5}>{children}</Box>}
		</div>
	);
}

const BusinessProfilesAnalytics = ({
	topBusinessProfilesTimeSpent,
	topBusinessProfiles,
	agentsAreaOfOperations,
	developresAreaOfOperations,
	landownerAreaOfOperations,
	professionalAreaOfOperations,
}: {
	topBusinessProfilesTimeSpent: any;
	topBusinessProfiles: any;
	agentsAreaOfOperations: any;
	developresAreaOfOperations: any;
	landownerAreaOfOperations: any;
	professionalAreaOfOperations: any;
}) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const [topBusinessProfilesData, setTopBusinessProfilesData] = React.useState<any>();
	const [topBusinessProfilesTimeSpentData, setTopBusinessProfilesTimeSpentData] = React.useState<any>();
	const [topAgents, setTopAgents] = React.useState<any>();
	const [topAgentsTimeSpent, setTopAgentsTimeSpent] = React.useState<any>();
	const [topDevelopers, setTopDevelopers] = React.useState<any>();
	const [topDevelopersTimeSpent, setTopDevelopersTimeSpent] = React.useState<any>();
	const [topProfessionals, setTopProfessionals] = React.useState<any>();
	const [topProfessionalsTimeSpent, setTopProfessionalsTimeSpent] = React.useState<any>();
	const [topLandowners, setTopLandowners] = React.useState<any>();
	const [topLandownersTimeSpent, setTopLandownersTimeSpent] = React.useState<any>();
	const [autocompleteValue, setAutocompleteValue] = React.useState<string | null>(null);
	const [options, setOptions] = React.useState<any[]>([]);

	const [last_24_hours, setLast_24_hours] = React.useState<any>();
	const [last_7_days, setLast_7_days] = React.useState<any>();
	const [last_30_days, setLast_30_days] = React.useState<any>();
	const [last_12_months, setLast_12_months] = React.useState<any>();
	const [searchButtonClicked, setSearchButtonClicked] = React.useState<boolean>(false);
	const [title, setTitle] = React.useState<string>("");
	const [slug, setSlug] = React.useState<string>("");
	const [showWeeks, setShowWeeks] = React.useState<boolean>(false);
	const [showMonths, setShowMonths] = React.useState<boolean>(false);
	const [showYears, setShowYears] = React.useState<boolean>(false);
	const [showLast24, setShowLast24] = React.useState<boolean>(false);
	const usertext = React.useRef<HTMLInputElement>(null);

	const [allBusinessProfilesViews, setAllBusinessProfilesViews] = React.useState<any>();
	const [page, setPage] = React.useState(0);

	React.useEffect(() => {
		setTopBusinessProfilesData(topBusinessProfiles);
		setTopBusinessProfilesTimeSpentData(topBusinessProfilesTimeSpent);
	}, [topBusinessProfiles, topBusinessProfilesTimeSpent]);

	const fetchOptions = {
		method: "POST",
		cache: "no-store" as RequestCache,
		headers: {
			"Content-Type": "text/plain",
			"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
		},
		body: JSON.stringify({}),
	};

	React.useEffect(() => {
		switch (value) {
			case 0:
				break;
			case 1:
				const fetchAgentData = async () => {
					try {
						const [agentsViewsResponse, agentsTimeSpentResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-agents-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-agents-time-spent", fetchOptions),
						]);

						const [agentsViewsRes, agentsTimeSpentRes] = await Promise.all([
							agentsViewsResponse.json(),
							agentsTimeSpentResponse.json(),
						]);
						setTopAgents(agentsViewsRes.payload);
						setTopAgentsTimeSpent(agentsTimeSpentRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 1 && !topAgents && !topAgentsTimeSpent) {
					fetchAgentData();
				}
				break;
			case 2:
				const fetchDeveloperData = async () => {
					try {
						const [developerViewsResponse, developerTimeSpentResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-developers-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-developers-time-spent", fetchOptions),
						]);

						const [developerViewsRes, developerTimeSpentRes] = await Promise.all([
							developerViewsResponse.json(),
							developerTimeSpentResponse.json(),
						]);
						setTopDevelopers(developerViewsRes.payload);
						setTopDevelopersTimeSpent(developerTimeSpentRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 2 && !topDevelopers && !topDevelopersTimeSpent) {
					fetchDeveloperData();
				}
				break;
			case 3:
				const fetchProfessionalData = async () => {
					try {
						const [professionalViewsResponse, professionalTimeSpentResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-professionals-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-professionals-time-spent", fetchOptions),
						]);

						const [professionalViewsRes, professionalTimeSpentRes] = await Promise.all([
							professionalViewsResponse.json(),
							professionalTimeSpentResponse.json(),
						]);
						setTopProfessionals(professionalViewsRes.payload);
						setTopProfessionalsTimeSpent(professionalTimeSpentRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 3 && !topProfessionals && !topProfessionalsTimeSpent) {
					fetchProfessionalData();
				}
				break;

			case 4:
				const fetchLandownerData = async () => {
					try {
						const [landownerViews, landownerTimeSpent] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-landowners-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "/analytics-api/top-landowners-time-spent", fetchOptions),
						]);

						const [landownerViewsRes, landownerTimeSpentRes] = await Promise.all([
							landownerViews.json(),
							landownerTimeSpent.json(),
						]);
						setTopLandowners(landownerViewsRes.payload);
						setTopLandownersTimeSpent(landownerTimeSpentRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 4 && !topLandowners && !topLandownersTimeSpent) {
					fetchLandownerData();
				}
				break;
			case 5:
				const fetchAllBusinesssProfileViewsData = async () => {
					try {
						const [allBusinessProfilesViews] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/all-business-profiles-views", {
								method: "POST",
								cache: "no-store",
								headers: {
									"Content-Type": "text/plain",
									"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
								},
								body: JSON.stringify({
									page,
								}),
							}),
						]);

						const [allBusinessProfilesViewsRes] = await Promise.all([allBusinessProfilesViews.json()]);
						setAllBusinessProfilesViews(allBusinessProfilesViewsRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if ((value === 5 && !allBusinessProfilesViews) || page >= 0) {
					fetchAllBusinesssProfileViewsData();
				}
				break;

			default:
				break;
		}
		setSearchButtonClicked(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, page]);

	const handleClickWeek = () => {
		setShowWeeks(true);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickMonth = () => {
		setShowWeeks(false);
		setShowMonths(true);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickYear = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(true);
		setShowLast24(false);
	};
	const handleClickLast24 = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(true);
	};

	const handleAutocompleteChange = async (value: any) => {
		setAutocompleteValue(value);
	};

	const handleAutocompleteInputChange = async (search_term: string) => {
		/*

    & Next, let's describe a debounced, asynchronous function that will be called when the company name changes.

    */

		const debouncedExecutor: Function = async (): Promise<void> => {
			const response = await (
				await fetch(
					process.env.PRODUCTION_API_URL +
						"/analytics-api/fetch-business-profiles-autocomplete?search_term=" +
						search_term,
					{
						cache: "no-store",
						headers: { "Content-Type": "application/json" },
						method: "POST",
					},
				)
			).json();

			if (response.length > 0) {
				console.log(response);
				setOptions(response);
			} else {
			}
		};

		/*

    & Next, let's use setTimeout to debounce the function call.

    */

		const debouncer: number = setTimeout(debouncedExecutor, 500);

		/*

    & Next, let's return a cleanup function that will clear the timeout.

    */

		return (): void => clearTimeout(debouncer);
	};

	const submitSearch = async () => {
		setShowLast24(false);
		setShowMonths(false);
		setShowWeeks(false);
		setShowYears(false);
		const response = await (
			await fetch(process.env.PRODUCTION_API_URL + "/analytics-api/single-business-profiles-views", {
				cache: "no-store",
				headers: { "Content-Type": "text/plain" },
				method: "POST",
				body: JSON.stringify({ autocompleteValue }),
			})
		).json();
		const timeSeries = response.payload;
		setLast_24_hours(timeSeries.graph_data.last_24_hours);
		setLast_7_days(timeSeries.graph_data.last_7_days);
		setLast_30_days(timeSeries.graph_data.last_30_days);
		setLast_12_months(timeSeries.graph_data.last_12_months);
		setShowWeeks(true);
		setTitle(timeSeries.title);
		setSlug(timeSeries.slug);
	};

	React.useEffect(() => {
		if (searchButtonClicked && usertext.current) {
			usertext.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [searchButtonClicked]);

	if (!topBusinessProfiles || !topBusinessProfilesTimeSpent) {
		return (
			<div style={{ width: "100%", height: "100vh", padding: "2rem" }}>
				<LoadingComponent />
			</div>
		);
	}

	return (
		<React.Fragment>
			<TabsContainer>
				<MiddleContainerTwo>
					<HeatMapForLandownerAndAgents
						agentsAreaOfOperations={agentsAreaOfOperations}
						landownerAreaOfOperations={landownerAreaOfOperations}
					/>

					<HeatMapForDeveloperAndProfessional
						developresAreaOfOperations={developresAreaOfOperations}
						professionalAreaOfOperations={professionalAreaOfOperations}
					/>
				</MiddleContainerTwo>
				<Tabs
					value={value}
					orientation="horizontal"
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "transparent",
						},
					}}
				>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 0 ? "primary" : "default"}>
									<MainTitle>Top Business Profiles</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={0}
						tabColor={value === 0 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 1 ? "primary" : "default"}>
									<MainTitle>Top Agents</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={1}
						tabColor={value === 1 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 2 ? "primary" : "default"}>
									<MainTitle>Top Developers</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={2}
						tabColor={value === 2 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 3 ? "primary" : "default"}>
									<MainTitle>Top Professionals</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={3}
						tabColor={value === 3 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 4 ? "primary" : "default"}>
									<MainTitle>Top Landowners</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={4}
						tabColor={value === 4 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 5 ? "primary" : "default"}>
									<MainTitle>All Business Profiles</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={5}
						tabColor={value === 5 ? "primary" : "default"}
					/>
				</Tabs>
			</TabsContainer>
			<ContainerForSelect>
				<Autocomplete
					disablePortal
					options={options}
					sx={{
						width: "70%",
						"& .MuiOutlinedInput-root": {
							backgroundColor: "#ffffff",
							borderRadius: "8px",
							"& fieldset": {
								borderColor: "#B05911",
							},
							"&:hover fieldset": {
								borderColor: "#F76C35",
							},
						},
						"& .MuiAutocomplete-inputRoot": {
							padding: "0.5rem",
						},
						"& .MuiInputBase-adornedEnd": {
							paddingRight: "0.5rem",
						},
					}}
					value={autocompleteValue ? autocompleteValue : null}
					onChange={(event, value) => handleAutocompleteChange(value)}
					onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						if (event !== null) {
							setSearchButtonClicked(false);
							handleAutocompleteInputChange(event.target.value);
						}
					}}
					getOptionLabel={(option) => option.title + " - " + option.business_profile_type}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder="Search for a business profile"
							sx={{
								background: "#FEEADA",
								borderRadius: "0.5rem",
								border: "1px solid #B05911",
								boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",

								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										border: "none",
									},
									"&:hover fieldset": {
										border: "none",
									},
								},
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={(event: React.MouseEvent<HTMLElement>) => {
												event.stopPropagation();
												setSearchButtonClicked(true);

												submitSearch();
											}}
											edge="end"
										>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			</ContainerForSelect>
			<TabContentContainer>
				<TabPanel
					value={value}
					index={0}
				>
					<GraphContainer>
						<AnalyticsBarGraph
							title="Top Business Profiles Views"
							graphData={topBusinessProfilesData}
						/>
						<AnalyticsBarGraph
							title="Top Business Profiles Time Spent"
							graphData={topBusinessProfilesTimeSpentData}
						/>
					</GraphContainer>
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
				>
					<GraphContainer>
						<AnalyticsBarGraph
							title="Top Agent Profiles Views"
							graphData={topAgents}
						/>
						<AnalyticsBarGraph
							title="Top Agent Profiles Time Spent"
							graphData={topAgentsTimeSpent}
						/>
					</GraphContainer>
				</TabPanel>
				<TabPanel
					value={value}
					index={2}
				>
					<GraphContainer>
						<AnalyticsBarGraph
							title="Top Developer Profiles Views"
							graphData={topDevelopers}
						/>
						<AnalyticsBarGraph
							title="Top Developer Profiles Time Spent"
							graphData={topDevelopersTimeSpent}
						/>
					</GraphContainer>
				</TabPanel>
				<TabPanel
					value={value}
					index={3}
				>
					<GraphContainer>
						<AnalyticsBarGraph
							title="Top Professional Profiles Views"
							graphData={topProfessionals}
						/>
						<AnalyticsBarGraph
							title="Top Professional Profiles Time Spent"
							graphData={topProfessionalsTimeSpent}
						/>
					</GraphContainer>
				</TabPanel>
				<TabPanel
					value={value}
					index={4}
				>
					<GraphContainer>
						<AnalyticsBarGraph
							title="Top Landowner Profiles Views"
							graphData={topLandowners}
						/>
						<AnalyticsBarGraph
							title="Top Landowner Profiles Time Spent"
							graphData={topLandownersTimeSpent}
						/>
					</GraphContainer>
				</TabPanel>
				<TabPanel
					value={value}
					index={5}
				>
					<AllBusinessProfilesViewsTable
						businessProfilesViews={allBusinessProfilesViews}
						page={page}
						setPage={setPage}
					/>
				</TabPanel>

				{searchButtonClicked ? (
					<TabContentContainer
						ref={usertext}
						sx={{
							padding: "0.5rem 2.5rem 2.5rem 2.5rem",
						}}
					>
						<Stack
							direction="row"
							spacing={2}
							padding={2}
						>
							<ChipData
								label="week"
								variant="outlined"
								onClick={handleClickWeek}
								id={showWeeks ? "week" : ""}
							/>
							<ChipData
								label="month"
								variant="outlined"
								onClick={handleClickMonth}
								id={showMonths ? "month" : ""}
							/>
							<ChipData
								label="year"
								variant="outlined"
								onClick={handleClickYear}
								id={showYears ? "year" : ""}
							/>
							<ChipData
								label="last 24 hours"
								variant="outlined"
								onClick={handleClickLast24}
								id={showLast24 ? "last 24 hours" : ""}
							/>
						</Stack>
						{showWeeks ? (
							<AnalyticsGraph
								title={`${title} Profiles Views Weekly`}
								graphData={last_7_days}
							/>
						) : null}
						{showMonths ? (
							<AnalyticsGraph
								title={`${title} Profiles Views Monthly`}
								graphData={last_30_days}
							/>
						) : null}
						{showYears ? (
							<AnalyticsGraph
								title={`${title} Profiles Views Yearly`}
								graphData={last_12_months}
							/>
						) : null}
						{showLast24 ? (
							<AnalyticsGraph
								title={`${title} Profiles Views Hourly`}
								graphData={last_24_hours}
							/>
						) : null}
					</TabContentContainer>
				) : null}
			</TabContentContainer>
		</React.Fragment>
	);
};

export default BusinessProfilesAnalytics;

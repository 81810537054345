9; /*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import { Box, FormControl, MenuItem, Select, SelectChangeEvent, styled, Tab, Tabs, Typography } from "@mui/material";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import LoadingComponent from "../../sub-components/LoadingComponent";
import AnalyticsDonutChart from "../analyticsDonutchart";
import AnalyticsGraph from "../analyticsGraph";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "1px solid #B05911" : "none",
	textTransform: "capitalize",
	minWidth: "23rem",
	// width: "100%",
	minHeight: "8rem",
	borderRadius: "16px",
	margin: "0rem 1rem 0.5rem 0rem",
	background: "#FFFFFF",
}));

const TitleContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
	// height: "100%",
	width: "100%",
	gap: "0.5rem",
	color: " #DBF1E8",
	minWidth: "12rem",
	minHeight: "3rem",
}));

const TitleMain = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	// border: tabColor === "primary" ? "2px solid #F76C35" : "2px solid rgb(187, 185, 184)",
	borderRadius: "8px",
	// boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	color: " #B05911",
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	color: " #B05911",
	padding: "0rem 0.5rem 0rem 0.5rem",
}));
const MainTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
	fontSize: "1.2rem",
	fontWeight: 600,
}));
const SubTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
}));
const TitleAndIcon = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	fontSize: "1rem",
	width: "100%",
	color: " #B05911",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));
const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const ContainerForSelect = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const ContainerFortotal = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	height: "100%",
	width: "100%",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	color: " #B05911",
}));
const FormControlContainer = styled(FormControl)(({ theme }) => ({
	width: "40%",
	height: "2rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0.5rem 0rem 0rem 0rem",
	border: "none",
}));

const SelectContainer = styled(Select)(({ theme }) => ({
	// width: "100%",
	height: "100%",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	border: "1px solid  #B05911",
	color: " #B05911",
	"& .MuiSelect-select": {
		padding: "0rem 0rem 0rem 0rem",
		margin: "0rem 0rem 0rem 0rem",
		border: "none",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));

const GraphContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));

/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={5}>{children}</Box>}
		</div>
	);
}

const SearchAnalytics = ({
	DashboardData,
	searchPerformedData,
	searchLocationData,
	searchTransactionTypeData,
}: {
	DashboardData: any;
	searchPerformedData: any;
	searchLocationData: any;
	searchTransactionTypeData: any;
}) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const [selectedForSearch, setselectedForSearch] = React.useState("week");

	const [searchData, setSearchData] = React.useState<any>();

	const [searchLocationDataState, setSearchLocationDataState] = React.useState<any>();

	const [searchTransactionTypeDataState, setSearchTransactionTypeDataState] = React.useState<any>();

	React.useEffect(() => {
		setSearchData(searchPerformedData);
		setSearchLocationDataState(searchLocationData);
		setSearchTransactionTypeDataState(searchTransactionTypeData);
	}, [searchPerformedData, searchLocationData, searchTransactionTypeData]);

	const handleChangeForSearch = async (event: SelectChangeEvent) => {
		setselectedForSearch(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/search-performed-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setSearchData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/search-performed-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setSearchData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "/analytics-api/search-performed-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setSearchData(data.payload);
					});
				break;
			default:
				break;
		}
	};

	if (!searchPerformedData || !searchTransactionTypeDataState || !searchLocationDataState) {
		return (
			<div style={{ width: "100%", height: "100vh", padding: "2rem" }}>
				<LoadingComponent />
			</div>
		);
	}

	return (
		<React.Fragment>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					gap: "1rem",
					width: "100%",
					height: "auto",
					// padding: "1rem",
					padding: "0.5rem 0rem 1rem 2.2rem",
				}}
			>
				<GraphContainer
					sx={{
						width: "50%",
						// height: "20rem",
						padding: "0.2rem 0.2rem 0.2rem 0.2rem",
						backgroundColor: "#ffffff",
						borderRadius: "8px",
						border: "1px solid #B05911",
						// margin: "1rem 1rem 1rem 2.5rem",
					}}
				>
					<AnalyticsDonutChart
						title="search locations"
						graphData={searchLocationDataState.pi_chart}
						graph="pie"
					/>
				</GraphContainer>
				<GraphContainer
					sx={{
						width: "50%",
						// height: "20rem",
						padding: "0.2rem 0.2rem 0.2rem 0.2rem",
						backgroundColor: "#ffffff",
						borderRadius: "8px",
						border: "1px solid #B05911",
						// margin: "1rem 1rem 1rem 2.5rem",
					}}
				>
					<AnalyticsDonutChart
						title="transaction types"
						graphData={searchTransactionTypeDataState.pi_chart}
						graph="pie"
					/>
				</GraphContainer>
				{/* <GraphContainer
								sx={{
									width: "30rem",
									height: "20rem",
									// padding: "1rem 1rem 1rem 1rem",
									backgroundColor: "#fffff",
									borderRadius: "8px",
									border: "1px solid #B05911",
									// margin: "1rem 1rem 1rem 2.5rem",
								}}
							>
								<AnalyticsDonutChart
									title="property types"
									graphData={propertyType.pi_chart}
									graph="pie"
								/>
							</GraphContainer> */}
			</div>
			<TabsContainer>
				<Tabs
					value={value}
					orientation="horizontal"
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "transparent",
						},
					}}
				>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 0 ? "primary" : "default"}>
									<MainTitle>Searches Performed</MainTitle>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										<SubTitle>all time</SubTitle>
										<Title>{searchPerformedData.total_search_queries}</Title>
									</ContainerFortotal>
									<ContainerFortotal>
										<SubTitle>
											{selectedForSearch === "week"
												? "this week"
												: selectedForSearch === "month"
													? "this month"
													: selectedForSearch === "year"
														? "this year"
														: selectedForSearch === "yesterday"
															? "today"
															: null}
										</SubTitle>
										<Title>
											{selectedForSearch === "week"
												? searchPerformedData.search_queries_this_week
												: selectedForSearch === "month"
													? searchData?.search_queries_this_month
													: selectedForSearch === "year"
														? searchData?.search_queries_this_year
														: selectedForSearch === "yesterday"
															? searchData?.search_queries_today
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>
								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForSearch === "week" ? (
											searchPerformedData.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForSearch === "month" ? (
											searchData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForSearch === "year" ? (
											searchData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForSearch === "yesterday" ? (
											searchData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForSearch === "week"
											? searchPerformedData.percentage_change_display_string
											: selectedForSearch === "month"
												? searchData?.percentage_change_display_string
												: selectedForSearch === "year"
													? searchData?.percentage_change_display_string
													: selectedForSearch === "yesterday"
														? searchData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForSearch}
											onChange={handleChangeForSearch}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={0}
						tabColor={value === 0 ? "primary" : "default"}
					/>
				</Tabs>
			</TabsContainer>

			<TabContentContainer>
				<TabPanel
					value={value}
					index={0}
				>
					{selectedForSearch === "month" ? (
						<>
							<AnalyticsGraph
								title="Searches Performed Monthly"
								graphData={searchData?.line_graph}
							/>
						</>
					) : selectedForSearch === "year" ? (
						<>
							<AnalyticsGraph
								title="Searches Performed Yearly"
								graphData={searchData?.line_graph}
							/>
						</>
					) : selectedForSearch === "week" ? (
						<>
							<AnalyticsGraph
								title="Searches Performed Weekly"
								graphData={searchPerformedData.line_graph}
							/>
						</>
					) : (
						<>
							<AnalyticsGraph
								title="Searches Performed Hourly"
								graphData={searchData?.line_graph}
							/>
						</>
					)}
				</TabPanel>
			</TabContentContainer>
		</React.Fragment>
	);
};

export default SearchAnalytics;

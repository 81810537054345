/*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import SearchIcon from "@mui/icons-material/Search";
import {
	Autocomplete,
	Box,
	Button,
	Chip,
	FormControl,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	styled,
	Tab,
	Tabs,
	TextField,
	Typography
} from "@mui/material";
import MapBoxHeatMap from "../Heatmap/MapboxHeat";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import LoadingComponent from "../../sub-components/LoadingComponent";
import AnalyticsBarGraph from "../analticsBarGraph";
import AnalyticsDonutChart from "../analyticsDonutchart";
import AnalyticsGraph from "../analyticsGraph";
import AllPropertiesViewsTable from "./AllPropertiesViewsTable";
type CustomAttributesForDivElement = {
	tabColor?: string | number;
	id?: string;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "1px solid #B05911" : "none",
	textTransform: "capitalize",
	minWidth: "23rem",
	// width: "100%",
	minHeight: "9rem",
	borderRadius: "16px",
	margin: "0rem 1rem 0.5rem 0rem",
	background: "#FFFFFF",
}));

const TitleContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
	// height: "100%",
	width: "100%",
	gap: "0.5rem",
	color: "#000000",
	textTransform: "lowercase",
	minWidth: "12rem",
	minHeight: "3rem",
}));

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) => ({
	background: "#FEEADA",
	color: "#B05911",
	border: "1px solid #B05911",
	textTransform: "capitalize",
	fontSize: "0.75rem",
	padding: "0.5rem 1rem",
	borderRadius: "0.5rem",
	margin: "0rem 1rem 0.5rem 0rem",
	"&:hover": {
		boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
		background: "#FEEADA",
	},
	"&:active": {
		boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
		background: "#FEEADA",
	},
	"&:focus": {
		boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
		background: "#FEEADA",
	},

	cursor: "pointer",
}));
const TitleMain = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "8px",
	color: "#B05911",
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	textTransform: "capitalize",
	color: " #B05911",
	padding: "0rem 0.5rem 0rem 0.5rem",
}));
const MainTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
	fontSize: "1.2rem",
	fontWeight: 600,
}));
const SubTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
}));
const TitleAndIcon = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	fontSize: "1rem",
	width: "100%",
	color: " #B05911",
	textTransform: "capitalize",
	padding: "0.5rem 0.5rem 0rem 0.5rem",
}));
const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const ContainerForSelect = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem",
}));

const ContainerFortotal = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	height: "100%",
	width: "100%",
	// gap: "0.5rem",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	color: " #B05911",
}));
const FormControlContainer = styled(FormControl)(({ theme }) => ({
	width: "40%",
	height: "2rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0.5rem 0rem 0rem 0rem",
	border: "none",
}));

const SelectContainer = styled(Select)(({ theme }) => ({
	// width: "100%",
	height: "100%",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	color: " #B05911",
	border: "1px solid #B05911",
	"& .MuiSelect-select": {
		padding: "0rem 0rem 0rem 0rem",
		margin: "0rem 0rem 0rem 0rem",
		border: "none",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));
const MiddleContainerTwo = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	padding: "2rem",
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
	height: "100%",
}));

const GraphContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));
/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={5}>{children}</Box>}
		</div>
	);
}

const PropertiesAnalytics = ({
	propertiesAreaOfOperations,
	propertyPDFDownloads,
	listingCreators,
	transactionTypes,
	propertyTypes,
	propertyStatus,
}: {
	propertiesAreaOfOperations: any;
	propertyPDFDownloads: any;
	listingCreators: any;
	transactionTypes: any;
	propertyTypes: any;
	propertyStatus: any;
}) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const [selectedForPropertyPdf, setselectedForPropertyPdf] = React.useState("week");

	const [propertyPdfData, setPropertyPdfData] = React.useState<any>();

	const [propsAreaOfOperations, setPropsAreaOfOperations] = React.useState();

	const [bdUser, setBdUser] = React.useState<any>();

	const [heatmap, setHeatmap] = React.useState(false);

	const [last_24_hours, setLast_24_hours] = React.useState<any>();
	const [last_7_days, setLast_7_days] = React.useState<any>();
	const [last_30_days, setLast_30_days] = React.useState<any>();
	const [last_12_months, setLast_12_months] = React.useState<any>();
	const [searchButtonClicked, setSearchButtonClicked] = React.useState<boolean>(false);
	const [title, setTitle] = React.useState<string>("");
	const [slug, setSlug] = React.useState<string>("");
	const [showWeeks, setShowWeeks] = React.useState<boolean>(false);
	const [showMonths, setShowMonths] = React.useState<boolean>(false);
	const [showYears, setShowYears] = React.useState<boolean>(false);
	const [showLast24, setShowLast24] = React.useState<boolean>(false);
	const [topPropertiesViews, setTopPropertiesViews] = React.useState<any>();
	const [topPropertiesTimeSpent, setTopPropertiesTimeSpent] = React.useState<any>();
	const [autocompleteValue, setAutocompleteValue] = React.useState<string | null>(null);
	const [options, setOptions] = React.useState<any[]>([]);
	const [allPropertiesViews, setAllPropertiesViews] = React.useState<any>();
	const [page, setPage] = React.useState(0);
	const [transactionType, setTransactionType] = React.useState<any>();
	const [propertyType, setPropertyType] = React.useState<any>();
	const usertext = React.useRef<HTMLInputElement>(null);

	const [propertyStatusData, setPropertyStatusData] = React.useState<any>();

	React.useEffect(() => {
		setPropsAreaOfOperations(propertiesAreaOfOperations);
		setPropertyPdfData(propertyPDFDownloads);
		setBdUser(listingCreators);
		setTransactionType(transactionTypes);
		setPropertyType(propertyTypes);
		setPropertyStatusData(propertyStatus);
	}, [
		propertiesAreaOfOperations,
		propertyPDFDownloads,
		listingCreators,
		transactionTypes,
		propertyTypes,
		propertyStatus,
	]);

	const handleChangeForPropertyPdf = async (event: SelectChangeEvent) => {
		setselectedForPropertyPdf(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "analytics-api/pdf-download-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPropertyPdfData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "analytics-api/pdf-download-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPropertyPdfData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "analytics-api/pdf-download-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setPropertyPdfData(data.payload);
					});
				break;
			default:
				break;
		}
	};

	const fetchOptions = {
		method: "POST",
		cache: "no-store" as RequestCache,
		headers: {
			"Content-Type": "text/plain",
			"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
		},
		body: JSON.stringify({}),
	};
	React.useEffect(() => {
		switch (value) {
			case 0:
				break;
			case 1:
				const fetchPropertiesData = async () => {
					try {
						const [propertiesViewsResponse, propertiesTimeSpentResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-properties-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-properties-time-spent", fetchOptions),
						]);

						const [propertiesViewsRes, propertiesTimeSpentRes] = await Promise.all([
							propertiesViewsResponse.json(),
							propertiesTimeSpentResponse.json(),
						]);

						setTopPropertiesViews(propertiesViewsRes.payload);
						setTopPropertiesTimeSpent(propertiesTimeSpentRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 1 && !topPropertiesViews && !topPropertiesTimeSpent) {
					fetchPropertiesData();
				}
				break;
			case 2:
				const fetchPropertiesViewsData = async () => {
					try {
						const [allPropertiesViewsResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/all-properties-views", {
								method: "POST",
								cache: "no-store",
								headers: {
									"Content-Type": "text/plain",
									"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
								},
								body: JSON.stringify({
									page,
								}),
							}),
						]);

						const propertiesViewsRes = await allPropertiesViewsResponse.json();
						setAllPropertiesViews(propertiesViewsRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if ((value === 2 && !allPropertiesViews) || page >= 0) {
					fetchPropertiesViewsData();
				}
				break;
			case 3:
				break;

			case 4:
				break;

			default:
				break;
		}
		setSearchButtonClicked(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, page]);

	const handleClickWeek = () => {
		setShowWeeks(true);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickMonth = () => {
		setShowWeeks(false);
		setShowMonths(true);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickYear = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(true);
		setShowLast24(false);
	};
	const handleClickLast24 = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(true);
	};

	const handleAutocompleteChange = async (value: any) => {
		setAutocompleteValue(value);
	};

	const handleAutocompleteInputChange = async (search_term: string) => {
		/*

    & Next, let's describe a debounced, asynchronous function that will be called when the company name changes.

    */

		const debouncedExecutor: Function = async (): Promise<void> => {
			const response = await (
				await fetch(
					process.env.PRODUCTION_API_URL + "/analytics-api/fetch-properties-autocomplete?search_term=" + search_term,
					{
						cache: "no-store",
						headers: { "Content-Type": "application/json" },
						method: "POST",
					},
				)
			).json();

			if (response.length > 0) {
				console.log(response);
				setOptions(response);
			} else {
			}
		};

		/*

    & Next, let's use setTimeout to debounce the function call.

    */

		const debouncer: number = setTimeout(debouncedExecutor, 500);

		/*

    & Next, let's return a cleanup function that will clear the timeout.

    */

		return (): void => clearTimeout(debouncer);
	};

	const submitSearch = async () => {
		setShowLast24(false);
		setShowMonths(false);
		setShowWeeks(false);
		setShowYears(false);
		const response = await (
			await fetch(process.env.PRODUCTION_API_URL + "/analytics-api/single-properties-views", {
				cache: "no-store",
				headers: { "Content-Type": "text/plain" },
				method: "POST",
				body: JSON.stringify({ autocompleteValue }),
			})
		).json();
		const timeSeries = response.payload;
		console.log(timeSeries,"response");
		setLast_24_hours(timeSeries.graph_data.last_24_hours);
		setLast_7_days(timeSeries.graph_data.last_7_days);
		setLast_30_days(timeSeries.graph_data.last_30_days);
		setLast_12_months(timeSeries.graph_data.last_12_months);
		setShowWeeks(true);
		setTitle(timeSeries.title);
		setSlug(timeSeries.slug);
		setSearchButtonClicked(true);
	};
	React.useEffect(() => {
		if (searchButtonClicked && usertext.current) {
			usertext.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [searchButtonClicked]);

	if (!propsAreaOfOperations || !propertyPDFDownloads || !listingCreators || !transactionTypes || !propertyTypes) {
		return (
			<div style={{ width: "100%", height: "100vh", padding: "2rem" }}>
				<LoadingComponent />
			</div>
		);
	}

	return (
		<React.Fragment>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					gap: "1rem",
					width: "100%",
					height: "auto",
					flexWrap: "wrap",
					// padding: "1rem",
					padding: "0.5rem 0rem 1rem 2.2rem",
				}}
			>
				<GraphContainer
					sx={{
						width: "25rem",
						height: "20rem",
						// padding: "1rem 1rem 1rem 1rem",
						backgroundColor: "#ffffff",
						borderRadius: "8px",
						border: "1px solid #B05911",
						// margin: "1rem 1rem 1rem 2.5rem",
					}}
				>
					<AnalyticsDonutChart
						title="creators"
						graphData={listingCreators.pi_chart}
						graph="donut"
					/>
				</GraphContainer>
				<GraphContainer
					sx={{
						width: "25rem",
						height: "20rem",
						// padding: "1rem 1rem 1rem 1rem",
						backgroundColor: "#ffffff",
						borderRadius: "8px",
						border: "1px solid #B05911",
						// margin: "1rem 1rem 1rem 2.5rem",
					}}
				>
					<AnalyticsDonutChart
						title="transaction types"
						graphData={transactionType.pi_chart}
						graph="pie"
					/>
				</GraphContainer>
				<GraphContainer
					sx={{
						width: "30rem",
						height: "20rem",
						// padding: "1rem 1rem 1rem 1rem",
						backgroundColor: "#fffff",
						borderRadius: "8px",
						border: "1px solid #B05911",
						// margin: "1rem 1rem 1rem 2.5rem",
					}}
				>
					<AnalyticsDonutChart
						title="property types"
						graphData={propertyType.pi_chart}
						graph="pie"
					/>
				</GraphContainer>
				<GraphContainer
					sx={{
						width: "25rem",
						height: "20rem",
						// padding: "1rem 1rem 1rem 1rem",
						backgroundColor: "#fffff",
						borderRadius: "8px",
						border: "1px solid #B05911",
						// margin: "1rem 1rem 1rem 2.5rem",
					}}
				>
					<AnalyticsDonutChart
						title="property status"
						graphData={propertyStatusData.pi_chart}
						graph="pie"
					/>
				</GraphContainer>
			</div>

			<TabsContainer>
				<Tabs
					value={value}
					orientation="horizontal"
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "transparent",
						},
					}}
				>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 0 ? "primary" : "default"}>
									<MainTitle>Property PDF downloads</MainTitle>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										<SubTitle>all time</SubTitle>
										<Title>{propertyPDFDownloads.total_pdf_downloded}</Title>
									</ContainerFortotal>
									<ContainerFortotal>
										<SubTitle>
											{selectedForPropertyPdf === "week"
												? "this week"
												: selectedForPropertyPdf === "month"
													? "this month"
													: selectedForPropertyPdf === "year"
														? "this year"
														: selectedForPropertyPdf === "yesterday"
															? "today"
															: null}
										</SubTitle>
										<Title>
											{selectedForPropertyPdf === "week"
												? propertyPDFDownloads.pdf_downloded_this_week
												: selectedForPropertyPdf === "month"
													? propertyPdfData?.pdf_downloded_this_month
													: selectedForPropertyPdf === "year"
														? propertyPdfData?.this_year_pdf_downloaded
														: selectedForPropertyPdf === "yesterday"
															? propertyPdfData?.pdf_downloded_today
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>
								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForPropertyPdf === "week" ? (
											propertyPDFDownloads.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPropertyPdf === "month" ? (
											propertyPdfData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPropertyPdf === "year" ? (
											propertyPdfData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForPropertyPdf === "yesterday" ? (
											propertyPdfData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForPropertyPdf === "week"
											? propertyPDFDownloads.percentage_change_display_string
											: selectedForPropertyPdf === "month"
												? propertyPdfData?.percentage_change_display_string
												: selectedForPropertyPdf === "year"
													? propertyPdfData?.percentage_change_display_string
													: selectedForPropertyPdf === "yesterday"
														? propertyPdfData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											aria-label="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForPropertyPdf}
											onChange={handleChangeForPropertyPdf}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={0}
						tabColor={value === 0 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<TitleMain>
									<MainTitle>Top Properties</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={1}
						tabColor={value === 1 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<TitleMain>
									<MainTitle>All Properties Views</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={2}
						tabColor={value === 2 ? "primary" : "default"}
					/>
				</Tabs>
			</TabsContainer>
<ContainerForSelect>
				<Autocomplete
					disablePortal
					options={options}
					sx={{
						width: "20rem",
						"& .MuiOutlinedInput-root": {
							backgroundColor: "#ffffff",
							borderRadius: "8px",
							"& fieldset": {
								borderColor: "#B05911",
							},
							"&:hover fieldset": {
								borderColor: "#F76C35",
							},
						},
						"& .MuiAutocomplete-inputRoot": {
							padding: "0.5rem",
						},
						"& .MuiInputBase-adornedEnd": {
							paddingRight: "0.5rem",
						},
					}}
					value={autocompleteValue ? autocompleteValue : null}
					onChange={(event, value) => handleAutocompleteChange(value)}
					onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						if (event !== null) {
							setSearchButtonClicked(false);
							handleAutocompleteInputChange(event.target.value);
						}
					}}
					getOptionLabel={(option) => option.title}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder="Search for a business profile"
							sx={{
								background: "#FEEADA",
								borderRadius: "0.5rem",
								border: "1px solid #B05911",
								boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",

								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										border: "none",
									},
									"&:hover fieldset": {
										border: "none",
									},
								},
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={(event: React.MouseEvent<HTMLElement>) => {
												event.stopPropagation();
												setSearchButtonClicked(true);

												submitSearch();
											}}
											edge="end"
										>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			</ContainerForSelect>
			<TabContentContainer>
				<TabPanel
					value={value}
					index={0}
				>
					{selectedForPropertyPdf === "month" ? (
						<GraphContainer>
							<AnalyticsGraph
								title="Property PDF downloads Monthly"
								graphData={propertyPdfData?.line_graph}
							/>
							<AnalyticsBarGraph
								title="Property PDF downloads Monthly"
								graphData={propertyPDFDownloads.bar_graph}
							/>
						</GraphContainer>
					) : selectedForPropertyPdf === "year" ? (
						<GraphContainer>
							<AnalyticsGraph
								title="Property PDF downloads Yearly"
								graphData={propertyPdfData?.line_graph}
							/>
							<AnalyticsBarGraph
								title="Property PDF downloads Yearly"
								graphData={propertyPDFDownloads.bar_graph}
							/>
						</GraphContainer>
					) : selectedForPropertyPdf === "week" ? (
						<GraphContainer>
							<AnalyticsGraph
								title="Property PDF downloads Weekly"
								graphData={propertyPDFDownloads.line_graph}
							/>
							<AnalyticsBarGraph
								title="Property PDF downloads Weekly"
								graphData={propertyPDFDownloads.bar_graph}
							/>
						</GraphContainer>
					) : (
						<GraphContainer>
							<AnalyticsGraph
								title="Property PDF downloads Hourly"
								graphData={propertyPdfData?.line_graph}
							/>
							<AnalyticsBarGraph
								title="Property PDF downloads Hourly"
								graphData={propertyPDFDownloads.bar_graph}
							/>
						</GraphContainer>
					)}
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
				>
					<GraphContainer>
						<AnalyticsBarGraph
							title="Top Properties Views"
							graphData={topPropertiesViews}
						/>
						<AnalyticsBarGraph
							title="Top Properties Time Spent"
							graphData={topPropertiesTimeSpent}
						/>
					</GraphContainer>
				</TabPanel>
				<TabPanel
					value={value}
					index={2}
				>
					<AllPropertiesViewsTable
						propertiesViews={allPropertiesViews}
						page={page}
						setPage={setPage}
					/>
				</TabPanel>


				{searchButtonClicked ? (
					<TabContentContainer
						ref={usertext}
						sx={{
							padding: "1rem 2.5rem 2.5rem 2.5rem",
						}}
					>
						<Stack
							direction="row"
							spacing={2}
							padding={2}
						>
							<ChipData
								label="week"
								variant="outlined"
								onClick={handleClickWeek}
								id={showWeeks ? "week" : ""}
							/>
							<ChipData
								label="month"
								variant="outlined"
								onClick={handleClickMonth}
								id={showMonths ? "month" : ""}
							/>
							<ChipData
								label="year"
								variant="outlined"
								onClick={handleClickYear}
								id={showYears ? "year" : ""}
							/>
							<ChipData
								label="last 24 hours"
								variant="outlined"
								onClick={handleClickLast24}
								id={showLast24 ? "last 24 hours" : ""}
							/>
						</Stack>
						{showWeeks ? (
							<AnalyticsGraph
								title={`${title} Views Weekly`}
								graphData={last_7_days}
							/>
						) : null}
						{showMonths ? (
							<AnalyticsGraph
								title={`${title} Views Monthly`}
								graphData={last_30_days}
							/>
						) : null}
						{showYears ? (
							<AnalyticsGraph
								title={`${title} Views Yearly`}
								graphData={last_12_months}
							/>
						) : null}
						{showLast24 ? (
							<AnalyticsGraph
								title={`${title} Views Hourly`}
								graphData={last_24_hours}
							/>
						) : null}

						{/* <AnalyticsBarGraph
							title="Top Landowner Profiles Time Spent"
							graphData={topLandownersTimeSpent}
						/> */}
					</TabContentContainer>
				) : null}

				<MiddleContainerTwo>
					<MapBoxHeatMap
						GeoJSON={propertiesAreaOfOperations ? propertiesAreaOfOperations : []}
						title="properties"
						openMainResults={() => {
							setHeatmap(true);
						}}
					/>
				</MiddleContainerTwo>
			</TabContentContainer>
		</React.Fragment>
	);
};

export default PropertiesAnalytics;

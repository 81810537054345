/*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import {
	Autocomplete,
	Box,
	Button,
	Chip,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	styled,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";
import MapBoxHeatMap from "../Heatmap/MapboxHeat";
import AnalyticsBarGraph from "../analticsBarGraph";
import AnalyticsGraph from "../analyticsGraph";
import AllServicesViewsTable from "./AllServicesViewsTable";
import LoadingComponent from "../../sub-components/LoadingComponent";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
	id?: string;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "1px solid #B05911" : "none",
	textTransform: "capitalize",
	minWidth: "23rem",
	// width: "100%",
	minHeight: "8rem",
	borderRadius: "16px",
	margin: "0rem 1rem 0.5rem 0rem",
	background: "#FFFFFF",
}));

const TitleContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
	// height: "100%",
	width: "100%",
	gap: "0.5rem",
	color: " #DBF1E8",
	minWidth: "12rem",
	minHeight: "3rem",
}));

const TitleMain = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	// border: tabColor === "primary" ? "2px solid #F76C35" : "2px solid rgb(187, 185, 184)",
	borderRadius: "8px",
	// boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	color: " #B05911",
}));

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "week"
		? {
				backgroundColor: "#F76C35",
				color: "#ffffff",
			}
		: id === "month"
			? {
					backgroundColor: "#F76C35",
					color: "#ffffff",
				}
			: id === "year"
				? {
						backgroundColor: "#F76C35",
						color: "#ffffff",
					}
				: id === "last 24 hours"
					? {
							backgroundColor: "#F76C35",
							color: "#ffffff",
						}
					: {
							backgroundColor: "#ffffff",
							color: "#000000",
						},
);

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	color: " #B05911",
	padding: "0rem 0.5rem 0rem 0.5rem",
}));
const TitleAndIcon = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	fontSize: "1rem",
	width: "100%",
	color: " #B05911",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));
const MainTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
	fontSize: "1.2rem",
	fontWeight: 600,
}));
const SubTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
}));
const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const ContainerForSelect = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const ContainerFortotal = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	height: "100%",
	width: "100%",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	color: " #B05911",
}));
const FormControlContainer = styled(FormControl)(({ theme }) => ({
	width: "40%",
	height: "2rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0.5rem 0rem 0rem 0rem",
	border: "none",
}));

const SelectContainer = styled(Select)(({ theme }) => ({
	// width: "100%",
	height: "100%",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	border: "1px solid  #B05911",
	color: " #B05911",
	"& .MuiSelect-select": {
		padding: "0rem 0rem 0rem 0rem",
		margin: "0rem 0rem 0rem 0rem",
		border: "none",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));
const MiddleContainerTwo = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	padding: "2rem",
}));
const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
	height: "100%",
}));

const GraphContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));
/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={5}>{children}</Box>}
		</div>
	);
}

const ServicesAnalytics = ({
	servicePDFDownloads,
	servicesAreaOfOperations,
}: {
	servicePDFDownloads: any;
	servicesAreaOfOperations: any;
}) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const [selectedForServicePdf, setselectedForServicePdf] = React.useState("week");
	const [servicePdfData, setServicePdfData] = React.useState<any>();

	const [ServsAreaOfOperations, setServsAreaOfOperations] = React.useState();

	const [heatmap, setHeatmap] = React.useState(false);
	const [last_24_hours, setLast_24_hours] = React.useState<any>();
	const [last_7_days, setLast_7_days] = React.useState<any>();
	const [last_30_days, setLast_30_days] = React.useState<any>();
	const [last_12_months, setLast_12_months] = React.useState<any>();
	const [searchButtonClicked, setSearchButtonClicked] = React.useState<boolean>(false);
	const [title, setTitle] = React.useState<string>("");
	const [slug, setSlug] = React.useState<string>("");
	const [showWeeks, setShowWeeks] = React.useState<boolean>(false);
	const [showMonths, setShowMonths] = React.useState<boolean>(false);
	const [showYears, setShowYears] = React.useState<boolean>(false);
	const [showLast24, setShowLast24] = React.useState<boolean>(false);
	const [topServicesViews, setTopServicesViews] = React.useState<any>();
	const [topServicesTimeSpent, setTopServicesTimeSpent] = React.useState<any>();
	const [autocompleteValue, setAutocompleteValue] = React.useState<string | null>(null);
	const [options, setOptions] = React.useState<any[]>([]);
	const [allServicesViews, setAllServicesViews] = React.useState<any>();
	const [page, setPage] = React.useState<number>(0);

	React.useEffect(() => {
		setServsAreaOfOperations(servicesAreaOfOperations);
		setServicePdfData(servicePDFDownloads);
	}, [servicesAreaOfOperations, servicePDFDownloads]);

	const handleChangeForServicePdf = async (event: SelectChangeEvent) => {
		setselectedForServicePdf(event.target.value as string);
		switch (event.target.value) {
			case "month":
				fetch(process.env.PRODUCTION_API_URL + "analytics-api/service-pdf-download-monthly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setServicePdfData(data.payload);
					});

				break;
			case "year":
				fetch(process.env.PRODUCTION_API_URL + "analytics-api/service-pdf-download-yearly", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setServicePdfData(data.payload);
					});
				break;
			case "week":
				break;
			case "yesterday":
				fetch(process.env.PRODUCTION_API_URL + "analytics-api/service-pdf-download-daily", {
					method: "GET",
					cache: "no-store",
				})
					.then((res) => res.json())
					.then((data) => {
						setServicePdfData(data.payload);
					});
				break;
			default:
				break;
		}
	};

	const fetchOptions = {
		method: "POST",
		cache: "no-store" as RequestCache,
		headers: {
			"Content-Type": "text/plain",
			"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
		},
		body: JSON.stringify({}),
	};

	React.useEffect(() => {
		switch (value) {
			case 0:
				break;
			case 1:
				const fetchservicesData = async () => {
					try {
						const [servicesViewsResponse, servicesTimeSpentResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-services-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-services-time-spent", fetchOptions),
						]);

						const [servicesViewsRes, servicesTimeSpentRes] = await Promise.all([
							servicesViewsResponse.json(),
							servicesTimeSpentResponse.json(),
						]);

						setTopServicesViews(servicesViewsRes.payload);
						setTopServicesTimeSpent(servicesTimeSpentRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 1 && !topServicesViews && !topServicesTimeSpent) {
					fetchservicesData();
				}
				break;
			case 2:
				const fetchServicesViewsData = async () => {
					try {
						const [allServicesViewsResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/all-services-views", {
								method: "POST",
								cache: "no-store",
								headers: {
									"Content-Type": "text/plain",
									"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
								},
								body: JSON.stringify({
									page,
								}),
							}),
						]);

						const ServicesViewsRes = await allServicesViewsResponse.json();
						setAllServicesViews(ServicesViewsRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if ((value === 2 && !allServicesViews) || page >= 0) {
					fetchServicesViewsData();
				}

				break;
			case 3:
				break;

			case 4:
				break;

			default:
				break;
		}
		setSearchButtonClicked(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, page]);

	const handleClickWeek = () => {
		setShowWeeks(true);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickMonth = () => {
		setShowWeeks(false);
		setShowMonths(true);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickYear = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(true);
		setShowLast24(false);
	};
	const handleClickLast24 = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(true);
	};

	const handleAutocompleteChange = async (value: any) => {
		setAutocompleteValue(value);
	};

	const handleAutocompleteInputChange = async (search_term: string) => {
		/*

    & Next, let's describe a debounced, asynchronous function that will be called when the company name changes.

    */

		const debouncedExecutor: Function = async (): Promise<void> => {
			const response = await (
				await fetch(
					process.env.PRODUCTION_API_URL + "/analytics-api/fetch-services-autocomplete?search_term=" + search_term,
					{
						cache: "no-store",
						headers: { "Content-Type": "application/json" },
						method: "POST",
					},
				)
			).json();

			if (response.length > 0) {
				console.log(response);
				setOptions(response);
			} else {
			}
		};

		/*

    & Next, let's use setTimeout to debounce the function call.

    */

		const debouncer: number = setTimeout(debouncedExecutor, 500);

		/*

    & Next, let's return a cleanup function that will clear the timeout.

    */

		return (): void => clearTimeout(debouncer);
	};

	const submitSearch = async () => {
		setShowLast24(false);
		setShowMonths(false);
		setShowWeeks(false);
		setShowYears(false);
		const response = await (
			await fetch(process.env.PRODUCTION_API_URL + "/analytics-api/single-service-views", {
				cache: "no-store",
				headers: { "Content-Type": "text/plain" },
				method: "POST",
				body: JSON.stringify({ autocompleteValue }),
			})
		).json();
		const timeSeries = response.payload;
		setLast_24_hours(timeSeries.graph_data.last_24_hours);
		setLast_7_days(timeSeries.graph_data.last_7_days);
		setLast_30_days(timeSeries.graph_data.last_30_days);
		setLast_12_months(timeSeries.graph_data.last_12_months);
		setShowWeeks(true);
		setTitle(timeSeries.title);
		setSlug(timeSeries.slug);
		setSearchButtonClicked(true);
	};

	if (!ServsAreaOfOperations || !servicePdfData) {
		return (
			<div style={{ width: "100%", height: "100vh", padding: "2rem" }}>
				<LoadingComponent />
			</div>
		);
	}

	return (
		<React.Fragment>
			<TabsContainer>
				<Tabs
					value={value}
					orientation="horizontal"
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "transparent",
						},
					}}
				>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv>
								<TitleMain tabColor={value === 0 ? "primary" : "default"}>
									<MainTitle>Service PDF downloads</MainTitle>
								</TitleMain>
								<SubContainer>
									<ContainerFortotal>
										<SubTitle>all time</SubTitle>
										<Title>{servicePDFDownloads.total_pdf_downloded}</Title>
									</ContainerFortotal>
									<ContainerFortotal>
										<SubTitle>
											{selectedForServicePdf === "week"
												? "this week"
												: selectedForServicePdf === "month"
													? "this month"
													: selectedForServicePdf === "year"
														? "this year"
														: selectedForServicePdf === "yesterday"
															? "today"
															: null}
										</SubTitle>
										<Title>
											{selectedForServicePdf === "week"
												? servicePDFDownloads.pdf_downloded_this_week
												: selectedForServicePdf === "month"
													? servicePdfData?.pdf_downloded_this_month
													: selectedForServicePdf === "year"
														? servicePdfData?.this_year_pdf_downloaded
														: selectedForServicePdf === "yesterday"
															? servicePdfData?.pdf_downloded_today
															: null}
										</Title>
									</ContainerFortotal>
								</SubContainer>
								<ContainerForSelect>
									<TitleAndIcon>
										{selectedForServicePdf === "week" ? (
											servicePDFDownloads.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForServicePdf === "month" ? (
											servicePdfData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForServicePdf === "year" ? (
											servicePdfData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : selectedForServicePdf === "yesterday" ? (
											servicePdfData?.percentage_change_display_string.includes("down") ? (
												<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
											) : (
												<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
											)
										) : null}
										{selectedForServicePdf === "week"
											? servicePDFDownloads.percentage_change_display_string
											: selectedForServicePdf === "month"
												? servicePdfData?.percentage_change_display_string
												: selectedForServicePdf === "year"
													? servicePdfData?.percentage_change_display_string
													: selectedForServicePdf === "yesterday"
														? servicePdfData?.percentage_change_display_string
														: null}
									</TitleAndIcon>
									<FormControlContainer>
										<SelectContainer
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={selectedForServicePdf}
											onChange={handleChangeForServicePdf}
										>
											<MenuItem value={"month"}>Month</MenuItem>
											<MenuItem value={"year"}>Year</MenuItem>
											<MenuItem value={"week"}> week</MenuItem>
											<MenuItem value={"yesterday"}>yesterday</MenuItem>
										</SelectContainer>
									</FormControlContainer>
								</ContainerForSelect>
							</TitleContentDiv>
						}
						value={0}
						tabColor={value === 0 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<TitleMain tabColor={value === 1 ? "primary" : "default"}>
									<MainTitle>Top Services</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={1}
						tabColor={value === 1 ? "primary" : "default"}
					/>
					<CustomTab
						disableRipple
						disableTouchRipple
						label={
							<TitleContentDiv
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<TitleMain tabColor={value === 2 ? "primary" : "default"}>
									<MainTitle>All Services</MainTitle>
								</TitleMain>
							</TitleContentDiv>
						}
						value={2}
						tabColor={value === 2 ? "primary" : "default"}
					/>
				</Tabs>
			</TabsContainer>
			<TabContentContainer>
				<TabPanel
					value={value}
					index={0}
				>
					{selectedForServicePdf === "month" ? (
						<GraphContainer>
							<AnalyticsGraph
								title="Service PDF downloads Monthly"
								graphData={servicePdfData?.line_graph}
							/>
							<AnalyticsBarGraph
								title="Service PDF downloads Monthly"
								graphData={servicePDFDownloads.bar_graph}
							/>
						</GraphContainer>
					) : selectedForServicePdf === "year" ? (
						<GraphContainer>
							<AnalyticsGraph
								title="Service PDF downloads Yearly"
								graphData={servicePdfData?.line_graph}
							/>
							<AnalyticsBarGraph
								title="Service PDF downloads Yearly"
								graphData={servicePDFDownloads.bar_graph}
							/>
						</GraphContainer>
					) : selectedForServicePdf === "week" ? (
						<GraphContainer>
							<AnalyticsGraph
								title="Service PDF downloads Weekly"
								graphData={servicePDFDownloads.line_graph}
							/>
							<AnalyticsBarGraph
								title="Service PDF downloads Weekly"
								graphData={servicePDFDownloads.bar_graph}
							/>
						</GraphContainer>
					) : (
						<GraphContainer>
							<AnalyticsGraph
								title="Service PDF downloads Hourly"
								graphData={servicePdfData?.line_graph}
							/>
							<AnalyticsBarGraph
								title="Service PDF downloads Hourly"
								graphData={servicePDFDownloads.bar_graph}
							/>
						</GraphContainer>
					)}
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
				>
					<AnalyticsBarGraph
						title="Top Services Views"
						graphData={topServicesViews}
					/>
					<AnalyticsBarGraph
						title="Top Services Time Spent"
						graphData={topServicesTimeSpent}
					/>
				</TabPanel>
				<TabPanel
					value={value}
					index={2}
				>
					<AllServicesViewsTable
						servicesViews={allServicesViews}
						setPage={setPage}
						page={page}
					/>
				</TabPanel>
				<ContainerForSelect>
					<Autocomplete
						disablePortal
						options={options}
						sx={{
							width: "50%",
							// padding: "1rem 0rem 0rem 2rem",
						}}
						value={autocompleteValue ? autocompleteValue : null}
						onChange={(event, value) => handleAutocompleteChange(value)}
						onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							if (event !== null) {
								handleAutocompleteInputChange(event.target.value);
							}
						}}
						getOptionLabel={(option) => option.title}
						renderInput={(params) => (
							<TextField
								sx={{
									backgroundColor: "#ffffff",
								}}
								{...params}
								label="search for a property"
							/>
						)}
					/>
					<ViewOrUploadButton
						variant="contained"
						color="primary"
						onClick={submitSearch}
					>
						Search
					</ViewOrUploadButton>
				</ContainerForSelect>
				{searchButtonClicked ? (
					<TabContentContainer
						sx={{
							padding: "1rem 2.5rem 2.5rem 2.5rem",
						}}
					>
						<Stack
							direction="row"
							spacing={2}
						>
							<ChipData
								label="week"
								variant="outlined"
								onClick={handleClickWeek}
								id={showWeeks ? "week" : ""}
							/>
							<ChipData
								label="month"
								variant="outlined"
								onClick={handleClickMonth}
								id={showMonths ? "month" : ""}
							/>
							<ChipData
								label="year"
								variant="outlined"
								onClick={handleClickYear}
								id={showYears ? "year" : ""}
							/>
							<ChipData
								label="last 24 hours"
								variant="outlined"
								onClick={handleClickLast24}
								id={showLast24 ? "last 24 hours" : ""}
							/>
						</Stack>
						{showWeeks ? (
							<AnalyticsGraph
								title={`${title} Views Weekly`}
								graphData={last_7_days}
							/>
						) : null}
						{showMonths ? (
							<AnalyticsGraph
								title={`${title} Views Monthly`}
								graphData={last_30_days}
							/>
						) : null}
						{showYears ? (
							<AnalyticsGraph
								title={`${title} Views Yearly`}
								graphData={last_12_months}
							/>
						) : null}
						{showLast24 ? (
							<AnalyticsGraph
								title={`${title} Views Hourly`}
								graphData={last_24_hours}
							/>
						) : null}

						{/* <AnalyticsBarGraph
							title="Top Landowner Profiles Time Spent"
							graphData={topLandownersTimeSpent}
						/> */}
					</TabContentContainer>
				) : null}
				<MiddleContainerTwo>
					<MapBoxHeatMap
						GeoJSON={servicesAreaOfOperations}
						title="services"
						openMainResults={() => {
							setHeatmap(true);
						}}
					/>
				</MiddleContainerTwo>
			</TabContentContainer>
		</React.Fragment>
	);
};

export default ServicesAnalytics;

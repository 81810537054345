/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	DataGridPremium,
	GridFilterInputValue,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext, QrCode } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Breadcrumbs, Button, Divider, Link, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import { deleteShortenedURL, listShortenedURLs } from "../../../../lib/URLShortener";
import CreateShortenUrlModal from "../../../modal/CreateShortenUrlModal";
import LoadingModal from "../../../modal/LoadingModal";
import ORShortenUrlModal from "../../../modal/QRShortenUrlModal";
// import LoadingModal from "../../../modal/LoadingModal";
// import InventoryModal from "../../../modal/PropertyInventoryModal";
// import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/
const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.5rem",
	padding: "0.5rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));
const LastSeen = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const LastSeenSub = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const urlLink: string = process.env.URL_SHORTENER_URL || "https://bgru.gg/";
// const urlLink = "https://takemeto.beegru.com/";

const ShortenURLDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [UrlShortenData, setUrlShortenData] = React.useState<any>([]);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [loadingOpenModal, setLoadingOpenModal] = React.useState(false);

	const [openCreateURLModal, setOpenCreateURLModal] = React.useState(false);

	const [edited, setEdited] = React.useState<boolean>(false);

	const [keyword, setKeyword] = React.useState<string>("");

	const [openQRURLModal, setOpenQRURLModal] = React.useState(false);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length > 0) {
			const query = queryOptions.items.map((item) => {
				return item.value;
			})[0];
			const data = async () => {
				const skip: number = paginationModel.page === 0 ? 0 : paginationModel.page * 100 - 100;
				const limit: number = 100;
				const listOfShortenUrls = await listShortenedURLs(
					skip,
					limit,
					{
						field: "timestamp",
						order: -1,
					},
					query,
				);

				if (listOfShortenUrls) {
					setUrlShortenData(listOfShortenUrls.result);
					setRowCountState(listOfShortenUrls.total);
				}
			};
			data();
		} else {
			const data = async () => {
				const skip: number = paginationModel.page === 0 ? 0 : paginationModel.page * 100 - 100;
				const limit: number = 100;
				const listOfShortenUrls = await listShortenedURLs(skip, limit, {
					field: "timestamp",
					order: -1,
				});
				if (listOfShortenUrls) {
					setUrlShortenData(listOfShortenUrls.result);
					setRowCountState(listOfShortenUrls.total);
				}
			};
			data();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page, paginationModel.pageSize, queryOptions.items, edited]);

	const handleDelete = async (cellValues: any) => {
		setLoadingOpenModal(true);
		await deleteShortenedURL(cellValues.row.keyword);

		setLoadingOpenModal(false);
		setEdited(!edited);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "keyword",
			headerName: "Keyword",
			width: 150,
			filterOperators: [
				{
					label: "Equals",
					value: "equals",
					getApplyFilterFn: (filterItem: any) => {
						if (!filterItem.value) {
							return null;
						}
						return ({ value }: { value: string }) => value === filterItem.value;
					},
					InputComponent: GridFilterInputValue, // Default input component for entering the filter value
				},
			],
		},
		{
			field: "url",
			headerName: "URL",
			width: 150,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={`${cellValues.row.url}`}
						target="_blank"
						rel="noreferrer"
						style={{
							textDecoration: "none",
							color: theme.palette.primary.main,
						}}
					>
						{`${cellValues.row.url}`}
					</Link>
				);
			},
		},
		{
			field: "shortened_url",
			headerName: "Shortened URL",
			width: 150,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={`${urlLink}${cellValues.row.keyword}`}
						target="_blank"
						rel="noreferrer"
						style={{
							textDecoration: "none",
							color: theme.palette.primary.main,
						}}
					>
						{`${urlLink}${cellValues.row.keyword}`}
					</Link>
				);
			},
		},
		{
			field: "title",
			headerName: "Title",
			width: 150,
			filterable: false,
		},
		{
			field: "ip",
			headerName: "IP",
			width: 150,
			filterable: false,
		},
		{
			field: "clicks",
			headerName: "Clicks",
			width: 150,
			filterable: false,
		},

		{
			field: "timestamp",
			headerName: "timestamp",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
						}}
					>
						<LastSeen>
							<LastSeenSub>
								<CalendarTodayOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",").slice(0, 2).join(",")}
								</Typography>
							</LastSeenSub>
							<LastSeenSub>
								<AccessTimeOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",")[2]}
								</Typography>
							</LastSeenSub>
						</LastSeen>
					</ActionActions>
				);
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								setKeyword(cellValues.row.keyword);
								setOpenQRURLModal(true);
								// setOpenCreateBlogsModal(true);
							}}
							startIcon={<QrCode fontSize="small" />}
						>
							Qr
						</Button>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								handleDelete(cellValues);
							}}
						>
							delete
						</Button>
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					sx={{
						color: "#F76C35",
						fontSize: "0.75rem",
						borderRadius: "0.5rem",
						margin: "0.5rem 1rem 0.2rem 1rem",
					}}
					onClick={() => {
						setOpenCreateURLModal(true);
					}}
					startIcon={<QrCode fontSize="small" />}
				>
					create shortened URL
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Shorten URL
		</Typography>,
	];

	return (
		<MainContainer>
			<LoadingModal open={loadingOpenModal} />
			<ORShortenUrlModal
				open={openQRURLModal}
				onClose={() => {
					setOpenQRURLModal(false);
				}}
				keyword={keyword}
			/>
			<CreateShortenUrlModal
				open={openCreateURLModal}
				onClose={() => {
					setOpenCreateURLModal(false);
				}}
				edited={edited}
				setEdited={setEdited}
			/>

			<HeadContainer>
				<HeadText>Moderation</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>

			<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
				<DataGridPremium
					disableRowSelectionOnClick={false}
					initialState={{
						filter: {
							filterModel: {
								items: [
									{
										field: "keyword",
										operator: "equals",
									},
								],
							},
						},
					}}
					slots={{ toolbar: GridToolbar }}
					rows={UrlShortenData?.map((item: any, key: number) => {
						return {
							id: key,
							keyword: item.keyword,
							url: item.url,
							title: item.title,
							timestamp: item.timestamp,
							ip: item.ip,
							clicks: item.clicks,
						};
					})}
					getRowId={(row) => row.id}
					columns={columns}
					pagination={true}
					paginationMode="server"
					onPaginationModelChange={(paginationModel) => {
						setPaginationModel(paginationModel);
					}}
					paginationModel={paginationModel}
					filterMode="server"
					onFilterModelChange={(newFilterModel) => {
						console.log("newFilterModel", newFilterModel);
						setQueryOptions(newFilterModel);
					}}
					rowCount={rowCountState}
					pageSizeOptions={[100]}
					sx={{
						background: "#fff",
						scrollbarWidth: "thin",
						scrollbarColor: "#B05911 transparent",
						"&::-webkit-scrollbar": {
							width: "10px",
							height: "10px",
						},
						"&::-webkit-scrollbar-thumb": {
							backgroundColor: "#B05911",
							borderRadius: "10px",
							border: "2px solid transparent",
							backgroundClip: "padding-box",
							transition: "background-color 0.3s ease, border-color 0.3s ease",
						},
						"&::-webkit-scrollbar-thumb:hover": {
							backgroundColor: "#8A3D0F",
							borderColor: "#8A3D0F",
						},
						"&::-webkit-scrollbar-track": {
							backgroundColor: "#F5F5F5",
							borderRadius: "10px",
						},
						"&::-webkit-scrollbar-track:hover": {
							backgroundColor: "#E0E0E0",
						},
					}}
				/>
			</Box>
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ShortenURLDataTable;

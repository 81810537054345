import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";

/* Mapbox API Key */
mapboxgl.accessToken =
	"pk.eyJ1IjoiYmVlZ3J1cmVhbHR5IiwiYSI6ImNreGg3YzQyMTBhMWsyd3Via2UycjEwb2IifQ.tuAt_hnBl0fgWt_IKeuRbg";

/* Styled Components */
const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	margin: "0rem 0rem 1rem 0rem",
	padding: "0rem 0rem 1rem 0rem",
	border: "1px",
	// boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
	// backgroundColor: "#ffffff",
}));

const Title = styled(Typography)(({ theme }) => ({
	padding: "1rem",
	fontSize: "1rem",
	fontWeight: "500",
	lineHeight: "123.5%",
	textTransform: "capitalize",
}));

const Map = styled("div")(({ theme }) => ({
	height: "20.5rem",
	overflow: "hidden",
	borderRadius: "16px",
}));

const MapContainer = styled("div")(({ theme }) => ({
	width: "100%",
	overflow: "hidden",
	padding: "1rem 1rem 1rem 1rem",
}));

/* Component */
const MapBoxHeatMap = (props: any) => {

	// consolest.log("MapBoxHeatMap", props);
	console.log("MapBoxHeatMap", props.GeoJSON);
	const map = React.useRef<any>(null);
	const marker = React.useRef<any>(null);
	const [isMapboxThingyLoaded, setIsMapboxThingyLoaded] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<string | null>(null);




	const loadMapLayers = () => {
		try {
			// Check if the source already exists
			if (!map.current.getSource("properties")) {
				map.current.addSource("properties", {
					type: "geojson",
					data: props.GeoJSON,
				});
			}

			// Add the heatmap layer
			if (!map.current.getLayer("properties-heat")) {
				map.current.addLayer(
					{
						id: "properties-heat",
						type: "heatmap",
						source: "properties",
						maxzoom: 9,
						paint: {

							"heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
							"heatmap-color": [
								"interpolate",
								["linear"],
								["heatmap-density"],
								0,
								"rgba(153,232,168,0)",
								0.2,
								"rgb(41, 228, 63)",
								0.4,
								"rgb(248, 221, 101)",
								0.6,
								"#0200FD",
								0.8,
								"#0000FF",
								1,
								"#FF0000",
							],
							"heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
							"heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0],
						},
					},
					"waterway-label",
				);
			}

			// Add the circle layer
			if (!map.current.getLayer("properties-point")) {
				map.current.addLayer(
					{
						id: "properties-point",
						type: "circle",
						source: "properties",
						minzoom: 7,
						paint: {
							"circle-radius": 5,
							"circle-color": "#F76C35",
							"circle-stroke-width": 1,
							"circle-stroke-color": "#fff",
						},
					},
					"waterway-label",
				);
			}

			// Add the symbol layer
			if (!map.current.getLayer("properties-point-label")) {
				map.current.addLayer(
					{
						id: "properties-point-label",
						type: "symbol",
						source: "properties",
						minzoom: 5,
						layout: {
							"text-field": ["get", "title"],
							"text-font": ["Open Sans Regular"],
							"text-weight": "bold",
							"text-size": 16,
							"text-anchor": "top",
							"text-offset": [0, 1],
							"text-allow-overlap": false,
						},
						paint: {
							"text-color": "#F76C35",
						},
					},
					"waterway-label",
				);
			}

			// Add a marker
			if (props.locationCoordinates && Array.isArray(props.locationCoordinates)) {
				if (!marker.current) {
					marker.current = new mapboxgl.Marker()
						.setLngLat(props.locationCoordinates.slice().reverse()) // Set the initial coordinates for the marker
						.addTo(map.current);
				}
			} else {
				console.warn("locationCoordinates is undefined or not an array");
			}

			setLoading(false);
			setError(null);
		} catch (err) {
			console.error("Error loading map layers:", err);
			setLoading(false);
			// Retry loading map layers after a delay
			setError("Failed to load map layers. Retrying...");
			setTimeout(loadMapLayers, 3000); // Retry after 3 second
		}
	};

	React.useEffect(() => {
		if (isMapboxThingyLoaded) {
			return;
		}
		map.current = new mapboxgl.Map({
			container: map.current,
			style: "mapbox://styles/beegrurealty/ckxj2dccl09h714krfwuepaqt",
			center: [77.6324513, 13.0559193],
			zoom: 7,
		});

		map.current.on("load", () => {
			loadMapLayers();
		});

		map.current.on("error", (e: any) => {
			console.error("Mapbox error:", e.error);
			setError("An error occurred while loading the map.");
		});

		setIsMapboxThingyLoaded(true);

		const popup = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: true,
			closeOnMove: true,
		});

		map.current.on("click", "properties-point", (e: any) => {
			map.current.getCanvas().style.cursor = "pointer";
			const coordinates = e.features[0].geometry.coordinates.slice();
			const name = e.features[0].properties.title;
			const price = props.price ? props.price : "";

			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}

			popup
				.setLngLat(coordinates)
				.setHTML(
					`<a style="color: #F76C35; text-decoration: none; font-size: 1rem; font-weight: 400; line-height: 123.5%; border-radius:8px;" href="https://beegru.com/${props.title}/${e.features[0].properties.slug || e.features[0].properties._id}">${name}</a>`,
				)
				.addTo(map.current);
		});

		map.current.on("mouseenter", "properties-point", () => {
			map.current.getCanvas().style.cursor = "pointer";
		});

		map.current.on("mouseleave", "properties-point", () => {
			map.current.getCanvas().style.cursor = "";
		});

		map.current.on("mouseenter", "properties-heat", () => {
			map.current.getCanvas().style.cursor = "pointer";
		});

		map.current.on("mouseleave", "properties-heat", () => {
			map.current.getCanvas().style.cursor = "";
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMapboxThingyLoaded, props.GeoJSON, props.price, props.title,]);

	// Update marker position when locationCoordinates changes
	React.useEffect(() => {
		if (marker.current) {
			marker.current.setLngLat(props.locationCoordinates.slice().reverse());
		}
	}, [props.locationCoordinates]);

	return (
		<React.Fragment>
			<MainContainer>
				<Title variant="h6">{props.title} Heat Map</Title>
				{loading && <Typography>Loading map...</Typography>}
				{error && <Typography color="error">{error}</Typography>}
				<MapContainer>
					<Map
						id={"mapbox_id"}
						ref={map}
					/>
				</MapContainer>
			</MainContainer>
		</React.Fragment>
	);
};

export default MapBoxHeatMap;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, NavigateNext } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
	Box,
	Breadcrumbs,
	Button,
	Chip,
	ClickAwayListener,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import GrandAccessModal from "../../../modal/GrandAccessModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};
const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) => {
	const colors: { [key: string]: { backgroundColor: string; color: string } } = {
		admin: { backgroundColor: "#d5d5d7", color: "#000000" },
		super_admin: { backgroundColor: "#FFD700", color: "#000000" },
		property: { backgroundColor: "#FFEB3B", color: "#000000" },
		service: { backgroundColor: "#2196F3", color: "#FFFFFF" },
		kyc: { backgroundColor: "#4CAF50", color: "#FFFFFF" },
		iam: { backgroundColor: "#F44336", color: "#FFFFFF" },
		user_management: { backgroundColor: "#9C27B0", color: "#FFFFFF" },
		analytics: { backgroundColor: "#E91E63", color: "#FFFFFF" },
		subscriptions: { backgroundColor: "#FF9800", color: "#FFFFFF" },
		report: { backgroundColor: "#FF5722", color: "#FFFFFF" },
		bug_report: { backgroundColor: "#795548", color: "#FFFFFF" },
		post: { backgroundColor: "#607D8B", color: "#FFFFFF" },
		business_profiles: { backgroundColor: "#00BCD4", color: "#FFFFFF" },
		employees: { backgroundColor: "#8BC34A", color: "#FFFFFF" },
		activity_log: { backgroundColor: "#CDDC39", color: "#000000" },
		beegru_points_transaction: { backgroundColor: "#FFC107", color: "#000000" },
		boost_pricing_manual_overrides: { backgroundColor: "#FFEB3B", color: "#000000" },
		cash_transaction: { backgroundColor: "#FF5722", color: "#FFFFFF" },
		search_logs: { backgroundColor: "#9E9E9E", color: "#FFFFFF" },
		logs: { backgroundColor: "#607D8B", color: "#FFFFFF" },
		latest_changes: { backgroundColor: "#FF9800", color: "#FFFFFF" },
		translations: { backgroundColor: "#4CAF50", color: "#FFFFFF" },
		leads: { backgroundColor: "#E91E63", color: "#FFFFFF" },
		boost: { backgroundColor: "#3F51B5", color: "#FFFFFF" },
		reviews: { backgroundColor: "#9C27B0", color: "#FFFFFF" },
		property_all: { backgroundColor: "#009688", color: "#FFFFFF" },
		service_all: { backgroundColor: "#2196F3", color: "#FFFFFF" },
		pwa_options: { backgroundColor: "#FFEB3B", color: "#000000" },
		whatsapp: { backgroundColor: "#FF5722", color: "#FFFFFF" },
		twilioWhatsappTemplates: { backgroundColor: "#795548", color: "#FFFFFF" },
		simple_campaigns: { backgroundColor: "#607D8B", color: "#FFFFFF" },
		clicks: { backgroundColor: "#FF9800", color: "#FFFFFF" },
		blogs: { backgroundColor: "#FFC107", color: "#000000" },
		blog_author_personas: { backgroundColor: "#FFEB3B", color: "#000000" },
		url_shortener: { backgroundColor: "#FF5722", color: "#FFFFFF" },
		default: { backgroundColor: "#FFFFFF", color: "#000000" },
	};

	return id ? colors[id] || colors.default : colors.default;
});

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const IAMDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a constant for router, let's use ref to get the current value of router

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the modal for loading

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	/*

  & Next, let's define a local state for the modal for access level

  */

	const [openAccessModal, setOpenAccessMoadal] = React.useState(false);

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [loading, setLoading] = React.useState(true);

	const [editAccessLevelRowId, setEditAccessLevelRowId] = React.useState<string | null>(null);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */
	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a function to close the access level modal

  */

	const handleClose = () => {
		setOpenAccessMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in access level

  */

	const handleChange = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		console.log(item);
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/accesslevel", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				userId: cellValues.id,
				access_level: item.join(","),
			}),
		});
		FilteringFunction(queryOptions);
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to remove admin access

  */

	const removeAdminAccess = async (cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/revoke-user", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				userId: cellValues.id,
			}),
		});
		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete access for rest of the access levels

  */

	const deleteAccess = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/delete-access-level", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deleteAccess: item,
				userId: cellValues.id,
			}),
		});
		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the click on grant access button

  */

	const handleClickGrandAccess = () => {
		setOpenAccessMoadal(true);
	};

	/*

	  & Next, let's define a useEffect to update the row count when the DashboardData changes

	  */

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "IAM-table/IAM-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	const accessLevelLabels: { [key: string]: string } = {
		property: "Property",
		service: "Service",
		kyc: "KYC",
		iam: "IAM",
		admin: "Admin",
		super_admin: "Super Admin",
		user_management: "User Management",
		analytics: "Analytics",
		subscriptions: "Subscriptions",
		report: "Report",
		bug_report: "Bug Report",
		business_profiles: "Business Profiles",
		post: "Post",
		employees: "Employees",
		activity_log: "Activity Log",
		beegru_points_transaction: "Beegru Points Transaction",
		boost_pricing_manual_overrides: "Manual Points Transaction",
		cash_transaction: "Cash Transaction",
		search_logs: "Search Logs",
		logs: "Logs",
		latest_changes: "Latest Changes",
		translations: "Translations",
		leads: "Leads",
		boost: "Boost",
		reviews: "Reviews",
		property_all: "Property All",
		service_all: "Service All",
		pwa_options: "PWA Options",
		whatsapp: "WhatsApp",
		twilioWhatsappTemplates: "WhatsApp Templates",
		simple_campaigns: "Simple Campaigns",
		clicks: "Clicks",
		blogs: "Blogs",
		blog_author_personas: "Blog Author Personas",
		url_shortener: "URL Shortener",
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Property",
			value: "property",
		},
		{
			id: 2,
			label: "Service",
			value: "service",
		},
		{
			id: 3,
			label: "KYC",
			value: "kyc",
		},
		{
			id: 4,
			label: "IAM",
			value: "iam",
		},
		{
			id: 5,
			label: "User Management",
			value: "user_management",
		},
		{
			id: 6,
			label: "Admin",
			value: "admin",
		},
		{
			id: 7,
			label: "Analytics",
			value: "analytics",
		},
		{
			id: 8,
			label: "Subscriptions",
			value: "subscriptions",
		},
		{
			id: 9,
			label: "Report",
			value: "report",
		},
		{
			id: 10,
			label: "Bug Report",
			value: "bug_report",
		},
		{
			id: 11,
			label: "Business Profiles",
			value: "business_profiles",
		},
		{
			id: 12,
			label: "Post",
			value: "post",
		},
		{
			id: 13,
			label: "Employees",
			value: "employees",
		},
		{
			id: 14,
			label: "Activity Log",
			value: "activity_log",
		},
		{
			id: 15,
			label: "Beegru Points Transaction",
			value: "beegru_points_transaction",
		},
		{
			id: 16,
			label: "Boost Pricing Manual Overrides",
			value: "boost_pricing_manual_overrides",
		},
		{
			id: 17,
			label: "Cash Transaction",
			value: "cash_transaction",
		},
		{
			id: 18,
			label: "Search Logs",
			value: "search_logs",
		},
		{
			id: 19,
			label: "Logs",
			value: "logs",
		},
		{
			id: 20,
			label: "Latest Changes",
			value: "latest-changes",
		},
		{
			id: 21,
			label: "Translations",
			value: "translations",
		},
		{
			id: 22,
			label: "Leads",
			value: "leads",
		},
		{
			id: 23,
			label: "Boost",
			value: "boost",
		},
		{
			id: 24,
			label: "Reviews",
			value: "reviews",
		},
		{
			id: 25,
			label: "Property All",
			value: "property_all",
		},
		{
			id: 26,
			label: "Service All",
			value: "service_all",
		},
		{
			id: 27,
			label: "PWA Options",
			value: "pwa_options",
		},
		{
			id: 28,
			label: "WhatsApp",
			value: "whatsapp",
		},
		{
			id: 29,
			label: "WhatsApp Templates",
			value: "twilioWhatsappTemplates",
		},
		{
			id: 30,
			label: "Simple Campaigns",
			value: "simple_campaigns",
		},
		{
			id: 31,
			label: "Clicks",
			value: "clicks",
		},
		{
			id: 32,
			label: "Blogs",
			value: "blogs",
		},
		{
			id: 33,
			label: "Blog Author Personas",
			value: "blog_author_personas",
		},
		{
			id: 34,
			label: "URL Shortener",
			value: "url_shortener",
		},
	];

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 250,
		},
		{
			field: "username",
			headerName: "Username",
			width: 200,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 200,
		},
		{
			field: "access_level",
			headerName: "Access Level",
			width: 300,
			valueOptions: menuItems,
			// editable: true,
			valueFormatter: (params: any) => {
				if (!params || !params.value) {
					return "";
				}
				return accessLevelLabels[params.value] || params.value;
			},
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				const isEditing = editAccessLevelRowId === cellValues.row.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditAccessLevelRowId(null)}>
						<Select
							multiple
							value={data ? data.access_level.split(",") : []}
							onChange={(e) => {
								const selectedValues = e.target.value as string[];
								handleChange(selectedValues, cellValues);
								setEditAccessLevelRowId(null);
							}}
							variant="standard"
							onClose={() => setEditAccessLevelRowId(null)}
							fullWidth
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
											height: "20rem",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
							}}
						>
							{menuItems.map((option) => (
								<MenuItem
									key={option.value}
									value={option.value}
									sx={{
										background: "#FFFFFF",
										color: "#000000",
										padding: "0.5rem 0.5rem 0.5rem 0.5rem",
										borderRadius: "0.5rem",
										fontSize: "0.875rem",
										margin: "0.25rem 0rem 0.25rem 0rem",
										display: "flex",
										justifyContent: "center",
										gap: "0.5rem",
										alignItems: "center",

										"&.Mui-selected": {
											background: "#B05911",
											color: "#FFFFFF",
										},
										"&.Mui-selected:hover": {
											background: "#B05911 !important",
											color: "#FFFFFF !important",
										},
										"&:hover": {
											background: "#F5F5F5",
											color: "#000000",
										},
									}}
								>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<div
						onClick={() => {
							setEditAccessLevelRowId(cellValues.id);
						}}
						style={{
							cursor: "pointer", // Change cursor to pointer
							display: "flex",
							alignItems: "center",


							gap: "0.5rem",
						}}
					>
							<AddIcon sx={{
								color: "#B05911",
								fontSize: "1rem",
							}} />

						{data?.access_level?.split(",").map((level: string, index: number) => (
							<ChipData
								key={index}
								id={level}
								label={accessLevelLabels[level] || level}
								variant="filled"
								onDelete={() => {
									deleteAccess(level, cellValues);
								}}
								sx={{
									fontSize: "0.875rem",
									padding: "0.5rem",
									borderRadius: "0.5rem",
									gap: "0.5rem",
									margin: "0.25rem",
									boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
									"&:hover": {
										background: "#F5F5F5",
										color: "#000000",
									},
								}}
							/>
						))}
					</div>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Button
						variant="outlined"
						color="primary"
						sx={{
							borderRadius: "0.5rem",
						}}
						onClick={() => {
							removeAdminAccess(cellValues);
						}}
					>
						Remove Access
					</Button>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<Add />}
					sx={{
						borderRadius: "0.5rem",
						// margin: "1rem 1rem 0rem 1rem",
					}}
					onClick={() => {
						handleClickGrandAccess();
					}}
				>
					Grant Access
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on breadcrumb

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			IAM
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Moderation</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<GrandAccessModal
				open={openAccessModal}
				onClose={handleClose}
			/>
			<HeadContainer>
				<HeadText>IAM</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel ? (
				accessLevel.includes("super_admin") ? (
					<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any) => {
								return {
									id: item._id,
									userId: item._id,
									username: item.first_name + " " + item.last_name,
									phonenumber: item.phone,
									access_level: item.access_level,
								};
							})}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}

							pageSizeOptions={[100]}
							sx={{
								background: "#fff",
								scrollbarWidth: "thin",
								scrollbarColor: "#B05911 transparent",
								"&::-webkit-scrollbar": {
									width: "10px",
									height: "10px",
								},
								"&::-webkit-scrollbar-thumb": {
									backgroundColor: "#B05911",
									borderRadius: "10px",
									border: "2px solid transparent",
									backgroundClip: "padding-box",
									transition: "background-color 0.3s ease, border-color 0.3s ease",
								},
								"&::-webkit-scrollbar-thumb:hover": {
									backgroundColor: "#8A3D0F",
									borderColor: "#8A3D0F",
								},
								"&::-webkit-scrollbar-track": {
									backgroundColor: "#F5F5F5",
									borderRadius: "10px",
								},
								"&::-webkit-scrollbar-track:hover": {
									backgroundColor: "#E0E0E0",
								},
							}}
						/>
					</Box>
				) : (
					<YouDontHaveAccessComponent />
				)
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default IAMDatatable;

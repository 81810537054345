/*
? First, let's import the essentials - React and Styled
*/
import { styled } from "@mui/system";
import React from "react";
/*
? Next, let's import all the UI components we intend to use on this page from Mui.
*/
import TableViewIcon from "@mui/icons-material/TableView";
import { Button } from "@mui/material";
import router from "next/router";
import AnalyticsPieChart from "../analyticsPichart";
import LoadingComponent from "../../sub-components/LoadingComponent";

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	padding: "1rem 1rem 1rem 1rem",
}));

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
}));
const CampaignsUtmSourcePieChart = ({
	campaignUTMSourceData,
	campaignUTMIDData,
}: {
	campaignUTMSourceData: any;
	campaignUTMIDData: any;
}) => {
	if (!campaignUTMSourceData || !campaignUTMIDData) {
		return (
			<div style={{ width: "100%", height: "100vh", padding: "2rem" }}>
				<LoadingComponent />
			</div>
		);
	}
	return (
		<React.Fragment>
			<MainContainer>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "flex-start",
						width: "100%",
						padding: "0rem 0rem 0rem 0rem ",
					}}
				>
					<Button
						variant="contained"
						sx={{
							background: "#FFFFFF",
							fontSize: "0.75rem",
							margin: " 0rem 0.5rem 0.5rem 0rem",
						}}
						onClick={() => {
							router.push(
								{
									pathname: router.pathname,
									query: {
										tab: "simple_campaigns",
									},
								},
								undefined,
								{
									shallow: true,
								},
							);
						}}
						startIcon={<TableViewIcon fontSize="small" />}
					>
						view table
					</Button>
				</div>
				<PaperContainer>
					<AnalyticsPieChart
						title="Campaigns UTM Source"
						graphData={campaignUTMSourceData ? campaignUTMSourceData.pi_chart : []}
					/>
					<AnalyticsPieChart
						title="Campaigns UTM Source ID"
						graphData={campaignUTMIDData ? campaignUTMIDData.pi_chart : []}
					/>
				</PaperContainer>
			</MainContainer>
		</React.Fragment>
	);
};
/*
& FInally, let's export the component
*/
export default CampaignsUtmSourcePieChart;

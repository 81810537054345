/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Add, NavigateNext } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import {
	Box,
	Breadcrumbs,
	Chip,
	debounce,
	Divider,
	IconButton,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";
import { ObjectId } from "mongodb";
import { useRouter } from "next/router";
import AddRemoveBeegruPoints from "../../../modal/AddRemoveBeegruPointsModal";
import BannnedUsersModal from "../../../modal/BannedUserModal";
import LoadingModal from "../../../modal/LoadingModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "admin"
		? {
				backgroundColor: "#d5d5d7",
				color: "#000000",
			}
		: id === "super_admin"
			? {
					backgroundColor: "#FFD700",
				}
			: id === "property"
				? {
						backgroundColor: "#FFFF00",
					}
				: id === "service"
					? {
							backgroundColor: "rgb(30,144,255,0.6)",
						}
					: id === "kyc"
						? {
								backgroundColor: " #00FF00",
							}
						: id === "iam"
							? {
									backgroundColor: "rgb(220,20,60,0.8)",
								}
							: id === "user_management"
								? {
										backgroundColor: "#FF00FF",
									}
								: id === "analytics"
									? {
											backgroundColor: "#eb57be",
										}
									: id === "subscriptions"
										? {
												backgroundColor: "#bf7752",
											}
										: id === "report"
											? {
													backgroundColor: "#FFA500",
												}
											: id === "bug_report"
												? {
														backgroundColor: "#B5A6D5",
													}
												: id === "post"
													? {
															backgroundColor: "rgba(255,0,10,0,1)",
														}
													: id === "business_profiles"
														? {
																backgroundColor: "rgb(0,191,255)",
															}
														: id === "employees"
															? {
																	backgroundColor: "#a8e3b8",
																}
															: id === "activity_log"
																? {
																		backgroundColor: "#e2d3e3",
																	}
																: id === "beegru_points_transaction"
																	? {
																			backgroundColor: "#86a80d",
																		}
																	: id === "manual_points_transaction"
																		? {
																				backgroundColor: "#d9f086",
																			}
																		: id === "cash_transaction"
																			? {
																					backgroundColor: "#ed725f",
																				}
																			: id === "search_logs"
																				? {
																						backgroundColor: "#72708a",
																					}
																				: {
																						backgroundColor: "rgba(0,0,0,0.1)",
																					},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const LastSeen = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const LastSeenSub = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const UsertDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	const [openModal, setOpenMoadal] = React.useState(false);

	const [bannedUserModal, setBannedUserModal] = React.useState(false);

	const [addBeegruPointsMoadal, setAddBeegruPointsMoadal] = React.useState(false);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);
	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });
	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);
	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	/*

  & Next, let's define a local state for the user id to add beegru points

  */
	const [userId, setUserId] = React.useState<string | ObjectId>("");

	const [username, setUsername] = React.useState<string>("");

	const [add, setAdd] = React.useState<string>("add");

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const verifyUser = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "IAM-table/verify-user", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				verify: event,
				userId: cellValues,
			}),
		});
		FilteringFunction(queryOptions);
		setOpenMoadal(false);
	};

	const referred = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "user-table/referred", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				is_referred: event,
				userId: cellValues,
			}),
		});
		FilteringFunction(queryOptions);
		setOpenMoadal(false);
	};
	const managed = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "user-table/managed", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				managed: event,
				userId: cellValues,
			}),
		});
		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "user-table/user-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				userId: cellValues.id,
			}),
		});
		FilteringFunction(queryOptions);
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "user-table/delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				userId: cellValues.id,
			}),
		});
		FilteringFunction(queryOptions);
		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "user-table/user-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setAccessLevel(res.payload.accessLevel);
			setListOfModerators(res.payload.list_of_moderators);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define a function to add beegru points

  */

	const AddBeegruPoints = async (cellValues: any) => {
		setUserId(cellValues.userId);
		setAddBeegruPointsMoadal(true);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/users/" + cellValues.value}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "username",
			headerName: "Username",
			width: 200,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},
		{
			field: "created_using_bd_tool",
			headerName: "Created using BD Tool",
			width: 150,
		},
		{
			field: "createdOn",
			headerName: "Account created on",
			width: 150,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toDateString(); // Format the date as a string for display
			},
		},

		{
			field: "lastSeen",
			headerName: "Last seen",
			width: 170,
			filterable: false,
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);

				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});

				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
						}}
					>
						{is_date_14_days_old ? (
							<Tooltip
								title="Last seen more than 14 days ago"
								placement="top"
							>
								<LastSeen
									sx={{
										color: "rgba(255,0,0,0.8)",
									}}
								>
									<LastSeenSub>
										<CalendarTodayOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",").slice(0, 2).join(",")}
										</Typography>
									</LastSeenSub>
									<LastSeenSub>
										<AccessTimeOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",")[2]}
										</Typography>
									</LastSeenSub>
								</LastSeen>
							</Tooltip>
						) : (
							<LastSeen>
								<LastSeenSub>
									<CalendarTodayOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",").slice(0, 2).join(",")}
									</Typography>
								</LastSeenSub>
								<LastSeenSub>
									<AccessTimeOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",")[2]}
									</Typography>
								</LastSeenSub>
							</LastSeen>
						)}
					</ActionActions>
				);
			},
		},

		{
			field: "creditDebit",
			headerName: "Credit/Debit",
			width: 140,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							padding: "0.5rem",
						}}
					>
						<IconButton
							onClick={() => {
								setAdd("add");
								AddBeegruPoints(cellValues.row);
							}}
							sx={{
								// color: "#17f52a",
								backgroundColor: "transparent",
								// borderRadius: "50%",
								border: "1px solid rgb(17, 82, 23)",
								padding: "0.5rem",
							}}
						>
							<AddCircleIcon sx={{ color: "rgba(28, 104, 36, 0.83)" }} />
						</IconButton>
						<IconButton
							onClick={() => {
								setAdd("remove");
								AddBeegruPoints(cellValues.row);
							}}
							sx={{
								// color: "#17f52a",
								backgroundColor: "transparent",
								// borderRadius: "50%",
								border: "1px solid rgba(226, 74, 14, 0.89)",
								padding: "0.5rem",
							}}
						>
							<RemoveCircleIcon sx={{ color: "rgba(226, 74, 14, 0.89)" }} />
						</IconButton>
					</ActionActions>
				);
			},
		},
		{
			field: "bannedUsers",
			headerName: "Banned Users",
			width: 120,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							setUserId(cellValues.row.id);
							setUsername(cellValues.row.username);
							setBannedUserModal(true);
						}}
					/>
				);
			},
		},
		{
			field: "location",
			headerName: "Location",
			width: 150,
		},
		{
			field: "accessLevel",
			headerName: "Access Level",
			width: 150,
			renderCell: (cellValues: any) => {
				return cellValues.value?.length > 0 ? (
					<ActionActions
						sx={{
							padding: "0.5rem",
							width: "100%",
						}}
					>
						{cellValues.value.map((item: any, index: number) => {
							return (
								<ChipData
									id={item}
									key={index}
									label={item}
									variant="outlined"
									sx={{
										boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
									}}
								/>
							);
						})}
					</ActionActions>
				) : (
					<ChipData
						id="assign"
						label=""
						color="primary"
					/>
				);
			},
		},
		{
			field: "beegruPoints",
			headerName: "Beegru Points",
			width: 100,
		},
		{
			field: "totalExpenditure",
			headerName: "Total Expenditure",
			width: 200,
			filterable: false,
		},
		{
			field: "verify",
			headerName: "verify",
			width: 100,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							verifyUser(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},
		{
			field: "referred",
			headerName: "Referred",
			width: 120,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							referred(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},
		{
			field: "referral_source",
			headerName: "Referral Source",
			width: 120,
		},
		{
			field: "referral_code",
			headerName: "Referral Code",
			width: 120,
		},
		{
			field: "managed",
			headerName: "Managed",
			width: 120,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							managed(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},
		{
			field: "assigned_to",
			headerName: "Assigned",
			width: 300,
			editable: true,
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return data && data.assigned_to?.length > 0 ? (
					<ActionActions>
						{data &&
							data.assigned_to?.map((item: any, index: number) => {
								return (
									<Chip
										id={item._id}
										key={index}
										label={
											item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"
										}
										variant="outlined"
										onDelete={() => {
											deleteAssigned(item._id, cellValues);
										}}
										color="primary"
									/>
								);
							})}
					</ActionActions>
				) : (
					<Chip
						id="assign"
						label="Assign to"
						variant="outlined"
						onDelete={() => {}}
						deleteIcon={<Add />}
						color="primary"
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);

				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={data ? data.assigned_to : cellValues.value}
					>
						{list_of_moderators?.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
		{
			field: "account_settings",
			headerName: "Account Settings",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/users/" + cellValues.value + "/account-settings"}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			User Management
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>User Management</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<BannnedUsersModal
				open={bannedUserModal}
				onClose={() => {
					setBannedUserModal(false);
				}}
				userId={userId}
				session={session}
				username={username}
			/>
			<AddRemoveBeegruPoints
				open={addBeegruPointsMoadal}
				onClose={() => {
					setAddBeegruPointsMoadal(false);
				}}
				userId={userId}
				addOrRemove={add}
				session={session}
			/>

			<LoadingModal open={openModal} />
			<HeadContainer>
				<HeadText>User Management</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("user_management")) ||
				accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any) => {
							const lastSeen = new Date(item.last_seen);

							return {
								id: item._id,
								userId: item._id,
								username: item.first_name && item.last_name ? item.first_name + " " + item.last_name : "no name",
								phonenumber: "+91 " + item.phone,
								createdOn: item.created_at ? new Date(item.created_at) : new Date(),
								lastSeen: item.last_seen ? lastSeen.toLocaleDateString() : "--",
								creditDebit: item,
								bannedUsers: item.banned?.status,
								location: item.address ? (item.address.city ? item.address.city : "--") : "--",
								accessLevel: item.access_level ? item.access_level.split(",") : "",
								beegruPoints: item.beegru_points,
								totalExpenditure: item.totalExpenditure,
								verify: item.verified,
								referred: item.referrer ? item.referrer.is_referred : false,
								referral_source: item.referrer ? item.referrer.referral_source : "--",
								referral_code: item.referrer
									? item.referrer.referral_code
										? item.referrer.referral_code
										: "--"
									: "--",
								managed: item.managed,
								assigned_to: item.assigned_to ? item.assigned_to : [],
								account_settings: item._id,

								created_using_bd_tool: item.created_using_bd_tool,
							};
						})}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default UsertDatatable;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	DataGridPremium,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

import { Add, NavigateNext } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Breadcrumbs, Button, debounce, Divider, Link, Switch, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import ClickerInfoModal from "../../../modal/ClickerModal";
import CreateBlogsModal from "../../../modal/CreateBlogsModal";
import LoadingModal from "../../../modal/LoadingModal";
import QueryParametersModal from "../../../modal/QueryParametersModal";
import ViewBlogModal from "../../../modal/ViewBlogModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

// import LoadingModal from "../../../modal/LoadingModal";
// import InventoryModal from "../../../modal/PropertyInventoryModal";
// import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.5rem",
	padding: "0.5rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));
const LastSeen = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const LastSeenSub = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const BlogsDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [openModal, setOpenModal] = React.useState(false);

	const [userInfo, setUserInfo] = React.useState<any>({});

	const [queryParams, setQueryParams] = React.useState<any>();

	const [openQueryModal, setOpenQueryModal] = React.useState(false);

	const [openCreateBlogsModal, setOpenCreateBlogsModal] = React.useState(false);

	const [openViewBlogModal, setOpenViewBlogModal] = React.useState(false);

	const [blogId, setBlogId] = React.useState("");

	const [loadingOpenModal, setLoadingOpenModal] = React.useState(false);

	const [viewBlogData, setViewBlogData] = React.useState<any>();

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "blogs-table/blogs-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);
	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	React.useEffect(() => {
		if (blogId) {
			setLoadingOpenModal(true);
			(async () => {
				const response = await fetch(process.env.PRODUCTION_API_URL + "blogs-table/delete-blog", {
					method: "POST",
					body: JSON.stringify({
						_id: blogId,
					}),
				});
				const payload = await response.json();
				if (payload.status === "success") {
					router.push(
						{
							pathname: router.pathname,
							query: {
								...router.query,
								page: paginationModel.page,
							},
						},
						undefined,
						{
							shallow: true,
						},
					);
					setLoadingOpenModal(false);
					setBlogId("");
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blogId]);

	const deleteBlogs = async (e: any, cellValues: any) => {
		setLoadingOpenModal(true);
		await fetch(process.env.PRODUCTION_API_URL + "blogs-table/delete-blog", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deleted: e.target.checked,
				_id: cellValues.id,
				published: cellValues.published,
				published_stage: cellValues.published_stage,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);

		setLoadingOpenModal(false);
	};

	const publishBlogs = async (e: any, cellValues: any) => {
		setLoadingOpenModal(true);
		await fetch(process.env.PRODUCTION_API_URL + "blogs-table/blogs-published", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				blogId: cellValues.id,
				published: e.target.checked,
				published_stage: cellValues.published_stage,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);

		setLoadingOpenModal(false);
	};

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "_id",
			headerName: "Id",
			width: 150,
			filterable: false,
		},
		{
			field: "title",
			headerName: "Title",
			width: 150,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={
							"https://beegru.com/blog/" +
							(cellValues.row.author
								? cellValues.row.author.first_name.toLowerCase() + "-" + cellValues.row.author.last_name.toLowerCase()
								: "") +
							"/" +
							(cellValues.value ? cellValues.value.toLowerCase().split(" ").join("-") : "")
						}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "search_points",
			headerName: "Search Points",
			width: 150,
		},

		{
			field: "author",
			headerName: "Author",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (cellValues.value) {
					return (
						<Typography
							variant="body1"
							sx={{
								padding: "1rem",
								fontSize: "0.85rem",
							}}
						>
							{cellValues.value.first_name} {cellValues.value.last_name}
						</Typography>
					);
				}
			},
		},
		{
			field: "category",
			headerName: "Category",
			width: 100,
		},
		{
			field: "sub_categories",
			headerName: "Sub Categories",
			width: 150,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (cellValues.value) {
					return (
						<ActionActions>
							{cellValues.value.map((subCategory: string, key: number) => {
								return (
									<Typography
										key={key}
										sx={{
											fontSize: "0.85rem",
											fontWeight: 500,
											// color: "#F76C35",
											padding: "0.5rem 0rem 0rem 0rem",
										}}
									>
										{subCategory} {key < cellValues.value.length - 1 ? "," : ""}
									</Typography>
								);
							})}
						</ActionActions>
					);
				}
			},
		},

		{
			field: "content",
			headerName: "Content",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (cellValues.value) {
					return (
						<ActionActions>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									setOpenViewBlogModal(true);
									setViewBlogData(cellValues.row);
								}}
							>
								View
							</Button>
						</ActionActions>
					);
				}
			},
		},
		{
			field: "tags",
			headerName: "Tags",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (cellValues.value) {
					return (
						<Typography
							sx={{
								fontSize: "0.85rem",
								fontWeight: 500,
								padding: "0.8rem",
							}}
						>
							{cellValues.value.join(", ")}
						</Typography>
					);
				}
				return null;
			},
		},
		{
			field: "published",
			headerName: "Published",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							publishBlogs(e, cellValues);
						}}
					/>
				);
			},
		},
		{
			field: "published_stage",
			headerName: "Published Stage",
			width: 150,
		},

		{
			field: "deleted",
			headerName: "Deleted",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							deleteBlogs(e, cellValues);
						}}
					/>
				);
			},
		},
		{
			field: "comments",
			headerName: "Comments",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (cellValues.value) {
					return (
						<ActionActions>
							{cellValues.value.map((comment: any, key: number) => {
								return (
									<Typography
										key={key}
										sx={{
											fontSize: "0.75rem",
											fontWeight: 500,
											color: "#F76C35",
										}}
									>
										{comment.commentator.first_name} {comment.commentator.last_name}
									</Typography>
								);
							})}
						</ActionActions>
					);
				}
			},
		},
		{
			field: "linked_blogs",
			headerName: "Linked Blogs",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (cellValues.value)
					return (
						<ActionActions>
							{cellValues.value.map((linkedBlog: string, key: number) => {
								return (
									<Typography
										key={key}
										sx={{
											fontSize: "0.75rem",
											fontWeight: 500,
											color: "#F76C35",
										}}
									>
										{linkedBlog}
									</Typography>
								);
							})}
						</ActionActions>
					);
			},
		},
		{
			field: "read_time_in_minutes",
			headerName: "Read Time",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Typography
							sx={{
								fontSize: "1rem",
								fontWeight: 500,
								color: "#F76C35",
							}}
						>
							{cellValues.value}
						</Typography>
					</ActionActions>
				);
			},
		},

		{
			field: "faqs",
			headerName: "FAQs",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (cellValues.value) {
					return (
						<ActionActions>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									setOpenViewBlogModal(true);
									setViewBlogData(cellValues.row);
								}}
							>
								View
							</Button>
						</ActionActions>
					);
				}
			},
		},
		{
			field: "meta_title",
			headerName: "Meta Title",
			width: 200,
			filterable: false,
		},
		{
			field: "meta_description",
			headerName: "Meta Description",
			width: 200,
			filterable: false,
		},
		{
			field: "meta_keywords",
			headerName: "Meta Keywords",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				if (!cellValues.value) return null;
				return (
					<ActionActions>
						{cellValues.value.map((keyword: string, key: number) => {
							return (
								<Typography
									key={key}
									sx={{
										fontSize: "0.85rem",
										fontWeight: 500,
										// color: "#F76C35",
										padding: "0.5rem 0rem 0rem 0rem",
									}}
								>
									{keyword} {key < cellValues.value.length - 1 ? "," : ""}
								</Typography>
							);
						})}
					</ActionActions>
				);
			},
		},

		{
			field: "createdOn",
			headerName: "createdOn",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
						}}
					>
						<LastSeen>
							<LastSeenSub>
								<CalendarTodayOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",").slice(0, 2).join(",")}
								</Typography>
							</LastSeenSub>
							<LastSeenSub>
								<AccessTimeOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",")[2]}
								</Typography>
							</LastSeenSub>
						</LastSeen>
					</ActionActions>
				);
			},
		},
		{
			field: "updateOn",
			headerName: "updateOn",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
						}}
					>
						<LastSeen>
							<LastSeenSub>
								<CalendarTodayOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",").slice(0, 2).join(",")}
								</Typography>
							</LastSeenSub>
							<LastSeenSub>
								<AccessTimeOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",")[2]}
								</Typography>
							</LastSeenSub>
						</LastSeen>
					</ActionActions>
				);
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								setQueryParams(cellValues.row);
								setOpenCreateBlogsModal(true);
							}}
						>
							Edit
						</Button>
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<Add />}
					sx={{
						borderRadius: "0.5rem",
						margin: "0.5rem 1rem 0.5rem 1rem",
					}}
					onClick={() => {
						setOpenCreateBlogsModal(true);
					}}
				>
					Create new blogs
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Blogs
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Blogs</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<LoadingModal open={loadingOpenModal} />
			<ViewBlogModal
				open={openViewBlogModal}
				onClose={() => {
					setOpenViewBlogModal(false);
					setViewBlogData(undefined);
				}}
				data={viewBlogData}
			/>

			<CreateBlogsModal
				open={openCreateBlogsModal}
				onClose={() => {
					setOpenCreateBlogsModal(false);
				}}
				data={queryParams ? queryParams : undefined}
				setQueryOptions={setQueryOptions}
				user_id={undefined}
			/>
			<ClickerInfoModal
				open={openModal}
				onClose={() => setOpenModal(false)}
				userInfo={userInfo}
			/>
			<QueryParametersModal
				open={openQueryModal}
				onClose={() => setOpenQueryModal(false)}
				queryParams={queryParams}
			/>
			<HeadContainer>
				<HeadText>Blogs</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("simple_campaigns")) ||
				accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							return {
								id: item ? item._id : key,
								_id: item ? item._id : "",
								title: item.title,
								slug: item.slug,
								author: item.author,
								category: item.category,
								sub_categories: item.sub_categories,
								tags: item.tags,
								comments: item.comments,
								content: item.content,
								linked_blogs: item.linked_blogs,
								linked_properties: item.linked_properties,
								linked_properties_title: item.linked_properties_title,
								linked_services_title: item.linked_services_title,
								linked_services: item.linked_services,
								read_time_in_minutes: item.read_time_in_minutes,
								faqs: item.faqs,
								meta_description: item.meta_description,
								meta_keywords: item.meta_keywords,
								createdOn: item.created_at ? new Date(item.created_at) : new Date(),
								updateOn: item.updated_at ? new Date(item.updated_at) : new Date(),
								cover_image_file_id: item.cover_image_file_id,
								published: item.published,
								published_stage: item.published_stage,
								deleted: item.deleted,
								search_points: item.search_metrics ? item.search_metrics.search_points : 0,
								meta_title: item.meta_title,
								images: item.images,
								cover_image_alt_text: item.cover_image_alt_text,
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default BlogsDataTable;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Close, NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, debounce, Divider, IconButton, Link, Snackbar, Typography } from "@mui/material";
import { useRouter } from "next/router";
import SearchQueryModal from "../../../modal/SearchQueryModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0.5rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const SearchLogDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [openInvalidateSearchCacheSnackbar, setOpenInvalidateSearchCacheSnackbar] = React.useState(false);

	const [searchQueryData, setSearchQueryData] = React.useState({});

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */
	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "search-log-table/search-log-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "_id",
			headerName: " ID",
			width: 150,
			filterable: false,
		},
		{
			field: "search_duration",
			headerName: "Search Duration",
			width: 150,
		},
		{
			field: "result_count",
			headerName: "Result Count",
			width: 150,
		},
		{
			field: "search_query",
			headerName: "Applied Filters",
			width: 150,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setOpenMoadal(true);
								setSearchQueryData(cellValues.value);
							}}
							sx={{ color: "#FFFFFF" }}
						>
							View
						</Button>
					</ActionActions>
				);
			},
		},
		{
			field: "cache_hit",
			headerName: "Cache Hit",
			width: 150,
		},
		{
			field: "cache_id",
			headerName: "Cache ID",
			width: 150,
		},
		{
			field: "filter_hash",
			headerName: "Filter Hash",
			width: 150,
		},
		{
			field: "cache_invalidated",
			headerName: "Cache Invalidated",
			width: 150,
		},
		{
			field: "cache_invalidated_at",
			headerName: "Cache Invalidated At",
			width: 150,
			filterable: false,
		},
		{
			field: "actual_page_number",
			headerName: "Actual Page Number",
			width: 150,
			filterable: false,
		},
		{
			field: "expected_page_number",
			headerName: "Expected Page Number",
			width: 150,
			filterable: false,
		},
		{
			field: "actual_page_size",
			headerName: "Actual Page Size",
			width: 150,
			filterable: false,
		},
		{
			field: "expected_page_size",
			headerName: "Expected Page Size",
			width: 150,
			filterable: false,
		},
		{
			field: "is_signed_in",
			headerName: "Is Signed In",
			width: 150,
		},

		{
			field: "ip_address",
			headerName: "IP Address",
			width: 150,
			filterable: false,
		},
		{
			field: "browser",
			headerName: "Browser",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Chrome"
									? "/images/icons/logo/🦆 icon _chrome_.svg"
									: cellValues.value === "Duck"
										? "/images/icons/logo/Duck Duck Go.svg"
										: cellValues.value === "Brave"
											? "/images/icons/logo/Brave.svg"
											: cellValues.value === "Edge"
												? "/images/icons/logo/Edge.svg"
												: cellValues.value === "Firefox"
													? "/images/icons/logo/Firefox.svg"
													: cellValues.value === "Opera"
														? "/images/icons/logo/Opera.svg"
														: cellValues.value?.includes("Safari")
															? "/images/icons/logo/Safari.svg"
															: cellValues.value === "Samsung"
																? "/images/icons/logo/Samsung Browser.svg"
																: cellValues.value === "undici"
																	? "/images/icons/logo/nodejs-icon.svg"
																	: cellValues.value === "Internet Explorer"
																		? "/images/icons/logo/Internet Explorer.svg"
																		: cellValues.value === "iOS"
																			? "/images/icons/logo/Apple.svg"
																			: "/images/icons/logo/🦆 icon _chrome_.svg"
							}
							alt="browser"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},
		{
			field: "os",
			headerName: "OS",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Windows"
									? "/images/icons/logo/Windows 11.svg"
									: cellValues.value === "MacOS"
										? "/images/icons/logo/Apple (1).svg"
										: cellValues.value?.includes("iOS")
											? "/images/icons/logo/Apple.svg"
											: cellValues.value?.includes("macOS")
												? "/images/icons/logo/Apple (1).svg"
												: cellValues.value === "Linux"
													? "/images/icons/logo/Linux.svg"
													: cellValues.value?.includes("Android")
														? "/images/icons/logo/android.svg"
														: "/images/icons/logo/Windows 11.svg"
							}
							alt="os"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},
		{
			field: "service_provider",
			headerName: "Service Provider",
			width: 150,
		},
		{
			field: "user_id",
			headerName: "User ID",
			width: 150,
		},

		{
			field: "createdAt",
			headerName: "Created At",
			width: 150,
			filterable: false,
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="contained"
					color="primary"
					sx={{ margin: "0.5rem 0rem 0rem 1rem", color: "#FFFFFF" }}
					onClick={handleClickInvalidateCache}
				>
					Invalidate Search Cache
				</Button>
			</ButtonContainer>
		);
	};

	/*

	& Next, let's define a function to handle the click on the invalidate search cache button

	*/

	const handleClickInvalidateCache = async () => {
		await fetch(process.env.PRODUCTION_API_URL + "invalidate-search-cache", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
		});
		setOpenInvalidateSearchCacheSnackbar(true);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Search Logs
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Search Logs</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			{/* Search Query Modal */}

			<SearchQueryModal
				open={openModal}
				onClose={() => {
					setOpenMoadal(false);
				}}
				searchQueryData={searchQueryData}
			/>

			{/* Snackbar for Invalidate Search Cache */}

			<Snackbar
				open={openInvalidateSearchCacheSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenInvalidateSearchCacheSnackbar(false)}
				message="Search Cache Invalidated!"
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={() => setOpenInvalidateSearchCacheSnackbar(false)}
					>
						<Close fontSize="small" />
					</IconButton>
				}
				ContentProps={{
					sx: {
						backgroundColor: "#2D6C3E",
						color: "#FFFFFF",
					},
				}}
			/>

			<HeadContainer>
				<HeadText>Search Logs</HeadText>

				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>

				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>

			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("search_logs")) ||
				accessLevel.includes("super_admin")) ? (
				<Box
					sx={{
						height: "75vh",
						padding: "1rem",
						width: "100%",
					}}
				>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							return {
								id: key,
								_id: item ? item._id : "",
								search_duration: item ? (item.metadata ? item.metadata.search_duration + " ms" : 0) : 0,
								result_count: item
									? item.metadata
										? item.metadata.total_no_of_results
											? item.metadata.total_no_of_results
											: 0
										: 0
									: 0,

								cache_hit: item
									? item.metadata
										? item.metadata.cache
											? item.metadata.cache.cache_hit
											: ""
										: ""
									: "",
								cache_id: item ? (item.metadata ? (item.metadata.cache ? item.metadata.cache.cache_id : "") : "") : "",
								filter_hash: item
									? item.metadata
										? item.metadata.cache
											? item.metadata.cache.filter_hash
											: ""
										: ""
									: "",
								cache_invalidated: item
									? item.metadata
										? item.metadata.cache
											? item.metadata.cache.invalidated
											: ""
										: ""
									: "",
								cache_invalidated_at: item
									? item.metadata
										? item.metadata.cache
											? new Date(item.metadata.cache.invalidated_at).toLocaleString()
											: ""
										: ""
									: "",
								actual_page_number: item
									? item.metadata
										? item.metadata.page_number
											? item.metadata.page_number.actual
											: 0
										: 0
									: 0,
								expected_page_number: item
									? item.metadata
										? item.metadata.page_number
											? item.metadata.page_number.expected
											: 0
										: 0
									: 0,
								actual_page_size: item
									? item.metadata
										? item.metadata.page_size
											? item.metadata.page_size.actual
											: 0
										: 0
									: 0,
								expected_page_size: item
									? item.metadata
										? item.metadata.page_size
											? item.metadata.page_size.expected
											: 0
										: 0
									: 0,

								ip_address: item
									? item.metadata
										? item.metadata.ip_stack_response
											? item.metadata.ip_stack_response.ip
											: ""
										: ""
									: "",
								browser: item
									? item.metadata
										? item.metadata.user_stack_response
											? item.metadata.user_stack_response.browser
												? item.metadata.user_stack_response.browser.name
												: ""
											: ""
										: ""
									: "",
								os: item
									? item.metadata
										? item.metadata.user_stack_response
											? item.metadata.user_stack_response.os
												? item.metadata.user_stack_response.os.name != null
													? item.metadata.user_stack_response.os.name
													: "Linux"
												: ""
											: ""
										: ""
									: "",
								service_provider: item
									? item.metadata
										? item.metadata.ip_stack_response
											? item.metadata.ip_stack_response.connection
												? item.metadata.ip_stack_response.connection.isp
													? item.metadata.ip_stack_response.connection.isp
													: "--"
												: "--"
											: "--"
										: ""
									: "",
								is_signed_in: item
									? item.metadata
										? item.metadata.user_details
											? item.metadata.user_details.is_signed_in
											: false
										: false
									: false,
								user_id: item
									? item.metadata
										? item.metadata.user_details
											? item.metadata.user_details.user_id
											: "--"
										: "--"
									: "--",

								search_query: item ? (item.metadata ? item.metadata.applied_filters : "") : "",
								createdAt: item ? new Date(item.created_at).toLocaleString() : "",
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default SearchLogDataTable;

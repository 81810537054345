// const NumberToCurrencyPipe: (
//   value: number | string,
//   format?: string
// ) => string = (value: number, format: string = "en-IN") => {
//   value = Number(String(value).replace(/\D/g, ""));
//   return new Intl.NumberFormat(format, {
//     maximumFractionDigits: 0,
//   }).format(value);
// };

// export default NumberToCurrencyPipe;

// export default NumberToCurrencyPipe;

const NumberToCurrencyPipe: (value: number | string, format?: string) => string = (
	value: number | string,
	format: string = "en-IN",
) => {
	// Remove all characters except digits, decimal point, and minus sign
	const cleanedValue = String(value).replace(/[^0-9.]+/g, "");

	// Convert to number
	const numericValue = Number(cleanedValue);

	// Determine the number of fraction digits
	let fractionDigits = 0;
	if (numericValue % 1 !== 0) {
		const decimalPart = cleanedValue.split(".")[1];
		fractionDigits = decimalPart.length === 1 ? 1 : 2;
	}

	// Format the number
	return new Intl.NumberFormat(format, {
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	}).format(numericValue);
};

export default NumberToCurrencyPipe;

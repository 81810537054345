/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	DataGridPremium,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

import { Add, ArrowDropDown, Equalizer, Inventory2Outlined } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import {
	Box,
	Button,
	Chip,
	ClickAwayListener,
	debounce,
	FormControlLabel,
	FormGroup,
	IconButton,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import ReactTimeAgo from "react-time-ago";
import PreparePriceString from "../../../../lib/PreparePriceString";
import LoadingModal from "../../../modal/LoadingModal";
import InventoryModal from "../../../modal/PropertyInventoryModal";
import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
// import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "sold_out"
		? {
				background: "rgba(244, 67, 54, 0.5)",
				color: "#FFFFFF",
				borderRadius: "0.5rem",
				fontSize: "0.875rem",
			}
		: id === "work_in_progress"
			? {
					background: "rgba(33, 150, 243, 0.5)",
					color: "#FFFFFF",
					padding: "0.5rem",
					borderRadius: "0.5rem",
					fontSize: "0.875rem",
				}
			: id === "pending_qc"
				? {
						background: "rgba(237, 108, 2, 0.5)",
						color: "rgb(156, 73, 5)",
						borderRadius: "0.5rem",
						fontSize: "0.875rem",
					}
				: id === "published"
					? {
							background: "rgba(76, 175, 80, 0.5)",
							color: "rgb(22, 104, 25)",
							padding: "0.5rem",
							borderRadius: "0.5rem",
							fontSize: "0.875rem",
						}
					: id === "publish_requested"
						? {
								background: "rgb(255, 191, 0)",
								color: "rgb(114, 90, 20)",
								padding: "0.5rem",
								borderRadius: "0.5rem",
								fontSize: "0.875rem",
							}
						: id === "edited"
							? {
									background: "#d5d5d7",
									color: "rgb(102, 101, 96)",
									// color: " #000000",
									padding: "0.5rem",
									borderRadius: "0.5rem",
									fontSize: "0.875rem",
								}
							: {
									background: "#FFFFFF",
									color: "#000000",
									padding: "0.5rem",
									borderRadius: "0.5rem",
									fontSize: "0.875rem",
								},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.5rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
}));

// const HeadContainer = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "column",
// 	justifyContent: "flex-start",
// 	alignItems: "flex-start",
// 	width: "100%",
// 	padding: "1rem 0rem",
// }));

// const HeadText = styled(Typography)(({ theme }) => ({
// 	fontSize: "1.5rem",
// 	fontWeight: "400",
// 	lineHeight: "123.5%",
// 	padding: "0rem 0rem 0.5rem 0rem",
// 	margin: "0rem 0rem 0rem 1rem",
// }));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));
const LastSeen = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const LastSeenSub = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const StyledGridToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
	backgroundColor: "transparent",
	border: "none",
	padding: "0.5rem 0rem 0.5rem 0.5rem",
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexDirection: "column",
}));

const StyledDataGridPremium = styled(DataGridPremium)(({ theme }) => ({
	background: "#fff",
	scrollbarWidth: "thin",
	scrollbarColor: "#F5F5F5 transparent",
	borderRadius: "0.5rem",
	border: "none",
	"& .fade-in-row": {
		opacity: 0,
		transform: "translateX(-20px)",
		animation: "fadeInRow 0.5s ease-out forwards",
	},
	"@keyframes fadeInRow": {
		"0%": {
			opacity: 0,
			transform: "translateX(-20px)",
		},
		"100%": {
			opacity: 1,
			transform: "translateY(0)",
		},
	},
	"& .MuiDataGrid-withBorderColor": {
		border: "none  !important",
	},
	"& .MuiDataGrid-columnHeader--pinnedRight": {
		backgroundColor: "#FFF2E8",
	},
	"& .MuiDataGrid-column--pinnedRight": {
		backgroundColor: "#FFF2E8",
	},
	"&::-webkit-scrollbar": {
		width: "10px",
		height: "10px",
	},

	"&::-webkit-scrollbar-thumb": {
		backgroundColor: "#F5F5F5",
		borderRadius: "10px",
		border: "2px solid transparent",
		backgroundClip: "padding-box",
		transition: "background-color 0.3s ease, border-color 0.3s ease",
	},
	"&::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#F5F5F5",
		borderColor: "#F5F5F5",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: "#F5F5F5",
		borderRadius: "10px",
	},
	"&::-webkit-scrollbar-track:hover": {
		backgroundColor: "#E0E0E0",
	},
}));

const StyledBox = styled(Box)(({ theme }) => ({
	height: "85vh",
	padding: "1rem",
	width: "100%",

	"& .super-app-theme--header": {
		// border: "1px solid #B05911",
		// backgroundColor: "#B05911",
		backgroundColor: "#FFF2E8",
		textTransform: "uppercase",
		// color: "#FC8019",
		fontSize: "0.875rem",
		fontWeight: 500,
		// borderRadius: "0.5rem",
		"& .MuiDataGrid-columnHeaderTitle": {
			fontSize: "0.875rem",
			fontWeight: 500,
		},
		
	},
}));

const TableTitle = styled(Typography)(({ theme }) => ({
	fontSize: "2rem",
	fontWeight: 500,
	color: "#B05911",
	margin: "0rem 1rem 0rem 0rem",
	textTransform: "uppercase",
	letterSpacing: "0.1rem",
	textAlign: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
	fontSize: "0.75rem",
	borderRadius: "0.5rem",
	background: "#FFFFFF",
	color: "#B05911",
	boxShadow: "none",
}));

const StyledDiv = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "flex-end",
	justifyContent: "flex-end",
	gap: "0.5rem",
}));

const PropertyDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	const [openModal, setOpenMoadal] = React.useState(false);

	const [reminderModal, setReminderModal] = React.useState(false);

	const [propertyId, setPropertyId] = React.useState("");

	const [transactiontype, settransactiontype] = React.useState("");

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [inventoryModal, setInventoryModal] = React.useState(false);

	const [editStatusRowId, setEditStatusRowId] = React.useState<string | null>(null);

	const [editAssignedRowId, setEditAssignedRowId] = React.useState<string | null>(null);

	const [loading, setLoading] = React.useState(true);

	const [checked, setChecked] = React.useState(true);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const Check = DashboardData
		? DashboardData.accessLevel
			? (DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("property")) ||
				DashboardData.accessLevel.includes("super_admin")
			: false
		: false;

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (router.query.filter) {
			FilteringFunction({
				items: [
					{
						field: "status",
						operator: "contains",
						value: router.query.filter,
					},
				],
			});
		} else if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page, router.query.filter, router.query.filter]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	React.useEffect(
		() => {
			if (Check) {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[Check],
	);

	/*

  & Next, let's define a function to handle the change in the select and update the status

  */

	const handleChange = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-status", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				status: event.target.value,
				propertyId: cellValues.id,
				transactiontype: cellValues.row.transactiontype,
				pre_status: cellValues.row.status,
				email: session ? (session.user ? session.user.email : "") : "",
				github_username: session ? (session.user ? session.username : "") : "",
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const handleChangeAssigned = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				assigned_to: event.target.value,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	/*

  & Next, let's define a function to delete the assigned to.

  */

	const deleteAssigned = async (item: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-delete-assigned-to", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deletedAssignedTo: item,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const updateFineTuning = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-use-data-for-fine-tuning", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				use_data_for_fine_tuning: e.target.checked,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const showInFeatured = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "property-table/property-show-in-featured", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				show_in_featured: e.target.checked,
				propertyId: cellValues.id,
			}),
		});
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						change: "true",
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "property-table/property-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	const handleClose = () => {
		setEditStatusRowId("");
	};

	/*

  & Next, let's define the data for the menu items

  */

	const menuItems = [
		{
			id: 1,
			label: "Sold Out",
			value: "sold_out",
			backgroundColor: "rgba(244, 67, 54, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 2,
			label: "Work in progress",
			value: "work_in_progress",
			backgroundColor: "rgba(33, 150, 243, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 3,
			label: "Pending QC",
			value: "pending_qc",
			backgroundColor: "rgba(237, 108, 2, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 4,
			label: "Published",
			value: "published",
			backgroundColor: "rgba(76, 175, 80, 0.5)",
			color: "#FFFFFF",
		},
		{
			id: 5,
			label: "Publish Requested",
			value: "publish_requested",
			backgroundColor: "rgb(255, 191, 0)",
			color: "#FFFFFF",
		},
		{
			id: 6,
			label: "Edited",
			value: "edited",
			backgroundColor: "#d5d5d7",
		},
	];

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "propertyId",
			headerName: "Property ID",
			width: 150,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "propertyName",
			headerName: "Property Name",
			headerClassName: "super-app-theme--header",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/properties/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "slug",
			headerName: "Slug",
			headerClassName: "super-app-theme--header",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/properties/" + cellValues.row.action}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "transactiontype",
			headerName: "Transaction Type",
			headerClassName: "super-app-theme--header",
			width: 150,
		},
		{
			field: "propertytype",
			headerName: "Property Type",
			headerClassName: "super-app-theme--header",
			width: 150,
		},
		{
			field: "propertycategory",
			headerName: "Property Category",
			width: 150,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "location",
			headerName: "Location",
			headerClassName: "super-app-theme--header",
			width: 150,
		},
		{
			field: "price_min",
			headerName: "Price Min",
			headerClassName: "super-app-theme--header",
			width: 150,
			type: "number" as const,
			renderCell: (cellValues: any) => {
				const price_string = PreparePriceString(cellValues.value);
				return (
					<Typography
						variant="body2"
						sx={{
							padding: "1rem",
						}}
					>
						{price_string}
					</Typography>
				);
			},
		},
		{
			field: "price_max",
			headerName: "Price Max",
			headerClassName: "super-app-theme--header",
			width: 150,
			type: "number" as const,
			renderCell: (cellValues: any) => {
				const price_string = PreparePriceString(cellValues.value);
				return (
					<Typography
						variant="body2"
						sx={{
							padding: "1rem",
						}}
					>
						{price_string}
					</Typography>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
			// editable: true,
			valueOptions: menuItems,
			headerClassName: "super-app-theme--header",
			valueFormatter: (params: any) => {
				if (!params) {
					return "";
				}
				return params.value === "sold_out"
					? "Sold Out"
					: params.value === "work_in_progress"
						? "Work in progress"
						: params.value === "pending_qc"
							? "Pending QC"
							: params.value === "published"
								? "Published"
								: params.value === "publish_requested"
									? "Publish Requested"
									: params.value === "edited"
										? "Edited"
										: "";
			},
			renderCell: (cellValues: any) => {
				const status_option = menuItems.find((option) => option.value === cellValues.value);
				const isEditing = editStatusRowId === cellValues.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditStatusRowId(null)}>
						<Select
							value={status_option ? status_option.label : ""}
							onChange={(e) => {
								const selected_status_option = menuItems.find((option) => option.label === e.target.value);
								if (selected_status_option) {
									handleChange(
										{ target: { value: selected_status_option.value, name: "" } } as SelectChangeEvent,
										cellValues,
									);
									setEditStatusRowId("");
								}
							}}
							variant="standard"
							onClose={handleClose}
							fullWidth
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: status_option ? status_option.backgroundColor : "#FFFFFF",
									color: status_option ? status_option.color : "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
								hover: {
									background: status_option ? status_option.backgroundColor : "#FFFFFF",
									color: status_option ? status_option.color : "#000000",
								},
							}}
						>
							{menuItems.map((option) => (
								<MenuItem
									key={option.value}
									value={option.label}
									sx={{
										background: option.backgroundColor,
										color: option.color,
										padding: "0.5rem 0.5rem 0.5rem 0.5rem",
										borderRadius: "0.5rem",
										fontSize: "0.875rem",
										margin: "0.25rem 0rem 0.25rem 0rem",
										display: "flex",
										justifyContent: "center",
										gap: "0.5rem",
										alignItems: "center",
										"&.Mui-selected": {
											background: option.backgroundColor,
											color: option.color,
										},
										"&.Mui-selected:hover": {
											background: `${option.backgroundColor} !important`,
											color: `${option.color} !important`,
										},
										"&:hover": {
											background: option.backgroundColor,
											color: option.color,
										},
									}}
								>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<ChipData
						id={cellValues.value}
						label={status_option ? status_option.label : ""}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
						sx={{
							width: "100%",
							boxShadow: " 0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
							borderRadius: "0.5rem",
							"&:hover": {
								background: status_option ? status_option.backgroundColor : "#FFFFFF",
								color: status_option ? status_option.color : "#000000",
							},
						}}
						onClick={() => {
							setEditStatusRowId(cellValues.id);
						}}
					/>
				);
			},
		},

		{
			field: "phonenumber",
			headerName: "Phone Number",
			headerClassName: "super-app-theme--header",
			width: 150,
		},
		{
			field: "is_employee_created",
			headerName: "Created By Employee",
			headerClassName: "super-app-theme--header",
			width: 150,
		},
		{
			field: "deleted",
			headerName: "Deleted",
			headerClassName: "super-app-theme--header",
			width: 150,
		},
		{
			field: "username",
			headerName: "Username",
			headerClassName: "super-app-theme--header",
			width: 160,
		},
		{
			field: "availability",
			headerName: "Availability",
			headerClassName: "super-app-theme--header",
			width: 160,
		},
		{
			field: "possession_date",
			headerName: "Possession Date",
			headerClassName: "super-app-theme--header",
			width: 150,
			filterable: false,
			// fiterOperators: ["contains"],
			renderCell: (cellValues: any) => {
				const date = new Date(cellValues.value);
				const isFutureDate = date > new Date();

				return (
					<ActionActions
						sx={{
							padding: "1rem",
						}}
					>
						{!isFutureDate ? (
							<Typography
								variant="body2"
								color="error"
							>
								{date.toDateString()}
							</Typography>
						) : (
							<Typography
								variant="body2"
								color="success"
							>
								{date.toDateString()}
							</Typography>
						)}
					</ActionActions>
				);
			},
		},

		{
			field: "createdOn",
			headerName: "Property created on",
			headerClassName: "super-app-theme--header",
			width: 150,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				}); // Format the date as a string for display
			},
		},
		{
			field: "last_activity_date",
			headerName: "Last Activity Date",
			headerClassName: "super-app-theme--header",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);

				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
						}}
					>
						{is_date_14_days_old ? (
							<Tooltip
								title="Last seen more than 14 days ago"
								placement="top"
							>
								<LastSeen
									sx={{
										color: "rgba(255,0,0,0.8)",
									}}
								>
									<LastSeenSub>
										<CalendarTodayOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",").slice(0, 2).join(",")}
										</Typography>
									</LastSeenSub>
									<LastSeenSub>
										<AccessTimeOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",")[2]}
										</Typography>
									</LastSeenSub>
								</LastSeen>
							</Tooltip>
						) : (
							<LastSeen>
								<LastSeenSub>
									<CalendarTodayOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",").slice(0, 2).join(",")}
									</Typography>
								</LastSeenSub>
								<LastSeenSub>
									<AccessTimeOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",")[2]}
									</Typography>
								</LastSeenSub>
							</LastSeen>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "last_activity",
			headerName: "Last Activity",
			headerClassName: "super-app-theme--header",
			width: 150,
			filterable: false,
			// fiterOperators: ["contains"],
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);
				return (
					<ActionActions>
						{cellValues.value ? (
							<ReactTimeAgo
								date={new Date(cellValues.value)}
								locale="en-US"
								style={{
									color: is_date_14_days_old ? "#F76C35" : "#000000",
									fontWeight: 400,
								}}
							/>
						) : (
							<Typography
								variant="body2"
								color="error"
							>
								no activity
							</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "use_data_for_fine_tuning",
			headerName: "Use Data For Fine Tuning",
			headerClassName: "super-app-theme--header",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							updateFineTuning(e, cellValues);
						}}
					/>
				);
			},
		},
		{
			field: "show_in_featured",
			headerName: "Show In Featured",
			headerClassName: "super-app-theme--header",
			width: 150,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							showInFeatured(e, cellValues);
						}}
					/>
				);
			},
		},

		{
			field: "assigned_to",
			headerName: "Assigned",
			headerClassName: "super-app-theme--header",
			width: 300,
			// editable: true,
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				const isEditing = editAssignedRowId === cellValues.id;
				return isEditing ? (
					<ClickAwayListener onClickAway={() => setEditAssignedRowId(null)}>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							fullWidth={true}
							onChange={(event: any) => handleChangeAssigned(event, cellValues)}
							value={data ? data.assigned_to : cellValues.value}
							onClose={() => setEditAssignedRowId(null)}
							autoFocus
							open={isEditing}
							MenuProps={{
								PaperProps: {
									sx: {
										"& .MuiList-root": {
											padding: "0rem 0.25rem 0rem 0.25rem",
											border: "none",
										},
									},
								},
								disableScrollLock: true,
							}}
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								"& .MuiSelect-select": {
									padding: "0.5rem 0.5rem 0.5rem 0.5rem",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#000000",
									fontSize: "0.875rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								},
								"& .MuiSelect-icon": {
									color: "#B05911",
								},
							}}
						>
							{DashboardData.list_of_moderators.map((item: any, index: number) => (
								<MenuItem
									value={item._id}
									key={index}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
									}}
								>
									{item.first_name + " " + item.last_name}
								</MenuItem>
							))}
						</Select>
					</ClickAwayListener>
				) : (
					<ActionActions
						sx={{
							padding: "0.8rem",
						}}
					>
						{data && data.assigned_to?.length > 0 ? (
							data.assigned_to.map((item: any, index: number) => (
								<Chip
									id={item._id}
									key={index}
									label={item.first_name + " " + item.last_name ? item.first_name + " " + item.last_name : "Assign to"}
									variant="outlined"
									onDelete={() => {
										deleteAssigned(item._id, cellValues);
									}}
									color="primary"
								/>
							))
						) : (
							<Chip
								id="assign"
								label="Assign to"
								variant="outlined"
								onDelete={() => {}}
								deleteIcon={<Add />}
								color="primary"
								onClick={() => setEditAssignedRowId(cellValues.id)}
							/>
						)}
					</ActionActions>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((user: any) => user._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						onChange={(event: any) => handleChangeAssigned(event, cellValues)}
						value={data ? data.assigned_to : cellValues.value}
					>
						{DashboardData.list_of_moderators.map((item: any, index: number) => (
							<MenuItem
								value={item._id}
								key={index}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}
							>
								{item.first_name + " " + item.last_name}
							</MenuItem>
						))}
					</Select>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			headerClassName: "super-app-theme--header",
			width: 155,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions
						sx={{
							padding: "0.5rem 0.5rem 0.5rem 0.5rem",
						}}
					>
						<Link
							href={"tel:91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/+91" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://beegru.com/properties/" + cellValues.row.action}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						{cellValues?.row?.status === "publish_requested" ? (
							<Tooltip
								title="The property is missing required data for publishing. Please send a reminder to the user to complete the information."
								placement="top"
								arrow
							>
								<IconButton
									onClick={() => {
										setReminderModal(true);
										setPropertyId(cellValues.row.id);
										settransactiontype(cellValues.row.transactiontype);
									}}
								>
									<EditNotificationsIcon
										sx={{
											color: "#FC8019",
											padding: "0rem 0rem 0rem 0rem",
										}}
									/>
								</IconButton>
							</Tooltip>
						) : null}
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<StyledGridToolbarContainer>
				<TableTitle>Properties</TableTitle>

				<StyledDiv>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								variant: "contained",
								sx: {
									// color: "#F76C35",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#B05911",
									boxShadow: "none",
								},
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								variant: "contained",
								sx: {
									// color: "#F76C35",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#B05911",
									boxShadow: "none",
								},
							},
							tooltip: {
								arrow: true,
								placement: "bottom",
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								variant: "contained",
								sx: {
									// color: "#F76C35",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#B05911",
									boxShadow: "none",
								},
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								variant: "contained",
								sx: {
									// color: "#F76C35",
									borderRadius: "0.5rem",
									background: "#FFFFFF",
									color: "#B05911",
									boxShadow: "none",
								},
							},
						}}
					/>
					<StyledButton
						variant="contained"
						onClick={() => {
							setInventoryModal(true);
						}}
						startIcon={<Inventory2Outlined fontSize="small" />}
					>
						Inventory
					</StyledButton>
					{DashboardData.accessLevel && DashboardData.accessLevel.includes("super_admin") ? (
						<Tooltip
							title={
								<>
									Caution: Turning this off might put your system into &apos;lag mode.&apos;
									<span
										role="img"
										aria-label="warning"
									>
										⚠️
									</span>{" "}
									Don&apos;t blame the developer! 😅
								</>
							}
							placement="left"
							arrow
						>
							<StyledButton
								variant="contained"
								onClick={() => {
									setChecked(!checked);
									if (!checked) {
										console.warn(
											"Caution: Turning this off might put your system into 'lag mode.' ⚠️ Don't blame the developer! 😅",
										);
									}
									setPaginationModel({
										page: -1,
										pageSize: 10,
									});
									setRowCountState(0);
								}}
							>
								<FormGroup
									aria-label="position"
									row
									sx={{
										padding: "0rem 0rem 0rem 0rem",
									}}
								>
									<FormControlLabel
										value="end"
										control={
											<Switch
												sx={{
													"& .MuiSwitch-thumb": {
														backgroundColor: "#B05911",
													},
													"& .Mui-checked": {
														color: "#B05911",
													},
												}}
												size="small"
												checked={checked}
												onChange={(e) => {
													setChecked(e.target.checked);
													if (e.target.checked === false) {
														setPaginationModel({
															page: -1,
															pageSize: 10,
														});
														setRowCountState(0);
													} else {
														setPaginationModel({
															page: 0,
															pageSize: 100,
														});
													}
												}}
											/>
										}
										label="Pagination"
										labelPlacement="start"
										sx={{
											margin: "0rem",
											"& .MuiFormControlLabel-label": {
												fontSize: "0.8rem",
												fontWeight: 500,
												color: "#B05911",
												margin: "0rem",
											},
										}}
									/>
								</FormGroup>
							</StyledButton>
						</Tooltip>
					) : null}

					<StyledButton
						variant="contained"
						onClick={() => {
							router.push(
								{
									pathname: router.pathname,
									query: {
										tab: "analytics",
										tab_id: "properties",
									},
								},
								undefined,
								{
									shallow: true,
								},
							);
						}}
						startIcon={<Equalizer fontSize="small" />}
					>
						analytics
					</StyledButton>
				</StyledDiv>
			</StyledGridToolbarContainer>
		);
	};

	if (loading) {
		return (
			<MainContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	const hasAccess =
		(DashboardData.accessLevel?.includes("admin") && DashboardData.accessLevel?.includes("property")) ||
		DashboardData.accessLevel?.includes("super_admin");

	const hasAnalyticsOrActivityLog = DashboardData.AnalyticsData || DashboardData.ActivityLogData;

	return (
		<MainContainer>
			<UpdatePropertyReminderModal
				open={reminderModal}
				onClose={() => {
					setReminderModal(false);
				}}
				propertyId={propertyId}
				transactiontype={transactiontype}
			/>
			<LoadingModal open={openModal} />
			<InventoryModal
				open={inventoryModal}
				onClose={() => {
					setInventoryModal(false);
				}}
				bugdata={""}
			/>
			{hasAccess ? (
				hasAnalyticsOrActivityLog ? null : (
					<StyledBox>
						<StyledDataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={
								dashboardData &&
								dashboardData.map((item: any, key: number) => {
									return {
										id: item ? item._id : key,
										propertyId: item._id,
										propertyName: item.title,
										slug: item.slug,
										propertytype: item.property_type
											? item.property_type.length > 0
												? item.property_type
												: "--"
											: "--",
										propertycategory: item.property_category
											? item.property_category.length > 0
												? item.property_category
												: "--"
											: "--",
										location: item.location ? (item.location.title.length > 0 ? item.location.title : "--") : "--",
										phonenumber: item.created_by
											? item.created_by.user_id
												? item.created_by.user_id.country_code
													? item.created_by.user_id.country_code + " " + item.created_by.user_id.phone
													: "no phone"
												: "no phone"
											: "no phone",
										username: item.created_by
											? item.created_by.business_profile_id
												? item.created_by.business_profile_id.title
												: item.created_by.user_id
													? item.created_by.user_id.first_name + " " + item.created_by.user_id.last_name
													: "no name"
											: "no name",
										status: item.status === "active" ? "untouched" : item.status,
										use_data_for_fine_tuning: item ? item.use_data_for_fine_tuning : false,
										show_in_featured: item ? item.show_in_featured : false,
										assigned_to: item.assigned_to ? item.assigned_to : [],
										action: item.slug,
										transactiontype: item.transaction_type,
										autoHeight: true,
										is_employee_created: item.employee_id ? item.employee_id : "--",
										createdOn: item.created_at ? new Date(item.created_at) : new Date(),
										last_activity_date: item.updated_at ? item.updated_at : item.updated_at,
										last_activity: item.updated_at ? item.updated_at : item.updated_at,
										possession_date: item ? item.possession_date : item.possession_date,
										deleted: item.deleted,
										availability: item ? (item.availability ? item.availability[0] : "--") : "--",
										price_min: item ? (item.price ? item.price.min : "--") : "--",
										price_max: item ? (item.price ? item.price.max : "--") : "--",
										rowClassName: "fade-in-row",
									};
								})
							}
							getRowId={(row) => row.id}
							getRowClassName={(params) => "fade-in-row"}
							columns={columns}
							pagination={true}
							initialState={{
								pinnedColumns: {
									right: ["status", "action"],
								},
							}}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
						/>
					</StyledBox>
				)
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default PropertyDataTable;

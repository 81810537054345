/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	DataGridPremium,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

import { Equalizer, NavigateNext } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Breadcrumbs, Button, Chip, debounce, Divider, Link, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
// import LoadingModal from "../../../modal/LoadingModal";
// import InventoryModal from "../../../modal/PropertyInventoryModal";
// import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "work_in_progress"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: id === "pending_qc"
				? {
						backgroundColor: "rgba(237, 108, 2, 0.5)",
					}
				: id === "published"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: id === "publish_requested"
						? {
								backgroundColor: "rgb(255, 191, 0)",
							}
						: id === "edited"
							? {
									backgroundColor: "#d5d5d7",
									color: "#000000",
								}
							: {
									backgroundColor: "rgba(255, 255, 255, 0.002)",
								},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.5rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));
const LastSeen = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const LastSeenSub = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const CampaignDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "campaigns-table/campaigns-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);
	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "utm_source",
			headerName: "Source",
			width: 200,
		},
		{
			field: "utm_source_id",
			headerName: "Source ID",
			width: 200,
		},

		{
			field: "createdOn",
			headerName: "createdOn",
			width: 180,
			type: "date" as const,
			renderCell: (cellValues: any) => {
				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
						}}
					>
						<LastSeen>
							<LastSeenSub>
								<CalendarTodayOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",").slice(0, 2).join(",")}
								</Typography>
							</LastSeenSub>
							<LastSeenSub>
								<AccessTimeOutlinedIcon
									sx={{
										fontSize: "0.875rem",
										color: "#B05911",
									}}
								/>
								<Typography
									sx={{
										fontSize: "0.75rem",
									}}
								>
									{date.split(",")[2]}
								</Typography>
							</LastSeenSub>
						</LastSeen>
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					sx={{
						color: "#F76C35",
						fontSize: "0.75rem",
						borderRadius: "0.5rem",
						margin: "0.5rem 1rem 0.2rem 1rem",
					}}
					onClick={() => {
						router.push(
							{
								pathname: router.pathname,
								query: {
									tab: "analytics",
									tab_id: "campaigns",
								},
							},
							undefined,
							{
								shallow: true,
							},
						);
					}}
					startIcon={<Equalizer fontSize="small" />}
				>
					view graph
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Campaigns
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Campaigns</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<HeadContainer>
				<HeadText>Campaigns</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("simple_campaigns")) ||
				accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							return {
								id: key,
								utm_source: item ? (item.metadata ? item.metadata.utm_source : "") : "",
								utm_source_id: item ? (item.metadata ? item.metadata.utm_source_id : "") : "",

								createdOn: item.created_at ? new Date(item.created_at) : new Date(),
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default CampaignDataTable;

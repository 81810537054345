import Dialog from "@mui/material/Dialog";
import React from "react";

import { Button, DialogActions, DialogContent, DialogTitle, Divider, Typography, styled } from "@mui/material";

import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useRouter } from "next/router";

const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1.5rem 0rem 1rem 0rem",
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	// wordSpacing: "0.5rem",
}));

const ORShortenUrlModal = ({ open, onClose, keyword }: { open: any; onClose: any; keyword: string }) => {
	const router = React.useRef(useRouter()).current;

	return (
		<Dialog
			open={Boolean(open)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "1rem",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				},
			}}
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{}}
			>
				<MainHeading>QR Shortened URL</MainHeading>
				<Divider />
				<Close
					aria-label="close"
					onClick={() => {
						onClose();
					}}
					sx={{
						position: "absolute",
						right: "0.5rem",
						top: "0.5rem",
						color: "#F76C35",
					}}
				>
					<CloseIcon />
				</Close>
			</DialogTitle>
			<DialogContent>
				<SubContainer>
					<img
						src={`${process.env.URL_SHORTENER_URL || ""}/${keyword}.qr`}
						alt="QR Code"
						style={{
							width: "350px",
							height: "350px",
						}}
					/>
				</SubContainer>
			</DialogContent>
			<DialogActions>
				<a
					href={`${process.env.URL_SHORTENER_URL || ""}/${keyword}.qr`}
					download={`${keyword}.png`}
					type="image/png"
					target="_blank"
					rel="noopener noreferrer"
				>
					Download QR Code
				</a>
			</DialogActions>
		</Dialog>
	);
};

export default ORShortenUrlModal;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Link,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import LoadingModal from "../../../modal/LoadingModal";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "1rem",
	margin: "0.35rem 0rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const CardAction = styled(CardActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
}));

const AntSwitch = styled(Switch)(({ theme }) => ({}));

const PWADataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [loadopenModal, setLoadOpenModal] = React.useState(false);

	const [deadline, setDeadline] = React.useState<Dayjs | null>(dayjs("2022-04-17"));

	const [releaseDate, setReleaseDate] = React.useState<Dayjs | null>(dayjs("2022-04-17"));

	const [invalidateCache, setInvalidateCache] = React.useState(false);

	const [unregisterSw, setUnregisterSw] = React.useState(false);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Link
			underline="hover"
			key="2"
			color="inherit"
			href="#"
			onClick={handleClick}
		>
			Moderation
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			PWA Options
		</Typography>,
	];

	React.useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(process.env.PRODUCTION_API_URL + "Marketplace-website-config/fetch-PWA", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					email: session ? (session.user ? session.user.email : "") : "",
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});
			const res = await response.json();
			setInvalidateCache(
				res.payload.DashboardData[0]
					? res.payload.DashboardData[0].flags
						? res.payload.DashboardData[0].flags.invalidate_cache
						: false
					: false,
			);
			setUnregisterSw(
				res.payload.DashboardData[0]
					? res.payload.DashboardData[0].flags
						? res.payload.DashboardData[0].flags.unregister_sw
						: false
					: false,
			);
			setDeadline(
				res.payload.DashboardData[0]
					? res.payload.DashboardData[0].deadline
						? dayjs(res.payload.DashboardData[0].deadline)
						: dayjs("2022-04-17")
					: dayjs("2022-04-17"),
			);
			setReleaseDate(
				res.payload.DashboardData[0]
					? res.payload.DashboardData[0].release_date
						? dayjs(res.payload.DashboardData[0].release_date)
						: dayjs("2022-04-17")
					: dayjs("2022-04-17"),
			);
			setAccessLevel(res ? (res.payload.accessLevel ? res.payload.accessLevel : []) : []);
			console.log(res);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveInvalidateCache = async () => {
		setLoadOpenModal(true);
		const response = await fetch(process.env.PRODUCTION_API_URL + "/pwa-options/update-invalidate-cache", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({ invalidate_cache: invalidateCache }),
		});
		const res = await response.json();
		console.log(res);
		setLoadOpenModal(false);
	};

	const saveUnregisterSw = async () => {
		setLoadOpenModal(true);
		const response = await fetch(process.env.PRODUCTION_API_URL + "/pwa-options/update-unregister-sw", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({ unregister_sw: unregisterSw }),
		});
		const res = await response.json();
		console.log(res);
		setLoadOpenModal(false);
	};

	const saveDeadline = async () => {
		setLoadOpenModal(true);
		const response = await fetch(process.env.PRODUCTION_API_URL + "/pwa-options/update-deadline", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				deadline: deadline,
			}),
		});
		const res = await response.json();
		console.log(res);
		setLoadOpenModal(false);
	};

	const saveReleaseDate = async () => {
		setLoadOpenModal(true);
		const response = await fetch(process.env.PRODUCTION_API_URL + "/pwa-options/update-release-date", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				release_date: releaseDate,
			}),
		});
		const res = await response.json();
		console.log(res);
		setLoadOpenModal(false);
	};

	return (
		<MainContainer>
			<LoadingModal open={loadopenModal} />

			<HeadContainer>
				<HeadText>Moderation</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("pwa_options")) ||
				accessLevel.includes("super_admin")) ? (
				<Box
					sx={{
						// height: "80vh",
						padding: " 1rem",
						display: "flex",
						flexWrap: "wrap",

						gap: "1rem",
						// width: "100%",
						scrollbarColor: "#F76C35 #FFFFFF",
						"&::-webkit-scrollbar": {
							width: "0.4rem",
						},
					}}
				>
					<Card
						sx={{
							minWidth: 275,
							"& .MuiCardContent-root": {
								padding: "1rem 1rem 0.35rem 1rem",
								margin: "0rem 0rem 0rem 0rem",
							},
							boxShadow: "none",
						}}
					>
						<CardContent>
							<Typography
								variant="h6"
								sx={{ color: "text.secondary" }}
							>
								Invalidate Cache
							</Typography>
							<Divider />
							<Stack
								direction="row"
								spacing={2}
								alignItems="center"
								sx={{
									width: "100%",
									justifyContent: "space-between",
								}}
							>
								<ActionActions>
									<Typography
										sx={{
											color: invalidateCache ? "#000000" : "#FF0000",
											fontWeight: invalidateCache ? "normal" : "bold",
										}}
									>
										OFF
									</Typography>
									<AntSwitch
										checked={invalidateCache}
										onChange={() => {
											setInvalidateCache(!invalidateCache);
										}}
										inputProps={{ "aria-label": "ant design" }}
									/>
									<Typography
										sx={{
											color: invalidateCache ? "#00FF00" : "#000000",
											fontWeight: invalidateCache ? "normal" : "bold",
										}}
									>
										ON
									</Typography>
								</ActionActions>
							</Stack>
						</CardContent>
						<CardAction>
							<ViewOrUploadButton
								variant="contained"
								onClick={saveInvalidateCache}
							>
								Save
							</ViewOrUploadButton>
						</CardAction>
					</Card>
					<Card
						sx={{
							minWidth: 275,
							"& .MuiCardContent-root": {
								padding: "1rem 1rem 0.35rem 1rem",
								margin: "0rem 0rem 0rem 0rem",
							},
							boxShadow: "none",
						}}
					>
						<CardContent>
							<Typography
								variant="h6"
								sx={{ color: "text.secondary" }}
							>
								Unregister SW
							</Typography>
							<Divider />

							<Stack
								direction="row"
								spacing={2}
								alignItems="center"
								sx={{
									width: "100%",
									justifyContent: "space-between",
								}}
							>
								<ActionActions>
									<Typography
										sx={{
											color: unregisterSw ? "#000000" : "#FF0000",
											fontWeight: unregisterSw ? "normal" : "bold",
										}}
									>
										OFF
									</Typography>
									<AntSwitch
										checked={unregisterSw}
										onChange={() => {
											setUnregisterSw(!unregisterSw);
										}}
										inputProps={{ "aria-label": "ant design" }}
									/>
									<Typography
										sx={{
											color: unregisterSw ? "#00FF00" : "#000000",
											fontWeight: unregisterSw ? "normal" : "bold",
										}}
									>
										ON
									</Typography>
								</ActionActions>
							</Stack>
						</CardContent>
						<CardAction>
							<ViewOrUploadButton
								variant="contained"
								onClick={saveUnregisterSw}
							>
								Save
							</ViewOrUploadButton>
						</CardAction>
					</Card>
					<Card
						sx={{
							minWidth: 275,
							"& .MuiCardContent-root": {
								padding: "1rem 1rem 0.35rem 1rem",
								margin: "0rem 0rem 0rem 0rem",
							},
							boxShadow: "none",
						}}
					>
						<CardContent>
							<Typography
								variant="h6"
								sx={{ color: "text.secondary" }}
							>
								Deadline
							</Typography>
							<Divider />
							<ActionActions>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										label="deadline"
										value={deadline}
										onChange={(newValue) => setDeadline(newValue)}
										disablePast
										sx={{
											" & .MuiOutlinedInput-input": {
												padding: "0rem 0.5rem 0rem 0.5rem",
											},
											marginTop: "0.35rem",
										}}
									/>
								</LocalizationProvider>
							</ActionActions>
						</CardContent>
						<CardAction>
							<ViewOrUploadButton
								variant="contained"
								onClick={saveDeadline}
							>
								Save
							</ViewOrUploadButton>
						</CardAction>
					</Card>
					<Card
						sx={{
							minWidth: 275,
							"& .MuiCardContent-root": {
								padding: "1rem 1rem 0.35rem 1rem",
								margin: "0rem 0rem 0rem 0rem",
							},
							boxShadow: "none",
						}}
					>
						<CardContent>
							<Typography
								variant="h6"
								sx={{ color: "text.secondary" }}
							>
								Release Date
							</Typography>
							<Divider />
							<ActionActions>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										label="release date"
										value={releaseDate}
										onChange={(newValue) => setReleaseDate(newValue)}
										sx={{
											" & .MuiOutlinedInput-input": {
												padding: "0rem 0.5rem 0rem 0.5rem",
											},
											marginTop: "0.35rem",
										}}
									/>
								</LocalizationProvider>
							</ActionActions>
						</CardContent>
						<CardAction>
							<ViewOrUploadButton
								variant="contained"
								onClick={saveReleaseDate}
							>
								Save
							</ViewOrUploadButton>
						</CardAction>
					</Card>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default PWADataTable;

import Dialog from "@mui/material/Dialog";
import React from "react";

import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	TextField,
	Typography,
	styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ObjectId } from "bson";

const Image = styled("img")(({ theme }) => ({
	objectFit: "contain",
	width: "100%",
	height: "12.5rem",
}));

const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1.5rem 0rem 1rem 0rem",
}));

const MessageBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	// wordSpacing: "0.5rem",
}));

const MessageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-end",
	// boxShadow: " 0px 1px 3px rgba(0, 0, 0, 0.12)",
	width: "100%",
	padding: "1rem",
	[theme.breakpoints.only("sm")]: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	// wordSpacing: "0.5rem",
}));

interface FileAndMore {
	file: File;
	mime_type: string;
}

interface UploadProgress {
	fileId: ObjectId | null;
	percentage: number;
	stage: string;
}

const ProgressContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const ViewBlogModal = ({ open, onClose, data }: { open: any; onClose: any; data: any }) => {
	const [faqs, setfaqs] = React.useState<Array<{ question: string; answer: [string] }>>([]);

	const [content, setContent] = React.useState<Array<string>>([]);

	React.useEffect(() => {
		if (data) {
			setContent(data.content);
			setfaqs(data.faqs);
		}
	}, [data]);

	return (
		<Dialog
			open={Boolean(open)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "1rem",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				},
			}}
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{}}
			>
				<MainHeading>Blogs</MainHeading>
				<Divider />
				<Close
					aria-label="close"
					onClick={() => {
						onClose();
						setContent([]);
						setfaqs([]);
					}}
					sx={{
						position: "absolute",
						right: "0.5rem",
						top: "0.5rem",
						color: "#F76C35",
					}}
				>
					<CloseIcon />
				</Close>
			</DialogTitle>
			<DialogContent>
				<MessageBoxContainer
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
						// width: "50%",
					}}
				>
					<Typography>Content </Typography>
					<TextField
						variant="outlined"
						fullWidth
						multiline
						rows={10} // Adjust the number of rows as needed
						value={content.join("\n")}
						placeholder="Enter your content here"
						disabled
						InputProps={{
							sx: {
								"& textarea": {
									resize: "both", // Make the textarea resizable
									color: "black",
								},
								"&.Mui-disabled": {
									color: "black", // Ensure text color remains black when disabled
								},
							},
						}}

					/>{" "}
				</MessageBoxContainer>

				<SubContainer
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "2rem",
					}}
				>
					<MessageBoxContainer
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "1rem",
							// width: "50%",
						}}
					>
						<Typography variant="h6">FAQs</Typography>
					</MessageBoxContainer>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",

							gap: "1rem",
						}}
					>
						{faqs.map((faq, index) => (
							<Accordion
								key={index}
								sx={{
									width: "100%",
									boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
									borderRadius: "0.5rem",
									overflow: "hidden",
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}-content`}
									id={`panel${index}-header`}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										backgroundColor: "#f5f5f5",
										padding: "0.5rem 1rem",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "1rem",
											justifyContent: "flex-start",
											alignItems: "center",
										}}
									>
										<Typography
											sx={{
												fontWeight: 500,
												fontSize: "1rem",
											}}
										>
											{faq.question}
										</Typography>
									</div>
								</AccordionSummary>
								<AccordionDetails
									sx={{
										backgroundColor: "#fff",
										padding: "1rem",
									}}
								>
									{faq.answer.map((answer, index) => (
										<Typography
											key={index}
											sx={{
												marginBottom: "0.5rem",
											}}
										>
											{answer}
										</Typography>
									))}
								</AccordionDetails>
							</Accordion>
						))}
					</Box>
				</SubContainer>
			</DialogContent>
			<DialogActions>
				<ViewOrUploadButton
					variant="outlined"
					sx={{
						color: "#FC8019",
					}}
					autoFocus
					onClick={() => {
						onClose();
						setContent([]);
						setfaqs([]);
					}}
				>
					Cancel
				</ViewOrUploadButton>
			</DialogActions>
		</Dialog>
	);
};

export default ViewBlogModal;

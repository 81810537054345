/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Chip, debounce, Divider, Link, Typography } from "@mui/material";
import { useRouter } from "next/router";
import ErrorMessageModal from "../../../modal/ErrorMessageModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "warn"
		? {
				backgroundColor: "#ffa726",
			}
		: id === "info"
			? {
					backgroundColor: "#29b6f6",
				}
			: {
					backgroundColor: "#f44336",
				},
);

const ChipDataForStatus = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "success"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "failed"
			? {
					backgroundColor: "rgba(244, 67, 54, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0.5rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const LogsDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the modal

  */

	const [openMoadal, setOpenMoadal] = React.useState(false);

	const [errorMessage, setErrorMessage] = React.useState<any>([]);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "logs-table/logs-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "_id",
			headerName: "ID",
			width: 150,
		},
		{
			field: "log_function",
			headerName: "Log Function",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<Typography
						variant="body1"
						color="text.primary"
						sx={{
							color: "#4CAF50",
							padding: "0.7rem",
						}}
					>
						+{cellValues.value}
					</Typography>
				);
			},
		},
		{
			field: "log_level",
			headerName: "Log Level",
			width: 200,
			renderCell: (cellValues: any) => {
				return (
					<ChipData
						id={cellValues.value}
						label={cellValues.value}
						variant="outlined"
						sx={{
							color: "#FFFFFF",
						}}
					/>
				);
			},
		},
		{
			field: "log_message",
			headerName: "Log Message",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setOpenMoadal(true);
								setErrorMessage(cellValues.value);
								// setSearchQueryData(cellValues.value);
							}}
							sx={{
								color: "#FFFFFF",
								padding: "0.2rem",
								boxShadow: "none",
							}}
						>
							View
						</Button>
					</ActionActions>
				);
			},
		},
		{
			field: "log_route",
			headerName: "Log Route",
			width: 220,
			renderCell: (cellValues: any) => {
				return (
					<Typography
						variant="body1"
						color="text.secondary"
						sx={{
							padding: "0.7rem",
						}}
					>
						+{cellValues.value}
					</Typography>
				);
			},
		},
		{
			field: "log_type",
			headerName: "Log Type",
			width: 160,
			renderCell: (cellValues: any) => {
				return (
					<ChipDataForStatus
						id={cellValues.value}
						label={cellValues.value}
						variant="outlined"
					/>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Logs
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Logs </HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<ErrorMessageModal
				open={openMoadal}
				onClose={() => setOpenMoadal(false)}
				errorMessage={errorMessage}
			/>
			<HeadContainer>
				<HeadText>Logs</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("logs")) || accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							return {
								id: item ? item._id : key,
								_id: item ? item._id : "",
								log_function: item ? (item.log_details ? item.log_details.log_function : "") : "",
								log_level: item ? (item.log_details ? item.log_details.log_level : "") : "",
								log_message: item ? (item.log_details ? item.log_details.log_message : "") : "",
								log_route: item ? (item.log_details ? item.log_details.log_route : "") : "",
								log_type: item ? (item.log_details ? item.log_details.log_type : "") : "",
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default LogsDataTable;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { NavigateNext } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Breadcrumbs, debounce, Divider, IconButton, Link, Switch, Typography, useTheme } from "@mui/material";
import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import LanguageModal from "../../../modal/LanguageModal";
import LoadingModal from "../../../modal/LoadingModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
// import LanguageModal from "../modal/LanguageModal";
// import LoadingModal from "../modal/LoadingModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	height: "100%",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ModalContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
}));

const TranslationsTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [languageModal, setLanguageModal] = React.useState(false);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [TranslationsData, setTranslationsData] = React.useState([]);

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);
	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	const autoExpired = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "translations-table/auto-expire", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				auto_expire: e.target.checked,
				_id: cellValues.row._id,
				time_to_live: cellValues.row.time_to_live,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	const invalidate = async (e: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "translations-table/invalidate", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				invalidate: e.target.checked,
				_id: cellValues.row._id,
				time_to_live: cellValues.row.time_to_live,
				auto_expire: cellValues.row.auto_expire,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};

	const ttl = async (newvalue: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "translations-table/time-to-live", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				_id: cellValues.row._id,
				time_to_live: newvalue,
				auto_expire: cellValues.row.auto_expire,
			}),
		});

		FilteringFunction(queryOptions);

		setOpenMoadal(false);
	};
	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "translations-table/translation-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "entity_id",
			headerName: "Entity ID",
			width: 150,
		},
		{
			field: "entity_type",
			headerName: "Entity Type",
			width: 100,
		},
		{
			field: "feature_name",
			headerName: "Feature Name",
			width: 150,
		},

		{
			field: "source_language",
			headerName: "Source Language",
			width: 150,
		},
		{
			field: "target_language",
			headerName: "Target Language",
			width: 150,
		},

		{
			field: "source_text",
			headerName: "Source Text",
			width: 250,
			renderCell: (cellValues: any) => {
				return (
					<ModalContainer
						onClick={() => {
							setTranslationsData(cellValues.row);
							setLanguageModal(true);
						}}
					>
						<Typography
							variant="body2"
							color="textPrimary"
						>
							{cellValues.value?.length > 20 ? cellValues.value.substring(0, 20) + "..." : cellValues.value}
						</Typography>
						<IconButton
							onClick={() => {
								setTranslationsData(cellValues.row);
								setLanguageModal(true);
							}}
						>
							<VisibilityIcon />
						</IconButton>
					</ModalContainer>
				);
			},
		},
		{
			field: "target_text",
			headerName: "Target Text",
			width: 250,
			renderCell: (cellValues: any) => {
				return (
					<ModalContainer
						onClick={() => {
							setTranslationsData(cellValues.row);
							setLanguageModal(true);
						}}
					>
						<Typography
							variant="body2"
							color="textPrimary"
						>
							{cellValues.value?.length > 20 ? cellValues.value.substring(0, 20) + "..." : cellValues.value}
						</Typography>
						<IconButton
							onClick={() => {
								setTranslationsData(cellValues.row);
								setLanguageModal(true);
							}}
						>
							<VisibilityIcon />
						</IconButton>
					</ModalContainer>
				);
			},
		},
		{
			field: "revision",
			headerName: "Revision",
			width: 100,
		},
		{
			field: "invalidated",
			headerName: "Invalidated",
			width: 100,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							invalidate(e, cellValues);
						}}
					/>
				);
			},
		},
		{
			field: "auto_expire",
			headerName: "Auto Expire",
			width: 100,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						disabled={cellValues.row.invalidated}
						checked={cellValues.value}
						onChange={(e) => {
							autoExpired(e, cellValues);
						}}
					/>
				);
			},
		},

		{
			field: "time_to_live",
			headerName: "Time To Live",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<ActionActions key={cellValues.row.id}>
							<DatePicker
								disabled={!cellValues.row.auto_expire}
								label="Basic date picker"
								value={dayjs(cellValues.value)}
								onChange={(newValue) => {
									ttl(newValue, cellValues);
								}}
							/>
						</ActionActions>
					</LocalizationProvider>
				);
			},
		},
		{
			field: "created_at",
			headerName: "Created At",
			width: 150,
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Translations Table
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Translations Table </HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<LoadingModal open={openModal} />
			<LanguageModal
				open={languageModal}
				onClose={() => {
					setLanguageModal(false);
				}}
				language={TranslationsData}
			/>
			<HeadContainer>
				<HeadText>Translations Table </HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("translations")) ||
				accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							const created_at = new Date(item.created_at).toDateString();
							return {
								id: item ? item._id : key,
								_id: item ? item._id : "",
								entity_id: item ? (item.translated_for ? item.translated_for.entity_id : "") : "",
								entity_type: item ? (item.translated_for ? item.translated_for.entity_type : "") : "",
								feature_name: item ? (item.translated_for ? item.translated_for.feature_name : "") : "",
								source_language: item ? item.source_language : "",
								target_language: item ? item.target_language : "",
								source_text: item ? item.source_text : "",
								target_text: item ? item.target_text : "",
								revision: item ? item.revision : "",
								invalidated: item ? item.invalidated : false,
								time_to_live: item ? item.time_to_live : "",
								created_at: item ? created_at : "",
								auto_expire: item ? item.auto_expire : false,
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							// FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default TranslationsTable;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	DataGridPremium,
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Breadcrumbs, Button, Chip, debounce, Divider, Link, Tooltip, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import ClickerInfoModal from "../../../modal/ClickerModal";
import QueryParametersModal from "../../../modal/QueryParametersModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";
// import LoadingModal from "../../../modal/LoadingModal";
// import InventoryModal from "../../../modal/PropertyInventoryModal";
// import UpdatePropertyReminderModal from "../../../modal/UpdatePropertyReminderModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "work_in_progress"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: id === "pending_qc"
				? {
						backgroundColor: "rgba(237, 108, 2, 0.5)",
					}
				: id === "published"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: id === "publish_requested"
						? {
								backgroundColor: "rgb(255, 191, 0)",
							}
						: id === "edited"
							? {
									backgroundColor: "#d5d5d7",
									color: "#000000",
								}
							: {
									backgroundColor: "rgba(255, 255, 255, 0.002)",
								},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.5rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));
const LastSeen = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const LastSeenSub = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

// const DynamicComponent = ({ value }: { value: string }) => {
// 	return (
// 		<div
// 			style={{
// 				width: "auto",
// 				height: "auto",
// 			}}
// 			dangerouslySetInnerHTML={{ __html: value }}
// 		/>
// 	);
// };

const ClicksDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [openModal, setOpenModal] = React.useState(false);

	const [userInfo, setUserInfo] = React.useState<any>({});

	const [queryParams, setQueryParams] = React.useState<any>({});

	const [openQueryModal, setOpenQueryModal] = React.useState(false);

	const [loading, setLoading] = React.useState(true);

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "clicks-table/clicks-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);
	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "element",
			headerName: "Element",
			width: 200,
			// renderCell: (cellValues: any) => {
			// 	return <DynamicComponent value={cellValues.value} />;
			// },
		},
		{
			field: "element_id",
			headerName: "Element ID",
			width: 200,
		},
		{
			field: "element_type",
			headreName: "Element type",
			width: 100,
		},
		{
			field: "path",
			headerName: "Path",
			width: 100,
		},
		{
			field: "query_string_parameters",
			headerName: "Query string parameters",
			width: 200,
			renderCell: (cellValues: any) => {
				return cellValues.value && cellValues.value.length > 0 ? (
					<Button
						variant="outlined"
						onClick={() => {
							setOpenQueryModal(true);
							setQueryParams(cellValues.value);
						}}
						style={{
							borderRadius: "1rem",
							padding: "0.5rem 1rem",
						}}
					>
						View
					</Button>
				) : null;
			},
		},
		{
			field: "clicker",
			headerName: "Clicker",
			width: 100,
			renderCell: (cellValues: any) => {
				return (
					<Button
						variant="contained"
						onClick={() => {
							setOpenModal(true);
							setUserInfo(cellValues.value);
						}}
						style={{
							backgroundColor: "rgb(235, 170, 29)",
							color: "#FFFFFF",
							borderRadius: "1rem",
							padding: "0.5rem 1rem",
						}}
					>
						View
					</Button>
				);
			},
		},
		{
			field: "client_x",
			headerName: "Client X",
			width: 100,
		},
		{
			field: "client_y",
			headerName: "Client Y",
			width: 100,
		},
		{
			field: "offset_x",
			headerName: "Offset X",
			width: 100,
		},
		{
			field: "offset_y",
			headerName: "Offset Y",
			width: 100,
		},
		{
			field: "page_x",
			headerName: "Page X",
			width: 100,
		},
		{
			field: "page_y",
			headerName: "Page Y",
			width: 100,
		},
		{
			field: "screen_x",
			headerName: "Screen X",
			width: 100,
		},
		{
			field: "screen_y",
			headerName: "Screen Y",
			width: 100,
		},

		{
			field: "createdOn",
			headerName: "createdOn",
			width: 180,
			filterable: false,
			renderCell: (cellValues: any) => {
				const is_date_14_days_old = new Date(Date.now() - 12096e5) > new Date(cellValues.value);

				const date = new Date(cellValues.value).toLocaleDateString("en-US", {
					// weekday: "short",
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				});
				return (
					<ActionActions
						sx={{
							padding: "0.5rem",
						}}
					>
						{is_date_14_days_old ? (
							<Tooltip
								title="Last seen more than 14 days ago"
								placement="top"
							>
								<LastSeen
									sx={{
										color: "rgba(255,0,0,0.8)",
									}}
								>
									<LastSeenSub>
										<CalendarTodayOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",").slice(0, 2).join(",")}
										</Typography>
									</LastSeenSub>
									<LastSeenSub>
										<AccessTimeOutlinedIcon
											sx={{
												fontSize: "0.875rem",
												color: "rgba(255,0,0,0.8)",
											}}
										/>
										<Typography
											sx={{
												fontSize: "0.75rem",
											}}
										>
											{date.split(",")[2]}
										</Typography>
									</LastSeenSub>
								</LastSeen>
							</Tooltip>
						) : (
							<LastSeen>
								<LastSeenSub>
									<CalendarTodayOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",").slice(0, 2).join(",")}
									</Typography>
								</LastSeenSub>
								<LastSeenSub>
									<AccessTimeOutlinedIcon
										sx={{
											fontSize: "0.875rem",
											color: "#B05911",
										}}
									/>
									<Typography
										sx={{
											fontSize: "0.75rem",
										}}
									>
										{date.split(",")[2]}
									</Typography>
								</LastSeenSub>
							</LastSeen>
						)}
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Clicks
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Clicks</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<ClickerInfoModal
				open={openModal}
				onClose={() => setOpenModal(false)}
				userInfo={userInfo}
			/>
			<QueryParametersModal
				open={openQueryModal}
				onClose={() => setOpenQueryModal(false)}
				queryParams={queryParams}
			/>
			<HeadContainer>
				<HeadText>Clicks</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("clicks")) || accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							return {
								id: item ? item._id : key,
								element: item ? (item.metadata ? (item.metadata.target ? item.metadata.target.element : "") : "") : "",
								element_id: item
									? item.metadata
										? item.metadata.target
											? item.metadata.target.element_id
											: ""
										: ""
									: "",
								element_type: item
									? item.metadata
										? item.metadata.target
											? item.metadata.target.element_type
											: ""
										: ""
									: "",
								path: item ? (item.metadata ? item.metadata.path : "") : "",
								query_string_parameters: item
									? item.metadata
										? item.metadata.query_string_parameters
											? item.metadata.query_string_parameters
											: []
										: []
									: [],
								mouse_position: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position
											: {}
										: {}
									: {},
								clicker: item ? (item.metadata ? item.metadata.clicker : "") : "",
								client_x: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.client_x
											: ""
										: ""
									: "",
								client_y: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.client_y
											: ""
										: ""
									: "",
								offset_x: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.offset_x
											: ""
										: ""
									: "",
								offset_y: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.offset_y
											: ""
										: ""
									: "",
								page_x: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.page_x
											: ""
										: ""
									: "",
								page_y: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.page_y
											: ""
										: ""
									: "",
								screen_x: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.screen_x
											: ""
										: ""
									: "",
								screen_y: item
									? item.metadata
										? item.metadata.mouse_position
											? item.metadata.mouse_position.screen_y
											: ""
										: ""
									: "",

								createdOn: item.created_at ? new Date(item.created_at) : new Date(),
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ClicksDataTable;

9; /*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import { Box, Button, Chip, CircularProgress, Stack, styled, Tab, Tabs, Typography } from "@mui/material";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import LoadingComponent from "../../sub-components/LoadingComponent";
import AnalyticsBarGraph from "../analticsBarGraph";
import AnalyticsGraph from "../analyticsGraph";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
	id?: string;
};

/*

& Next, let's define the styled components we need on this page

*/

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	// flexDirection: "row",
	// justifyContent: "flex-start",
	// alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "1px solid #B05911" : "none",
	textTransform: "capitalize",
	minWidth: "23rem",
	// width: "100%",
	minHeight: "5rem",
	borderRadius: "16px",
	margin: "0rem 1rem 0.5rem 0rem",
	background: "#FFFFFF",
}));

const TitleContentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	flexWrap: "wrap",
	height: "auto",
	// height: "100%",
	width: "100%",
	gap: "0.5rem",
	color: " #B05911",
	minWidth: "12rem",
	minHeight: "3rem",
}));

const TitleMain = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	// border: tabColor === "primary" ? "2px solid #F76C35" : "2px solid rgb(187, 185, 184)",
	borderRadius: "8px",
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	textTransform: "capitalize",
	color: " #B05911",
	padding: "0rem 0.5rem 0rem 0.5rem",
}));

const MainTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
	fontSize: "1.2rem",
	fontWeight: 600,
}));
const SubTitle = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	padding: "0.5rem",
}));
const TitleAndIcon = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	fontSize: "1rem",
	width: "100%",
	color: " #B05911",
	textTransform: "capitalize",
	padding: "0.5rem 0.5rem 0rem 0.5rem",
}));
const GraphContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
}));
const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	// gap: "1rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "week"
		? {
				backgroundColor: "#F76C35",
				color: "#ffffff",
			}
		: id === "month"
			? {
					backgroundColor: "#F76C35",
					color: "#ffffff",
				}
			: id === "year"
				? {
						backgroundColor: "#F76C35",
						color: "#ffffff",
					}
				: id === "last 24 hours"
					? {
							backgroundColor: "#F76C35",
							color: "#ffffff",
						}
					: {
							backgroundColor: "#ffffff",
							color: "#000000",
						},
);

const ContainerForSelect = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	color: " #B05911",
	padding: "0rem 0rem 0rem 2rem",
}));

const MiddleContainerTwo = styled("div")(({ theme }) => ({
	width: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	padding: "2rem",
}));
const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
	height: "100%",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	height: "100%",
}));
/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={5}>{children}</Box>}
		</div>
	);
}

const UserProfilesAnalytics = ({
	DashboardData,
	dailyActiveUsersGraphData,
	monthlyActiveUsersGraphData,
}: {
	DashboardData: any;
	dailyActiveUsersGraphData: any;
	monthlyActiveUsersGraphData: any;
}) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const [dailyActiveUsersData, setDailyActiveUsersData] = React.useState();
	const [monthlyActiveUsersData, setMonthlyActiveUsersData] = React.useState();
	const [heatmap, setHeatmap] = React.useState(false);
	const [last_24_hours, setLast_24_hours] = React.useState<any>();
	const [last_7_days, setLast_7_days] = React.useState<any>();
	const [last_30_days, setLast_30_days] = React.useState<any>();
	const [last_12_months, setLast_12_months] = React.useState<any>();
	const [searchButtonClicked, setSearchButtonClicked] = React.useState<boolean>(false);
	const [title, setTitle] = React.useState<string>("");
	const [slug, setSlug] = React.useState<string>("");
	const [showWeeks, setShowWeeks] = React.useState<boolean>(false);
	const [showMonths, setShowMonths] = React.useState<boolean>(false);
	const [showYears, setShowYears] = React.useState<boolean>(false);
	const [showLast24, setShowLast24] = React.useState<boolean>(false);
	const [topUsersViews, setTopUsersViews] = React.useState<any>();
	const [topUsersTimeSpent, setTopUsersTimeSpent] = React.useState<any>();
	const [autocompleteValue, setAutocompleteValue] = React.useState<string | null>(null);
	const [options, setOptions] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
	const [endDate, setEndDate] = React.useState<Dayjs | null>(null);

	React.useEffect(() => {
		setDailyActiveUsersData(dailyActiveUsersGraphData);
		setMonthlyActiveUsersData(monthlyActiveUsersGraphData);
	}, [dailyActiveUsersGraphData, monthlyActiveUsersGraphData]);

	const fetchOptions = {
		method: "POST",
		cache: "no-store" as RequestCache,
		headers: {
			"Content-Type": "text/plain",
			"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
		},
		body: JSON.stringify({}),
	};
	React.useEffect(() => {
		switch (value) {
			case 0:
				break;
			case 1:
				break;
			case 2:
				const fetchUsersData = async () => {
					try {
						const [usersViewsResponse, usersTimeSpentResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-users-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-users-time-spent", fetchOptions),
						]);

						const [usersViewsRes, usersTimeSpentRes] = await Promise.all([
							usersViewsResponse.json(),
							usersTimeSpentResponse.json(),
						]);

						setTopUsersViews(usersViewsRes.payload);
						setTopUsersTimeSpent(usersTimeSpentRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 2 && !topUsersViews && !topUsersTimeSpent) {
					fetchUsersData();
				}
				break;
			case 3:
				break;

			case 4:
				break;

			default:
				break;
		}
		setSearchButtonClicked(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const handleClickWeek = () => {
		setShowWeeks(true);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickMonth = () => {
		setShowWeeks(false);
		setShowMonths(true);
		setShowYears(false);
		setShowLast24(false);
	};
	const handleClickYear = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(true);
		setShowLast24(false);
	};
	const handleClickLast24 = () => {
		setShowWeeks(false);
		setShowMonths(false);
		setShowYears(false);
		setShowLast24(true);
	};

	const handleAutocompleteChange = async (value: any) => {
		setAutocompleteValue(value);
	};

	const handleAutocompleteInputChange = async (search_term: string) => {
		/*

		& Next, let's describe a debounced, asynchronous function that will be called when the company name changes.

		*/

		const debouncedExecutor: Function = async (): Promise<void> => {
			const response = await (
				await fetch(
					process.env.PRODUCTION_API_URL + "analytics-api/fetch-users-autocomplete?search_term=" + search_term,
					{
						cache: "no-store",
						headers: { "Content-Type": "application/json" },
						method: "POST",
					},
				)
			).json();

			if (response.length > 0) {
				console.log(response);
				setOptions(response);
			} else {
			}
		};

		/*

		& Next, let's use setTimeout to debounce the function call.

		*/

		const debouncer: number = setTimeout(debouncedExecutor, 500);

		/*

		& Next, let's return a cleanup function that will clear the timeout.

		*/

		return (): void => clearTimeout(debouncer);
	};

	const submitSearch = async () => {
		setShowLast24(false);
		setShowMonths(false);
		setShowWeeks(false);
		setShowYears(false);
		const response = await (
			await fetch(process.env.PRODUCTION_API_URL + "analytics-api/single-user-views", {
				cache: "no-store",
				headers: { "Content-Type": "text/plain" },
				method: "POST",
				body: JSON.stringify({ autocompleteValue }),
			})
		).json();
		const timeSeries = response.payload;
		console.log(timeSeries);
		setLast_24_hours(timeSeries.graph_data.last_24_hours);
		setLast_7_days(timeSeries.graph_data.last_7_days);
		setLast_30_days(timeSeries.graph_data.last_30_days);
		setLast_12_months(timeSeries.graph_data.last_12_months);
		setShowWeeks(true);
		setTitle(timeSeries.title);
		setSlug(timeSeries.slug);
		setSearchButtonClicked(true);
	};

	const customDateDownload = async (startDate: any, endDate: any) => {
		setLoading(true);

		try {
			const response = await fetch(
				process.env.PRODUCTION_API_URL +
					"active-users?startDate=" +
					(startDate ? new Date(startDate) : null) +
					"&endDate=" +
					(endDate ? new Date(endDate) : null),
				{
					cache: "no-store",
					headers: { "Content-Type": "text/plain" },
				},
			);

			if (!response.ok) {
				throw new Error("Failed to fetch CSV data");
			}

			const csvData = await response.text();
			const blob = new Blob([csvData], { type: "text/csv" });
			const url = window.URL.createObjectURL(blob);
			setLoading(false);

			const a = document.createElement("a");
			a.setAttribute("href", url);
			a.setAttribute("download", "active-users.csv");
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error("Error downloading CSV:", error);
		}
	};
	if (!dailyActiveUsersData || !monthlyActiveUsersData) {
		return (
			<div style={{ width: "100%", height: "100vh", padding: "2rem" }}>
				<LoadingComponent />
			</div>
		);
	}

	return (
		<React.Fragment>
			<MainContainer>
				<TabsContainer>
					<Tabs
						value={value}
						orientation="horizontal"
						// variant="scrollable"
						scrollButtons={false}
						// allowScrollButtonsMobile
						onChange={handleChange}
						TabIndicatorProps={{
							style: {
								backgroundColor: "transparent",
							},
						}}
						sx={{
							"& .MuiTabs-flexContainer": {
								justifyContent: "flex-start",
								gap: "1rem",
								flexWrap: "wrap",
								padding: "1rem",
							},
						}}
					>
						<CustomTab
							disableRipple
							disableTouchRipple
							label={
								<TitleContentDiv>
									<TitleMain tabColor={value === 0 ? "primary" : "default"}>
										<MainTitle>Daily active users (monthly avg)</MainTitle>
									</TitleMain>
									<Title>{dailyActiveUsersGraphData.this_month_average_daily_active_users.toFixed(2)}</Title>
									<TitleAndIcon>
										{dailyActiveUsersGraphData.percentage_change_display_string.includes("down") ? (
											<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
										) : (
											<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
										)}
										{dailyActiveUsersGraphData.percentage_change_display_string}
									</TitleAndIcon>
								</TitleContentDiv>
							}
							value={0}
							tabColor={value === 0 ? "primary" : "default"}
						/>

						<CustomTab
							disableRipple
							disableTouchRipple
							label={
								<TitleContentDiv>
									<TitleMain tabColor={value === 1 ? "primary" : "default"}>
										<MainTitle>Monthly active users(yearly avg)</MainTitle>
									</TitleMain>
									<Title>{monthlyActiveUsersGraphData.this_year_average_monthly_active_users.toFixed(2)}</Title>
									<TitleAndIcon>
										{monthlyActiveUsersGraphData.percentage_change_display_string.includes("down") ? (
											<ArrowCircleDownIcon sx={{ color: "#F44336" }} />
										) : (
											<ArrowCircleUpOutlinedIcon sx={{ color: "#4CAF50" }} />
										)}
										{monthlyActiveUsersGraphData.percentage_change_display_string}
									</TitleAndIcon>
								</TitleContentDiv>
							}
							value={1}
							tabColor={value === 1 ? "primary" : "default"}
						/>
						<CustomTab
							disableRipple
							disableTouchRipple
							label={
								<TitleContentDiv
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<TitleMain tabColor={value === 2 ? "primary" : "default"}>
										<MainTitle>Top Users</MainTitle>
									</TitleMain>
								</TitleContentDiv>
							}
							value={2}
							tabColor={value === 2 ? "primary" : "default"}
						/>
						<Box
							sx={{
								padding: "0.5rem",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<Stack
									sx={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "flex-start",
										gap: "1rem",
										padding: "0rem 1rem 0rem 0rem",
									}}
								>
									<MainTitle
										sx={{
											textTransform: "uppercase",
											color: "#B05911",
											fontSize: "1.2rem",
											fontWeight: 600,
										}}
									>
										Custom Date
									</MainTitle>
									<DesktopDatePicker
										value={startDate}
										onChange={(newValue) => {
											setStartDate(newValue);
										}}
										slotProps={{
											textField: {
												placeholder: "Start Date",
												sx: {
													"& .MuiOutlinedInput-root": {
														borderRadius: "8px",
														height: "2rem",
														padding: "0rem 1rem",
														lineHeight: "0.5rem",
														"& fieldset": {
															borderColor: "#B05911",
														},
														"&:hover fieldset": {
															borderColor: "#F76C35",
														},
														"&.Mui-focused fieldset": {
															borderColor: "#F76C35",
														},
													},
													"& .MuiInputLabel-root": {
														color: "#B05911",
														top: "-10px",
													},
													"& .MuiInputLabel-root.Mui-focused": {
														color: "#F76C35",
													},
												},
											},
										}}
									/>
									<DesktopDatePicker
										sx={{
											padding: "0rem 1rem 0rem 0rem",
										}}
										value={endDate}
										onChange={(newValue) => {
											setEndDate(newValue);
										}}
										slotProps={{
											textField: {
												placeholder: "End Date",
												sx: {
													"& .MuiOutlinedInput-root": {
														borderRadius: "8px",
														height: "2rem",
														padding: "0rem 1rem",
														lineHeight: "0.5rem",
														"& fieldset": {
															borderColor: "#B05911",
														},
														"&:hover fieldset": {
															borderColor: "#F76C35",
														},
														"&.Mui-focused fieldset": {
															borderColor: "#F76C35",
														},
													},
													"& .MuiInputLabel-root": {
														color: "#B05911",
														top: "-10px",
													},
													"& .MuiInputLabel-root.Mui-focused": {
														color: "#F76C35",
													},
												},
											},
										}}
									/>
									<ViewOrUploadButton
										variant="contained"
										color="primary"
										sx={{
											padding: "0.5rem 1rem",
											textTransform: "none",
											borderRadius: "0.5rem",
											fontSize: "0.75rem",
											background: "#B05911",
											color: "#fff",
											boxShadow: "none",
											height: "2rem",
										}}
										onClick={() => {
											customDateDownload(startDate, endDate);
										}}
									>
										{loading ? (
											<CircularProgress
												size={20}
												sx={{
													color: "#ffffff",
												}}
											/>
										) : (
											"Download CSV"
										)}
									</ViewOrUploadButton>
								</Stack>
							</LocalizationProvider>
						</Box>
					</Tabs>
				</TabsContainer>
				<TabContentContainer>
					<TabPanel
						value={value}
						index={0}
					>
						<AnalyticsGraph
							title="Daily Active Users"
							graphData={dailyActiveUsersGraphData.graph}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={1}
					>
						<AnalyticsGraph
							title="Monthly Active Users"
							graphData={monthlyActiveUsersGraphData.graph}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={2}
					>
						<GraphContainer>
							<AnalyticsBarGraph
								title="Top User Views"
								graphData={topUsersViews}
							/>
							<AnalyticsBarGraph
								title="Top User Time Spent"
								graphData={topUsersTimeSpent}
							/>
						</GraphContainer>
					</TabPanel>
					{/* <ContainerForSelect>
					<Autocomplete
						disablePortal
						options={options}
						sx={{
							width: "50%",
							// padding: "1rem 0rem 0rem 2rem",
						}}
						value={autocompleteValue ? autocompleteValue : null}
						onChange={(event, value) => handleAutocompleteChange(value)}
						onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							if (event !== null) {
								handleAutocompleteInputChange(event.target.value);
							}
						}}
						getOptionLabel={(option) => option.title}
						renderInput={(params) => (
							<TextField
								sx={{
									backgroundColor: "#ffffff",
								}}
								{...params}
								label="search for a user"
							/>
						)}
					/>
					<ViewOrUploadButton
						variant="contained"
						color="primary"
						onClick={submitSearch}
					>
						Search
					</ViewOrUploadButton>
				</ContainerForSelect> */}
					{searchButtonClicked ? (
						<TabContentContainer
							sx={{
								padding: "1rem 2.5rem 2.5rem 2.5rem",
							}}
						>
							<Stack
								direction="row"
								spacing={2}
							>
								<ChipData
									label="week"
									variant="outlined"
									onClick={handleClickWeek}
									id={showWeeks ? "week" : ""}
								/>
								<ChipData
									label="month"
									variant="outlined"
									onClick={handleClickMonth}
									id={showMonths ? "month" : ""}
								/>
								<ChipData
									label="year"
									variant="outlined"
									onClick={handleClickYear}
									id={showYears ? "year" : ""}
								/>
								<ChipData
									label="last 24 hours"
									variant="outlined"
									onClick={handleClickLast24}
									id={showLast24 ? "last 24 hours" : ""}
								/>
							</Stack>
							{showWeeks ? (
								<AnalyticsGraph
									title={`${title} Views Weekly`}
									graphData={last_7_days}
								/>
							) : null}
							{showMonths ? (
								<AnalyticsGraph
									title={`${title} Views Monthly`}
									graphData={last_30_days}
								/>
							) : null}
							{showYears ? (
								<AnalyticsGraph
									title={`${title} Views Yearly`}
									graphData={last_12_months}
								/>
							) : null}
							{showLast24 ? (
								<AnalyticsGraph
									title={`${title} Views Hourly`}
									graphData={last_24_hours}
								/>
							) : null}

							{/* <AnalyticsBarGraph
							title="Top Landowner Profiles Time Spent"
							graphData={topLandownersTimeSpent}
						/> */}
						</TabContentContainer>
					) : null}
					{/* <MiddleContainerTwo>
					<MapBoxHeatMap
						GeoJSON={DashboardData.AnalyticsData?.users_area_Of_Operations}
						title="users"
						openMainResults={() => {
							setHeatmap(true);
						}}
					/>
				</MiddleContainerTwo> */}
				</TabContentContainer>
			</MainContainer>
		</React.Fragment>
	);
};

export default UserProfilesAnalytics;

/*

* This library function is written to aid in the upload of files to the server. Since the upload process consists of five distinct steps, it can be quite cumbersome to write code for each step. This library function abstracts the process of uploading files to the server and returns a promise to make it easier to use.

* The five distinct steps are:
    * Preparing the file for upload
    * Verifying the signed URL
    * Uploading the file and tracking the upload progress
    * Updating the database with the upload status at each step
    * Fetching the latest file information from the database

^ Before we begin writing the function, let's describe the parameters that the function will accept:

    ^ @param {ObjectId} sessionId - The ID of the session that the files are being uploaded from
    ^ @param {UploadFilesParamType} files - The files to be uploaded
    ^ @param {OnUploadProgressType} onUploadProgress - A callback function that will be called when the upload progress
    ^ changes - it will have the following signature:
        ^ onUploadProgress(Array<{percentage: number; fileId: ObjectId}>): void

*/

/*

? Let's begin by importing Axios, which is the main library we will use to make HTTP requests to the server. We will also import some types associated with using Axios.

*/

import axios, { AxiosProgressEvent, AxiosResponse } from "axios";

/*

? Next, we'll import the FilesInterface to help describe the type of the files parameter.

*/

/*

? Next, let's import all the types we will use to describe some of the constants we will use in this function.

*/

import { ObjectId } from "bson";
import { FilesInterface } from "../models/files";

/*

& Next, let's describe an interface to determine the shape of the data that will be returned by the function.

*/

interface UploadFilesReturnType {
	uploadedFiles: Array<FilesInterface>;
	error: boolean;
}

/*

& Next, let's describe an interface to determine the shape of the data that we will pass as a parameter to the onUploadProgress callback function.

*/

interface UploadProgressType {
	fileId: ObjectId | null;
	percentage: number;
	stage: string;
	isMultiple: boolean;
}

/*

& Next, let's describe a type to determine the signature of the onUploadProgress callback function.

*/

type OnUploadProgressType = (progress: Array<UploadProgressType>) => void;

/*

& Next, let's describe an interface to determine the shape of the array of files that the function will receive as a parameter.

*/

interface UploadFilesParamsType {
	file: File;
	mime_type: string;
	original_file_id: ObjectId;
	original_file_server_path: string;
	original: boolean;
	is_business_profile: boolean;
	business_profile_id: ObjectId;
	entity: string;
	entity_id: ObjectId;
	use_case: string;
	uiPath: string;
	isPublic: boolean;
	text?: string;
	isProfilePicture?: boolean;
}

/*

& Next, let's describe an interface to determine the shape of the API_ENDPOINTS constant which will hold the URLs of the endpoints we will use to make requests to the server.

*/

interface APIEndpointsType {
	PREPARE_FILE_UPLOAD: string;
	UPDATE_FILE_UPLOAD_STAGE: string;
	FINALIZE_FILE_UPLOAD: string;
	FETCH_FILE_INFORMATION: string;
}

/*

& Next, let's describe an interface to determine the shape of the video_metadata constant which will hold the metadata of the video file (if) that will be uploaded.

*/

interface VideoMetadataType {
	width: number;
	height: number;
	duration: number;
}

/*

& Next, let's describe an interface to determine the shape of the body that we will send to the PREPARE_FILE_UPLOAD endpoint.

*/

interface PrepareFileUploadBodyType {
	name: string;
	size: number;
	type: string;
	video_metadata: VideoMetadataType;
	webkitRelativePath: string;
	lastModified: number;
	lastModifiedDate: string;
	uiPath: string;
	isPublic: boolean;
	sessionId: string;
	addText: boolean;
	text: string;
	isProfilePicture: boolean;
	original_file_id: string;
	original_file_server_path: string;
	original_file_mime_type: string;
	original: boolean;
	is_business_profile: boolean;
	business_profile_id: string;
	entity: string;
	entity_id: string;
	use_case: string;
}

/*

& Next, let's describe an interface to determine the shape of the body that we will send to the UPDATE_FILE_UPLOAD_STAGE endpoint.

*/

interface UpdateFileUploadStageBodyType {
	fileId: string;
	sessionId: string;
	stage: string;
	isMultiple: boolean;
}

/*

& Next, we can begin writing the function.

*/

const uploadFiles: Function = async (
	isMultiple: boolean,
	sessionId: ObjectId,
	files: Array<UploadFilesParamsType>,
	onUploadProgress: OnUploadProgressType,
): Promise<UploadFilesReturnType> => {

	console.log("uploadFiles function called",isMultiple);
	/*

    & Next, let's declare a constant that will eventually hold the response.

    */

	const UploadFilesResponse: UploadFilesReturnType = {
		uploadedFiles: [],
		error: true,
	};

	/*

    * The upload process includes two pre-flight API calls to prepare the file for upload and generate the pre-signed URLs needed to commence the upload and update the file upload stage on the database followed by the actual upload and a post-flight API call to update the database with the upload stage. The Axios library allows us to use request and response interceptors to modify the request and response before they are sent and received respectively. We will use this feature to make all the API calls in just two steps.

    */

	/*

    & Next, let's describe the API endpoints that we will use.

    */

	const API_ENDPOINTS: APIEndpointsType = {
		PREPARE_FILE_UPLOAD:
			"https://alternate.beegru.com/api/upload/prepare-file-upload?security_bypass_key=" +
			(process.env.FILE_UPLOAD_API_SECURITY_BYPASS_KEY || ""),
		UPDATE_FILE_UPLOAD_STAGE:
			"https://alternate.beegru.com/api/upload/update-file-upload-stage?security_bypass_key=" +
			(process.env.FILE_UPLOAD_API_SECURITY_BYPASS_KEY || ""),
		FINALIZE_FILE_UPLOAD:
			"https://alternate.beegru.com/api/upload/finalize-file-upload?security_bypass_key=" +
			(process.env.FILE_UPLOAD_API_SECURITY_BYPASS_KEY || ""),
		FETCH_FILE_INFORMATION:
			"https://alternate.beegru.com/api/upload/fetch-file-information?security_bypass_key=" +
			(process.env.FILE_UPLOAD_API_SECURITY_BYPASS_KEY || ""),
	};

	/*

    & Next, let's create the onUploadProgress parameter array that will be passed to the onUploadProgress callback function.

    */

	const uploadProgress: Array<UploadProgressType> = files.map((file) => ({
		fileId: null,
		percentage: 0,
		stage: "NOT_STARTED",
		isMultiple: isMultiple,
	}));

	console.log("uploadProgress1", uploadProgress);

	/*

    & Next, let's map all the files and prepare the body of the request that will be sent to the PREPARE_FILE_UPLOAD endpoint.

    */

	const prepareFileUploadBodies: Array<PrepareFileUploadBodyType> = await Promise.all(
		files.map(async (file: UploadFilesParamsType, index: number) => {
			/*

        & Next, let's call the callback function to let the caller know that we are preparing the request for upload.

        */

			uploadProgress[index].stage = "PREPARING_REQUEST";
			uploadProgress[index].isMultiple = isMultiple;
			onUploadProgress(uploadProgress);

			console.log("uploadProgress2", uploadProgress);

			/*

        & Next, let's determine if the current file is a video file and then extract it's metadata if it is.

        */

			const video_metadata: VideoMetadataType = {
				width: 0,
				height: 0,
				duration: 0,
			};

			if (file.file.type.includes("video")) {
				const videoMetadata: VideoMetadataType = await new Promise((resolve: Function, reject: Function) => {
					const fileURL = URL.createObjectURL(file.file);
					const video = document.createElement("video");
					video.setAttribute("src", fileURL);
					video.addEventListener("loadedmetadata", (event: Event) => {
						resolve({
							width: video.videoWidth,
							height: video.videoHeight,
							duration: video.duration,
						});
					});
				});
				video_metadata.width = videoMetadata.width;
				video_metadata.height = videoMetadata.height;
				video_metadata.duration = videoMetadata.duration;
			}

			return {
				name: file.file.name,
				size: file.file.size,
				type: file.mime_type,
				video_metadata: video_metadata,
				webkitRelativePath: file.file.webkitRelativePath,
				lastModified: file.file.lastModified,
				lastModifiedDate: new Date(file.file.lastModified).toString(),
				uiPath: file.uiPath,
				isPublic: file.isPublic,
				sessionId: sessionId.toString(),
				addText: !!file.text,
				text: file.text ? file.text : "",
				isProfilePicture: !!file.isProfilePicture,
				original_file_id: file.original ? new ObjectId().toString() : file.original_file_id.toString(),
				original_file_server_path: file.original ? "" : file.original_file_server_path,
				original_file_mime_type: file.mime_type,
				original: file.original,
				is_business_profile: file.is_business_profile,
				business_profile_id: file.business_profile_id.toString(),
				entity: file.entity,
				entity_id: file.entity_id.toString(),
				use_case: file.use_case,
			};
		}),
	);


	/*

    & Next, let's loop through all the prepareFileUploadBodies and make a request to the PREPARE_FILE_UPLOAD endpoint.

    */

	const prepareFileUploadResponsesAxios: Array<AxiosResponse> = await Promise.all(
		prepareFileUploadBodies.map((body: PrepareFileUploadBodyType, index: number) => {
			/*

        & Next, let's call the callback function to let the caller know that we are preparing the file for upload.

        */

			uploadProgress[index].stage = "PREPARING_FILE_UPLOAD";
			uploadProgress[index].isMultiple = isMultiple;
			onUploadProgress(uploadProgress);

			console.log("uploadProgress3", uploadProgress);

			/*

        & Next, let's make a request to the PREPARE_FILE_UPLOAD endpoint and return the promise.

        */

			const response: Promise<AxiosResponse> = axios.post(API_ENDPOINTS.PREPARE_FILE_UPLOAD, JSON.stringify(body), {
				headers: {
					"Content-Type": "text/json",
					"Cache-Control": "no-store",
				},
			});

			return response;
		}),
	);

	/*

    & Next, let's extract the response data from the prepareFileUploadResponsesAxios array.

    */

	const prepareFileUploadResponses: Array<FilesInterface> = prepareFileUploadResponsesAxios.map(
		(response: AxiosResponse, index: number) => {
			/*

        & Next, let's extract the response from the API call.

        */

			const prepareFileUploadResponse: FilesInterface = response.data;

			/*

        & Next, let's call the callback function to let the caller know that we are done preparing the file for upload.

        */

			uploadProgress[index].fileId = prepareFileUploadResponse._id;
			uploadProgress[index].stage = "PREPARED_FILE_UPLOAD";
			uploadProgress[index].isMultiple = isMultiple;
			onUploadProgress(uploadProgress);

			console.log("uploadProgress4", uploadProgress);

			/*

        & Next, let's return the response from the PREPARE_FILE_UPLOAD API endpoint.

        */

			return prepareFileUploadResponse;
		},
	);

	/*

    & Next, let's loop through all the prepareFileUploadResponses and begin the file upload process.

    */

await Promise.all(
	prepareFileUploadResponses.map(async (file: FilesInterface, index: number) => {
		// Capture the current value of isMultiple
		const currentIsMultiple = isMultiple;

		// Create a new Axios instance for this file upload
		const axiosInstance = axios.create();

		// Add request interceptor to the local Axios instance
		axiosInstance.interceptors.request.use(async (requestConfig: any) => {
			if (requestConfig.method === "put") {
				const fileUploadStageUpdateBody: UpdateFileUploadStageBodyType = {
					fileId: file._id.toString(),
					sessionId: sessionId.toString(),
					stage: "uploading",
					isMultiple: currentIsMultiple, // Use the captured value
				};

				await axios.post(API_ENDPOINTS.UPDATE_FILE_UPLOAD_STAGE, JSON.stringify(fileUploadStageUpdateBody), {
					headers: {
						"Content-Type": "text/json",
						"Cache-Control": "no-store",
					},
				});

				uploadProgress[index].stage = "UPLOADING_FILE";
				uploadProgress[index].isMultiple = currentIsMultiple; // Use the captured value
				onUploadProgress(uploadProgress);

				console.log("uploadProgress5", uploadProgress);
			}

			return requestConfig;
		});

		// Add response interceptor to the local Axios instance
		axiosInstance.interceptors.response.use(async (response: any) => {
			if (response && response.config.method === "put") {
				const fileUploadStageUpdateBody: UpdateFileUploadStageBodyType = {
					fileId: file._id.toString(),
					sessionId: sessionId.toString(),
					stage: "uploaded",
					isMultiple: currentIsMultiple, // Use the captured value
				};

				await axios.post(API_ENDPOINTS.FINALIZE_FILE_UPLOAD, JSON.stringify(fileUploadStageUpdateBody), {
					headers: {
						"Content-Type": "text/json",
						"Cache-Control": "no-store",
					},
				});

				uploadProgress[index].stage = "UPLOADED_FILE";
				uploadProgress[index].isMultiple = currentIsMultiple; // Use the captured value
				onUploadProgress(uploadProgress);

				console.log("uploadProgress6", uploadProgress);
			}

			return response;
		});

		// Check if the presigned URL is still valid
		if (new Date().getTime() < new Date(file.presigned_url_expires_at).getTime()) {
			await axiosInstance.put(file.presigned_url, files[index].file, {
				headers: {
					"Content-Type": files[index].mime_type ? files[index].mime_type : files[index].file.type,
					"Cache-Control": "max-age=31536000",
				},
				onUploadProgress(progressEvent: AxiosProgressEvent): void {
					uploadProgress[index].percentage =
						(progressEvent.loaded / (progressEvent ? (progressEvent.total ? progressEvent.total : 1) : 1)) * 100;
					uploadProgress[index].isMultiple = currentIsMultiple; // Use the captured value
					onUploadProgress(uploadProgress);
				},
			});
		} else {
			uploadProgress[index].stage = "PRESIGNED_URL_EXPIRED";
			uploadProgress[index].isMultiple = currentIsMultiple; // Use the captured value
			onUploadProgress(uploadProgress);

			console.log("uploadProgress7", uploadProgress);
		}

		return file;
	}),
);

	/*

    & Next, let's fetch the latest file upload progress from the database.

    */

	const latestFileInformationResponses: Array<AxiosResponse> = await Promise.all(
		prepareFileUploadResponses.map((file: FilesInterface, index: number) => {
			/*

        & Next, let's fetch the latest file information from the database.

        */

			const response: Promise<AxiosResponse> = axios.post(
				API_ENDPOINTS.FETCH_FILE_INFORMATION,
				JSON.stringify({
					fileId: file._id.toString(),
					sessionId: sessionId.toString(),
				}),
				{
					headers: {
						"Content-Type": "text/json",
						"Cache-Control": "no-store",
					},
				},
			);

			/*

        & Next, let's return the latest file information promise.

        */

			return response;
		}),
	);

	/*

    & Next, let's extract the responses from the API calls.

    */

	const latestFileInformation: Array<FilesInterface> = latestFileInformationResponses.map(
		(response: AxiosResponse, index: number) => {
			/*

        & Next, let's extract the data from the response.

        */

			const latestFileInformation: FilesInterface = response.data;

			/*

        & Next, let's call the callback function to let the caller know that we are done fetching the latest file information.

        */

			uploadProgress[index].stage = "FETCHED_FILE_INFORMATION";
			uploadProgress[index].isMultiple = isMultiple;
			onUploadProgress(uploadProgress);

			console.log("uploadProgress8", uploadProgress);
			return latestFileInformation;
		},
	);

	/*

    & Next, let's update the return value of the function.

    */

	UploadFilesResponse.uploadedFiles = latestFileInformation;
	UploadFilesResponse.error = false;

	/*

    & Next, let's return the response.

    */

	return UploadFilesResponse;
};

/*

& Finally, let's export the function.

*/

export default uploadFiles;

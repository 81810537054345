/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import * as data from "../../../public/animation/noAccess.json";

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
						position: "relative",

}));

const YouDontHaveAccessComponent = () => {
	const theme = useTheme();
	let animationRef = React.useRef<HTMLDivElement | null>(null);
	async function getLottie() {
		const lot = await import("lottie-web");

		lot.default.loadAnimation({
			autoplay: true,
			loop: true,
			animationData: data,
			container: animationRef.current || document.createElement("div"),
		});
	}

	React.useEffect(() => {
		getLottie();
	}, []);

	return (
		<YouDontHaveAccess>
			<Box
				ref={animationRef}
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",

					// gap: "1rem",
					maxWidth: "fit-content",

				}}
			></Box>
			<Typography
				variant="h6"
				sx={{
					color: "#F76C35",
					fontSize: "1.5rem",
					fontWeight: 600,
					position: "absolute",
					top: "66%",

					display: "inline-block",
				}}
			>
				You don&apos;t have access to this page
			</Typography>

			<style>
				{`
        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-10px);
            }
            60% {
                transform: translateY(-5px);
            }
        }

        @keyframes fadeInRotate {
            0% {
                opacity: 0;
                transform: rotate(0deg); /* Start with no rotation */
            }
            50% {
                opacity: 1;
                transform: rotate(0deg); /* Fully visible, no rotation */
            }
            100% {
                opacity: 1;
                transform: rotate(-15deg); /* Rotate to -15 degrees */
            }
        }
        `}
			</style>
		</YouDontHaveAccess>
	);
};

/*

& FInally, let's export the component

*/

export default YouDontHaveAccessComponent;

import Dialog from "@mui/material/Dialog";
import React from "react";

import {
	Autocomplete,
	Box,
	Button,
	Chip,
	CircularProgress,
	CircularProgressProps,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	TextField,
	Typography,
	styled,
} from "@mui/material";

import { Cancel, Check, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ObjectId } from "bson";
import dayjs, { Dayjs } from "dayjs";
import { useRouter } from "next/router";
import uploadFiles from "../../lib/uploadFiles";
import validateFiles from "../../lib/validateFiles";


const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1.5rem 0rem 1rem 0rem",
}));

const MessageBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	boxShadow: "none",
	// border: "1px solid #B05911",
	// wordSpacing: "0.5rem",
}));

const MessageContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-end",
	// boxShadow: " 0px 1px 3px rgba(0, 0, 0, 0.12)",
	width: "100%",
	padding: "1rem",
	[theme.breakpoints.only("sm")]: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
	},
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	// wordSpacing: "0.5rem",
}));

interface FileAndMore {
	file: File;
	mime_type: string;
}

interface UploadProgress {
	fileId: ObjectId | null;
	percentage: number;
	stage: string;
	isMultiple: boolean;
}

const ProgressContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const DocumentDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	width: "100%",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
		gap: "0.5rem",
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		gap: "0.5rem",
	},
}));



const UploadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	border: theme.palette.mode == "dark" ? "2px dashed #FFFFFF" : "2px dashed #00000061",
	borderRadius: "8px",
	background: theme.palette.background.paper,
	backgroundColor: "#FEEADA",

	height: "12.5rem",
	// [theme.breakpoints.down("md")]: {
	//   display: "none",
	// },
}));

const InputContent = styled("input")(({ theme }) => ({
	display: "none",
	minWidth: "50%",
	maxWidth: "50%",
}));


const SubHeading = styled(Typography)(({ theme }) => ({
	color: "#B05911",
	fontWeight: 500,
	textTransform: "uppercase",
	fontSize: "0.875rem",
}));



const FrontDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "0rem",
	width: "100%",
}));

const CreateBlogsModal = ({
	open,
	onClose,
	data,
	user_id,
	setQueryOptions,
}: {
	open: any;
	onClose: any;
	data: any;
	user_id: any;
	setQueryOptions: any;
}) => {
	const router = React.useRef(useRouter()).current;

	const [blogId, setBlogId] = React.useState("");

	const [title, setTitle] = React.useState("");

	const [slug, setSlug] = React.useState("");

	const [faqs, setfaqs] = React.useState<Array<{ question: string; answer: [string] }>>([]);

	const [currentQuestion, setCurrentQuestion] = React.useState("");

	const [currentAnswer, setCurrentAnswer] = React.useState<string[]>([]);

	const [tags, setTags] = React.useState<string[]>([]);

	const [tagInput, setTagInput] = React.useState("");

	const [subCategories, setSubCategories] = React.useState<string[]>([]);

	const [subCategoryInput, setSubCategoryInput] = React.useState("");

	const [author, setAuthor] = React.useState({
		first_name: "",
		last_name: "",
		user_id: "",
		aura: "",
	});

	const [createdAt, setCreatedAt] = React.useState<Dayjs | null>(null);

	const [updatedAt, setUpdatedAt] = React.useState<Dayjs | null>(null);

	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

	const [fileName, setFileName] = React.useState("");

	const [linkedBlogs, setLinkedBlogs] = React.useState<string[]>([]);

	const [linkedBlogsOptions, setLinkedBlogsOptions] = React.useState<any[]>([]);

	const [content, setContent] = React.useState<Array<string>>([]);

	const [category, setCategory] = React.useState("");

	const [metaDescription, setMetaDescription] = React.useState("");

	const [metaKeywords, setMetaKeywords] = React.useState<string[]>([]);

	const [readTimeInMinutes, setReadTimeInMinutes] = React.useState<number>(0);

	const [linkedProperties, setLinkedProperties] = React.useState<string[]>([]);

	const [linkedServices, setLinkedServices] = React.useState<string[]>([]);

	const [linkedPropertiesOptions, setLinkedPropertiesOptions] = React.useState<any[]>([]);

	const [linkedServicesOptions, setLinkedServicesOptions] = React.useState<any[]>([]);

	const [linkedUsers, setLinkedUsers] = React.useState<string[]>([]);

	const [linkedUsersOptions, setLinkedUsersOptions] = React.useState<any[]>([]);

	const [percentage, setPercentage] = React.useState<Array<number>>([0]);

	const [stage, setStage] = React.useState<Array<string>>(["NOT_STARTED"]);

	const [loading, setLoading] = React.useState<boolean>(false);

	const [load, setLoad] = React.useState<boolean>(false);

	const [selectedFiles, setSelectedFiles] = React.useState<Array<FileAndMore>>([]);

	const [selectedFilePreview, setSelectedFilePreview] = React.useState<Array<string>>([]);

	const [fileId, setFileId] = React.useState<string>("");

	const [mime_type, setMime_type] = React.useState<string>("");

	const [linkedPropertiesTitle, setLinkedPropertiesTitle] = React.useState<string>("");

	const [linkedServicesTitle, setLinkedServicesTitle] = React.useState<string>("");

	const [metaTitle, setMetaTitle] = React.useState<string>("");

	const BlogInput = React.useRef<HTMLInputElement>(null);

	const usertext = React.useRef<HTMLInputElement>(null);

	const [percentageForMultiple, setPercentageForMultiple] = React.useState<Array<number>>([0]);

	const [stageForMultiple, setStageForMultiple] = React.useState<Array<string>>(["NOT_STARTED"]);

	const [loadingForMultiple, setLoadingForMultiple] = React.useState<boolean>(false);

	const [loadForMultiple, setLoadForMultiple] = React.useState<boolean>(false);

	const [selectedFilesForMultiple, setSelectedFilesForMultiple] = React.useState<Array<FileAndMore>>([]);

	const [selectedFilePreviewForMultiple, setSelectedFilePreviewForMultiple] = React.useState<Array<string>>([]);

	const [fileIdForMultiple, setFileIdForMultiple] = React.useState<Array<string>>([]);

	const [mime_typeForMultiple, setMime_typeForMultiple] = React.useState<string>("");

	const [arrayFilenames, setArrayFileNames] = React.useState<string[]>([]);

	const [sessionId, setSessionId] = React.useState<string>("");

	const [cover_image_alt_text, setCoverImageAltText] = React.useState<string>("");

	React.useEffect(() => {
		if (data) {
			// console.log(data);
			setBlogId(data._id);
			setTitle(data.title);
			setSlug(data.slug);
			setAuthor({
				first_name: data.author.first_name,
				last_name: data.author.last_name,
				user_id: data.author.user_id,
				aura: data.author.aura,
			});
			setLinkedUsers([data.author.user_id]);
			setContent(data.content);
			setCategory(data.category);
			setSubCategories(data.sub_categories);
			setTags(data.tags);
			setCreatedAt(dayjs(data.createdOn));
			setUpdatedAt(dayjs());
			setLinkedBlogs(data.linked_blogs);
			setLinkedProperties(data.linked_properties);
			setLinkedServices(data.linked_services);
			setfaqs(data.faqs);
			setMetaDescription(data.meta_description);
			setMetaKeywords(data.meta_keywords);
			setReadTimeInMinutes(data.read_time_in_minutes);
			setFileId(data.cover_image_file_id ? data.cover_image_file_id.toString() : "");
			setFileIdForMultiple(data.images.map((image: any) => image.toString()));
			setLinkedPropertiesTitle(data.linked_properties_title);
			setLinkedServicesTitle(data.linked_services_title);
			setMetaTitle(data.meta_title);
			setCoverImageAltText(data.cover_image_alt_text);
		}
	}, [data]);

	React.useEffect(() => {
		if (!open) return;

		const fetchLinkedData = async () => {
			try {
				setLoading(true);
				const [blogsResponse, propertiesResponse, servicesResponse, usersResponse] = await Promise.all([
					fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-linked-blogs", {
						method: "POST",
						body: JSON.stringify({ user_id }),
					}),
					fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-linked-properties", {
						method: "POST",
						body: JSON.stringify({ user_id }),
					}),
					fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-linked-services", {
						method: "POST",
						body: JSON.stringify({ user_id }),
					}),
					fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-users", {
						method: "POST",
						body: JSON.stringify({ user_id }),
					}),
				]);

				const [blogsData, propertiesData, servicesData, usersData] = await Promise.all([
					blogsResponse.json(),
					propertiesResponse.json(),
					servicesResponse.json(),
					usersResponse.json(),
				]);

				if (blogsData.status === "success") {
					setLinkedBlogsOptions(
						blogsData.payload?.map((blog: any) => ({
							_id: blog._id,
							title: blog.title,
						})) || [],
					);
				}

				if (propertiesData.status === "success") {
					setLinkedPropertiesOptions(
						propertiesData.payload?.map((property: any) => ({
							_id: property._id,
							title: property.title,
						})) || [],
					);
				}

				if (servicesData.status === "success") {
					setLinkedServicesOptions(
						servicesData.payload?.map((service: any) => ({
							_id: service._id,
							title: service.title,
						})) || [],
					);
				}

				if (usersData.status === "success") {
					setLinkedUsersOptions(
						usersData.payload?.map((user: any) => ({
							_id: user._id,
							title: user.first_name ? `${user.first_name} ${user.last_name}` : `${user.country_code} ${user.phone}`,
						})) || [],
					);
				}
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch linked data:", error);
			}
		};

		fetchLinkedData();
	}, [user_id, open]);

	React.useEffect(() => {
		const fetchLinkedData = async () => {
			if (fileId) {
				await fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-file-name", {
					method: "POST",
					body: JSON.stringify({ fileId: [fileId] }),
					headers: {
						"Content-Type": "text/plain",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.status === "success") {
							setFileName(data.payload);
						}
					})
					.catch((error) => {
						console.error("Failed to fetch linked properties:", error);
					});
			}

			if (fileIdForMultiple.length > 0) {
				await fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-file-name", {
					method: "POST",
					body: JSON.stringify({ fileId: fileIdForMultiple }),
					headers: {
						"Content-Type": "text/plain",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.status === "success") {
							setArrayFileNames(data.payload);
						}
					})
					.catch((error) => {
						console.error("Failed to fetch linked properties:", error);
					});
			}
		};
		if (open && (fileId || fileIdForMultiple.length > 0)) {
			fetchLinkedData();
		}
	}, [fileId, fileIdForMultiple, open]);

	React.useEffect(() => {
		const fetchLinkedData = async () => {
			if (author.user_id) {
				await fetch(process.env.PRODUCTION_API_URL + "blogs-table/fetch-sessionId", {
					method: "POST",
					body: JSON.stringify({ user_id: author.user_id }),
					headers: {
						"Content-Type": "text/plain",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.status === "success") {
							setSessionId(data.payload);
						}
					})
					.catch((error) => {
						console.error("Failed to fetch linked properties:", error);
					});
			}
		};
		if (open && author.user_id) {
			fetchLinkedData();
		}
	}, [author.user_id, open]);

	const handleUpload = async (validFiles: Array<FileAndMore>, filePreview: Array<string>) => {
		setLoad(true);
		await uploadFiles(
			false,
			sessionId,
			validFiles.map((file: FileAndMore) => ({
				file: file.file,
				mime_type: file.mime_type,
				original_file_id: new ObjectId(),
				original_file_server_path: "",
				original: true,
				is_business_profile: false,
				business_profile_id: new ObjectId(),
				entity: "business_profile",
				entity_id: new ObjectId(),
				use_case: "blogs",
				uiPath: "/",
				isPublic: false,
				text: "",
			})),
			(uploadProgress: Array<UploadProgress>) => {

				const data = uploadProgress.map((progress) => progress.fileId);
				const stageInfo = uploadProgress.map((progress) => progress.stage);
				setPercentage(uploadProgress.map((progress) => progress.percentage));
				stage[0] !== "NOT_STARTED" ? setStage([...stage, ...stageInfo]) : setStage(stageInfo);
				setFileId(
					uploadProgress
						.map((progress) => progress.fileId)
						.filter((fileId) => fileId !== null)[0]
						?.toString(),
				);
				setMime_type(validFiles[0].mime_type);
			},
		);

		setLoad(false);
	};

	const handleUploadMultipleImages = async (validFiles: Array<FileAndMore>, filePreview: Array<string>) => {
		setLoad(true);
		await uploadFiles(
			true,
			sessionId,
			validFiles.map((file: FileAndMore) => ({
				file: file.file,
				mime_type: file.mime_type,
				original_file_id: new ObjectId(),
				original_file_server_path: "",
				original: true,
				is_business_profile: false,
				business_profile_id: new ObjectId(),
				entity: "business_profile",
				entity_id: new ObjectId(),
				use_case: "blogs",
				uiPath: "/",
				isPublic: false,
				text: "",
			})),
			(uploadProgress: Array<UploadProgress>) => {

				const data = uploadProgress.map((progress) => progress.fileId);
				const stageInfo = uploadProgress.map((progress) => progress.stage);
				setPercentageForMultiple(uploadProgress.map((progress) => progress.percentage));
				stage[0] !== "NOT_STARTED" ? setStageForMultiple([...stage, ...stageInfo]) : setStageForMultiple(stageInfo);
				const newFileIds = uploadProgress
					.map((progress) => progress.fileId?.toString())
					.filter((fileId): fileId is string => fileId !== null && fileId !== undefined); // Filter out undefined values

				setFileIdForMultiple((prevFileIds) => {
					const uniqueFileIds = [...new Set([...prevFileIds, ...newFileIds])]; // Ensure uniqueness
					return uniqueFileIds;
				});

				setPercentageForMultiple(uploadProgress.map((progress) => progress.percentage));
				setStageForMultiple((prevStages) => [...prevStages, ...uploadProgress.map((progress) => progress.stage)]);
				setMime_typeForMultiple(validFiles[0].mime_type);
			},
		);

		setLoad(false);
	};

	function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
		return (
			<Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress
					variant="determinate"
					{...props}
				/>
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="caption"
						component="div"
						sx={{ color: "text.secondary" }}
					>{`${Math.round(props.value)}%`}</Typography>
				</Box>
			</Box>
		);
	}

	const handleAuthorChange = (field: string, value: string) => {
		setAuthor((prevAuthor) => ({
			...prevAuthor,
			[field]: value,
		}));
	};

	const handleSubCategoryInputChange = (event: any) => {
		setSubCategoryInput(event.target.value);
	};

	const handleSubCategoryInputKeyDown = (event: any) => {
		if (event.key === "Enter" || event.key === ",") {
			event.preventDefault();
			const newSubCategories = subCategoryInput
				.split(",")
				.map((subCategory) => subCategory.trim())
				.filter((subCategory) => subCategory !== "");
			setSubCategories([...subCategories, ...newSubCategories]);
			setSubCategoryInput("");
		}
	};

	const handleDeleteSubCategory = (subCategoryToDelete: string) => {
		setSubCategories(subCategories.filter((subCategory) => subCategory !== subCategoryToDelete));
	};

	const handleTagInputChange = (event: any) => {
		setTagInput(event.target.value);
	};

	const handleTagInputKeyDown = (event: any) => {
		if (event.key === "Enter" || event.key === ",") {
			event.preventDefault();
			const newTags = tagInput
				.split(",")
				.map((tag) => tag.trim())
				.filter((tag) => tag !== "");
			setTags([...tags, ...newTags]);
			setTagInput("");
		}
	};

	const handleDeleteTag = (tagToDelete: string) => {
		setTags(tags.filter((tag) => tag !== tagToDelete));
	};
	const handleChangeQuestion = (value: any) => {
		setCurrentQuestion(value);
	};

	const handleChangeAnswer = (value: any) => {
		const answers = value.split("\n");
		setCurrentAnswer(answers);
	};

	const add = (question: any, answer: any) => {
		setfaqs([...faqs, { question, answer }]);
		setCurrentQuestion("");
		setCurrentAnswer([]);
	};

	const handleCreatedAtChange = (date: Dayjs | null) => {
		setCreatedAt(date);
	};

	const handleUpdatedAtChange = (date: Dayjs | null) => {
		setUpdatedAt(date);
	};

	const generateSlug = (title: string) => {
		return title.toLowerCase().replace(/\s+/g, "-");
	};

	const handleTitleChange = (value: string) => {
		setTitle(value);
		setSlug(generateSlug(value));
	};

	const handleMetaKeywordsChange = (event: any) => {
		const keywords = event.target.value.split(",").map((keyword: string) => keyword.trim());
		setMetaKeywords(keywords);
	};

	const handleDeleteFaq = (index: number) => {
		setfaqs(faqs.filter((_, i) => i !== index));
	};

	const submit = async () => {
		if (data) {
			const response = await fetch(process.env.PRODUCTION_API_URL + "blogs-table/update-blog", {
				method: "POST",
				body: JSON.stringify({
					_id: blogId,
					title,
					slug,
					author,
					content,
					category,
					subCategories,
					tags,
					createdAt: createdAt,
					updatedAt: updatedAt,
					linkedBlogs,
					linkedProperties,
					linkedServices,
					faqs,
					metaDescription,
					metaKeywords,
					readTimeInMinutes,
					fileId,
					cover_image_alt_text,
					linkedPropertiesTitle,
					linkedServicesTitle,
					metaTitle,
					images: fileIdForMultiple,
				}),
			});
			const payload = await response.json();
			if (payload.status === "success") {
				onClose();
				router.reload();
			} else {
				alert("Error updating blog");
			}
		} else {
			const response = await fetch(process.env.PRODUCTION_API_URL + "blogs-table/create-blog", {
				method: "POST",
				body: JSON.stringify({
					title,
					slug,
					author,
					content,
					category,
					subCategories,
					tags,
					createdAt: createdAt,
					updatedAt: updatedAt,
					linkedBlogs,
					linkedProperties,
					linkedServices,
					faqs,
					metaDescription,
					metaKeywords,
					readTimeInMinutes,
					fileId,
					cover_image_alt_text,
					linkedPropertiesTitle,
					linkedServicesTitle,
					metaTitle,
					images: fileIdForMultiple,
				}),
			});
			const payload = await response.json();
			if (payload.status === "success") {
				onClose();
				router.reload();
			} else {
				alert("Error creating blog");
			}
		}
	};


	if (loading) {
		return (
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				fullScreen
				sx={{
					"& .MuiDialog-paper": {
						borderRadius: "1rem",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
					},
				}}
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{}}
				>
					<MainHeading>Blogs</MainHeading>
					<Divider />
					<Close
						aria-label="close"
						onClick={() => {
							onClose();
							setQueryOptions(undefined);
							setTitle("");
							setSlug("");
							setAuthor({
								first_name: "",
								last_name: "",
								user_id: "",
								aura: "",
							});
							setContent([]);
							setCategory("");
							setSubCategories([]);
							setTags([]);
							setCreatedAt(null);
							setUpdatedAt(null);
							setLinkedBlogs([]);
							setfaqs([]);
							setMetaDescription("");
							setMetaKeywords([]);
							setReadTimeInMinutes(0);
							setFileId("");
							setMetaTitle("");
							setLinkedProperties([]);
							setLinkedServices([]);
							setLinkedPropertiesTitle("");
							setLinkedServicesTitle("");
							setLinkedUsers([]);
							setSelectedFilePreview([]);
							setPercentage([]);
							setStage([]);
							setLoading(false);
							setLoad(false);
							setSelectedFilesForMultiple([]);
							setSelectedFilePreviewForMultiple([]);
							setPercentageForMultiple([]);
							setStageForMultiple([]);
							setLoadingForMultiple(false);
							setLoadForMultiple(false);
							setFileIdForMultiple([]);
							setMime_type("");
							setMime_typeForMultiple("");
							setArrayFileNames([]);
							setSessionId("");
							setCoverImageAltText("");
						}}
						sx={{
							position: "absolute",
							right: "0.5rem",
							top: "0.5rem",
							color: "#F76C35",
						}}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				<DialogContent>
					<MessageContainer>
						<MessageBoxContainer
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							<CircularProgress />
							<Typography>Loading...</Typography>
						</MessageBoxContainer>
					</MessageContainer>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog
			open={Boolean(open)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={onClose}
			fullWidth={true}
			fullScreen
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "1rem",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				},
			}}
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{}}
			>
				<MainHeading>Blogs</MainHeading>
				<Divider />
				<Close
					aria-label="close"
					onClick={() => {
						onClose();
						setQueryOptions(undefined);
						setTitle("");
						setSlug("");
						setAuthor({
							first_name: "",
							last_name: "",
							user_id: "",
							aura: "",
						});
						setContent([]);
						setCategory("");
						setSubCategories([]);
						setTags([]);
						setCreatedAt(null);
						setUpdatedAt(null);
						setLinkedBlogs([]);
						setfaqs([]);
						setMetaDescription("");
						setMetaKeywords([]);
						setReadTimeInMinutes(0);
						setFileId("");
						setMetaTitle("");
						setLinkedProperties([]);
						setLinkedServices([]);
						setLinkedPropertiesTitle("");
						setLinkedServicesTitle("");
						setLinkedUsers([]);
						setSelectedFilePreview([]);
						setPercentage([]);
						setStage([]);
						setLoading(false);
						setLoad(false);
						setSelectedFilesForMultiple([]);
						setSelectedFilePreviewForMultiple([]);
						setPercentageForMultiple([]);
						setStageForMultiple([]);
						setLoadingForMultiple(false);
						setLoadForMultiple(false);
						setFileIdForMultiple([]);
						setMime_type("");
						setMime_typeForMultiple("");
						setArrayFileNames([]);
						setSessionId("");
						setCoverImageAltText("");
					}}
					sx={{
						position: "absolute",
						right: "0.5rem",
						top: "0.5rem",
						color: "#F76C35",
					}}
				>
					<CloseIcon />
				</Close>
			</DialogTitle>
			<DialogContent>
				<DocumentDiv>
					<FrontDiv>
						<InputContent
							disabled={sessionId === ""}
							ref={BlogInput}
							type="file"
							accept="image/jpeg, image/png"
							onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
								const files = event.target.files;
								const customMimeTypes = ["image/jpeg", "image/png"];
								const customValidExtensions = ["jpg", "jpeg", "png"];
								const uploadType: string = "user_or_business";
								if (files && files.length > 0) {
									const {
										validFiles,
										invalidFiles,
									}: {
										validFiles: Array<FileAndMore>;
										invalidFiles: Array<File>;
									} = await validateFiles(Array.from(files), customMimeTypes, customValidExtensions, uploadType);
									if (validFiles.length > 0) {
										setSelectedFiles([...selectedFiles, ...validFiles]);

										const filePreview = [
											...selectedFilePreview,
											...validFiles.map((file: FileAndMore) => URL.createObjectURL(file.file)),
										];
										setSelectedFilePreview(filePreview);
										handleUpload(validFiles, filePreview);
									}
									if (invalidFiles.length > 0) {
										const fileNames: Array<string> = invalidFiles.map((file: File) => file.name);
										alert(`Invalid file types: ${fileNames.join(", ")}`);
									} else {
									}
								}
							}}
						/>
						<UploadContent
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								if (BlogInput.current) {
									BlogInput.current.click();
								}
							}}
						>
							<CloudUploadIcon
								fontSize="large"
								sx={{
									color: "#0000008A",
								}}
							/>
							Select a file or drag and drop here
							<span
								style={{
									fontSize: "0.8rem",
									color: "#00000061",
								}}
							>
								File types: JPEG or PNG; Max file size: 10MB
							</span>
							{fileName && fileName.length > 0 ? (
								<Typography
									sx={{
										color: "#000000",
										fontSize: "1rem",
										backgroundColor: "#FEEADA",
										borderRadius: "0.5rem",
										padding: "0.5rem",
										border: "1px solid #B05911",
									}}
								>
									{fileName}
								</Typography>
							) : null}
							<Button
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									if (usertext.current) {
										usertext.current.click();
									}
								}}
								// flavor="primary"
								variant="outlined"
								sx={{
									gap: "0.5rem",
									margin: "1rem 0rem 0rem 0rem",
								}}
							>
								{sessionId === "" ? "please select a user" : "Upload"}
							</Button>
							{selectedFilePreview.map((file: string, index: number) =>
								file !== "" ? (
									<ProgressContainer key={index}>
										{stage[index] && stage[index] !== "FETCHED_FILE_INFORMATION" ? (
											// <CircularProgress
											// 	size="1.5rem"
											// 	value={percentage[index]}
											// 	variant="determinate"
											// />
											<CircularProgressWithLabel value={percentage[index]} />
										) : stage[index] === undefined || stage[index] === "FETCHED_FILE_INFORMATION" ? (
											<Check
												sx={{
													color: "#4CC417",
													fontSize: "2rem",
													backgroundColor: "rgba(0, 0, 0, 0.5)",
													borderRadius: "50%",
												}}
											/>
										) : null}
									</ProgressContainer>
								) : null,
							)}
						</UploadContent>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">
								cover image alt text
							</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={cover_image_alt_text}
								placeholder="Enter your cover image alt text here"
								onChange={(event: any) => {
									setCoverImageAltText(event.target.value);
								}}
							/>
						</MessageBoxContainer>
					</FrontDiv>
				</DocumentDiv>

				<SubContainer>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: "2rem",
						}}
					>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Title</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={title}
								placeholder="Enter your title here"
								onChange={(event: any) => {
									handleTitleChange(event.target.value);
								}}
							/>
						</MessageBoxContainer>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Slug</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={slug}
								placeholder="Enter your title here"
								onChange={(event: any) => {
									setSlug(event.target.value);
								}}
							/>
						</MessageBoxContainer>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Meta title</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={metaTitle}
								placeholder="Enter your meta title here"
								onChange={(event: any) => {
									setMetaTitle(event.target.value);
								}}
							/>
						</MessageBoxContainer>

						<MessageBoxContainer
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Author </SubHeading>
							<MessageBoxContainer>
								<TextField
									sx={{
										width: "100%",
									}}
									variant="outlined"
									size="small"
									value={author.first_name}
									placeholder="First Name"
									onChange={(event: any) => handleAuthorChange("first_name", event.target.value)}
								/>
								<TextField
									sx={{
										width: "100%",
									}}
									variant="outlined"
									size="small"
									value={author.last_name}
									placeholder="Last Name"
									onChange={(event: any) => handleAuthorChange("last_name", event.target.value)}
								/>
								<Autocomplete
									ref={usertext}
									options={linkedUsersOptions}
									value={linkedUsersOptions.filter((option) => linkedUsers.includes(option._id))[0] || null}
									getOptionLabel={(option) => option.title || ""}
									onChange={(event, newValue) => {
										setLinkedUsers(newValue ? [newValue._id] : []);
										setAuthor((prevAuthor) => ({
											...prevAuthor,
											user_id: newValue ? newValue._id : "",
											first_name: newValue ? newValue.title.split(" ")[0] : "",
											last_name: newValue ? newValue.title.split(" ")[1] : "",
										}));
									}}
									renderOption={(props, option) => (
										<li
											{...props}
											key={option._id}
										>
											{option.title}
										</li>
									)}
									renderInput={(params) => (
										<TextField
											sx={{
												width: "100%",
											}}
											{...params}
											variant="outlined"
											size="small"
											placeholder="Select linked users"
										/>
									)}
									sx={{
										width: "100%",
										minWidth: "10rem",
									}}
								/>

								<TextField
									sx={{
										width: "100%",
									}}
									variant="outlined"
									size="small"
									value={author.aura}
									placeholder="Aura"
									onChange={(event: any) => handleAuthorChange("aura", event.target.value)}
								/>
							</MessageBoxContainer>
						</MessageBoxContainer>
					</div>
				</SubContainer>

				<MessageBoxContainer
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "1rem",
					}}
				>
					<SubHeading variant="h6">Content </SubHeading>
					<TextField
						variant="outlined"
						fullWidth
						multiline
						rows={10} // Adjust the number of rows as needed
						value={content.join("\n")}
						placeholder="Enter your content here"
						onChange={(event: any) => {
							setContent(event.target.value.split("\n"));
						}}
						InputProps={{
							sx: {
								"& textarea": {
									resize: "both", // Make the textarea resizable
								},
							},
						}}
					/>{" "}
				</MessageBoxContainer>
				<MessageBoxContainer
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "1rem",
					}}
				>
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
							flexWrap: "wrap",
						}}
					>
						<SubHeading variant="h6">Upload Images</SubHeading>
						{fileIdForMultiple.length >= 1
							? arrayFilenames.map((filename: string, key: number) => (
									<div
										key={key}
										style={{
											color: "#000000",
											fontSize: "1rem",
											backgroundColor: "#FEEADA",
											borderRadius: "0.5rem",
											padding: "0.5rem 0.5rem 0.5rem 0.5rem",
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											gap: "1rem",
										}}
									>
										<SubHeading>{filename}</SubHeading>
										<IconButton
											aria-label="delete"
											onClick={() => {
												setSelectedFilesForMultiple((prev) => prev.filter((_, i) => i !== key));
												setSelectedFilePreviewForMultiple((prev) => prev.filter((_, i) => i !== key));
												setPercentageForMultiple((prev) => prev.filter((_, i) => i !== key));
												setStageForMultiple((prev) => prev.filter((_, i) => i !== key));
												setFileIdForMultiple((prev) => prev.filter((_, i) => i !== key));
												setMime_typeForMultiple((prev) => (prev.length === 1 ? "" : prev));
											}}
											sx={{ color: "#FC8019" }}
										>
											<Cancel />
										</IconButton>
									</div>
								))
							: null}
					</div>
					<SubContainer>
						<input
							type="file"
							id="file"
							name="file"
							aria-label="file"
							accept="image/jpeg,image/png,image/jpg"
							multiple={true}
							style={{ display: "none" }} // Hide the default file input
							onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
								const files = event.target.files;
								const customMimeTypes = ["image/jpeg", "image/png", "image/jpg"];
								const customValidExtensions = ["jpeg", "png", "jpg"];
								const uploadType: string = "post";
								if (files && files.length > 0) {
									const {
										validFiles,
										invalidFiles,
									}: {
										validFiles: Array<FileAndMore>;
										invalidFiles: Array<File>;
									} = await validateFiles(Array.from(files), customMimeTypes, customValidExtensions, uploadType);
									if (validFiles.length > 0) {
										setSelectedFilesForMultiple([...selectedFiles, ...validFiles]);

										const filePreview = [
											...selectedFilePreviewForMultiple,
											...validFiles.map((file: FileAndMore) => URL.createObjectURL(file.file)),
										];
										setSelectedFilePreviewForMultiple(filePreview);
										handleUploadMultipleImages(validFiles, filePreview);
									}
									if (invalidFiles.length > 0) {
										const fileNames: Array<string> = invalidFiles.map((file: File) => file.name);
										alert(`Invalid file types: ${fileNames.join(", ")}`);
									}
								}
							}}
						/>
						<Button
							variant="outlined"
							onClick={() => {
								document.getElementById("file")?.click();
							}}
							sx={{
								backgroundColor: "#FEEADA", // Change the background color here
								color: "#000000",
								border: "1px solid #B05911", // Change the border color here
								gap: "0.5rem",
							}}
						>
							<CloudUploadIcon />
							Select Files
						</Button>
						{selectedFilePreviewForMultiple.map((file: string, index: number) =>
							file !== "" ? (
								<ProgressContainer key={index}>
									{stageForMultiple[index] && stageForMultiple[index] !== "FETCHED_FILE_INFORMATION" ? (
										<CircularProgressWithLabel value={percentageForMultiple[index]} />
									) : stageForMultiple[index] === undefined ||
									  stageForMultiple[index] === "FETCHED_FILE_INFORMATION" ? (
										<Check
											sx={{
												color: "#4CC417",
												fontSize: "2rem",
												backgroundColor: "rgba(0, 0, 0, 0.5)",
												borderRadius: "50%",
											}}
										/>
									) : null}
									<IconButton
										aria-label="delete"
										onClick={() => {
											setSelectedFilesForMultiple((prev) => prev.slice(0, -1));
											setSelectedFilePreviewForMultiple((prev) => prev.slice(0, -1));
											setPercentageForMultiple((prev) => prev.slice(0, -1));
											setStageForMultiple((prev) => prev.slice(0, -1));
											setFileIdForMultiple((prev) => prev.slice(0, -1));
											setMime_typeForMultiple((prev) => (prev.length === 1 ? "" : prev));
										}}
										sx={{ color: "#FC8019" }}
									>
										<DeleteIcon />
									</IconButton>
								</ProgressContainer>
							) : null,
						)}
					</SubContainer>
				</MessageBoxContainer>
				<SubContainer>
					<MessageBoxContainer>
						<SubHeading variant="h6">Categories :</SubHeading>
						<TextField
							variant="outlined"
							size="small"
							value={category}
							placeholder="Enter category"
							onChange={(event: any) => {
								setCategory(event.target.value);
							}}
						/>
					</MessageBoxContainer>
					<MessageBoxContainer>
						<SubHeading variant="h6">Meta description</SubHeading>
						<TextField
							variant="outlined"
							size="small"
							value={metaDescription}
							placeholder="Enter meta_description"
							onChange={(event: any) => {
								setMetaDescription(event.target.value);
							}}
						/>
					</MessageBoxContainer>
					<MessageBoxContainer>
						<SubHeading variant="h6">Meta keywords </SubHeading>

						<TextField
							variant="outlined"
							size="small"
							value={metaKeywords.join(",")}
							placeholder="Enter meta_keywords"
							onChange={(event: any) => {
								handleMetaKeywordsChange(event);
							}}
						/>
					</MessageBoxContainer>

					<MessageBoxContainer>
						<SubHeading variant="h6">Read time in minutes </SubHeading>

						<TextField
							variant="outlined"
							size="small"
							value={readTimeInMinutes}
							placeholder="Enter meta_keywords"
							type="number"
							onChange={(event: any) => {
								setReadTimeInMinutes(parseInt(event.target.value));
							}}
						/>
					</MessageBoxContainer>
				</SubContainer>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "2rem",
					}}
				>
					<MessageBoxContainer
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
						}}
					>
						<SubHeading variant="h6">Sub Categories</SubHeading>
						<TextField
							variant="outlined"
							size="small"
							value={subCategoryInput}
							placeholder="Enter subcategories separated by commas"
							onChange={handleSubCategoryInputChange}
							onKeyDown={handleSubCategoryInputKeyDown}
							fullWidth
						/>
						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								gap: "0.5rem",
								marginTop: "0.5rem",
							}}
						>
							{subCategories.map((subCategory, index) => (
								<Chip
									key={index}
									label={subCategory}
									onDelete={() => handleDeleteSubCategory(subCategory)}
									sx={{
										backgroundColor: "#FEEADA",
										color: "#000000",
									}}
								/>
							))}
						</Box>
					</MessageBoxContainer>
					<MessageBoxContainer
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
						}}
					>
						<SubHeading variant="h6">Linked Blogs</SubHeading>
						<Autocomplete
							multiple
							options={linkedBlogsOptions}
							value={linkedBlogsOptions.filter((option) => linkedBlogs?.includes(option._id))}
							getOptionLabel={(option) => option.title}
							onChange={(event, newValue) => {
								setLinkedBlogs(newValue.map((option) => option._id));
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									size="small"
									placeholder="Select linked blogs"
									fullWidth
								/>
							)}
							sx={{ width: "100%" }}
						/>
					</MessageBoxContainer>
					<MessageBoxContainer
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
						}}
					>
						<SubHeading variant="h6">Tags</SubHeading>
						<TextField
							variant="outlined"
							size="small"
							value={tagInput}
							placeholder="Enter tags separated by commas"
							onChange={handleTagInputChange}
							onKeyDown={handleTagInputKeyDown}
							fullWidth
						/>
						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								gap: "0.5rem",
								marginTop: "0.5rem",
							}}
						>
							{tags.map((tag, index) => (
								<Chip
									key={index}
									label={tag}
									onDelete={() => handleDeleteTag(tag)}
									sx={{
										backgroundColor: "#FEEADA",
										color: "#000000",
									}}
								/>
							))}
						</Box>
					</MessageBoxContainer>
				</div>
				<SubContainer
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "2rem",
					}}
				>
					<MessageBoxContainer
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
						}}
					>
						<SubHeading variant="h6">Linked Properties</SubHeading>
						<TextField
							variant="outlined"
							fullWidth
							size="small"
							value={linkedPropertiesTitle}
							placeholder="Enter linked properties title"
							onChange={(event: any) => {
								setLinkedPropertiesTitle(event.target.value);
							}}
							sx={{ marginBottom: "1rem" }}
						/>
						<Autocomplete
							multiple
							options={linkedPropertiesOptions}
							value={linkedPropertiesOptions.filter((option) => linkedProperties?.includes(option._id))}
							getOptionLabel={(option) => option.title || ""}
							onChange={(event, newValue) => {
								setLinkedProperties(newValue.map((option) => option._id));
							}}
							renderOption={(props, option) => (
								<li
									{...props}
									key={option._id}
								>
									{option.title}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									size="small"
									placeholder="Select linked properties"
									fullWidth
								/>
							)}
							sx={{ width: "100%" }}
						/>
					</MessageBoxContainer>
					<MessageBoxContainer
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							gap: "1rem",
						}}
					>
						<SubHeading variant="h6">Linked Services</SubHeading>
						<TextField
							variant="outlined"
							fullWidth
							size="small"
							value={linkedServicesTitle}
							placeholder="Enter linked services title"
							onChange={(event: any) => {
								setLinkedServicesTitle(event.target.value);
							}}
							sx={{ marginBottom: "1rem" }}
						/>
						<Autocomplete
							multiple
							options={linkedServicesOptions}
							value={linkedServicesOptions.filter((option) => linkedServices?.includes(option._id))}
							getOptionLabel={(option) => option.title || ""}
							onChange={(event, newValue) => {
								setLinkedServices(newValue.map((option) => option._id));
							}}
							renderOption={(props, option) => (
								<li
									{...props}
									key={option._id}
								>
									{option.title}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									size="small"
									placeholder="Select linked services"
									fullWidth
								/>
							)}
							sx={{ width: "100%" }}
						/>
					</MessageBoxContainer>
				</SubContainer>
				<SubContainer
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "2rem",
					}}
				>
					<MessageBoxContainer
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "1rem",
							width: "50%",
						}}
					>
						<SubHeading variant="h6">FAQs</SubHeading>
						<TextField
							variant="outlined"
							fullWidth
							size="small"
							value={currentQuestion}
							placeholder="Enter your question here"
							onChange={(event: any) => {
								handleChangeQuestion(event.target.value);
							}}
							sx={{ marginBottom: "1rem" }}
						/>
						<TextField
							variant="outlined"
							fullWidth
							multiline
							size="small"
							value={currentAnswer.join("\n")}
							placeholder="Enter your answer here"
							onChange={(event: any) => {
								handleChangeAnswer(event.target.value);
							}}
							InputProps={{
								sx: {
									"& textarea": {
										resize: "both", // Make the textarea resizable
									},
								},
							}}
							sx={{ marginBottom: "1rem" }}
						/>
						<ViewOrUploadButton
							variant="outlined"
							color="primary"
							sx={{
								color: "#FC8019",
								alignSelf: "flex-start",
							}}
							disabled={currentQuestion === "" || currentAnswer.length === 0}
							onClick={() => {
								add(currentQuestion, currentAnswer);
							}}
						>
							Add
						</ViewOrUploadButton>
					</MessageBoxContainer>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",

							width: "50%",
							gap: "1rem",
						}}
					>
						{faqs.map((faq, index) => (
							<Accordion
								key={index}
								sx={{
									width: "100%",
									boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
									borderRadius: "0.5rem",
									overflow: "hidden",
								}}
								defaultExpanded
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}-content`}
									id={`panel${index}-header`}
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										backgroundColor: "#f5f5f5",
										padding: "0.5rem 1rem",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "1rem",
											justifyContent: "flex-start",
											alignItems: "center",
										}}
									>
										<Typography
											sx={{
												fontWeight: 500,
												fontSize: "1rem",
											}}
										>
											{faq.question}
										</Typography>
										<IconButton
											aria-label="delete"
											onClick={() => handleDeleteFaq(index)}
											sx={{ color: "#FC8019" }}
										>
											<DeleteIcon sx={{}} />
										</IconButton>
									</div>
								</AccordionSummary>
								<AccordionDetails
									sx={{
										backgroundColor: "#fff",
										padding: "1rem",
									}}
								>
									{faq.answer.map((answer, index) => (
										<Typography
											key={index}
											sx={{
												marginBottom: "0.5rem",
											}}
										>
											{answer}
										</Typography>
									))}
								</AccordionDetails>
							</Accordion>
						))}
					</Box>
				</SubContainer>
				<SubContainer
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						width: "50%",
					}}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<MessageBoxContainer>
							<SubHeading variant="h6">Created at :</SubHeading>
							<DatePicker
								label="created at"
								value={createdAt as Dayjs}
								onChange={handleCreatedAtChange}
								slotProps={{ textField: { size: "small" } }}
							/>
						</MessageBoxContainer>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<MessageBoxContainer>
							<SubHeading variant="h6">Updated at :</SubHeading>
							<DatePicker
								label="updated at"
								slotProps={{ textField: { size: "small" } }}
								value={updatedAt}
								onChange={handleUpdatedAtChange}
							/>
						</MessageBoxContainer>
					</LocalizationProvider>
				</SubContainer>
			</DialogContent>
			<DialogActions>
				<ViewOrUploadButton
					variant="outlined"
					sx={{
						color: "#FC8019",
					}}
					autoFocus
					onClick={() => {
						onClose();
						setQueryOptions(undefined);
						setTitle("");
						setSlug("");
						setAuthor({
							first_name: "",
							last_name: "",
							user_id: "",
							aura: "",
						});
						setContent([]);
						setCategory("");
						setSubCategories([]);
						setTags([]);
						setCreatedAt(null);
						setUpdatedAt(null);
						setLinkedBlogs([]);
						setfaqs([]);
						setMetaDescription("");
						setMetaKeywords([]);
						setReadTimeInMinutes(0);
						setFileId("");
						setMetaTitle("");
						setLinkedProperties([]);
						setLinkedServices([]);
						setLinkedPropertiesTitle("");
						setLinkedServicesTitle("");
						setLinkedUsers([]);
						setSelectedFilePreview([]);
						setPercentage([]);
						setStage([]);
						setLoading(false);
						setLoad(false);
						setSelectedFilesForMultiple([]);
						setSelectedFilePreviewForMultiple([]);
						setPercentageForMultiple([]);
						setStageForMultiple([]);
						setLoadingForMultiple(false);
						setLoadForMultiple(false);
						setFileIdForMultiple([]);
						setMime_type("");
						setMime_typeForMultiple("");
						setArrayFileNames([]);
						setSessionId("");
					}}
				>
					Cancel
				</ViewOrUploadButton>
				<ViewOrUploadButton
					variant="contained"
					disabled={slug !== generateSlug(title)}
					onClick={() => {
						submit();
						onClose();
						setQueryOptions(undefined);
					}}
				>
					Save
				</ViewOrUploadButton>
			</DialogActions>
		</Dialog>
	);
};

export default CreateBlogsModal;

/* Common Imports */

import { styled } from "@mui/system";
import { merge } from "lodash";
import React from "react";

/* Component Imports */

import { Typography } from "@mui/material";
import ReactApexChart from ".";
import BaseOptionChart from "./plotOptions";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	width: "100%",
	".apexcharts-tooltip": {
		color: "#000000",
	},
	".apexcharts-text ": {
		fill: "#FFFFFF",
	},
	backgroundColor: "#ffffff",
	height: "100%",
	borderRadius: "10px",
}));

const Title = styled(Typography)(({ theme }) => ({
	padding: "1.5rem",
	fontSize: "1rem",
	fontWeight: "500",
	lineHeight: "123.5%",
	textTransform: "uppercase",
}));

const AnalyticsDonutChart = (props: any) => {
	/* x-axis data */

	const chartOptions = merge(BaseOptionChart(), {
		labels: props.graphData?.x,
		legend: {
			// width: "50%",
			show: true,
			position: "left", // Position the legend on the right side
			horizontalAlign: "center",
			verticalAlign: "center", // Align the legend items vertically
			floating: false,
			fontSize: "14px",
			offsetX: -10,
			offsetY: 0,
			formatter: function (val: any, opts: any) {
				return val + ": " + opts.w.globals.series[opts.seriesIndex];
			},
		},

		chart: {
			animations: {
				enabled: true,
			},
			offsetX: 0,
			offsetY: 0,
			top : 0,
			height: 350,
			width: "100%",
		},
		grid: {
			padding: {
				top: 20,
				right: 20,
				bottom: 20,
				left: 20,
			},
		},
	});

	const series = props.graphData?.y;

	return (
		<React.Fragment>
			<MainContainer>
				<Title variant="h6">{props.title}</Title>
				<ReactApexChart
					type={props.graph}
					series={series}
					options={chartOptions}
					height={350}
					width="100%"
				/>
			</MainContainer>
		</React.Fragment>
	);
};

export default AnalyticsDonutChart;

/*

? First, let's import the essentials - React and Styled

*/

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Box, useTheme } from "@mui/material";
import React from "react";
import * as data from "../../../public/animation/loading.json";

const LoadingComponent = () => {
	const theme = useTheme();

	let animationRef = React.useRef<HTMLDivElement | null>(null);
	async function getLottie() {
		const lot = await import("lottie-web");

		lot.default.loadAnimation({
			autoplay: true,
			loop: true,
			animationData: data,
			container: animationRef.current || document.createElement("div"),
		});
	}
	React.useEffect(() => {
		getLottie();
	}, []);

	return (
		<Box
			ref={animationRef}
			sx={{
				height: "75vh",
				padding: "1rem",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				gap: "1rem",
			}}
		>
			{/* <HourglassTopOutlinedIcon
				sx={{
					fontSize: "3rem",
					color: "#B05911",
					animation: "loadingAnimation 2s linear infinite",
				}}
			/> */}
			{/* <div ></div> */}
			{/* Loading Spinner */}
			{/* <CircularProgress
				sx={{
					color: "#B05911",
					animation: "loadingAnimation 2s linear infinite",
				}}
				size={50}
			/> */}

			{/* Loading Text */}
			{/* <Typography
				variant="h6"
				sx={{
					color: "#B05911",
					fontWeight: 600,
					animation: "fadeIn 1.5s ease-in-out infinite",
				}}
			>
				Loading, please wait...
			</Typography> */}

			{/* Add keyframes for animations */}
			{/* <style>
				{`
			@keyframes loadingAnimation {
				0% {
					background-position: 200% 0;
				}
				100% {
					background-position: -200% 0;
				}
			}

			@keyframes fadeIn {
				0%, 100% {
					opacity: 0.5;
				}
				50% {
					opacity: 1;
				}
			}
			`}
			</style> */}
		</Box>
	);
};

/*

& FInally, let's export the component

*/

export default LoadingComponent;

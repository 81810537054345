import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import { DialogContent, DialogTitle, IconButton, Link, Paper, styled, Typography } from "@mui/material";
import { ObjectId } from "bson";
import React from "react";
import Theme from "../layout-components/Theme";

const Close = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: theme.spacing(0.5),
	top: theme.spacing(0.5),
	color: theme.palette.grey[500],
}));

const SubContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	height: "15rem",
	justifyContent: "space-between",
	overflowY: "auto",
	// border: "1px solid black",
	padding: "1rem 1rem ",
	borderRadius: "16px",
	boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	background: Theme.palette.background.paper,
}));

const SubHeader = styled(Typography)(({ theme }) => ({
	fontSize: "1.2rem",
	fontWeight: "bold",
	padding: "1rem",
	color: "rgba(126, 78, 40, 0.99)",
}));

const PublishedUnpublishedLinksModal = ({
	open,
	onClose,
	businessProfileId,
}: {
	open: any;
	onClose: any;
	businessProfileId: string | ObjectId;
}) => {
	const [publishedProperties, setPublishedProperties] = React.useState<any>([]);
	const [publishedServices, setPublishedServices] = React.useState<any>([]);
	const [unpublishedProperties, setUnpublishedProperties] = React.useState<any>([]);
	const [unpublishedServices, setUnpublishedServices] = React.useState<any>([]);
	const [propertiesViewOpen, setPropertiesViewOpen] = React.useState(false);
	React.useEffect(() => {
		const fetchPublishedAndUnpublishedLinks = async () => {
			const response = await fetch(
				process.env.PRODUCTION_API_URL +
					`business-profile-table/get-published-unpublished-listings?businessProfileId=${businessProfileId}`,
			);
			const data = await response.json();
			if (!data.payload) {
				console.error("Failed to fetch published and unpublished links");
				return;
			}
			setPublishedProperties(data.payload.publishedProperties);
			setPublishedServices(data.payload.publishedServices);
			setUnpublishedProperties(data.payload.unpublishedProperties);
			setUnpublishedServices(data.payload.unpublishedServices);
		};
		fetchPublishedAndUnpublishedLinks();
	}, [businessProfileId]);

	return (
		<div>
			<Dialog
				open={Boolean(open)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onClose={onClose}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						color: "primary.secondary",
						padding: "1rem",
						borderRadius: "4px",
						fontSize: "1.5rem",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					Listings
					<Close
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</Close>
				</DialogTitle>
				{/*
				 need to add two columnfor properties and services
				 sub column for published and unpublished
				 need to add a button to view the properties and services

				*/}
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: "1rem",

						width: "100%",
					}}
				>
					<table>
						<thead>
							<tr>
								<th
									style={{
										padding: "1rem",
										border: "1px solid ",
										borderRadius: "16px",
										boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
										background: Theme.palette.background.paper,
										backgroundColor: "rgba(252, 128, 25, 0.16)",
										color: "rgba(126, 78, 40, 0.99)",
										width: "50%",
									}}
								>
									Properties ({publishedProperties.length + unpublishedProperties.length})
								</th>
								<th
									style={{
										padding: "1rem",
										border: "1px solid ",
										borderRadius: "16px",
										boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",

										background: Theme.palette.background.paper,
										backgroundColor: "rgba(230, 199, 117, 0.49)",
										color: "rgb(136, 111, 44)",
										width: "50%",
									}}
								>
									Services ({publishedServices.length + unpublishedServices.length})
								</th>
							</tr>
						</thead>
						<tbody
							style={{
								gap: "1rem",
							}}
						>
							<tr>
								<td
									style={{
										padding: "1rem",
									}}
								>
									<SubHeader>Published ({publishedProperties.length})</SubHeader>
									<SubContainer>
										{publishedProperties.map((property: any) => (
											<Link
												key={property._id}
												href={`https://beegru.com/properties/${property.slug}`}
												target="_blank"
												style={{
													textDecoration: "none",
												}}
											>
												{property.title}
											</Link>
										))}
									</SubContainer>
									<SubHeader>Unpublished ({unpublishedProperties.length})</SubHeader>
									<SubContainer>
										{unpublishedProperties.map((property: any) => (
											<Link
												key={property._id}
												href={`https://beegru.com/properties/${property.slug}`}
												target="_blank"
												style={{
													textDecoration: "none",
												}}
											>
												{property.title}
											</Link>
										))}
									</SubContainer>
								</td>
								<td
									style={{
										padding: "1rem",
									}}
								>
									<SubHeader>Published ({publishedServices.length})</SubHeader>
									<SubContainer>
										{publishedServices.map((service: any) => (
											<Link
												key={service._id}
												href={`https://beegru.com/services/${service.slug}`}
												target="_blank"
												style={{
													color: "purple",
													textDecoration: "none",
												}}
											>
												{service.title}
											</Link>
										))}
									</SubContainer>
									<SubHeader>Unpublished ({unpublishedServices.length})</SubHeader>
									<SubContainer>
										{unpublishedServices.map((service: any) => (
											<Link
												key={service._id}
												href={`https://beegru.com/services/${service.slug}`}
												target="_blank"
												style={{
													color: "purple",
													textDecoration: "none",
												}}
											>
												{service.title}
											</Link>
										))}
									</SubContainer>
								</td>
							</tr>
						</tbody>
					</table>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default PublishedUnpublishedLinksModal;

/*

? First, let's import the essentials - React and Styled

*/

import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import { Box, Paper, styled, Tab, Tabs, Typography } from "@mui/material";
import { useRouter } from "next/router";
import BeegruPointsTab from "./sub-component/BeegruPoints";
import BusinessProfilesAnalytics from "./sub-component/BusinessProfilesData";
import CampaignsUtmSourcePieChart from "./sub-component/CampaignsSourcePieGraph";
import ClicksDataSourcePieChart from "./sub-component/ClicksSourcePieGraph";
import FilesData from "./sub-component/FilesData";
import PropertiesAnalytics from "./sub-component/PropertiesData";
import SearchAnalytics from "./sub-component/SearchLogsData";
import ServicesAnalytics from "./sub-component/ServicesData";
import UsersData from "./sub-component/UsersData";

type CustomAttributesForDivElement = {
	tabColor?: string | number;
};

/*

& Next, let's define the styled components we need on this page

*/
const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	height: "100%",
}));

const TabsContainer = styled("div")(({ theme }) => ({
	width: "100%",
	position: "sticky",
	top: "0",
	zIndex: 10,
	display: "flex",
	// flexDirection: "column",
	// backgroundColor: "#fff",
}));

const CustomTab = styled(Tab, {
	shouldForwardProp: (prop: any) => prop !== "tabColor",
})<CustomAttributesForDivElement>(({ tabColor, theme }) => ({
	border: tabColor === "primary" ? "2px solid #B05911" : "none",
	textTransform: "capitalize",
	borderRadius: "8px",
	// margin: "0.5rem 0.5rem 0.5rem 0rem",
	boxShadow: "1px 1px 3px  rgba(0, 0, 0, 0.1)",
	backgroundColor: "#ffffff",
	// transition: "all 0.3s ease",
	" &.MuiTab-root": {
		"&.Mui-selected": {
			backgroundColor: "#FFF2E8",
			color: "#B05911",
		},
	},
}));

const TabContentContainer = styled("div")(({ theme }) => ({
	width: "100%",
	height: "100%",
	overflowY: "scroll",
	display: "flex",
	flexDirection: "column",
}));

const HeadContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "auto",
	// margin: "0.5rem 0.5rem 0rem 0.5rem",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	// boxShadow: "1px 1px 1px  rgba(0, 0, 0, 0.1)",
}));

const TabContent = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	// height: "calc(100vh - 8.5625rem)",
	height: "100%",
	// overflow: "auto",
	padding: "1rem 0rem 0rem 0rem",
}));
const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 1rem",
	textTransform: "capitalize",
}));
/*

& Next, let's define the tab panel component

*/

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <TabContent>{children}</TabContent>}
		</div>
	);
}

const MainAnalyticstab = ({ DashboardData }: { DashboardData: any }) => {
	/*

  & Next, let's declare the local state variables we need on this page

  */

	const [value, setValue] = React.useState(0);

	const [filesData, setFilesData] = React.useState();
	const [filesSizeData, setFilesSizeData] = React.useState();
	const [propertiesAreaOfOperations, setPropertiesAreaOfOperations] = React.useState();
	const [servicesAreaOfOperations, setServicesAreaOfOperations] = React.useState();
	const [propertyPDFDownloads, setPropertyPDFDownloads] = React.useState();
	const [servicePDFDownloads, setServicePDFDownloads] = React.useState();
	const [searchPerformedInWeeks, setSearchPerformedInWeeks] = React.useState();
	const [dailyActiveUsersGraphData, setDailyActiveUsersGraphData] = React.useState();
	const [monthlyActiveUsersGraphData, setMonthlyActiveUsersGraphData] = React.useState();
	const [topBps, setTopBps] = React.useState();
	const [topBpsTimeSpent, setTopBpsTimeSpent] = React.useState();
	const [agentsAreaOfOperations, setAgentsAreaOfOperations] = React.useState();
	const [developresAreaOfOperations, setDevelopresAreaOfOperations] = React.useState();
	const [landownerAreaOfOperations, setLandownerAreaOfOperations] = React.useState();
	const [professionalAreaOfOperations, setProfessionalAreaOfOperations] = React.useState();
	const [campaignUTMSourceData, setCampaignUTMSourceData] = React.useState<{ pi_chart: any[] } | null>(null);
	const [campaignUTMIDData, setCampaignUTMIDData] = React.useState<{ pi_chart: any[] } | null>(null);
	const [clicksData, setClicksData] = React.useState<{ pi_chart: any[] } | null>(null);
	const router = React.useRef(useRouter()).current;
	const [listingCreators, setListingCreators] = React.useState();
	const [transactionTypes, setTransactionTypes] = React.useState();
	const [propertyTypes, setPropertyTypes] = React.useState();

	const [searchLocationData, setSearchLocationData] = React.useState();
	const [searchTransactionTypeData, setSearchTransactionTypeData] = React.useState();

	const [propertyStatusData, setPropertyStatusData] = React.useState();

	/*

  & Next, let's define the event handlers we need on this page

  */

	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};

	const fetchOptions = {
		method: "POST",
		cache: "no-store" as RequestCache,
		headers: {
			"Content-Type": "text/plain",
			"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
		},
		body: JSON.stringify({}),
	};

	React.useEffect(() => {
		switch (value) {
			case 0:
				break;
			case 1:
				const fetchData = async () => {
					try {
						const [filesDataResponse, filesSizeDataResponse] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/files-data-dashboard", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/files-size-dashboard", fetchOptions),
						]);

						const filesData = await filesDataResponse.json();
						const filesSizeData = await filesSizeDataResponse.json();

						setFilesData(filesData.payload);
						setFilesSizeData(filesSizeData.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 1 && !filesData) {
					fetchData();
				}
				break;
			case 2:
				const fetchPropertyData = async () => {
					try {
						const [
							propertiesAreaOfOperationsResponse,
							propertiesPDFDownloadsForWeeks,
							listingCreatorsResponse,
							propertyTransactiontypesResponse,
							propertyTypesResponse,
							propertyStatusResponse,
						] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/properties-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/properties-pdf-weekly", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/listings-bd-user", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/transaction-types", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/property-types", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/property-status", fetchOptions),
						]);

						const propertiesAreaOfOperations = await propertiesAreaOfOperationsResponse.json();
						const propertiesPDFDownloadsForWeeksData = await propertiesPDFDownloadsForWeeks.json();
						const listingCreators = await listingCreatorsResponse.json();
						const propertyTransactiontypes = await propertyTransactiontypesResponse.json();
						const propertyTypes = await propertyTypesResponse.json();
						const propertyStatus = await propertyStatusResponse.json();
						setPropertyTypes(propertyTypes.payload);
						setTransactionTypes(propertyTransactiontypes.payload);
						setListingCreators(listingCreators.payload);
						setPropertiesAreaOfOperations(propertiesAreaOfOperations.payload);
						setPropertyPDFDownloads(propertiesPDFDownloadsForWeeksData.payload);
						setPropertyStatusData(propertyStatus.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (
					value === 2 &&
					!propertiesAreaOfOperations &&
					!propertyPDFDownloads &&
					!listingCreators &&
					!transactionTypes &&
					!propertyTypes
				) {
					fetchPropertyData();
				}
			case 3:
				const fetchServicesData = async () => {
					try {
						const [servciesAreaOfOperationsResponse, servicesPDFDownloadsForWeeks] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/services-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/service-pdf-weekly", fetchOptions),
						]);

						const servicesAreaOfOperations = await servciesAreaOfOperationsResponse.json();
						const servicesPDFDownloadsForWeeksData = await servicesPDFDownloadsForWeeks.json();
						setServicesAreaOfOperations(servicesAreaOfOperations.payload);
						setServicePDFDownloads(servicesPDFDownloadsForWeeksData.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 3 && !servicesAreaOfOperations && !servicePDFDownloads) {
					fetchServicesData();
				}
				break;

			case 4:
				const fetchBpsData = async () => {
					try {
						const [
							topBps,
							topBpsTimeSpent,
							agentsAreaOfOperations,
							developresAreaOfOperations,
							landownerAreaOfOperations,
							professionalAreaOfOperations,
						] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-business-profiles-views", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/top-business-profiles-time-spent", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/agents-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/developers-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/landowner-area-of-operations", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/professionals-area-of-operations", fetchOptions),
						]);

						const [
							topBpsRes,
							topBpsTimeSpentRes,
							agentsAreaOfOperationsRes,
							developresAreaOfOperationsRes,
							landownerAreaOfOperationsRes,
							professionalAreaOfOperationsRes,
						] = await Promise.all([
							topBps.json(),
							topBpsTimeSpent.json(),
							agentsAreaOfOperations.json(),
							developresAreaOfOperations.json(),
							landownerAreaOfOperations.json(),
							professionalAreaOfOperations.json(),
						]);
						setTopBps(topBpsRes.payload);
						setTopBpsTimeSpent(topBpsTimeSpentRes.payload);
						setAgentsAreaOfOperations(agentsAreaOfOperationsRes.payload);
						setDevelopresAreaOfOperations(developresAreaOfOperationsRes.payload);
						setLandownerAreaOfOperations(landownerAreaOfOperationsRes.payload);
						setProfessionalAreaOfOperations(professionalAreaOfOperationsRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 4 && !topBps && !topBpsTimeSpent) {
					fetchBpsData();
				}
				break;
			case 5:
				const fetchSearchData = async () => {
					try {
						const [searchPerformedInWeeksResponse, searchLocationDataResponse, searchTransactionTypeDataResponse] =
							await Promise.all([
								fetch(process.env.PRODUCTION_API_URL + "analytics-api/search-performed-weekly", fetchOptions),
								fetch(process.env.PRODUCTION_API_URL + "analytics-api/most-searched-locations", fetchOptions),
								fetch(process.env.PRODUCTION_API_URL + "analytics-api/most-searched-property-types", fetchOptions),
							]);

						const searchPerformedInWeeks = await searchPerformedInWeeksResponse.json();
						const searchLocationData = await searchLocationDataResponse.json();
						const searchTransactionTypeData = await searchTransactionTypeDataResponse.json();
						setSearchLocationData(searchLocationData.payload.MostSearchedLocations);
						setSearchTransactionTypeData(searchTransactionTypeData.payload.MostSearchedPropertyTypes);
						setSearchPerformedInWeeks(searchPerformedInWeeks.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 5 && !searchPerformedInWeeks && !searchLocationData && !searchTransactionTypeData) {
					fetchSearchData();
				}
				break;
			case 6:
				const fetchUsersData = async () => {
					try {
						const [dailyActiveUsers, monthlyActiveUsers] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/daily-active-users", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/monthly-active-users", fetchOptions),
						]);

						const dailyActiveUsersRes = await dailyActiveUsers.json();
						const monthlyActiveUsersRes = await monthlyActiveUsers.json();
						setDailyActiveUsersGraphData(dailyActiveUsersRes.payload);
						setMonthlyActiveUsersGraphData(monthlyActiveUsersRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 6 && !dailyActiveUsersGraphData && !monthlyActiveUsersGraphData) {
					fetchUsersData();
				}
				break;
			case 7:
				const fetchCampaignUTMSourceData = async () => {
					try {
						const [campaignsUTMSource, campaignsUTMID] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/campaignsUTMSource", fetchOptions),
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/campaignsUTMID", fetchOptions),
						]);

						const campaignsUTMSourceRes = await campaignsUTMSource.json();
						const campaignsUTMIDRes = await campaignsUTMID.json();
						setCampaignUTMSourceData(campaignsUTMSourceRes.payload);
						setCampaignUTMIDData(campaignsUTMIDRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 7 && !campaignUTMSourceData && !campaignUTMIDData) {
					fetchCampaignUTMSourceData();
				}
				break;
			case 8:
				const fetchClicksSourceData = async () => {
					try {
						const [clicks] = await Promise.all([
							fetch(process.env.PRODUCTION_API_URL + "analytics-api/clicks-pie-chart", fetchOptions),
						]);

						const clicksRes = await clicks.json();
						setClicksData(clicksRes.payload);
					} catch (error) {
						console.error("Error fetching data:", error);
					}
				};
				if (value === 8 && !clicksData) {
					fetchClicksSourceData();
				}
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	React.useEffect(() => {
		if (router.query.tab_id === "beegru_points") {
			setValue(0);
		} else if (router.query.tab_id === "files") {
			setValue(1);
		} else if (router.query.tab_id === "properties") {
			setValue(2);
		} else if (router.query.tab_id === "services") {
			setValue(3);
		} else if (router.query.tab_id === "business_profile") {
			setValue(4);
		} else if (router.query.tab_id === "search") {
			setValue(5);
		} else if (router.query.tab_id === "users") {
			setValue(6);
		} else if (router.query.tab_id === "campaigns") {
			setValue(7);
		} else if (router.query.tab_id === "clicks") {
			setValue(8);
		}
	}, [router.query]);

	return (
		<React.Fragment>
			<MainContainer>
				<HeadContainer elevation={0}>
					<HeadText>Analytics</HeadText>
				</HeadContainer>
				<TabsContainer>
					<Tabs
						value={value}
						orientation="horizontal"
						// variant=""
						scrollButtons={false}
						// allowScrollButtonsMobile
						onChange={handleChange}
						TabIndicatorProps={{
							style: {
								backgroundColor: "transparent",
							},
						}}
						sx={{
							"& .MuiTabs-flexContainer": {
								justifyContent: "flex-start",
								gap: "1rem",
								flexWrap: "wrap",
								padding: "1rem",
							},
						}}
					>
						<CustomTab
							label="Beegru Points"
							value={0}
							tabColor={value === 0 ? "primary" : "default"}
						/>
						<CustomTab
							label="Files"
							value={1}
							tabColor={value === 1 ? "primary" : "default"}
						/>
						<CustomTab
							label="Properties"
							value={2}
							tabColor={value === 2 ? "primary" : "default"}
						/>
						<CustomTab
							label="Services"
							value={3}
							tabColor={value === 3 ? "primary" : "default"}
						/>
						<CustomTab
							label="Business Profile"
							value={4}
							tabColor={value === 4 ? "primary" : "default"}
						/>
						<CustomTab
							label="Search"
							value={5}
							tabColor={value === 5 ? "primary" : "default"}
						/>
						<CustomTab
							label="Users"
							value={6}
							tabColor={value === 6 ? "primary" : "default"}
						/>
						<CustomTab
							label="campaigns"
							value={7}
							tabColor={value === 7 ? "primary" : "default"}
						/>
						<CustomTab
							label="Clicks"
							value={8}
							tabColor={value === 8 ? "primary" : "default"}
						/>
					</Tabs>
				</TabsContainer>
				<TabContentContainer>
					<TabPanel
						value={value}
						index={0}
						sx={{
							padding: "0rem 0rem 0rem 0rem",
							// overflow: "auto",
						}}
					>
						<BeegruPointsTab DashboardData={DashboardData} />
					</TabPanel>
					<TabPanel
						value={value}
						index={1}
					>
						<FilesData
							filesUpload={filesData}
							filesSize={filesSizeData}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={2}
					>
						<PropertiesAnalytics
							propertiesAreaOfOperations={propertiesAreaOfOperations}
							propertyPDFDownloads={propertyPDFDownloads}
							listingCreators={listingCreators}
							transactionTypes={transactionTypes}
							propertyTypes={propertyTypes}
							propertyStatus={propertyStatusData}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={3}
					>
						<ServicesAnalytics
							servicesAreaOfOperations={servicesAreaOfOperations}
							servicePDFDownloads={servicePDFDownloads}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={4}
					>
						<BusinessProfilesAnalytics
							topBusinessProfiles={topBps}
							topBusinessProfilesTimeSpent={topBpsTimeSpent}
							agentsAreaOfOperations={agentsAreaOfOperations}
							developresAreaOfOperations={developresAreaOfOperations}
							landownerAreaOfOperations={landownerAreaOfOperations}
							professionalAreaOfOperations={professionalAreaOfOperations}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={5}
					>
						<SearchAnalytics
							DashboardData={searchPerformedInWeeks}
							searchPerformedData={searchPerformedInWeeks}
							searchLocationData={searchLocationData}
							searchTransactionTypeData={searchTransactionTypeData}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={6}
					>
						<UsersData
							DashboardData={dailyActiveUsersGraphData}
							dailyActiveUsersGraphData={dailyActiveUsersGraphData}
							monthlyActiveUsersGraphData={monthlyActiveUsersGraphData}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={7}
					>
						<CampaignsUtmSourcePieChart
							campaignUTMSourceData={campaignUTMSourceData}
							campaignUTMIDData={campaignUTMIDData}
						/>
					</TabPanel>
					<TabPanel
						value={value}
						index={8}
					>
						<ClicksDataSourcePieChart clicksData={clicksData} />
					</TabPanel>
				</TabContentContainer>
			</MainContainer>
		</React.Fragment>
	);
};

export default MainAnalyticstab;

/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Chip, debounce, Divider, Link, Paper, Typography } from "@mui/material";
import { useRouter } from "next/router";
import ActionModal from "../../../modal/ActionModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "Business Profile"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "User Profile"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadText = styled(Typography, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) => ({
	fontSize: id === "subcontainer1" || id === "subcontainer2" ? "1.5rem" : "1rem",
	fontWeight: id === "subcontainer1" || id === "subcontainer2" ? "bold" : "400",
	lineHeight: "1rem",
	padding: "0rem 0.5rem 0.5rem 0rem",
	color: id === "subcontainer2" ? "#693A70" : "#6E3037",
	flexWrap: "wrap",
	whiteSpace: "wrap",
}));

const Title = styled(Typography, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) => ({
	fontSize: "1rem",
	fontWeight: "bold",
	color: "#6E3037",
	padding: "0rem 0.5rem 0.5rem 0.5rem",
	whiteSpace: "wrap",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	overflowY: "auto",
}));

const MainInnerContainer = styled("div")(({ theme }) => ({
	// height: "100%",
	width: "100%",
	padding: "1rem",
	// overflowY: "scroll",
}));
const SubMainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "3rem",
	flexWrap: "wrap",
}));
const DivMainContainer = styled("div", {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	background: id === "title" ? "#FFF7F0" : "#FEF0EF",
	borderRadius: "0.5rem",
	width: "100%",
	gap: "1rem",
	padding: "1rem",
}));
const SubContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "25rem",
	height: "100%",
	gap: "0.5rem",
	boxShadow: "none",
	borderRadius: "0.5rem",
	padding: "0.5rem",
	"&:hover": {
		boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
	},
	"&:active": {
		boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
	},
	"&:focus": {
		boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.1)",
	},
}));

const InnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: "1rem",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const ViewButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "1rem",
	fontWeight: "600",
	background: "#FFFFFF",
	color: "#6E3037",
	// border: "1px solid #6E3037",
	boxShadow: "none",
	margin: "0.5rem 0.5rem 0.5rem 0.5rem",
}));

const ActivityDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

    & Next, let's define a local state for the modal

  */

	const [openMoadal, setOpenMoadal] = React.useState(false);

	const [logMessage, setLogMessage] = React.useState<any>([]);

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.ActivityLogData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [loading, setLoading] = React.useState(true);

	const Check = DashboardData
		? DashboardData.accessLevel
			? (DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("activity_log")) ||
				DashboardData.accessLevel.includes("super_admin")
			: false
		: false;

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount || 0);
		setDashboardData(DashboardData.ActivityLogData || []);
	}, [DashboardData]);

	React.useEffect(
		() => {
			if (Check) {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[Check],
	);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "activity-log-table/activity-log-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			console.log(res);
			setDashboardData(res.payload.ActivityLogData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "log_id",
			headerName: "Log ID",
			width: 200,
		},
		{
			field: "log_creator_type",
			headerName: "Log Creator Type",
			width: 180,
			renderCell: (cellValues: any) => {
				return (
					<ChipData
						id={cellValues.value}
						label={cellValues.value}
						variant="outlined"
					/>
				);
			},
		},
		{
			field: "log_creator",
			headerName: "Log Creator ",
			width: 200,
		},
		{
			field: "action",
			headerName: "Action",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						onClick={() => {
							setOpenMoadal(true);
							setLogMessage(cellValues.value);
						}}
						sx={{
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "entity_type",
			headerName: "Entity Type",
			width: 150,
		},
		{
			field: "entity",
			headerName: "Entity",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={
							cellValues.row.entity_type === "property"
								? "https://beegru.com/properties/" + cellValues.row.entity.entity_id?.slug
								: cellValues.row.entity_type === "service"
									? "https://beegru.com/services/" + cellValues.row.entity_id?.slug
									: cellValues.row.entity_type === "business profile"
										? cellValues.row.entity?.entity_id?.business_profile_type === "agent"
											? "https://beegru.com/agents/" + cellValues.row.entity?.entity_id?.slug
											: cellValues.row.entity?.entity_id?.business_profile_type === "developer"
												? "https://beegru.com/developers/" + cellValues.row.entity?.entity_id?.slug
												: cellValues.row.entity?.entity_id?.business_profile_type === "landowner"
													? "https://beegru.com/landowners/" + cellValues.row.entity?.entity_id?.slug
													: cellValues.row.entity?.entity_id?.business_profile_type === "professional"
														? "https://beegru.com/professionals/" + cellValues.row.entity?.entity_id?.slug
														: ""
										: cellValues.row.entity_type === "user"
											? "https://beegru.com/users/" + cellValues.row.entity?.entity_id?._id
											: cellValues.row.entity_type === "post"
												? "https://beegru.com/post/" + cellValues.row.entity.entity_id?._id
												: ""
						}
						target="_blank"
						sx={{
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						{cellValues.row.entity_type === "property"
							? cellValues.row.entity?.entity_id?.title
							: cellValues.row.entity_type === "service"
								? cellValues.row.entity?.entity_id?.title
								: cellValues.row.entity_type === "business profile"
									? cellValues.row.entity?.entity_id?.title
									: cellValues.row.entity_type === "user"
										? cellValues.row.entity?.entity_id?.first_name + " " + cellValues.row.entity?.entity_id?.last_name
										: cellValues.row.entity_type === "post"
											? cellValues.row.entity?.entity_id?._id
											: ""}
					</Link>
				);
			},
		},
		{
			field: "action_performed_at",
			headerName: "Action Performed At",
			width: 200,
			filterable: false,
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Activity Log
		</Typography>,
	];

	const handleButtonClick = (tab: any, filter: any) => {
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					tab: tab,
					filter: filter,
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
	};

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Moderation</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<ActionModal
				open={openMoadal}
				onClose={() => setOpenMoadal(false)}
				logMessage={logMessage}
			/>
			<HeadContainer>
				<HeadText
					sx={{
						padding: "0rem 0rem 0.5rem 1rem",
						color: "text.primary",
					}}
				>
					Activity Log
				</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			<MainInnerContainer>
				{DashboardData.accessLevel &&
				((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("activity_log")) ||
					DashboardData.accessLevel.includes("super_admin")) ? (
					DashboardData.AnalyticsData || !DashboardData.ActivityLogData ? null : (
						<>
							<SubMainContainer>
								<SubContainer>
									<InnerContainer>
										<Title>Properties</Title>
										<ViewButton
											variant="text"
											onClick={() => {
												handleButtonClick("property", "publish_requested");
											}}
										>
											View All
										</ViewButton>
									</InnerContainer>
									<ContentContainer>
										<DivMainContainer id="title">
											<HeadText id="subcontainer1">{DashboardData.propertyPublishRequestsPendingCount}</HeadText>
											<HeadText>Publish Requested</HeadText>
										</DivMainContainer>
										<DivMainContainer id="subTitle">
											<HeadText id="subcontainer2">{DashboardData.propertyPublishRequestedAssigned}</HeadText>
											<HeadText>Not Assigned</HeadText>
										</DivMainContainer>
									</ContentContainer>
								</SubContainer>
								<SubContainer>
									<InnerContainer>
										<Title>Properties</Title>
										<ViewButton
											variant="text"
											onClick={() => {
												handleButtonClick("property", "untouched");
											}}
										>
											View All
										</ViewButton>
									</InnerContainer>
									<ContentContainer>
										<DivMainContainer id="title">
											<HeadText id="subcontainer1">{DashboardData.propertyUntouchedCount}</HeadText>

											<HeadText>Untouched</HeadText>
										</DivMainContainer>

										<DivMainContainer id="subTitle">
											<HeadText id="subcontainer2">{DashboardData.propertyUntouchedCountAssigned}</HeadText>
											<HeadText>Not Assigned</HeadText>
										</DivMainContainer>
									</ContentContainer>
								</SubContainer>
								{/* </SubMainContainer>
							<SubMainContainer> */}
								<SubContainer>
									<InnerContainer>
										<Title>Services</Title>
										<ViewButton
											variant="text"
											onClick={() => {
												handleButtonClick("service", "publish_requested");
											}}
										>
											View All
										</ViewButton>
									</InnerContainer>
									<ContentContainer>
										<DivMainContainer id="title">
											<HeadText id="subcontainer1">{DashboardData.servicePublishRequestsPendingCount}</HeadText>

											<HeadText>Publish Requested</HeadText>
										</DivMainContainer>
										<DivMainContainer id="subTitle">
											<HeadText id="subcontainer2">{DashboardData.servicePublishRequestedAssigned}</HeadText>
											<HeadText>Not Assigned</HeadText>
										</DivMainContainer>
									</ContentContainer>
								</SubContainer>

								<SubContainer>
									<InnerContainer>
										<Title>Services</Title>
										<ViewButton
											variant="text"
											onClick={() => {
												handleButtonClick("service", "untouched");
											}}
										>
											View All
										</ViewButton>
									</InnerContainer>
									<ContentContainer>
										<DivMainContainer id="title">
											<HeadText id="subcontainer1">{DashboardData.serviceUntouchedCount}</HeadText>
											<HeadText>Untouched </HeadText>
										</DivMainContainer>
										<DivMainContainer id="subTitle">
											<HeadText id="subcontainer2">{DashboardData.serviceUntouchedCountAssigned}</HeadText>
											<HeadText>Not Assigned</HeadText>
										</DivMainContainer>
									</ContentContainer>
								</SubContainer>
								{/* </SubMainContainer>
							<SubMainContainer> */}
								<SubContainer>
									<InnerContainer>
										<Title>Business Profiles KYC</Title>
										<ViewButton
											variant="text"
											onClick={() => {
												handleButtonClick("business-kyc", "kyc_pending");
											}}
										>
											View All
										</ViewButton>
									</InnerContainer>
									<ContentContainer>
										<DivMainContainer id="title">
											<HeadText id="subcontainer1">{DashboardData.businessProfileKycRequestsPendingCount}</HeadText>

											<HeadText>Request Pending</HeadText>
										</DivMainContainer>
										<DivMainContainer id="subTitle">
											<HeadText id="subcontainer2">
												{DashboardData.businessProfileKycRequestsPendingCountAssigned}
											</HeadText>
											<HeadText>Not Assigned</HeadText>
										</DivMainContainer>
									</ContentContainer>
								</SubContainer>
								<SubContainer>
									<InnerContainer>
										<Title>Business Profiles KYC</Title>
										<ViewButton
											variant="text"
											onClick={() => {
												handleButtonClick("business-kyc", "untouched");
											}}
										>
											View All
										</ViewButton>
									</InnerContainer>
									<ContentContainer>
										<DivMainContainer id="title">
											<HeadText id="subcontainer1">{DashboardData.businessProfileKycRequestsUntouchedCount}</HeadText>

											<HeadText>Request Untouched</HeadText>
										</DivMainContainer>
										<DivMainContainer id="subTitle">
											<HeadText id="subcontainer2">
												{DashboardData.businessProfileKycRequestsUntouchedCountAssigned}
											</HeadText>
											<HeadText>Not Assigned</HeadText>
										</DivMainContainer>
									</ContentContainer>
								</SubContainer>

								<Box sx={{ height: "80vh", width: "100%", margin: "0rem 0rem 1rem 0rem" }}>
									<DataGridPremium
										disableRowSelectionOnClick={false}
										slots={{ toolbar: GridToolbar }}
										rows={dashboardData?.map((item: any) => {
											return {
												id: item._id,
												log_id: item._id,
												log_creator_type: item.logs?.created_by?.is_business_profile
													? "Business Profile"
													: "User Profile",
												log_creator: item.logs?.created_by
													? item.logs?.created_by.is_business_profile
														? item.logs?.created_by.business_profile_id?.title
														: item.logs?.created_by.user_id?.first_name + " " + item.logs?.created_by.user_id?.last_name
													: "",
												action: item.logs?.action,
												entity_type: item.logs?.entity?.entity_type,
												entity: item.logs?.entity,

												action_performed_at: item.action_performed_at,
											};
										})}
										columns={columns}
										pagination={true}
										paginationMode="server"
										onPaginationModelChange={(paginationModel) => {
											setPaginationModel(paginationModel);
										}}
										rowCount={rowCountState}
										pageSizeOptions={[100]}
										filterMode="server"
										onFilterModelChange={(newFilterModel) => {
											setQueryOptions(newFilterModel);
											FilteringFunction(newFilterModel);
										}}
										sx={{
											backgroundColor: "#FFFFFF",
											"& > .MuiDataGrid-columnSeparator": {
												display: "none",
											},
											"& .MuiDataGrid-cell": {
												borderBottom: "none",
											},
										}}
									/>
								</Box>
							</SubMainContainer>
						</>
					)
				) : (
					<YouDontHaveAccessComponent />
				)}
			</MainInnerContainer>
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ActivityDatatable;

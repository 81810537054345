/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { Add, DeleteForever, ElectricBolt, Info, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Button,
	debounce,
	Divider,
	IconButton,
	Link,
	Stack,
	Switch,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import CreateBoostModal from "../../../modal/CreateBoostModal";
import DaysTableModal from "../../../modal/DaysOfWeekModal";
import LoadingModal from "../../../modal/LoadingModal";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
}));


const ButtonContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "1.5rem",
	width: "1.5rem",
}));

const AntSwitch = styled(Switch)(({ theme }) => ({}));

const BoostPricingManualOverridesDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [loadopenModal, setLoadOpenModal] = React.useState(false);

	const [tableOpen, setTableOpen] = React.useState(false);

	const [tableData, setTableData] = React.useState<object | null>();

	const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a function to handle the click on grant access button

  */

	const handleClickGrandAccess = () => {
		setOpenMoadal(true);
	};

	const handleModalOpen = () => {
		setTableOpen(true);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(
				process.env.PRODUCTION_API_URL + "boost-pricing-manual-overrides/manual-boost-pricing-filtering",
				{
					method: "POST",
					cache: "no-store",
					headers: {
						"Content-Type": "text/plain",
						"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
					},
					body: JSON.stringify({
						...router.query,
						email: session ? (session.user ? session.user.email : "") : "",
						filter: newFilterModel,
						page: paginationModel.page,
						github_username: session ? (session.user ? session.username : "") : "",
					}),
				},
			);

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	const handleDelete = async (cellValues: any) => {
		setLoadOpenModal(true);
		await fetch(process.env.PRODUCTION_API_URL + "boost-pricing-manual-overrides/delete-boost-pricing-manual", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
			},
			body: JSON.stringify({
				_id: cellValues,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		setLoadOpenModal(false);
	};

	const columns = [
		{
			field: "boostId",
			headerName: "Boost ID",
			width: 100,
		},
		{
			field: "locationTitle",
			headerName: "Location Title",
			width: 200,
			renderCell: (cellValues: any) => {
				if (!cellValues.value || !cellValues.value?.coordinates) {
					return (
						<ActionActions>
							<Typography>no location</Typography>
						</ActionActions>
					);
				}

				return (
					<ActionActions
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "space-between",
							// gap: "1rem",
							width: "100%",
							padding: "0rem 1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							<Link
								href={`https://www.google.com/maps?q=${cellValues.value?.coordinates[1]},${cellValues.value?.coordinates[0]}`}
								target="_blank"
								sx={{
									textDecoration: "none",
								}}
							>
								{cellValues.value?.title}
							</Link>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							<Tooltip
								title={<Typography>{cellValues.row?.description}</Typography>}
								placement="right"
							>
								<Info
									sx={{
										color: "#0000FF",
									}}
								/>
							</Tooltip>
						</div>
					</ActionActions>
				);
			},
		},
		{
			field: "radius",
			headerName: "Radius",
			width: 100,
		},
		{
			field: "basePrice",
			headerName: "Base Price",
			width: 100,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<img
							src="/images/icons/BeegruPoints/BeegruLogo.svg"
							alt="logo"
						/>
						{cellValues.value}
					</ActionActions>
				);
			},
		},
		{
			field: "surge_multiplier",
			headerName: "Surge Multiplier",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ElectricBolt
							sx={{
								color: "rgb(255, 191, 0)",
							}}
						/>
						{cellValues.value} x
					</ActionActions>
				);
			},
		},
		{
			field: "type",
			headerName: "Type",
			width: 120,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						{cellValues.value === "time_of_day"
							? "Time of Day"
							: cellValues.value === "days_of_week"
								? "Days of Week"
								: cellValues.value === "one_off"
									? "One Off"
									: "Permanent"}
					</ActionActions>
				);
			},
		},
		{
			field: "start_time",
			headerName: "Start Time",
			width: 200,
			renderCell: (cellValues: any) => {
				const currentHours = new Date(cellValues.value.toString()).getHours();
				const currentMinutes = new Date(cellValues.value.toString()).getMinutes();
				const ampm = currentHours >= 12 ? "pm" : "am";
				return (
					<ActionActions
						sx={{
							padding: "1rem",
						}}
					>
						{cellValues.row?.type === "time_of_day" ? (
							<Typography>
								{("0" + currentHours).slice(-2) + ":" + ("0" + currentMinutes).slice(-2) + " " + ampm}
							</Typography>
						) : cellValues.row?.type === "days_of_week" ? (
							<Button
								variant="contained"
								color="primary"
								size="small"
								sx={{
									borderRadius: "0.5rem",
									color: "#FFFFFF",
								}}
								onClick={() => {
									setTableOpen(true);
									setTableData(cellValues.row?.days_of_week);
								}}
							>
								view
							</Button>
						) : cellValues.row?.type === "one_off" ? (
							<Typography>{cellValues.value.toLocaleString("en-IN")}</Typography>
						) : (
							<Typography>Permanent</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "end_time",
			headerName: "End Time",
			width: 200,
			renderCell: (cellValues: any) => {
				const currentHours = new Date(cellValues.value.toString()).getHours();
				const currentMinutes = new Date(cellValues.value.toString()).getMinutes();
				const ampm = currentHours >= 12 ? "pm" : "am";
				return (
					<ActionActions sx={{
						padding: "1rem",
					}}>
						{cellValues.row?.type === "time_of_day" ? (
							<Typography>
								{("0" + currentHours).slice(-2) + ":" + ("0" + currentMinutes).slice(-2) + " " + ampm}
							</Typography>
						) : cellValues.row?.type === "days_of_week" ? (
							<Button
								variant="contained"
								color="primary"
								size="small"
								sx={{
									borderRadius: "0.5rem",
									color: "#FFFFFF",
								}}
								onClick={() => {
									setTableData(cellValues.row?.days_of_week);
									handleModalOpen();
								}}
							>
								view
							</Button>
						) : cellValues.row?.type === "one_off" ? (
							<Typography>{cellValues.value.toLocaleString("en-IN")}</Typography>
						) : (
							<Typography>Permanent</Typography>
						)}
					</ActionActions>
				);
			},
		},
		{
			field: "created_by",
			headerName: "Created By",
			width: 100,
		},
		{
			field: "activated",
			headerName: "Activated",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Stack
							direction="row"
							spacing={1}
							alignItems="center"
						>
							<Typography
								sx={{
									color: cellValues.value ? "#000000" : "#FF0000",
									fontWeight: cellValues.value ? "normal" : "bold",
								}}
							>
								OFF
							</Typography>
							<AntSwitch
								checked={cellValues.value}
								// defaultChecked
								inputProps={{ "aria-label": "ant design" }}
							/>
							<Typography
								sx={{
									color: cellValues.value ? "#00FF00" : "#000000",
									fontWeight: cellValues.value ? "bold" : "normal",
								}}
							>
								ON
							</Typography>
						</Stack>
					</ActionActions>
				);
			},
		},
		{
			field: "edit",
			headerName: "Edit",
			width: 120,
			renderCell: (cellValues: any) => {
				return (
					<>
						<IconButton
							onClick={() => {
								setOpenMoadal(true);
								setTableData(cellValues.value);
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</IconButton>
						<IconButton
							onClick={() => {
								handleDelete(cellValues.value ? cellValues.value._id : "");
							}}
						>
							<DeleteForever
								sx={{
									color: "#FF0000",
								}}
							/>
						</IconButton>
					</>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<ButtonContainer>
				<GridToolbarContainer>
					<GridToolbarColumnsButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarDensitySelector
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
					<GridToolbarExport
						slotProps={{
							button: {
								sx: { color: "#F76C35" },
							},
						}}
					/>
				</GridToolbarContainer>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<Add />}
					sx={{
						borderRadius: "0.5rem",
						margin: "0.2rem 0.2rem 0.2rem 0.2rem",

					}}
					onClick={() => {
						handleClickGrandAccess();
						setTableData(null);
					}}
				>
					create new boost override
				</Button>
			</ButtonContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,

		<Typography
			key="3"
			color="text.primary"
		>
			Boost Pricing Manual Overrides
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Boost Pricing Manual Overrides</HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<LoadingModal open={loadopenModal} />
			<CreateBoostModal
				open={openModal}
				onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
					// setOpenMoadal(false);
					if (reason !== "backdropClick") {
						setOpenMoadal(false);
					}
				}}
				tableData={tableData}
				session={session}
			/>
			<DaysTableModal
				open={tableOpen}
				onClose={() => {
					setTableOpen(false);
				}}
				tabledata={tableData}
			/>
			<HeadContainer>
				<HeadText>Boost Pricing Manual Overrides</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("boost_pricing_manual_overrides")) ||
				accessLevel.includes("super_admin")) ? (
				<Box
					sx={{
						height: "75vh",
						padding: "1rem",
						width: "100%",

						scrollbarColor: "#F76C35 #FFFFFF",
						"&::-webkit-scrollbar": {
							width: "0.4rem",
						},
					}}
				>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							const startDate = new Date(item.activates_at);
							const endDate = new Date(item.deactivates_at);
							return {
								id: item ? item._id : key,
								boostId: item._id,
								locationTitle: item.location,
								radius: item.applicable_radius ? item.applicable_radius : "no radius",
								basePrice: item.base_price ? item.base_price.toFixed(2) : "",
								surge_multiplier: item.static_surge_multiplier
									? item.static_surge_multiplier
									: "no static surge multiplier",
								use_static_surge_multiplier: item.use_static_surge_multiplier
									? item.use_static_surge_multiplier
									: false,
								type: item.activation_strategy ? item.activation_strategy : "no type",
								days_of_week: item.days_of_week,
								start_time: startDate,
								end_time: endDate,
								activated: item.activated,
								created_by: item.created_by
									? item.created_by.first_name
										? item.created_by.first_name
										: "no name"
									: "no name" + " " + item.created_by
										? item.created_by?.last_name
											? item.created_by.last_name
											: "--"
										: "--",
								description: item.rationale ? item.rationale : "no description",
								edit: item,
								autoHeight: true,
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default BoostPricingManualOverridesDataTable;

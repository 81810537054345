import Dialog from "@mui/material/Dialog";
import React from "react";

import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	TextField,
	Typography,
	styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useRouter } from "next/router";
import { shortenURL } from "../../lib/URLShortener";

const MainHeading = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "1.125rem",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1.5rem 0rem 1rem 0rem",
}));

const MessageBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	boxShadow: "none",
	// border: "1px solid #B05911",
	// wordSpacing: "0.5rem",
}));

const ViewOrUploadButton = styled(Button)(({ theme }) => ({
	padding: "0.5rem 1rem",
	textTransform: "none",
	borderRadius: "0.5rem",
	fontSize: "0.75rem",
	background: "primary",
	color: "#fff",
	boxShadow: "none",
}));

const SubContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	padding: "0.5rem",
	// wordSpacing: "0.5rem",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
	color: "#B05911",
	fontWeight: 500,
	textTransform: "uppercase",
	fontSize: "0.875rem",
}));

const CreateShortenUrlModal = ({
	open,
	onClose,
	edited,
	setEdited,
}: {
	open: any;
	onClose: any;
	edited: any;
	setEdited: any;
}) => {
	const router = React.useRef(useRouter()).current;

	const [keywords, setKeywords] = React.useState<string>("");

	const [title, setTitle] = React.useState<string>("");

	const [destination, setDestination] = React.useState<string>("");

	const submit = async () => {
		if (destination) {
			const res = await shortenURL(destination, title, keywords);
			if (res) {
				setEdited((prev: any) => !prev);
				onClose();
			}
		}
	};

	return (
		<Dialog
			open={Boolean(open)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "1rem",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				},
			}}
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{}}
			>
				<MainHeading>Create Shortened URL</MainHeading>
				<Divider />
				<Close
					aria-label="close"
					onClick={() => {
						onClose();
					}}
					sx={{
						position: "absolute",
						right: "0.5rem",
						top: "0.5rem",
						color: "#F76C35",
					}}
				>
					<CloseIcon />
				</Close>
			</DialogTitle>
			<DialogContent>
				<SubContainer>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: "2rem",
						}}
					>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Keyword</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={keywords}
								placeholder="Enter your keyword here"
								onChange={(event: any) => {
									setKeywords(event.target.value);
								}}
							/>
						</MessageBoxContainer>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Title</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={title}
								placeholder="Enter your title here"
								onChange={(event: any) => {
									setTitle(event.target.value);
								}}
							/>
						</MessageBoxContainer>
						<MessageBoxContainer
							sx={{
								width: "50%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
							}}
						>
							<SubHeading variant="h6">Destination</SubHeading>

							<TextField
								variant="outlined"
								size="small"
								value={destination}
								placeholder="Enter your destination here"
								onChange={(event: any) => {
									setDestination(event.target.value);
								}}
							/>
						</MessageBoxContainer>
					</div>
				</SubContainer>
			</DialogContent>
			<DialogActions>
				<ViewOrUploadButton
					variant="outlined"
					sx={{
						color: "#FC8019",
					}}
					autoFocus
					onClick={() => {
						onClose();
					}}
				>
					Cancel
				</ViewOrUploadButton>
				<ViewOrUploadButton
					disabled={!destination}
					variant="contained"
					onClick={() => {
						submit();
						onClose();
					}}
				>
					Save
				</ViewOrUploadButton>
			</DialogActions>
		</Dialog>
	);
};

export default CreateShortenUrlModal;

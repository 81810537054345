/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Chip, debounce, Divider, Link, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import LoadingComponent from "../../sub-components/LoadingComponent";
import YouDontHaveAccessComponent from "../../sub-components/YouDontHaveAccess";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "untouched"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "work_in_progress"
			? {
					backgroundColor: "rgba(33, 150, 243, 0.5)",
				}
			: id === "pending_qc"
				? {
						backgroundColor: "rgba(237, 108, 2, 0.5)",
					}
				: id === "published"
					? {
							backgroundColor: "rgba(76, 175, 80, 0.5)",
						}
					: id === "publish_requested"
						? {
								backgroundColor: "rgb(255, 191, 0)",
							}
						: id === "edited"
							? {
									backgroundColor: "#d5d5d7",
									color: "#000000",
								}
							: {
									backgroundColor: "rgba(255, 255, 255, 0.002)",
								},
);

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "80vh",
	padding: "0rem 0rem 2.5rem 0rem",
}));

const PropertyStatusDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount || 0);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	const [list_of_moderators, setListOfModerators] = React.useState(DashboardData.list_of_moderators || []);

	const [accessLevel, setAccessLevel] = React.useState(DashboardData.accessLevel || []);

		const [loading, setLoading] = React.useState(true);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		FilteringFunction(queryOptions);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "property-table/latest-changes-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
					github_username: session ? (session.user ? session.username : "") : "",
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
			setListOfModerators(res.payload.list_of_moderators);
			setAccessLevel(res.payload.accessLevel);
			setLoading(false);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "user_id",
			headerName: "User ID",
			width: 150,
			filterable: false,
		},
		{
			field: "is_employee",
			headerName: "Is Employee",
			width: 200,
		},
		{
			field: "employee_id",
			headerName: "Employee ID",
			width: 150,
			filterable: false,
		},

		{
			field: "status",
			headerName: "Status",
			width: 150,
			editable: true,

			valueFormatter: (params: any) => {
				if (!params) {
					return "untouched";
				}
				return params.value === "untouched"
					? "Untouched"
					: params.value === "work_in_progress"
						? "Work in progress"
						: params.value === "pending_qc"
							? "Pending QC"
							: params.value === "published"
								? "Published"
								: params.value === "publish_requested"
									? "Publish Requested"
									: params.value === "edited"
										? "Edited"
										: "";
			},
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((property: any) => property._id === cellValues.row.id);
				return (
					<ChipData
						id={data ? (data.status_history ? data.status_history.status : cellValues.value) : cellValues.value}
						label={
							(data ? (data.status_history ? data.status_history.status : cellValues.value) : cellValues.value) ===
							"untouched"
								? "Untouched"
								: (data ? (data.status_history ? data.status_history.status : cellValues.value) : cellValues.value) ===
									  "work_in_progress"
									? "Work in progress"
									: (data
												? data.status_history
													? data.status_history.status
													: cellValues.value
												: cellValues.value) === "pending_qc"
										? "Pending QC"
										: (data
													? data.status_history
														? data.status_history.status
														: cellValues.value
													: cellValues.value) === "published"
											? "Published"
											: (data
														? data.status_history
															? data.status_history.status
															: cellValues.value
														: cellValues.value) === "publish_requested"
												? "Publish Requested"
												: (data
															? data.status_history
																? data.status_history.status
																: cellValues.value
															: cellValues.value) === "edited"
													? "Edited"
													: ""
						}
						variant="filled"
					/>
				);
			},
		},
		{
			field: "status_changed_at",
			headerName: "Status Changed At",
			width: 180,
			filterable: false,
			type: "date" as const,
			valueGetter: (params: any) => {
				return new Date(params); // Ensure this returns a Date object
			},
			renderCell: (params: any) => {
				const date = new Date(params.value);
				return date.toLocaleString(); // Format the date as a string for display
			},
		},

		{
			field: "property_title",
			headerName: "Property Title",
			width: 250,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/properties/" + cellValues.value.slug}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value.title}
					</Link>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Latest Changes
		</Typography>,
	];

	if (loading) {
		return (
			<MainContainer>
				<HeadContainer>
					<HeadText>Latest Changes </HeadText>
					<Breadcrumbs
						separator={<NavigateNext fontSize="small" />}
						aria-label="breadcrumb"
						sx={{ margin: "0rem 0rem 0rem 1rem" }}
					>
						{breadcrumbs}
					</Breadcrumbs>
					<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
				</HeadContainer>
				<LoadingComponent />
			</MainContainer>
		);
	}

	return (
		<MainContainer>
			<HeadContainer>
				<HeadText>Latest Changes </HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{accessLevel &&
			((accessLevel.includes("admin") && accessLevel.includes("latest-changes")) ||
				accessLevel.includes("super_admin")) ? (
				<Box sx={{ height: "75vh", padding: " 1rem", width: "100%" }}>
					<DataGridPremium
						disableRowSelectionOnClick={false}
						slots={{ toolbar: GridToolbar }}
						rows={dashboardData?.map((item: any, key: number) => {
							return {
								id: item ? item._id : key,
								_id: item ? item._id : "",
								user_id: item
									? item.status_history
										? item.status_history.changed_by
											? item.status_history.changed_by.user_id
											: ""
										: ""
									: "",
								is_employee: item ? (item.status_history ? item.status_history.changed_by.is_employee : "") : "",
								employee_id: item
									? item.status_history
										? item.status_history.changed_by.employee_id.length > 0
											? item.status_history.changed_by.employee_id
											: "--"
										: "--"
									: "--",

								status: item ? (item.status_history ? item.status_history.status : "") : "",
								status_changed_at: item ? item.status_changed_at : "",
								property_title: item ? (item.status_history ? item.status_history.property_id : "") : "",
							};
						})}
						getRowId={(row) => row.id}
						columns={columns}
						pagination={true}
						paginationMode="server"
						onPaginationModelChange={(paginationModel) => {
							setPaginationModel(paginationModel);
						}}
						rowCount={rowCountState}
						pageSizeOptions={[100]}
						filterMode="server"
						onFilterModelChange={(newFilterModel) => {
							setQueryOptions(newFilterModel);
							FilteringFunction(newFilterModel);
						}}
						sx={{
							background: "#fff",
							scrollbarWidth: "thin",
							scrollbarColor: "#B05911 transparent",
							"&::-webkit-scrollbar": {
								width: "10px",
								height: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#B05911",
								borderRadius: "10px",
								border: "2px solid transparent",
								backgroundClip: "padding-box",
								transition: "background-color 0.3s ease, border-color 0.3s ease",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#8A3D0F",
								borderColor: "#8A3D0F",
							},
							"&::-webkit-scrollbar-track": {
								backgroundColor: "#F5F5F5",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-track:hover": {
								backgroundColor: "#E0E0E0",
							},
						}}
					/>
				</Box>
			) : (
				<YouDontHaveAccessComponent />
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default PropertyStatusDataTable;
